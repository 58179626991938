/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductCategoriesData
// ====================================================

export interface ProductCategoriesData_productCategories {
  __typename: "ProductCategory";
  id: string;
  name: string;
}

export interface ProductCategoriesData {
  productCategories: (ProductCategoriesData_productCategories | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasketYearOverview
// ====================================================

export interface BasketYearOverview_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface BasketYearOverview_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: BasketYearOverview_productOrders_product_category | null;
}

export interface BasketYearOverview_productOrders_deliveries_productOrder_clientOrder_client {
  __typename: "Client";
  name: string | null;
  firstName: string | null;
  email: string;
}

export interface BasketYearOverview_productOrders_deliveries_productOrder_clientOrder {
  __typename: "ClientOrder";
  id: string;
  client: BasketYearOverview_productOrders_deliveries_productOrder_clientOrder_client;
}

export interface BasketYearOverview_productOrders_deliveries_productOrder {
  __typename: "ProductOrder";
  clientOrder: BasketYearOverview_productOrders_deliveries_productOrder_clientOrder;
}

export interface BasketYearOverview_productOrders_deliveries {
  __typename: "ProductOrderDelivery";
  id: string;
  plannedDeliveryDate: any;
  deliveryDate: any | null;
  productOrder: BasketYearOverview_productOrders_deliveries_productOrder;
}

export interface BasketYearOverview_productOrders {
  __typename: "ProductOrder";
  id: string;
  product: BasketYearOverview_productOrders_product | null;
  deliveries: BasketYearOverview_productOrders_deliveries[] | null;
}

export interface BasketYearOverview {
  productOrders: (BasketYearOverview_productOrders | null)[];
}

export interface BasketYearOverviewVariables {
  farmId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HarvestDeliveryReferences
// ====================================================

export interface HarvestDeliveryReferences_productOrderDeliveries_productOrder_clientOrder_client {
  __typename: "Client";
  id: string;
  firstName: string | null;
  name: string | null;
  companyName: string | null;
}

export interface HarvestDeliveryReferences_productOrderDeliveries_productOrder_clientOrder {
  __typename: "ClientOrder";
  id: string;
  client: HarvestDeliveryReferences_productOrderDeliveries_productOrder_clientOrder_client;
}

export interface HarvestDeliveryReferences_productOrderDeliveries_productOrder {
  __typename: "ProductOrder";
  id: string;
  clientOrder: HarvestDeliveryReferences_productOrderDeliveries_productOrder_clientOrder;
}

export interface HarvestDeliveryReferences_productOrderDeliveries {
  __typename: "ProductOrderDelivery";
  deliveryLocation: DeliveryLocation | null;
  productOrder: HarvestDeliveryReferences_productOrderDeliveries_productOrder;
}

export interface HarvestDeliveryReferences_shopOrders_shopClient {
  __typename: "ShopClient";
  id: string;
  firstName: string;
  email: string;
  name: string;
}

export interface HarvestDeliveryReferences_shopOrders {
  __typename: "ShopOrder";
  deliveryLocation: DeliveryLocation;
  id: string;
  shopClient: HarvestDeliveryReferences_shopOrders_shopClient;
}

export interface HarvestDeliveryReferences_clientOrders_client {
  __typename: "Client";
  id: string;
  firstName: string | null;
  name: string | null;
  companyName: string | null;
  city: string | null;
}

export interface HarvestDeliveryReferences_clientOrders {
  __typename: "ClientOrder";
  id: string;
  client: HarvestDeliveryReferences_clientOrders_client;
}

export interface HarvestDeliveryReferences {
  productOrderDeliveries: (HarvestDeliveryReferences_productOrderDeliveries | null)[];
  shopOrders: (HarvestDeliveryReferences_shopOrders | null)[];
  clientOrders: (HarvestDeliveryReferences_clientOrders | null)[];
}

export interface HarvestDeliveryReferencesVariables {
  referenceIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClientsBillingData
// ====================================================

export interface ClientsBillingData_client_farm {
  __typename: "Farm";
  code: string;
  name: string;
}

export interface ClientsBillingData_client_clientOrders_invoice_payments {
  __typename: "Payment";
  id: string;
  paymentDate: any | null;
  dueDate: any;
  amount: number;
  paid: boolean | null;
}

export interface ClientsBillingData_client_clientOrders_invoice {
  __typename: "Invoice";
  id: string;
  file: string | null;
  createdAt: any;
  type: InvoiceType;
  number: string;
  filename: string;
  data: any | null;
  payments: ClientsBillingData_client_clientOrders_invoice_payments[] | null;
}

export interface ClientsBillingData_client_clientOrders {
  __typename: "ClientOrder";
  id: string;
  status: ClientOrderStatus;
  invoice: ClientsBillingData_client_clientOrders_invoice | null;
}

export interface ClientsBillingData_client {
  __typename: "Client";
  name: string | null;
  farm: ClientsBillingData_client_farm;
  billingEmail: string | null;
  clientOrders: ClientsBillingData_client_clientOrders[] | null;
}

export interface ClientsBillingData {
  client: ClientsBillingData_client | null;
}

export interface ClientsBillingDataVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FarmConfiguration
// ====================================================

export interface FarmConfiguration_farm_configuration {
  __typename: "FarmConfig";
  id: string;
  code: string;
  value: any | null;
}

export interface FarmConfiguration_farm {
  __typename: "Farm";
  configuration: FarmConfiguration_farm_configuration[] | null;
}

export interface FarmConfiguration {
  farm: FarmConfiguration_farm | null;
}

export interface FarmConfigurationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FarmConfigurationsByCode
// ====================================================

export interface FarmConfigurationsByCode_farmConfigs {
  __typename: "FarmConfig";
  id: string;
  code: string;
  value: any | null;
}

export interface FarmConfigurationsByCode {
  farmConfigs: (FarmConfigurationsByCode_farmConfigs | null)[];
}

export interface FarmConfigurationsByCodeVariables {
  farmId: string;
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertFarmConfiguration
// ====================================================

export interface UpsertFarmConfiguration_upsertFarmConfig {
  __typename: "FarmConfig";
  id: string;
  code: string;
  value: any | null;
}

export interface UpsertFarmConfiguration {
  upsertFarmConfig: UpsertFarmConfiguration_upsertFarmConfig;
}

export interface UpsertFarmConfigurationVariables {
  id: string;
  createData: FarmConfigCreateInput;
  updateData: FarmConfigUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFarmConfiguration
// ====================================================

export interface DeleteFarmConfiguration_deleteFarmConfig {
  __typename: "FarmConfig";
  id: string;
}

export interface DeleteFarmConfiguration {
  deleteFarmConfig: DeleteFarmConfiguration_deleteFarmConfig | null;
}

export interface DeleteFarmConfigurationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OogstfeestRegistrations
// ====================================================

export interface OogstfeestRegistrations_oogstfeestRegistrations {
  __typename: "OogstfeestRegistration";
  id: string;
  name: string;
  firstName: string;
  phone: string;
  email: string;
  buffetSlot: number | null;
  buffetAdults: number;
  buffetChildren: number;
  buffetFreeChildren: number;
  workshopAdults: number;
  workshopChildren: number;
  createdAt: any;
  paid: boolean;
  membershipId: string | null;
}

export interface OogstfeestRegistrations {
  oogstfeestRegistrations: (OogstfeestRegistrations_oogstfeestRegistrations | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOogstfeestRegistrationPaymentStatus
// ====================================================

export interface UpdateOogstfeestRegistrationPaymentStatus_updateOogstfeestRegistration {
  __typename: "OogstfeestRegistration";
  id: string;
  paid: boolean;
}

export interface UpdateOogstfeestRegistrationPaymentStatus {
  updateOogstfeestRegistration: UpdateOogstfeestRegistrationPaymentStatus_updateOogstfeestRegistration | null;
}

export interface UpdateOogstfeestRegistrationPaymentStatusVariables {
  id: string;
  paid: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOogstfeestRegistrationBuffetSlot
// ====================================================

export interface UpdateOogstfeestRegistrationBuffetSlot_updateOogstfeestRegistration {
  __typename: "OogstfeestRegistration";
  id: string;
  buffetSlot: number | null;
}

export interface UpdateOogstfeestRegistrationBuffetSlot {
  updateOogstfeestRegistration: UpdateOogstfeestRegistrationBuffetSlot_updateOogstfeestRegistration | null;
}

export interface UpdateOogstfeestRegistrationBuffetSlotVariables {
  id: string;
  buffetSlot: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveOogstfeestRegistration
// ====================================================

export interface RemoveOogstfeestRegistration_deleteOogstfeestRegistration {
  __typename: "OogstfeestRegistration";
  id: string;
}

export interface RemoveOogstfeestRegistration {
  deleteOogstfeestRegistration: RemoveOogstfeestRegistration_deleteOogstfeestRegistration | null;
}

export interface RemoveOogstfeestRegistrationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PointsData
// ====================================================

export interface PointsData_clients_clientOrders_client {
  __typename: "Client";
  email: string;
}

export interface PointsData_clients_clientOrders_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface PointsData_clients_clientOrders_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: PointsData_clients_clientOrders_productOrders_product_category | null;
  name: string;
}

export interface PointsData_clients_clientOrders_productOrders_deliveries_basketComposition_items_product {
  __typename: "Product";
  id: string;
  name: string;
}

export interface PointsData_clients_clientOrders_productOrders_deliveries_basketComposition_items {
  __typename: "BasketItem";
  id: string;
  packagingOrder: number;
  portionsSmall: number;
  portionsLarge: number;
  quantitySmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  quantityLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
  product: PointsData_clients_clientOrders_productOrders_deliveries_basketComposition_items_product;
}

export interface PointsData_clients_clientOrders_productOrders_deliveries_basketComposition {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
  status: BasketCompositionStatus;
  deliveryWeek: any | null;
  items: PointsData_clients_clientOrders_productOrders_deliveries_basketComposition_items[] | null;
}

export interface PointsData_clients_clientOrders_productOrders_deliveries {
  __typename: "ProductOrderDelivery";
  id: string;
  deliveryDate: any | null;
  plannedDeliveryDate: any;
  cancelled: boolean;
  deliveredProductIds: string[];
  undeliverableProductIds: string[];
  deliveryLocation: DeliveryLocation | null;
  basketComposition: PointsData_clients_clientOrders_productOrders_deliveries_basketComposition | null;
}

export interface PointsData_clients_clientOrders_productOrders {
  __typename: "ProductOrder";
  id: string;
  usedPortions: number | null;
  product: PointsData_clients_clientOrders_productOrders_product | null;
  portions: number | null;
  deliveries: PointsData_clients_clientOrders_productOrders_deliveries[] | null;
}

export interface PointsData_clients_clientOrders {
  __typename: "ClientOrder";
  id: string;
  orderDate: any;
  delivery: boolean;
  deliveryDate: any;
  deliveryEndDate: any | null;
  completionStatus: ClientOrderCompletionStatus | null;
  client: PointsData_clients_clientOrders_client;
  productOrders: PointsData_clients_clientOrders_productOrders[] | null;
}

export interface PointsData_clients {
  __typename: "Client";
  name: string | null;
  email: string;
  phone: string | null;
  firstName: string | null;
  clientOrders: PointsData_clients_clientOrders[] | null;
}

export interface PointsData {
  clients: (PointsData_clients | null)[];
}

export interface PointsDataVariables {
  afterDeliveryEndDate: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductOrdersForReset
// ====================================================

export interface ProductOrdersForReset_productOrders_clientOrder_client {
  __typename: "Client";
  name: string | null;
  firstName: string | null;
}

export interface ProductOrdersForReset_productOrders_clientOrder {
  __typename: "ClientOrder";
  client: ProductOrdersForReset_productOrders_clientOrder_client;
  deliveryEndDate: any | null;
  deliveryDate: any;
}

export interface ProductOrdersForReset_productOrders_deliveries_basketComposition {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
}

export interface ProductOrdersForReset_productOrders_deliveries {
  __typename: "ProductOrderDelivery";
  plannedDeliveryDate: any;
  basketComposition: ProductOrdersForReset_productOrders_deliveries_basketComposition | null;
}

export interface ProductOrdersForReset_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface ProductOrdersForReset_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: ProductOrdersForReset_productOrders_product_category | null;
}

export interface ProductOrdersForReset_productOrders {
  __typename: "ProductOrder";
  id: string;
  clientOrder: ProductOrdersForReset_productOrders_clientOrder;
  deliveries: ProductOrdersForReset_productOrders_deliveries[] | null;
  product: ProductOrdersForReset_productOrders_product | null;
  portions: number | null;
  usedPortions: number | null;
}

export interface ProductOrdersForReset {
  productOrders: (ProductOrdersForReset_productOrders | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllOrders
// ====================================================

export interface AllOrders_clientOrders_client {
  __typename: "Client";
  id: string;
}

export interface AllOrders_clientOrders_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface AllOrders_clientOrders_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: AllOrders_clientOrders_productOrders_product_category | null;
  name: string;
  amount: number | null;
  unit: ProductUnit | null;
}

export interface AllOrders_clientOrders_productOrders {
  __typename: "ProductOrder";
  id: string;
  product: AllOrders_clientOrders_productOrders_product | null;
  priceExcl: number;
  quantity: number;
  remark: string | null;
}

export interface AllOrders_clientOrders_invoice_payments {
  __typename: "Payment";
  id: string;
  paymentDate: any | null;
  dueDate: any;
  amount: number;
  paid: boolean | null;
}

export interface AllOrders_clientOrders_invoice {
  __typename: "Invoice";
  id: string;
  createdAt: any;
  type: InvoiceType;
  number: string;
  filename: string;
  file: string | null;
  data: any | null;
  payments: AllOrders_clientOrders_invoice_payments[] | null;
}

export interface AllOrders_clientOrders {
  __typename: "ClientOrder";
  id: string;
  createdAt: any;
  updatedAt: any;
  orderDate: any;
  deliveryDate: any;
  delivery: boolean;
  status: ClientOrderStatus;
  orderDiscount: number | null;
  client: AllOrders_clientOrders_client;
  productOrders: AllOrders_clientOrders_productOrders[] | null;
  invoice: AllOrders_clientOrders_invoice | null;
}

export interface AllOrders {
  clientOrders: (AllOrders_clientOrders | null)[];
}

export interface AllOrdersVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInvoiceRecipient
// ====================================================

export interface UpdateInvoiceRecipient_updateInvoice {
  __typename: "Invoice";
  id: string;
}

export interface UpdateInvoiceRecipient {
  updateInvoice: UpdateInvoiceRecipient_updateInvoice | null;
}

export interface UpdateInvoiceRecipientVariables {
  invoiceId: string;
  invoiceData: InvoiceUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PromoData
// ====================================================

export interface PromoData_productPromoes {
  __typename: "ProductPromo";
  id: string;
  startDate: any;
  endDate: any | null;
  type: ProductPromoType;
  value: number;
  secondValue: number | null;
}

export interface PromoData {
  productPromoes: (PromoData_productPromoes | null)[];
}

export interface PromoDataVariables {
  productId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertPromotion
// ====================================================

export interface UpsertPromotion_updateProduct_productPromos {
  __typename: "ProductPromo";
  id: string;
  startDate: any;
  endDate: any | null;
  type: ProductPromoType;
  value: number;
  secondValue: number | null;
}

export interface UpsertPromotion_updateProduct {
  __typename: "Product";
  id: string;
  productPromos: UpsertPromotion_updateProduct_productPromos[] | null;
}

export interface UpsertPromotion {
  updateProduct: UpsertPromotion_updateProduct | null;
}

export interface UpsertPromotionVariables {
  promoId: string;
  productId: string;
  startDate: any;
  endDate?: any | null;
  type: ProductPromoType;
  value: number;
  secondValue?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PricelistData
// ====================================================

export interface PricelistData_clientGroups {
  __typename: "ClientGroup";
  id: string;
  name: string;
  code: string | null;
  profitMargin: number;
  order: number;
  default: boolean | null;
}

export interface PricelistData_products_category {
  __typename: "ProductCategory";
  id: string;
  name: string;
}

export interface PricelistData_products_productPrices_clientGroup {
  __typename: "ClientGroup";
  id: string;
  code: string | null;
  default: boolean | null;
}

export interface PricelistData_products_productPrices {
  __typename: "ProductPrice";
  id: string;
  clientGroup: PricelistData_products_productPrices_clientGroup | null;
  value: number;
  base: boolean | null;
  endDate: any | null;
  note: string | null;
  unit: PriceUnit;
}

export interface PricelistData_products_productAvailabilities_clientGroup {
  __typename: "ClientGroup";
  id: string;
  code: string | null;
}

export interface PricelistData_products_productAvailabilities {
  __typename: "ProductAvailability";
  id: string;
  clientGroup: PricelistData_products_productAvailabilities_clientGroup;
  startDate: any;
  endDate: any | null;
  note: string | null;
}

export interface PricelistData_products_productPromos {
  __typename: "ProductPromo";
  id: string;
  startDate: any;
  endDate: any | null;
}

export interface PricelistData_products {
  __typename: "Product";
  id: string;
  code: string | null;
  name: string;
  image: string | null;
  unit: ProductUnit | null;
  amount: number | null;
  availableAsAlternativeUnitFor: string[];
  alternativeUnit: ProductUnit | null;
  minOrderQuantity: number | null;
  category: PricelistData_products_category | null;
  productPrices: PricelistData_products_productPrices[] | null;
  productAvailabilities: PricelistData_products_productAvailabilities[] | null;
  productPromos: PricelistData_products_productPromos[] | null;
  portionWillem: number | null;
  portionDirk: number | null;
  type: ProductType;
  groupName: string | null;
  extra: string | null;
  avgWeight: number | null;
  status: ProductStatus;
  vat: Vat;
}

export interface PricelistData {
  clientGroups: (PricelistData_clientGroups | null)[];
  products: (PricelistData_products | null)[];
}

export interface PricelistDataVariables {
  farmId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SalesData
// ====================================================

export interface SalesData_sequences {
  __typename: "Sequence";
  id: string;
  sequenceType: SequenceType;
  prefix: string;
  nextValue: number;
  season: string | null;
}

export interface SalesData_clientOrders_client_group {
  __typename: "ClientGroup";
  id: string;
}

export interface SalesData_clientOrders_client {
  __typename: "Client";
  id: string;
  companyName: string | null;
  name: string | null;
  firstName: string | null;
  street: string | null;
  streetNumber: string | null;
  cityPostalCode: string | null;
  city: string | null;
  type: ClientType | null;
  vatNumber: string | null;
  email: string;
  billingEmail: string | null;
  group: SalesData_clientOrders_client_group;
}

export interface SalesData_clientOrders_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface SalesData_clientOrders_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: SalesData_clientOrders_productOrders_product_category | null;
  name: string;
  extra: string | null;
  amount: number | null;
  unit: ProductUnit | null;
  vat: Vat;
  type: ProductType;
  availableAsAlternativeUnitFor: string[];
  alternativeUnit: ProductUnit | null;
  avgWeight: number | null;
}

export interface SalesData_clientOrders_productOrders_deliveries {
  __typename: "ProductOrderDelivery";
  plannedDeliveryDate: any;
}

export interface SalesData_clientOrders_productOrders {
  __typename: "ProductOrder";
  id: string;
  productDescription: string | null;
  product: SalesData_clientOrders_productOrders_product | null;
  priceExcl: number;
  quantity: number;
  remark: string | null;
  ambassadorDiscount: boolean;
  deliveries: SalesData_clientOrders_productOrders_deliveries[] | null;
}

export interface SalesData_clientOrders_invoice_payments {
  __typename: "Payment";
  id: string;
  paymentDate: any | null;
  dueDate: any;
  amount: number;
  paid: boolean | null;
}

export interface SalesData_clientOrders_invoice {
  __typename: "Invoice";
  id: string;
  createdAt: any;
  type: InvoiceType;
  number: string;
  filename: string;
  file: string | null;
  data: any | null;
  payments: SalesData_clientOrders_invoice_payments[] | null;
}

export interface SalesData_clientOrders {
  __typename: "ClientOrder";
  id: string;
  createdAt: any;
  updatedAt: any;
  orderDate: any;
  deliveryDate: any;
  deliveryEndDate: any | null;
  delivery: boolean;
  status: ClientOrderStatus;
  orderDiscount: number | null;
  client: SalesData_clientOrders_client;
  productOrders: SalesData_clientOrders_productOrders[] | null;
  invoice: SalesData_clientOrders_invoice | null;
}

export interface SalesData_clientGroups {
  __typename: "ClientGroup";
  id: string;
  name: string;
  profitMargin: number;
  order: number;
  code: string | null;
  default: boolean | null;
}

export interface SalesData_products_category {
  __typename: "ProductCategory";
  id: string;
  name: string;
}

export interface SalesData_products_productPrices_clientGroup {
  __typename: "ClientGroup";
  id: string;
}

export interface SalesData_products_productPrices {
  __typename: "ProductPrice";
  id: string;
  clientGroup: SalesData_products_productPrices_clientGroup | null;
  value: number;
  base: boolean | null;
  endDate: any | null;
  note: string | null;
  unit: PriceUnit;
}

export interface SalesData_products_productAvailabilities_clientGroup {
  __typename: "ClientGroup";
  id: string;
  code: string | null;
}

export interface SalesData_products_productAvailabilities {
  __typename: "ProductAvailability";
  id: string;
  clientGroup: SalesData_products_productAvailabilities_clientGroup;
  startDate: any;
  endDate: any | null;
  note: string | null;
}

export interface SalesData_products {
  __typename: "Product";
  id: string;
  category: SalesData_products_category | null;
  productPrices: SalesData_products_productPrices[] | null;
  type: ProductType;
  name: string;
  portionWillem: number | null;
  portionDirk: number | null;
  groupName: string | null;
  extra: string | null;
  unit: ProductUnit | null;
  amount: number | null;
  avgWeight: number | null;
  availableAsAlternativeUnitFor: string[];
  alternativeUnit: ProductUnit | null;
  productAvailabilities: SalesData_products_productAvailabilities[] | null;
  status: ProductStatus;
  vat: Vat;
  image: string | null;
}

export interface SalesData {
  sequences: (SalesData_sequences | null)[];
  clientOrders: (SalesData_clientOrders | null)[];
  clientGroups: (SalesData_clientGroups | null)[];
  products: (SalesData_products | null)[];
}

export interface SalesDataVariables {
  farmId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateProduct
// ====================================================

export interface CreateProduct_createProduct_category {
  __typename: "ProductCategory";
  id: string;
  name: string;
}

export interface CreateProduct_createProduct_productPrices_clientGroup {
  __typename: "ClientGroup";
  id: string;
}

export interface CreateProduct_createProduct_productPrices {
  __typename: "ProductPrice";
  id: string;
  value: number;
  startDate: any;
  endDate: any | null;
  clientGroup: CreateProduct_createProduct_productPrices_clientGroup | null;
  base: boolean | null;
  note: string | null;
}

export interface CreateProduct_createProduct_productAvailabilities_clientGroup {
  __typename: "ClientGroup";
  id: string;
  code: string | null;
}

export interface CreateProduct_createProduct_productAvailabilities {
  __typename: "ProductAvailability";
  id: string;
  clientGroup: CreateProduct_createProduct_productAvailabilities_clientGroup;
  startDate: any;
  endDate: any | null;
  note: string | null;
}

export interface CreateProduct_createProduct {
  __typename: "Product";
  id: string;
  type: ProductType;
  category: CreateProduct_createProduct_category | null;
  productPrices: CreateProduct_createProduct_productPrices[] | null;
  name: string;
  unit: ProductUnit | null;
  amount: number | null;
  groupName: string | null;
  extra: string | null;
  portionWillem: number | null;
  portionDirk: number | null;
  avgWeight: number | null;
  productAvailabilities: CreateProduct_createProduct_productAvailabilities[] | null;
  status: ProductStatus;
  vat: Vat;
  image: string | null;
}

export interface CreateProduct {
  createProduct: CreateProduct_createProduct;
}

export interface CreateProductVariables {
  data: ProductCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertClientOrder
// ====================================================

export interface UpsertClientOrder_upsertClientOrder_client_group {
  __typename: "ClientGroup";
  id: string;
}

export interface UpsertClientOrder_upsertClientOrder_client {
  __typename: "Client";
  id: string;
  name: string | null;
  firstName: string | null;
  street: string | null;
  streetNumber: string | null;
  cityPostalCode: string | null;
  city: string | null;
  type: ClientType | null;
  vatNumber: string | null;
  email: string;
  billingEmail: string | null;
  group: UpsertClientOrder_upsertClientOrder_client_group;
}

export interface UpsertClientOrder_upsertClientOrder_productOrders_product {
  __typename: "Product";
  id: string;
  vat: Vat;
}

export interface UpsertClientOrder_upsertClientOrder_productOrders_deliveries {
  __typename: "ProductOrderDelivery";
  plannedDeliveryDate: any;
}

export interface UpsertClientOrder_upsertClientOrder_productOrders {
  __typename: "ProductOrder";
  id: string;
  product: UpsertClientOrder_upsertClientOrder_productOrders_product | null;
  priceExcl: number;
  quantity: number;
  remark: string | null;
  ambassadorDiscount: boolean;
  deliveries: UpsertClientOrder_upsertClientOrder_productOrders_deliveries[] | null;
}

export interface UpsertClientOrder_upsertClientOrder_invoice_payments {
  __typename: "Payment";
  id: string;
  paymentDate: any | null;
  dueDate: any;
  amount: number;
  paid: boolean | null;
}

export interface UpsertClientOrder_upsertClientOrder_invoice {
  __typename: "Invoice";
  id: string;
  createdAt: any;
  type: InvoiceType;
  number: string;
  filename: string;
  file: string | null;
  data: any | null;
  payments: UpsertClientOrder_upsertClientOrder_invoice_payments[] | null;
}

export interface UpsertClientOrder_upsertClientOrder {
  __typename: "ClientOrder";
  id: string;
  createdAt: any;
  updatedAt: any;
  orderDate: any;
  deliveryDate: any;
  deliveryEndDate: any | null;
  delivery: boolean;
  status: ClientOrderStatus;
  orderDiscount: number | null;
  client: UpsertClientOrder_upsertClientOrder_client;
  productOrders: UpsertClientOrder_upsertClientOrder_productOrders[] | null;
  invoice: UpsertClientOrder_upsertClientOrder_invoice | null;
}

export interface UpsertClientOrder {
  upsertClientOrder: UpsertClientOrder_upsertClientOrder;
}

export interface UpsertClientOrderVariables {
  id?: string | null;
  createData: ClientOrderCreateInput;
  updateData: ClientOrderUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MarkClientOrdersForInvoiceAsSent
// ====================================================

export interface MarkClientOrdersForInvoiceAsSent_updateManyClientOrders {
  __typename: "BatchPayload";
  /**
   * The number of nodes that have been affected by the Batch operation.
   */
  count: any;
}

export interface MarkClientOrdersForInvoiceAsSent {
  updateManyClientOrders: MarkClientOrdersForInvoiceAsSent_updateManyClientOrders;
}

export interface MarkClientOrdersForInvoiceAsSentVariables {
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddPaymentToInvoice
// ====================================================

export interface AddPaymentToInvoice_updateManyClientOrders {
  __typename: "BatchPayload";
  /**
   * The number of nodes that have been affected by the Batch operation.
   */
  count: any;
}

export interface AddPaymentToInvoice_updateInvoice_payments {
  __typename: "Payment";
  id: string;
  paymentDate: any | null;
  dueDate: any;
  amount: number;
  paid: boolean | null;
}

export interface AddPaymentToInvoice_updateInvoice {
  __typename: "Invoice";
  id: string;
  createdAt: any;
  type: InvoiceType;
  number: string;
  filename: string;
  file: string | null;
  data: any | null;
  payments: AddPaymentToInvoice_updateInvoice_payments[] | null;
}

export interface AddPaymentToInvoice {
  updateManyClientOrders: AddPaymentToInvoice_updateManyClientOrders;
  updateInvoice: AddPaymentToInvoice_updateInvoice | null;
}

export interface AddPaymentToInvoiceVariables {
  id: string;
  paymentDate: any;
  amount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertProduct
// ====================================================

export interface UpsertProduct_upsertProduct_category {
  __typename: "ProductCategory";
  id: string;
  name: string;
}

export interface UpsertProduct_upsertProduct_productAvailabilities_clientGroup {
  __typename: "ClientGroup";
  id: string;
  code: string | null;
}

export interface UpsertProduct_upsertProduct_productAvailabilities {
  __typename: "ProductAvailability";
  id: string;
  clientGroup: UpsertProduct_upsertProduct_productAvailabilities_clientGroup;
  startDate: any;
  endDate: any | null;
  note: string | null;
}

export interface UpsertProduct_upsertProduct {
  __typename: "Product";
  id: string;
  type: ProductType;
  category: UpsertProduct_upsertProduct_category | null;
  name: string;
  unit: ProductUnit | null;
  amount: number | null;
  groupName: string | null;
  extra: string | null;
  portionWillem: number | null;
  portionDirk: number | null;
  avgWeight: number | null;
  productAvailabilities: UpsertProduct_upsertProduct_productAvailabilities[] | null;
  status: ProductStatus;
  vat: Vat;
  image: string | null;
}

export interface UpsertProduct {
  upsertProduct: UpsertProduct_upsertProduct;
}

export interface UpsertProductVariables {
  id?: string | null;
  createData: ProductCreateInput;
  updateData: ProductUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteProduct
// ====================================================

export interface DeleteProduct_deleteProduct {
  __typename: "Product";
  id: string;
}

export interface DeleteProduct {
  deleteProduct: DeleteProduct_deleteProduct | null;
}

export interface DeleteProductVariables {
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateProduct
// ====================================================

export interface UpdateProduct_updateProduct_category {
  __typename: "ProductCategory";
  id: string;
  name: string;
}

export interface UpdateProduct_updateProduct_productAvailabilities_clientGroup {
  __typename: "ClientGroup";
  id: string;
  code: string | null;
}

export interface UpdateProduct_updateProduct_productAvailabilities {
  __typename: "ProductAvailability";
  id: string;
  clientGroup: UpdateProduct_updateProduct_productAvailabilities_clientGroup;
  startDate: any;
  endDate: any | null;
  note: string | null;
}

export interface UpdateProduct_updateProduct_productPrices_clientGroup {
  __typename: "ClientGroup";
  id: string;
}

export interface UpdateProduct_updateProduct_productPrices {
  __typename: "ProductPrice";
  id: string;
  value: number;
  startDate: any;
  endDate: any | null;
  note: string | null;
  clientGroup: UpdateProduct_updateProduct_productPrices_clientGroup | null;
  base: boolean | null;
}

export interface UpdateProduct_updateProduct {
  __typename: "Product";
  id: string;
  type: ProductType;
  category: UpdateProduct_updateProduct_category | null;
  name: string;
  unit: ProductUnit | null;
  amount: number | null;
  groupName: string | null;
  extra: string | null;
  portionWillem: number | null;
  portionDirk: number | null;
  avgWeight: number | null;
  productAvailabilities: UpdateProduct_updateProduct_productAvailabilities[] | null;
  status: ProductStatus;
  vat: Vat;
  image: string | null;
  productPrices: UpdateProduct_updateProduct_productPrices[] | null;
}

export interface UpdateProduct {
  updateProduct: UpdateProduct_updateProduct | null;
}

export interface UpdateProductVariables {
  id: string;
  data: ProductUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetClientOrdersToProcess
// ====================================================

export interface GetClientOrdersToProcess_clientOrders_client_group {
  __typename: "ClientGroup";
  id: string;
}

export interface GetClientOrdersToProcess_clientOrders_client {
  __typename: "Client";
  id: string;
  companyName: string | null;
  name: string | null;
  firstName: string | null;
  street: string | null;
  streetNumber: string | null;
  cityPostalCode: string | null;
  city: string | null;
  type: ClientType | null;
  vatNumber: string | null;
  email: string;
  billingEmail: string | null;
  group: GetClientOrdersToProcess_clientOrders_client_group;
}

export interface GetClientOrdersToProcess_clientOrders_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface GetClientOrdersToProcess_clientOrders_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: GetClientOrdersToProcess_clientOrders_productOrders_product_category | null;
  name: string;
  extra: string | null;
  amount: number | null;
  unit: ProductUnit | null;
  vat: Vat;
  type: ProductType;
  availableAsAlternativeUnitFor: string[];
  alternativeUnit: ProductUnit | null;
  avgWeight: number | null;
}

export interface GetClientOrdersToProcess_clientOrders_productOrders_deliveries {
  __typename: "ProductOrderDelivery";
  plannedDeliveryDate: any;
}

export interface GetClientOrdersToProcess_clientOrders_productOrders {
  __typename: "ProductOrder";
  id: string;
  productDescription: string | null;
  product: GetClientOrdersToProcess_clientOrders_productOrders_product | null;
  priceExcl: number;
  quantity: number;
  remark: string | null;
  ambassadorDiscount: boolean;
  deliveries: GetClientOrdersToProcess_clientOrders_productOrders_deliveries[] | null;
}

export interface GetClientOrdersToProcess_clientOrders_invoice_payments {
  __typename: "Payment";
  id: string;
  paymentDate: any | null;
  dueDate: any;
  amount: number;
  paid: boolean | null;
}

export interface GetClientOrdersToProcess_clientOrders_invoice {
  __typename: "Invoice";
  id: string;
  createdAt: any;
  type: InvoiceType;
  number: string;
  filename: string;
  file: string | null;
  data: any | null;
  payments: GetClientOrdersToProcess_clientOrders_invoice_payments[] | null;
}

export interface GetClientOrdersToProcess_clientOrders {
  __typename: "ClientOrder";
  id: string;
  createdAt: any;
  updatedAt: any;
  orderDate: any;
  deliveryDate: any;
  deliveryEndDate: any | null;
  delivery: boolean;
  status: ClientOrderStatus;
  orderDiscount: number | null;
  client: GetClientOrdersToProcess_clientOrders_client;
  productOrders: GetClientOrdersToProcess_clientOrders_productOrders[] | null;
  invoice: GetClientOrdersToProcess_clientOrders_invoice | null;
}

export interface GetClientOrdersToProcess {
  clientOrders: (GetClientOrdersToProcess_clientOrders | null)[];
}

export interface GetClientOrdersToProcessVariables {
  status?: ClientOrderStatus[] | null;
  farmId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Revenue
// ====================================================

export interface Revenue_pakketten_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface Revenue_pakketten_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: Revenue_pakketten_product_category | null;
  name: string;
  vat: Vat;
}

export interface Revenue_pakketten {
  __typename: "ProductOrder";
  priceExcl: number;
  product: Revenue_pakketten_product | null;
}

export interface Revenue_winterpakketten_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface Revenue_winterpakketten_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: Revenue_winterpakketten_product_category | null;
  name: string;
  vat: Vat;
}

export interface Revenue_winterpakketten {
  __typename: "ProductOrder";
  priceExcl: number;
  product: Revenue_winterpakketten_product | null;
}

export interface Revenue_zelfoogst_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface Revenue_zelfoogst_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: Revenue_zelfoogst_product_category | null;
  name: string;
  vat: Vat;
}

export interface Revenue_zelfoogst {
  __typename: "ProductOrder";
  priceExcl: number;
  product: Revenue_zelfoogst_product | null;
}

export interface Revenue_ambassadeur_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface Revenue_ambassadeur_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: Revenue_ambassadeur_product_category | null;
  name: string;
  vat: Vat;
}

export interface Revenue_ambassadeur {
  __typename: "ProductOrder";
  priceExcl: number;
  product: Revenue_ambassadeur_product | null;
}

export interface Revenue_eieren_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface Revenue_eieren_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: Revenue_eieren_product_category | null;
  name: string;
  vat: Vat;
}

export interface Revenue_eieren {
  __typename: "ProductOrder";
  priceExcl: number;
  ambassadorDiscount: boolean;
  product: Revenue_eieren_product | null;
}

export interface Revenue_uien_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface Revenue_uien_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: Revenue_uien_product_category | null;
  name: string;
  vat: Vat;
}

export interface Revenue_uien {
  __typename: "ProductOrder";
  priceExcl: number;
  ambassadorDiscount: boolean;
  product: Revenue_uien_product | null;
}

export interface Revenue_aardappelen_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface Revenue_aardappelen_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: Revenue_aardappelen_product_category | null;
  name: string;
  vat: Vat;
}

export interface Revenue_aardappelen {
  __typename: "ProductOrder";
  priceExcl: number;
  product: Revenue_aardappelen_product | null;
}

export interface Revenue_bloemen_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface Revenue_bloemen_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: Revenue_bloemen_product_category | null;
  name: string;
  vat: Vat;
}

export interface Revenue_bloemen {
  __typename: "ProductOrder";
  priceExcl: number;
  ambassadorDiscount: boolean;
  product: Revenue_bloemen_product | null;
}

export interface Revenue {
  pakketten: (Revenue_pakketten | null)[];
  winterpakketten: (Revenue_winterpakketten | null)[];
  zelfoogst: (Revenue_zelfoogst | null)[];
  ambassadeur: (Revenue_ambassadeur | null)[];
  eieren: (Revenue_eieren | null)[];
  uien: (Revenue_uien | null)[];
  aardappelen: (Revenue_aardappelen | null)[];
  bloemen: (Revenue_bloemen | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ActiveSubscriptions
// ====================================================

export interface ActiveSubscriptions_productsInClientOrders_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface ActiveSubscriptions_productsInClientOrders {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: ActiveSubscriptions_productsInClientOrders_category | null;
  name: string;
  vat: Vat;
  amount: number | null;
  unit: ProductUnit | null;
}

export interface ActiveSubscriptions_clientOrders_productOrders_deliveries_basketComposition {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
}

export interface ActiveSubscriptions_clientOrders_productOrders_deliveries {
  __typename: "ProductOrderDelivery";
  id: string;
  plannedDeliveryDate: any;
  deliveryDate: any | null;
  cancelled: boolean;
  deliveryLocation: DeliveryLocation | null;
  deliveredProductIds: string[];
  undeliverableProductIds: string[];
  basketComposition: ActiveSubscriptions_clientOrders_productOrders_deliveries_basketComposition | null;
}

export interface ActiveSubscriptions_clientOrders_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface ActiveSubscriptions_clientOrders_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: ActiveSubscriptions_clientOrders_productOrders_product_category | null;
}

export interface ActiveSubscriptions_clientOrders_productOrders {
  __typename: "ProductOrder";
  id: string;
  quantity: number;
  remark: string | null;
  priceExcl: number;
  ambassadorDiscount: boolean;
  frequency: string | null;
  portions: number | null;
  usedPortions: number | null;
  productDescription: string | null;
  unit: ProductUnit | null;
  vat: Vat | null;
  deliveries: ActiveSubscriptions_clientOrders_productOrders_deliveries[] | null;
  product: ActiveSubscriptions_clientOrders_productOrders_product | null;
}

export interface ActiveSubscriptions_clientOrders_client_farm {
  __typename: "Farm";
  id: string;
  code: string;
}

export interface ActiveSubscriptions_clientOrders_client_membership {
  __typename: "Membership";
  id: string;
  season: string;
  name: string;
  firstName: string;
}

export interface ActiveSubscriptions_clientOrders_client {
  __typename: "Client";
  id: string;
  farm: ActiveSubscriptions_clientOrders_client_farm;
  name: string | null;
  firstName: string | null;
  email: string;
  secondaryEmail: string | null;
  type: ClientType | null;
  auth0userId: string | null;
  remarks: string | null;
  membership: ActiveSubscriptions_clientOrders_client_membership | null;
}

export interface ActiveSubscriptions_clientOrders_invoice_payments {
  __typename: "Payment";
  id: string;
  amount: number;
  dueDate: any;
  paid: boolean | null;
}

export interface ActiveSubscriptions_clientOrders_invoice {
  __typename: "Invoice";
  id: string;
  filename: string;
  file: string | null;
  payments: ActiveSubscriptions_clientOrders_invoice_payments[] | null;
}

export interface ActiveSubscriptions_clientOrders {
  __typename: "ClientOrder";
  id: string;
  createdAt: any;
  status: ClientOrderStatus;
  completionStatus: ClientOrderCompletionStatus | null;
  orderDiscount: number | null;
  orderDate: any;
  delivery: boolean;
  deliveryDate: any;
  deliveryEndDate: any | null;
  productOrders: ActiveSubscriptions_clientOrders_productOrders[] | null;
  client: ActiveSubscriptions_clientOrders_client;
  invoice: ActiveSubscriptions_clientOrders_invoice | null;
}

export interface ActiveSubscriptions {
  productsInClientOrders: (ActiveSubscriptions_productsInClientOrders | null)[];
  clientOrders: (ActiveSubscriptions_clientOrders | null)[];
}

export interface ActiveSubscriptionsVariables {
  farmId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllProducts
// ====================================================

export interface AllProducts_products_productPromos {
  __typename: "ProductPromo";
  id: string;
  startDate: any;
  endDate: any | null;
  type: ProductPromoType;
  value: number;
  secondValue: number | null;
}

export interface AllProducts_products {
  __typename: "Product";
  id: string;
  name: string;
  unit: ProductUnit | null;
  amount: number | null;
  image: string | null;
  productPromos: AllProducts_products_productPromos[] | null;
}

export interface AllProducts {
  products: (AllProducts_products | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ShopClients
// ====================================================

export interface ShopClients_shopClients {
  __typename: "ShopClient";
  id: string;
  name: string;
  firstName: string;
  email: string;
  newsletter: boolean;
  phone: string;
}

export interface ShopClients_clients_clientOrders_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface ShopClients_clients_clientOrders_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: ShopClients_clients_clientOrders_productOrders_product_category | null;
}

export interface ShopClients_clients_clientOrders_productOrders {
  __typename: "ProductOrder";
  product: ShopClients_clients_clientOrders_productOrders_product | null;
}

export interface ShopClients_clients_clientOrders {
  __typename: "ClientOrder";
  deliveryEndDate: any | null;
  productOrders: ShopClients_clients_clientOrders_productOrders[] | null;
}

export interface ShopClients_clients {
  __typename: "Client";
  name: string | null;
  firstName: string | null;
  email: string;
  clientOrders: ShopClients_clients_clientOrders[] | null;
}

export interface ShopClients {
  shopClients: (ShopClients_shopClients | null)[];
  clients: (ShopClients_clients | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ShopOrders
// ====================================================

export interface ShopOrders_shopOrders_shopClient {
  __typename: "ShopClient";
  id: string;
  phone: string;
  email: string;
}

export interface ShopOrders_shopOrders_shopOrderItems_product {
  __typename: "Product";
  id: string;
  name: string;
}

export interface ShopOrders_shopOrders_shopOrderItems_currentPromo {
  __typename: "ProductPromo";
  id: string;
}

export interface ShopOrders_shopOrders_shopOrderItems {
  __typename: "ShopOrderItem";
  id: string;
  delivered: boolean;
  product: ShopOrders_shopOrders_shopOrderItems_product;
  currentPromo: ShopOrders_shopOrders_shopOrderItems_currentPromo | null;
  amount: number;
  priceIncl: number;
}

export interface ShopOrders_shopOrders {
  __typename: "ShopOrder";
  id: string;
  orderDate: any;
  deliveryDate: any;
  deliveryLocation: DeliveryLocation;
  paid: boolean;
  status: ShopOrderStatus;
  extraPackaging: boolean;
  promocode: string | null;
  createdAt: any;
  shopClient: ShopOrders_shopOrders_shopClient;
  shopOrderItems: ShopOrders_shopOrders_shopOrderItems[] | null;
}

export interface ShopOrders {
  shopOrders: (ShopOrders_shopOrders | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrderStatus
// ====================================================

export interface UpdateOrderStatus_updateShopOrder {
  __typename: "ShopOrder";
  id: string;
  status: ShopOrderStatus;
}

export interface UpdateOrderStatus {
  updateShopOrder: UpdateOrderStatus_updateShopOrder | null;
}

export interface UpdateOrderStatusVariables {
  id: string;
  status: ShopOrderStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrderPaymentStatus
// ====================================================

export interface UpdateOrderPaymentStatus_updateShopOrder {
  __typename: "ShopOrder";
  id: string;
  paid: boolean;
}

export interface UpdateOrderPaymentStatus {
  updateShopOrder: UpdateOrderPaymentStatus_updateShopOrder | null;
}

export interface UpdateOrderPaymentStatusVariables {
  id: string;
  paid: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveShopOrder
// ====================================================

export interface RemoveShopOrder_deleteManyShopOrderItems {
  __typename: "BatchPayload";
  /**
   * The number of nodes that have been affected by the Batch operation.
   */
  count: any;
}

export interface RemoveShopOrder_deleteManyShopOrders {
  __typename: "BatchPayload";
  /**
   * The number of nodes that have been affected by the Batch operation.
   */
  count: any;
}

export interface RemoveShopOrder {
  deleteManyShopOrderItems: RemoveShopOrder_deleteManyShopOrderItems;
  deleteManyShopOrders: RemoveShopOrder_deleteManyShopOrders;
}

export interface RemoveShopOrderVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LinkShopClientAndMembership
// ====================================================

export interface LinkShopClientAndMembership_updateMembership_shopClient {
  __typename: "ShopClient";
  id: string;
}

export interface LinkShopClientAndMembership_updateMembership {
  __typename: "Membership";
  shopClient: LinkShopClientAndMembership_updateMembership_shopClient | null;
}

export interface LinkShopClientAndMembership {
  updateMembership: LinkShopClientAndMembership_updateMembership | null;
}

export interface LinkShopClientAndMembershipVariables {
  membershipId: string;
  shopClientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveShopClient
// ====================================================

export interface RemoveShopClient_deleteShopClient {
  __typename: "ShopClient";
  id: string;
}

export interface RemoveShopClient {
  deleteShopClient: RemoveShopClient_deleteShopClient | null;
}

export interface RemoveShopClientVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateShopOrderItemDeliveredFlag
// ====================================================

export interface UpdateShopOrderItemDeliveredFlag_updateShopOrderItem {
  __typename: "ShopOrderItem";
  id: string;
  delivered: boolean;
}

export interface UpdateShopOrderItemDeliveredFlag {
  updateShopOrderItem: UpdateShopOrderItemDeliveredFlag_updateShopOrderItem | null;
}

export interface UpdateShopOrderItemDeliveredFlagVariables {
  id: string;
  delivered: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindMembershipByShopClient
// ====================================================

export interface FindMembershipByShopClient_memberships {
  __typename: "Membership";
  id: string;
  ambassador: AmbassadorOption | null;
}

export interface FindMembershipByShopClient {
  memberships: (FindMembershipByShopClient_memberships | null)[];
}

export interface FindMembershipByShopClientVariables {
  shopClientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ShopOrderDetails
// ====================================================

export interface ShopOrderDetails_shopOrder_shopClient {
  __typename: "ShopClient";
  id: string;
  name: string;
  firstName: string;
  email: string;
  phone: string;
}

export interface ShopOrderDetails_shopOrder_shopOrderItems_currentPromo {
  __typename: "ProductPromo";
  id: string;
}

export interface ShopOrderDetails_shopOrder_shopOrderItems_product {
  __typename: "Product";
  id: string;
  name: string;
  amount: number | null;
  unit: ProductUnit | null;
  avgWeight: number | null;
  alternativeUnit: ProductUnit | null;
  availableAsAlternativeUnitFor: string[];
}

export interface ShopOrderDetails_shopOrder_shopOrderItems {
  __typename: "ShopOrderItem";
  id: string;
  delivered: boolean;
  currentPromo: ShopOrderDetails_shopOrder_shopOrderItems_currentPromo | null;
  product: ShopOrderDetails_shopOrder_shopOrderItems_product;
  amount: number;
  priceIncl: number;
}

export interface ShopOrderDetails_shopOrder {
  __typename: "ShopOrder";
  id: string;
  orderDate: any;
  deliveryDate: any;
  deliveryLocation: DeliveryLocation;
  paid: boolean;
  status: ShopOrderStatus;
  extraPackaging: boolean;
  promocode: string | null;
  shopClient: ShopOrderDetails_shopOrder_shopClient;
  shopOrderItems: ShopOrderDetails_shopOrder_shopOrderItems[] | null;
}

export interface ShopOrderDetails {
  shopOrder: ShopOrderDetails_shopOrder | null;
}

export interface ShopOrderDetailsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: User
// ====================================================

export interface User_user_farms {
  __typename: "Farm";
  id: string;
  code: string;
  name: string;
  logo: string | null;
}

export interface User_user {
  __typename: "User";
  id: string;
  auth0userId: string | null;
  name: string;
  firstName: string;
  email: string;
  secondaryEmail: string | null;
  street: string | null;
  streetNumber: string | null;
  city: string | null;
  cityPostalCode: string | null;
  phone: string | null;
  dateOfBirth: any | null;
  permissions: Permission[];
  farms: User_user_farms[] | null;
}

export interface User {
  user: User_user | null;
}

export interface UserVariables {
  where: UserWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserInfo
// ====================================================

export interface GetUserInfo_user {
  __typename: "User";
  id: string;
  email: string;
  name: string;
  firstName: string;
}

export interface GetUserInfo {
  user: GetUserInfo_user | null;
}

export interface GetUserInfoVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckUser
// ====================================================

export interface CheckUser_user {
  __typename: "User";
  id: string;
}

export interface CheckUser {
  user: CheckUser_user | null;
}

export interface CheckUserVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BankTransactions
// ====================================================

export interface BankTransactions_bankTransactions_bankAccount {
  __typename: "BankAccount";
  id: string;
}

export interface BankTransactions_bankTransactions_client {
  __typename: "Client";
  id: string;
}

export interface BankTransactions_bankTransactions {
  __typename: "BankTransaction";
  id: string;
  date: any;
  amount: number;
  ibanNumber: string | null;
  bic: string | null;
  name: string | null;
  address: string | null;
  sct: string | null;
  description: string | null;
  bankAccount: BankTransactions_bankTransactions_bankAccount | null;
  bankAccountBalance: number;
  client: BankTransactions_bankTransactions_client | null;
}

export interface BankTransactions {
  bankTransactions: (BankTransactions_bankTransactions | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BankTransactionsCreate
// ====================================================

export interface BankTransactionsCreate_updateBankAccount_transactions_bankAccount {
  __typename: "BankAccount";
  id: string;
}

export interface BankTransactionsCreate_updateBankAccount_transactions_client {
  __typename: "Client";
  id: string;
}

export interface BankTransactionsCreate_updateBankAccount_transactions {
  __typename: "BankTransaction";
  id: string;
  date: any;
  amount: number;
  ibanNumber: string | null;
  bic: string | null;
  name: string | null;
  address: string | null;
  sct: string | null;
  description: string | null;
  bankAccount: BankTransactionsCreate_updateBankAccount_transactions_bankAccount | null;
  bankAccountBalance: number;
  client: BankTransactionsCreate_updateBankAccount_transactions_client | null;
}

export interface BankTransactionsCreate_updateBankAccount {
  __typename: "BankAccount";
  id: string;
  transactions: BankTransactionsCreate_updateBankAccount_transactions[] | null;
}

export interface BankTransactionsCreate {
  updateBankAccount: BankTransactionsCreate_updateBankAccount | null;
}

export interface BankTransactionsCreateVariables {
  ibanNumber: string;
  transactionCreates: BankTransactionCreateWithoutBankAccountInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasketCompositionSets
// ====================================================

export interface BasketCompositionSets_basketCompositionSets_basketCompositions {
  __typename: "BasketComposition";
  id: string;
  deliveryLocation: DeliveryLocation | null;
  basketSize: BasketSize | null;
  totalPortions: number | null;
}

export interface BasketCompositionSets_basketCompositionSets {
  __typename: "BasketCompositionSet";
  id: string;
  deliveryWeek: any;
  status: BasketCompositionStatus;
  basketCompositions: BasketCompositionSets_basketCompositionSets_basketCompositions[] | null;
}

export interface BasketCompositionSets {
  basketCompositionSets: (BasketCompositionSets_basketCompositionSets | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateBasketCompositionSet
// ====================================================

export interface CreateBasketCompositionSet_createBasketCompositionSet {
  __typename: "BasketCompositionSet";
  id: string;
}

export interface CreateBasketCompositionSet {
  createBasketCompositionSet: CreateBasketCompositionSet_createBasketCompositionSet;
}

export interface CreateBasketCompositionSetVariables {
  data: BasketCompositionSetCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasketCompositions
// ====================================================

export interface BasketCompositions_basketCompositions_items_product {
  __typename: "Product";
  id: string;
}

export interface BasketCompositions_basketCompositions_items {
  __typename: "BasketItem";
  id: string;
  product: BasketCompositions_basketCompositions_items_product;
  packagingOrder: number;
  quantitySmall: number;
  portionsSmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
}

export interface BasketCompositions_basketCompositions {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
  status: BasketCompositionStatus;
  deliveryWeek: any | null;
  items: BasketCompositions_basketCompositions_items[] | null;
}

export interface BasketCompositions {
  basketCompositions: (BasketCompositions_basketCompositions | null)[];
}

export interface BasketCompositionsVariables {
  where: BasketCompositionWhereInput;
  farmId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasketComposition
// ====================================================

export interface BasketComposition_basketComposition_items_product {
  __typename: "Product";
  id: string;
}

export interface BasketComposition_basketComposition_items {
  __typename: "BasketItem";
  id: string;
  product: BasketComposition_basketComposition_items_product;
  packagingOrder: number;
  quantitySmall: number;
  portionsSmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
}

export interface BasketComposition_basketComposition {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
  status: BasketCompositionStatus;
  deliveryWeek: any | null;
  items: BasketComposition_basketComposition_items[] | null;
}

export interface BasketComposition {
  basketComposition: BasketComposition_basketComposition | null;
}

export interface BasketCompositionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BasketCompositionPublish
// ====================================================

export interface BasketCompositionPublish_updateBasketComposition_items_product {
  __typename: "Product";
  id: string;
}

export interface BasketCompositionPublish_updateBasketComposition_items {
  __typename: "BasketItem";
  id: string;
  product: BasketCompositionPublish_updateBasketComposition_items_product;
  packagingOrder: number;
  quantitySmall: number;
  portionsSmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
}

export interface BasketCompositionPublish_updateBasketComposition {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
  status: BasketCompositionStatus;
  deliveryWeek: any | null;
  items: BasketCompositionPublish_updateBasketComposition_items[] | null;
}

export interface BasketCompositionPublish {
  updateBasketComposition: BasketCompositionPublish_updateBasketComposition | null;
}

export interface BasketCompositionPublishVariables {
  id: string;
  deliveries: ProductOrderDeliveryUpdateManyWithoutBasketCompositionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BasketCompositionConnectToDelivery
// ====================================================

export interface BasketCompositionConnectToDelivery_updateBasketComposition_items_product {
  __typename: "Product";
  id: string;
}

export interface BasketCompositionConnectToDelivery_updateBasketComposition_items {
  __typename: "BasketItem";
  id: string;
  product: BasketCompositionConnectToDelivery_updateBasketComposition_items_product;
  packagingOrder: number;
  quantitySmall: number;
  portionsSmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
}

export interface BasketCompositionConnectToDelivery_updateBasketComposition {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
  status: BasketCompositionStatus;
  deliveryWeek: any | null;
  items: BasketCompositionConnectToDelivery_updateBasketComposition_items[] | null;
}

export interface BasketCompositionConnectToDelivery {
  updateBasketComposition: BasketCompositionConnectToDelivery_updateBasketComposition | null;
}

export interface BasketCompositionConnectToDeliveryVariables {
  id: string;
  deliveries: ProductOrderDeliveryUpdateManyWithoutBasketCompositionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BasketCompositionBackToDraft
// ====================================================

export interface BasketCompositionBackToDraft_updateBasketComposition_items_product {
  __typename: "Product";
  id: string;
}

export interface BasketCompositionBackToDraft_updateBasketComposition_items {
  __typename: "BasketItem";
  id: string;
  product: BasketCompositionBackToDraft_updateBasketComposition_items_product;
  packagingOrder: number;
  quantitySmall: number;
  portionsSmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
}

export interface BasketCompositionBackToDraft_updateBasketComposition {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
  status: BasketCompositionStatus;
  deliveryWeek: any | null;
  items: BasketCompositionBackToDraft_updateBasketComposition_items[] | null;
}

export interface BasketCompositionBackToDraft {
  updateBasketComposition: BasketCompositionBackToDraft_updateBasketComposition | null;
}

export interface BasketCompositionBackToDraftVariables {
  id: string;
  deliveries: ProductOrderDeliveryUpdateManyWithoutBasketCompositionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BasketCompositionUpdate
// ====================================================

export interface BasketCompositionUpdate_updateBasketComposition_items_product {
  __typename: "Product";
  id: string;
}

export interface BasketCompositionUpdate_updateBasketComposition_items {
  __typename: "BasketItem";
  id: string;
  product: BasketCompositionUpdate_updateBasketComposition_items_product;
  packagingOrder: number;
  quantitySmall: number;
  portionsSmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
}

export interface BasketCompositionUpdate_updateBasketComposition {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
  status: BasketCompositionStatus;
  deliveryWeek: any | null;
  items: BasketCompositionUpdate_updateBasketComposition_items[] | null;
}

export interface BasketCompositionUpdate {
  updateBasketComposition: BasketCompositionUpdate_updateBasketComposition | null;
}

export interface BasketCompositionUpdateVariables {
  where: BasketCompositionWhereUniqueInput;
  data: BasketCompositionUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BasketCompositionDelete
// ====================================================

export interface BasketCompositionDelete_deleteBasketComposition_items_product {
  __typename: "Product";
  id: string;
}

export interface BasketCompositionDelete_deleteBasketComposition_items {
  __typename: "BasketItem";
  id: string;
  product: BasketCompositionDelete_deleteBasketComposition_items_product;
  packagingOrder: number;
  quantitySmall: number;
  portionsSmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
}

export interface BasketCompositionDelete_deleteBasketComposition {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
  status: BasketCompositionStatus;
  deliveryWeek: any | null;
  items: BasketCompositionDelete_deleteBasketComposition_items[] | null;
}

export interface BasketCompositionDelete {
  deleteBasketComposition: BasketCompositionDelete_deleteBasketComposition | null;
}

export interface BasketCompositionDeleteVariables {
  where: BasketCompositionWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BasketCompositionUpsert
// ====================================================

export interface BasketCompositionUpsert_upsertBasketComposition_items_product {
  __typename: "Product";
  id: string;
}

export interface BasketCompositionUpsert_upsertBasketComposition_items {
  __typename: "BasketItem";
  id: string;
  product: BasketCompositionUpsert_upsertBasketComposition_items_product;
  packagingOrder: number;
  quantitySmall: number;
  portionsSmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
}

export interface BasketCompositionUpsert_upsertBasketComposition {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
  status: BasketCompositionStatus;
  deliveryWeek: any | null;
  items: BasketCompositionUpsert_upsertBasketComposition_items[] | null;
}

export interface BasketCompositionUpsert {
  upsertBasketComposition: BasketCompositionUpsert_upsertBasketComposition;
}

export interface BasketCompositionUpsertVariables {
  where: BasketCompositionWhereUniqueInput;
  create: BasketCompositionCreateInput;
  update: BasketCompositionUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClientUpdate_CustomerInfo_Members
// ====================================================

export interface ClientUpdate_CustomerInfo_Members_updateClient_customerInfo_members {
  __typename: "Member";
  id: string;
  name: string;
  firstName: string;
  dateOfBirth: any;
}

export interface ClientUpdate_CustomerInfo_Members_updateClient_customerInfo {
  __typename: "CustomerInfo";
  members: ClientUpdate_CustomerInfo_Members_updateClient_customerInfo_members[] | null;
}

export interface ClientUpdate_CustomerInfo_Members_updateClient {
  __typename: "Client";
  id: string;
  customerInfo: ClientUpdate_CustomerInfo_Members_updateClient_customerInfo | null;
}

export interface ClientUpdate_CustomerInfo_Members {
  updateClient: ClientUpdate_CustomerInfo_Members_updateClient | null;
}

export interface ClientUpdate_CustomerInfo_MembersVariables {
  id: string;
  membersUpdate: MemberUpdateManyWithoutCustomerInfoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Clients
// ====================================================

export interface Clients_clients_farm {
  __typename: "Farm";
  id: string;
}

export interface Clients_clients_group {
  __typename: "ClientGroup";
  id: string;
}

export interface Clients_clients_bankTransactions {
  __typename: "BankTransaction";
  id: string;
  amount: number;
}

export interface Clients_clients_customerInfo_members {
  __typename: "Member";
  id: string;
  name: string;
  firstName: string;
  dateOfBirth: any;
}

export interface Clients_clients_customerInfo {
  __typename: "CustomerInfo";
  members: Clients_clients_customerInfo_members[] | null;
}

export interface Clients_clients {
  __typename: "Client";
  id: string;
  name: string | null;
  firstName: string | null;
  dateOfBirth: any | null;
  street: string | null;
  streetNumber: string | null;
  city: string | null;
  cityPostalCode: string | null;
  companyName: string | null;
  email: string;
  secondaryEmail: string | null;
  newsletter: boolean | null;
  whatsApp: boolean | null;
  phone: string | null;
  farm: Clients_clients_farm;
  type: ClientType | null;
  billingEmail: string | null;
  vatNumber: string | null;
  remarks: string | null;
  group: Clients_clients_group;
  ibanNumbers: string[];
  bankTransactions: Clients_clients_bankTransactions[] | null;
  auth0userId: string | null;
  customerInfo: Clients_clients_customerInfo | null;
}

export interface Clients {
  clients: (Clients_clients | null)[];
}

export interface ClientsVariables {
  farmId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Client
// ====================================================

export interface Client_client_farm {
  __typename: "Farm";
  id: string;
}

export interface Client_client_group {
  __typename: "ClientGroup";
  id: string;
}

export interface Client_client_bankTransactions {
  __typename: "BankTransaction";
  id: string;
  amount: number;
}

export interface Client_client_customerInfo_members {
  __typename: "Member";
  id: string;
  name: string;
  firstName: string;
  dateOfBirth: any;
}

export interface Client_client_customerInfo {
  __typename: "CustomerInfo";
  members: Client_client_customerInfo_members[] | null;
}

export interface Client_client {
  __typename: "Client";
  id: string;
  name: string | null;
  firstName: string | null;
  dateOfBirth: any | null;
  street: string | null;
  streetNumber: string | null;
  city: string | null;
  cityPostalCode: string | null;
  companyName: string | null;
  email: string;
  secondaryEmail: string | null;
  newsletter: boolean | null;
  whatsApp: boolean | null;
  phone: string | null;
  farm: Client_client_farm;
  type: ClientType | null;
  billingEmail: string | null;
  vatNumber: string | null;
  remarks: string | null;
  group: Client_client_group;
  ibanNumbers: string[];
  bankTransactions: Client_client_bankTransactions[] | null;
  auth0userId: string | null;
  customerInfo: Client_client_customerInfo | null;
}

export interface Client {
  client: Client_client | null;
}

export interface ClientVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClientByAuth0UserId
// ====================================================

export interface ClientByAuth0UserId_clientsByUserId_farm {
  __typename: "Farm";
  id: string;
}

export interface ClientByAuth0UserId_clientsByUserId_group {
  __typename: "ClientGroup";
  id: string;
}

export interface ClientByAuth0UserId_clientsByUserId_bankTransactions {
  __typename: "BankTransaction";
  id: string;
  amount: number;
}

export interface ClientByAuth0UserId_clientsByUserId_customerInfo_members {
  __typename: "Member";
  id: string;
  name: string;
  firstName: string;
  dateOfBirth: any;
}

export interface ClientByAuth0UserId_clientsByUserId_customerInfo {
  __typename: "CustomerInfo";
  members: ClientByAuth0UserId_clientsByUserId_customerInfo_members[] | null;
}

export interface ClientByAuth0UserId_clientsByUserId_clientOrders_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface ClientByAuth0UserId_clientsByUserId_clientOrders_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: ClientByAuth0UserId_clientsByUserId_clientOrders_productOrders_product_category | null;
  name: string;
}

export interface ClientByAuth0UserId_clientsByUserId_clientOrders_productOrders {
  __typename: "ProductOrder";
  id: string;
  product: ClientByAuth0UserId_clientsByUserId_clientOrders_productOrders_product | null;
}

export interface ClientByAuth0UserId_clientsByUserId_clientOrders {
  __typename: "ClientOrder";
  id: string;
  deliveryDate: any;
  deliveryEndDate: any | null;
  productOrders: ClientByAuth0UserId_clientsByUserId_clientOrders_productOrders[] | null;
}

export interface ClientByAuth0UserId_clientsByUserId {
  __typename: "Client";
  id: string;
  name: string | null;
  firstName: string | null;
  dateOfBirth: any | null;
  street: string | null;
  streetNumber: string | null;
  city: string | null;
  cityPostalCode: string | null;
  companyName: string | null;
  email: string;
  secondaryEmail: string | null;
  newsletter: boolean | null;
  whatsApp: boolean | null;
  phone: string | null;
  farm: ClientByAuth0UserId_clientsByUserId_farm;
  type: ClientType | null;
  billingEmail: string | null;
  vatNumber: string | null;
  remarks: string | null;
  group: ClientByAuth0UserId_clientsByUserId_group;
  ibanNumbers: string[];
  bankTransactions: ClientByAuth0UserId_clientsByUserId_bankTransactions[] | null;
  auth0userId: string | null;
  customerInfo: ClientByAuth0UserId_clientsByUserId_customerInfo | null;
  createdAt: any;
  clientOrders: ClientByAuth0UserId_clientsByUserId_clientOrders[] | null;
}

export interface ClientByAuth0UserId_user {
  __typename: "User";
  id: string;
}

export interface ClientByAuth0UserId {
  clientsByUserId: (ClientByAuth0UserId_clientsByUserId | null)[];
  user: ClientByAuth0UserId_user | null;
}

export interface ClientByAuth0UserIdVariables {
  auth0userId: string;
  auth0Email: string;
  today: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClientById
// ====================================================

export interface ClientById_client_farm {
  __typename: "Farm";
  id: string;
}

export interface ClientById_client_group {
  __typename: "ClientGroup";
  id: string;
}

export interface ClientById_client_bankTransactions {
  __typename: "BankTransaction";
  id: string;
  amount: number;
}

export interface ClientById_client_customerInfo_members {
  __typename: "Member";
  id: string;
  name: string;
  firstName: string;
  dateOfBirth: any;
}

export interface ClientById_client_customerInfo {
  __typename: "CustomerInfo";
  members: ClientById_client_customerInfo_members[] | null;
}

export interface ClientById_client_clientOrders_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface ClientById_client_clientOrders_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: ClientById_client_clientOrders_productOrders_product_category | null;
  name: string;
}

export interface ClientById_client_clientOrders_productOrders {
  __typename: "ProductOrder";
  id: string;
  product: ClientById_client_clientOrders_productOrders_product | null;
}

export interface ClientById_client_clientOrders {
  __typename: "ClientOrder";
  id: string;
  deliveryDate: any;
  deliveryEndDate: any | null;
  productOrders: ClientById_client_clientOrders_productOrders[] | null;
}

export interface ClientById_client {
  __typename: "Client";
  id: string;
  name: string | null;
  firstName: string | null;
  dateOfBirth: any | null;
  street: string | null;
  streetNumber: string | null;
  city: string | null;
  cityPostalCode: string | null;
  companyName: string | null;
  email: string;
  secondaryEmail: string | null;
  newsletter: boolean | null;
  whatsApp: boolean | null;
  phone: string | null;
  farm: ClientById_client_farm;
  type: ClientType | null;
  billingEmail: string | null;
  vatNumber: string | null;
  remarks: string | null;
  group: ClientById_client_group;
  ibanNumbers: string[];
  bankTransactions: ClientById_client_bankTransactions[] | null;
  auth0userId: string | null;
  customerInfo: ClientById_client_customerInfo | null;
  createdAt: any;
  clientOrders: ClientById_client_clientOrders[] | null;
}

export interface ClientById {
  client: ClientById_client | null;
}

export interface ClientByIdVariables {
  id?: string | null;
  today: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateClient
// ====================================================

export interface UpdateClient_updateClient {
  __typename: "Client";
  id: string;
}

export interface UpdateClient {
  updateClient: UpdateClient_updateClient | null;
}

export interface UpdateClientVariables {
  id: string;
  data: ClientUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertClient
// ====================================================

export interface UpsertClient_upsertClient {
  __typename: "Client";
  id: string;
}

export interface UpsertClient {
  upsertClient: UpsertClient_upsertClient;
}

export interface UpsertClientVariables {
  id?: string | null;
  createData: ClientCreateInput;
  updateData: ClientUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClientsForMailChimp
// ====================================================

export interface ClientsForMailChimp_clients_farm {
  __typename: "Farm";
  id: string;
}

export interface ClientsForMailChimp_clients_group {
  __typename: "ClientGroup";
  id: string;
  code: string | null;
}

export interface ClientsForMailChimp_clients_bankTransactions {
  __typename: "BankTransaction";
  id: string;
  amount: number;
}

export interface ClientsForMailChimp_clients_customerInfo_members {
  __typename: "Member";
  id: string;
  name: string;
  firstName: string;
  dateOfBirth: any;
}

export interface ClientsForMailChimp_clients_customerInfo {
  __typename: "CustomerInfo";
  members: ClientsForMailChimp_clients_customerInfo_members[] | null;
}

export interface ClientsForMailChimp_clients_clientOrders_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface ClientsForMailChimp_clients_clientOrders_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: ClientsForMailChimp_clients_clientOrders_productOrders_product_category | null;
}

export interface ClientsForMailChimp_clients_clientOrders_productOrders_deliveries {
  __typename: "ProductOrderDelivery";
  id: string;
  deliveryLocation: DeliveryLocation | null;
  plannedDeliveryDate: any;
  deliveryDate: any | null;
}

export interface ClientsForMailChimp_clients_clientOrders_productOrders {
  __typename: "ProductOrder";
  product: ClientsForMailChimp_clients_clientOrders_productOrders_product | null;
  deliveries: ClientsForMailChimp_clients_clientOrders_productOrders_deliveries[] | null;
}

export interface ClientsForMailChimp_clients_clientOrders {
  __typename: "ClientOrder";
  deliveryEndDate: any | null;
  productOrders: ClientsForMailChimp_clients_clientOrders_productOrders[] | null;
}

export interface ClientsForMailChimp_clients {
  __typename: "Client";
  id: string;
  name: string | null;
  firstName: string | null;
  dateOfBirth: any | null;
  street: string | null;
  streetNumber: string | null;
  city: string | null;
  cityPostalCode: string | null;
  companyName: string | null;
  email: string;
  secondaryEmail: string | null;
  newsletter: boolean | null;
  whatsApp: boolean | null;
  phone: string | null;
  farm: ClientsForMailChimp_clients_farm;
  type: ClientType | null;
  billingEmail: string | null;
  vatNumber: string | null;
  remarks: string | null;
  group: ClientsForMailChimp_clients_group;
  ibanNumbers: string[];
  bankTransactions: ClientsForMailChimp_clients_bankTransactions[] | null;
  auth0userId: string | null;
  customerInfo: ClientsForMailChimp_clients_customerInfo | null;
  clientOrders: ClientsForMailChimp_clients_clientOrders[] | null;
}

export interface ClientsForMailChimp {
  clients: (ClientsForMailChimp_clients | null)[];
}

export interface ClientsForMailChimpVariables {
  farmId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateClientGroup
// ====================================================

export interface UpdateClientGroup_updateClientGroup_productPrices {
  __typename: "ProductPrice";
  id: string;
}

export interface UpdateClientGroup_updateClientGroup {
  __typename: "ClientGroup";
  id: string;
  productPrices: UpdateClientGroup_updateClientGroup_productPrices[] | null;
}

export interface UpdateClientGroup {
  updateClientGroup: UpdateClientGroup_updateClientGroup | null;
}

export interface UpdateClientGroupVariables {
  clientGroupId: string;
  data: ClientGroupUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClientGroups
// ====================================================

export interface ClientGroups_clientGroups {
  __typename: "ClientGroup";
  id: string;
  name: string;
  code: string | null;
  profitMargin: number;
  order: number;
  default: boolean | null;
}

export interface ClientGroups {
  clientGroups: (ClientGroups_clientGroups | null)[];
}

export interface ClientGroupsVariables {
  farmId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClientOrder
// ====================================================

export interface ClientOrder_clientOrder_productOrders_deliveries_basketComposition_items_product {
  __typename: "Product";
  id: string;
}

export interface ClientOrder_clientOrder_productOrders_deliveries_basketComposition_items {
  __typename: "BasketItem";
  id: string;
  product: ClientOrder_clientOrder_productOrders_deliveries_basketComposition_items_product;
  packagingOrder: number;
  quantitySmall: number;
  portionsSmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
}

export interface ClientOrder_clientOrder_productOrders_deliveries_basketComposition {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
  status: BasketCompositionStatus;
  deliveryWeek: any | null;
  items: ClientOrder_clientOrder_productOrders_deliveries_basketComposition_items[] | null;
}

export interface ClientOrder_clientOrder_productOrders_deliveries {
  __typename: "ProductOrderDelivery";
  id: string;
  plannedDeliveryDate: any;
  deliveryDate: any | null;
  cancelled: boolean;
  deliveryLocation: DeliveryLocation | null;
  deliveredProductIds: string[];
  undeliverableProductIds: string[];
  basketComposition: ClientOrder_clientOrder_productOrders_deliveries_basketComposition | null;
}

export interface ClientOrder_clientOrder_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface ClientOrder_clientOrder_productOrders_product_productPrices {
  __typename: "ProductPrice";
  value: number;
  startDate: any;
  endDate: any | null;
}

export interface ClientOrder_clientOrder_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: ClientOrder_clientOrder_productOrders_product_category | null;
  name: string;
  vat: Vat;
  amount: number | null;
  type: ProductType;
  avgWeight: number | null;
  availableAsAlternativeUnitFor: string[];
  alternativeUnit: ProductUnit | null;
  unit: ProductUnit | null;
  image: string | null;
  productPrices: ClientOrder_clientOrder_productOrders_product_productPrices[] | null;
}

export interface ClientOrder_clientOrder_productOrders {
  __typename: "ProductOrder";
  id: string;
  quantity: number;
  remark: string | null;
  priceExcl: number;
  ambassadorDiscount: boolean;
  frequency: string | null;
  portions: number | null;
  usedPortions: number | null;
  productDescription: string | null;
  unit: ProductUnit | null;
  vat: Vat | null;
  deliveries: ClientOrder_clientOrder_productOrders_deliveries[] | null;
  product: ClientOrder_clientOrder_productOrders_product | null;
}

export interface ClientOrder_clientOrder_client_group {
  __typename: "ClientGroup";
  id: string;
}

export interface ClientOrder_clientOrder_client {
  __typename: "Client";
  id: string;
  type: ClientType | null;
  name: string | null;
  firstName: string | null;
  companyName: string | null;
  street: string | null;
  streetNumber: string | null;
  cityPostalCode: string | null;
  city: string | null;
  vatNumber: string | null;
  email: string;
  billingEmail: string | null;
  group: ClientOrder_clientOrder_client_group;
}

export interface ClientOrder_clientOrder_invoice_payments {
  __typename: "Payment";
  id: string;
  paymentDate: any | null;
  dueDate: any;
  amount: number;
  paid: boolean | null;
}

export interface ClientOrder_clientOrder_invoice {
  __typename: "Invoice";
  id: string;
  type: InvoiceType;
  number: string;
  filename: string;
  file: string | null;
  data: any | null;
  createdAt: any;
  payments: ClientOrder_clientOrder_invoice_payments[] | null;
}

export interface ClientOrder_clientOrder {
  __typename: "ClientOrder";
  id: string;
  createdAt: any;
  status: ClientOrderStatus;
  completionStatus: ClientOrderCompletionStatus | null;
  orderDiscount: number | null;
  orderDate: any;
  delivery: boolean;
  deliveryDate: any;
  deliveryEndDate: any | null;
  productOrders: ClientOrder_clientOrder_productOrders[] | null;
  client: ClientOrder_clientOrder_client;
  invoice: ClientOrder_clientOrder_invoice | null;
}

export interface ClientOrder {
  clientOrder: ClientOrder_clientOrder | null;
}

export interface ClientOrderVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClientOrders
// ====================================================

export interface ClientOrders_clientOrders_productOrders_deliveries_basketComposition_items_product {
  __typename: "Product";
  id: string;
}

export interface ClientOrders_clientOrders_productOrders_deliveries_basketComposition_items {
  __typename: "BasketItem";
  id: string;
  product: ClientOrders_clientOrders_productOrders_deliveries_basketComposition_items_product;
  packagingOrder: number;
  quantitySmall: number;
  portionsSmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
}

export interface ClientOrders_clientOrders_productOrders_deliveries_basketComposition {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
  status: BasketCompositionStatus;
  deliveryWeek: any | null;
  items: ClientOrders_clientOrders_productOrders_deliveries_basketComposition_items[] | null;
}

export interface ClientOrders_clientOrders_productOrders_deliveries {
  __typename: "ProductOrderDelivery";
  id: string;
  plannedDeliveryDate: any;
  deliveryDate: any | null;
  cancelled: boolean;
  deliveryLocation: DeliveryLocation | null;
  deliveredProductIds: string[];
  undeliverableProductIds: string[];
  basketComposition: ClientOrders_clientOrders_productOrders_deliveries_basketComposition | null;
}

export interface ClientOrders_clientOrders_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface ClientOrders_clientOrders_productOrders_product_productPrices {
  __typename: "ProductPrice";
  value: number;
  startDate: any;
  endDate: any | null;
}

export interface ClientOrders_clientOrders_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: ClientOrders_clientOrders_productOrders_product_category | null;
  name: string;
  vat: Vat;
  amount: number | null;
  type: ProductType;
  avgWeight: number | null;
  availableAsAlternativeUnitFor: string[];
  alternativeUnit: ProductUnit | null;
  unit: ProductUnit | null;
  image: string | null;
  productPrices: ClientOrders_clientOrders_productOrders_product_productPrices[] | null;
}

export interface ClientOrders_clientOrders_productOrders {
  __typename: "ProductOrder";
  id: string;
  quantity: number;
  remark: string | null;
  priceExcl: number;
  ambassadorDiscount: boolean;
  frequency: string | null;
  portions: number | null;
  usedPortions: number | null;
  productDescription: string | null;
  unit: ProductUnit | null;
  vat: Vat | null;
  deliveries: ClientOrders_clientOrders_productOrders_deliveries[] | null;
  product: ClientOrders_clientOrders_productOrders_product | null;
}

export interface ClientOrders_clientOrders_client_group {
  __typename: "ClientGroup";
  id: string;
}

export interface ClientOrders_clientOrders_client {
  __typename: "Client";
  id: string;
  type: ClientType | null;
  name: string | null;
  firstName: string | null;
  companyName: string | null;
  street: string | null;
  streetNumber: string | null;
  cityPostalCode: string | null;
  city: string | null;
  vatNumber: string | null;
  email: string;
  billingEmail: string | null;
  group: ClientOrders_clientOrders_client_group;
}

export interface ClientOrders_clientOrders_invoice_payments {
  __typename: "Payment";
  id: string;
  paymentDate: any | null;
  dueDate: any;
  amount: number;
  paid: boolean | null;
}

export interface ClientOrders_clientOrders_invoice {
  __typename: "Invoice";
  id: string;
  type: InvoiceType;
  number: string;
  filename: string;
  file: string | null;
  data: any | null;
  createdAt: any;
  payments: ClientOrders_clientOrders_invoice_payments[] | null;
}

export interface ClientOrders_clientOrders {
  __typename: "ClientOrder";
  id: string;
  createdAt: any;
  status: ClientOrderStatus;
  completionStatus: ClientOrderCompletionStatus | null;
  orderDiscount: number | null;
  orderDate: any;
  delivery: boolean;
  deliveryDate: any;
  deliveryEndDate: any | null;
  productOrders: ClientOrders_clientOrders_productOrders[] | null;
  client: ClientOrders_clientOrders_client;
  invoice: ClientOrders_clientOrders_invoice | null;
}

export interface ClientOrders {
  clientOrders: (ClientOrders_clientOrders | null)[];
}

export interface ClientOrdersVariables {
  where: ClientOrderWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteClientOrder
// ====================================================

export interface DeleteClientOrder_deleteManyProductOrderDeliveries {
  __typename: "BatchPayload";
  /**
   * The number of nodes that have been affected by the Batch operation.
   */
  count: any;
}

export interface DeleteClientOrder_deleteManyProductOrders {
  __typename: "BatchPayload";
  /**
   * The number of nodes that have been affected by the Batch operation.
   */
  count: any;
}

export interface DeleteClientOrder_deleteClientOrder {
  __typename: "ClientOrder";
  id: string;
}

export interface DeleteClientOrder {
  deleteManyProductOrderDeliveries: DeleteClientOrder_deleteManyProductOrderDeliveries;
  deleteManyProductOrders: DeleteClientOrder_deleteManyProductOrders;
  deleteClientOrder: DeleteClientOrder_deleteClientOrder | null;
}

export interface DeleteClientOrderVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClientOrderUpdate
// ====================================================

export interface ClientOrderUpdate_updateClientOrder_productOrders_deliveries_basketComposition_items_product {
  __typename: "Product";
  id: string;
}

export interface ClientOrderUpdate_updateClientOrder_productOrders_deliveries_basketComposition_items {
  __typename: "BasketItem";
  id: string;
  product: ClientOrderUpdate_updateClientOrder_productOrders_deliveries_basketComposition_items_product;
  packagingOrder: number;
  quantitySmall: number;
  portionsSmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
}

export interface ClientOrderUpdate_updateClientOrder_productOrders_deliveries_basketComposition {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
  status: BasketCompositionStatus;
  deliveryWeek: any | null;
  items: ClientOrderUpdate_updateClientOrder_productOrders_deliveries_basketComposition_items[] | null;
}

export interface ClientOrderUpdate_updateClientOrder_productOrders_deliveries {
  __typename: "ProductOrderDelivery";
  id: string;
  plannedDeliveryDate: any;
  deliveryDate: any | null;
  cancelled: boolean;
  deliveryLocation: DeliveryLocation | null;
  deliveredProductIds: string[];
  undeliverableProductIds: string[];
  basketComposition: ClientOrderUpdate_updateClientOrder_productOrders_deliveries_basketComposition | null;
}

export interface ClientOrderUpdate_updateClientOrder_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface ClientOrderUpdate_updateClientOrder_productOrders_product_productPrices {
  __typename: "ProductPrice";
  value: number;
  startDate: any;
  endDate: any | null;
}

export interface ClientOrderUpdate_updateClientOrder_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: ClientOrderUpdate_updateClientOrder_productOrders_product_category | null;
  name: string;
  vat: Vat;
  amount: number | null;
  type: ProductType;
  avgWeight: number | null;
  availableAsAlternativeUnitFor: string[];
  alternativeUnit: ProductUnit | null;
  unit: ProductUnit | null;
  image: string | null;
  productPrices: ClientOrderUpdate_updateClientOrder_productOrders_product_productPrices[] | null;
}

export interface ClientOrderUpdate_updateClientOrder_productOrders {
  __typename: "ProductOrder";
  id: string;
  quantity: number;
  remark: string | null;
  priceExcl: number;
  ambassadorDiscount: boolean;
  frequency: string | null;
  portions: number | null;
  usedPortions: number | null;
  productDescription: string | null;
  unit: ProductUnit | null;
  vat: Vat | null;
  deliveries: ClientOrderUpdate_updateClientOrder_productOrders_deliveries[] | null;
  product: ClientOrderUpdate_updateClientOrder_productOrders_product | null;
}

export interface ClientOrderUpdate_updateClientOrder_client_group {
  __typename: "ClientGroup";
  id: string;
}

export interface ClientOrderUpdate_updateClientOrder_client {
  __typename: "Client";
  id: string;
  type: ClientType | null;
  name: string | null;
  firstName: string | null;
  companyName: string | null;
  street: string | null;
  streetNumber: string | null;
  cityPostalCode: string | null;
  city: string | null;
  vatNumber: string | null;
  email: string;
  billingEmail: string | null;
  group: ClientOrderUpdate_updateClientOrder_client_group;
}

export interface ClientOrderUpdate_updateClientOrder_invoice_payments {
  __typename: "Payment";
  id: string;
  paymentDate: any | null;
  dueDate: any;
  amount: number;
  paid: boolean | null;
}

export interface ClientOrderUpdate_updateClientOrder_invoice {
  __typename: "Invoice";
  id: string;
  type: InvoiceType;
  number: string;
  filename: string;
  file: string | null;
  data: any | null;
  createdAt: any;
  payments: ClientOrderUpdate_updateClientOrder_invoice_payments[] | null;
}

export interface ClientOrderUpdate_updateClientOrder {
  __typename: "ClientOrder";
  id: string;
  createdAt: any;
  status: ClientOrderStatus;
  completionStatus: ClientOrderCompletionStatus | null;
  orderDiscount: number | null;
  orderDate: any;
  delivery: boolean;
  deliveryDate: any;
  deliveryEndDate: any | null;
  productOrders: ClientOrderUpdate_updateClientOrder_productOrders[] | null;
  client: ClientOrderUpdate_updateClientOrder_client;
  invoice: ClientOrderUpdate_updateClientOrder_invoice | null;
}

export interface ClientOrderUpdate {
  updateClientOrder: ClientOrderUpdate_updateClientOrder | null;
}

export interface ClientOrderUpdateVariables {
  where: ClientOrderWhereUniqueInput;
  data: ClientOrderUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateClientOrderInvoice
// ====================================================

export interface CreateClientOrderInvoice_updateSequence {
  __typename: "Sequence";
  id: string;
  sequenceType: SequenceType;
  prefix: string;
  nextValue: number;
  season: string | null;
}

export interface CreateClientOrderInvoice_updateManyClientOrders {
  __typename: "BatchPayload";
  /**
   * The number of nodes that have been affected by the Batch operation.
   */
  count: any;
}

export interface CreateClientOrderInvoice_createInvoice_clientOrders_client {
  __typename: "Client";
  id: string;
  type: ClientType | null;
}

export interface CreateClientOrderInvoice_createInvoice_clientOrders_productOrders_product {
  __typename: "Product";
  id: string;
  vat: Vat;
}

export interface CreateClientOrderInvoice_createInvoice_clientOrders_productOrders {
  __typename: "ProductOrder";
  id: string;
  product: CreateClientOrderInvoice_createInvoice_clientOrders_productOrders_product | null;
  priceExcl: number;
  quantity: number;
  remark: string | null;
  ambassadorDiscount: boolean;
}

export interface CreateClientOrderInvoice_createInvoice_clientOrders_invoice_payments {
  __typename: "Payment";
  id: string;
  paymentDate: any | null;
  dueDate: any;
  amount: number;
  paid: boolean | null;
}

export interface CreateClientOrderInvoice_createInvoice_clientOrders_invoice {
  __typename: "Invoice";
  id: string;
  createdAt: any;
  type: InvoiceType;
  number: string;
  filename: string;
  file: string | null;
  data: any | null;
  payments: CreateClientOrderInvoice_createInvoice_clientOrders_invoice_payments[] | null;
}

export interface CreateClientOrderInvoice_createInvoice_clientOrders {
  __typename: "ClientOrder";
  id: string;
  createdAt: any;
  updatedAt: any;
  orderDate: any;
  orderDiscount: number | null;
  deliveryDate: any;
  delivery: boolean;
  status: ClientOrderStatus;
  client: CreateClientOrderInvoice_createInvoice_clientOrders_client;
  productOrders: CreateClientOrderInvoice_createInvoice_clientOrders_productOrders[] | null;
  invoice: CreateClientOrderInvoice_createInvoice_clientOrders_invoice | null;
}

export interface CreateClientOrderInvoice_createInvoice {
  __typename: "Invoice";
  clientOrders: CreateClientOrderInvoice_createInvoice_clientOrders[] | null;
}

export interface CreateClientOrderInvoice {
  updateSequence: CreateClientOrderInvoice_updateSequence | null;
  updateManyClientOrders: CreateClientOrderInvoice_updateManyClientOrders;
  createInvoice: CreateClientOrderInvoice_createInvoice;
}

export interface CreateClientOrderInvoiceVariables {
  invoiceData: InvoiceCreateInput;
  clientOrders?: string[] | null;
  sequenceId: string;
  nextSequenceValue: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateClientOrderInvoice
// ====================================================

export interface UpdateClientOrderInvoice_deleteManyPayments {
  __typename: "BatchPayload";
  /**
   * The number of nodes that have been affected by the Batch operation.
   */
  count: any;
}

export interface UpdateClientOrderInvoice_deleteInvoice {
  __typename: "Invoice";
  id: string;
}

export interface UpdateClientOrderInvoice_createInvoice_clientOrders_client {
  __typename: "Client";
  id: string;
  type: ClientType | null;
}

export interface UpdateClientOrderInvoice_createInvoice_clientOrders_productOrders_product {
  __typename: "Product";
  id: string;
  vat: Vat;
}

export interface UpdateClientOrderInvoice_createInvoice_clientOrders_productOrders {
  __typename: "ProductOrder";
  id: string;
  product: UpdateClientOrderInvoice_createInvoice_clientOrders_productOrders_product | null;
  priceExcl: number;
  quantity: number;
  remark: string | null;
  ambassadorDiscount: boolean;
}

export interface UpdateClientOrderInvoice_createInvoice_clientOrders_invoice_payments {
  __typename: "Payment";
  id: string;
  paymentDate: any | null;
  dueDate: any;
  amount: number;
  paid: boolean | null;
}

export interface UpdateClientOrderInvoice_createInvoice_clientOrders_invoice {
  __typename: "Invoice";
  id: string;
  createdAt: any;
  type: InvoiceType;
  number: string;
  filename: string;
  file: string | null;
  data: any | null;
  payments: UpdateClientOrderInvoice_createInvoice_clientOrders_invoice_payments[] | null;
}

export interface UpdateClientOrderInvoice_createInvoice_clientOrders {
  __typename: "ClientOrder";
  id: string;
  createdAt: any;
  updatedAt: any;
  orderDate: any;
  deliveryDate: any;
  delivery: boolean;
  status: ClientOrderStatus;
  orderDiscount: number | null;
  client: UpdateClientOrderInvoice_createInvoice_clientOrders_client;
  productOrders: UpdateClientOrderInvoice_createInvoice_clientOrders_productOrders[] | null;
  invoice: UpdateClientOrderInvoice_createInvoice_clientOrders_invoice | null;
}

export interface UpdateClientOrderInvoice_createInvoice {
  __typename: "Invoice";
  clientOrders: UpdateClientOrderInvoice_createInvoice_clientOrders[] | null;
}

export interface UpdateClientOrderInvoice {
  deleteManyPayments: UpdateClientOrderInvoice_deleteManyPayments;
  deleteInvoice: UpdateClientOrderInvoice_deleteInvoice | null;
  createInvoice: UpdateClientOrderInvoice_createInvoice;
}

export interface UpdateClientOrderInvoiceVariables {
  invoiceData: InvoiceCreateInput;
  oldInvoiceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClientOrderUpdatePaymentStatus
// ====================================================

export interface ClientOrderUpdatePaymentStatus_updateClientOrder {
  __typename: "ClientOrder";
  id: string;
  status: ClientOrderStatus;
}

export interface ClientOrderUpdatePaymentStatus_updatePayment {
  __typename: "Payment";
  id: string;
  paymentDate: any | null;
  dueDate: any;
  amount: number;
  paid: boolean | null;
}

export interface ClientOrderUpdatePaymentStatus {
  updateClientOrder: ClientOrderUpdatePaymentStatus_updateClientOrder | null;
  updatePayment: ClientOrderUpdatePaymentStatus_updatePayment | null;
}

export interface ClientOrderUpdatePaymentStatusVariables {
  clientOrderId: string;
  paymentId: string;
  date: any;
  paid: boolean;
  clientOrderStatus: ClientOrderStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Invoices
// ====================================================

export interface Invoices_invoices_clientOrders_client {
  __typename: "Client";
  id: string;
  ibanNumbers: string[];
}

export interface Invoices_invoices_clientOrders {
  __typename: "ClientOrder";
  client: Invoices_invoices_clientOrders_client;
}

export interface Invoices_invoices_payments {
  __typename: "Payment";
  id: string;
  paymentDate: any | null;
  amount: number;
  dueDate: any;
  paid: boolean | null;
  createdAt: any;
}

export interface Invoices_invoices {
  __typename: "Invoice";
  id: string;
  number: string;
  filename: string;
  file: string | null;
  type: InvoiceType;
  createdAt: any;
  data: any | null;
  clientOrders: Invoices_invoices_clientOrders[] | null;
  payments: Invoices_invoices_payments[] | null;
}

export interface Invoices {
  invoices: (Invoices_invoices | null)[];
}

export interface InvoicesVariables {
  where: InvoiceWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Invoice
// ====================================================

export interface Invoice_client_farm {
  __typename: "Farm";
  id: string;
}

export interface Invoice_client_group {
  __typename: "ClientGroup";
  id: string;
}

export interface Invoice_client_bankTransactions {
  __typename: "BankTransaction";
  id: string;
  amount: number;
}

export interface Invoice_client_customerInfo_members {
  __typename: "Member";
  id: string;
  name: string;
  firstName: string;
  dateOfBirth: any;
}

export interface Invoice_client_customerInfo {
  __typename: "CustomerInfo";
  members: Invoice_client_customerInfo_members[] | null;
}

export interface Invoice_client {
  __typename: "Client";
  id: string;
  name: string | null;
  firstName: string | null;
  dateOfBirth: any | null;
  street: string | null;
  streetNumber: string | null;
  city: string | null;
  cityPostalCode: string | null;
  companyName: string | null;
  email: string;
  secondaryEmail: string | null;
  newsletter: boolean | null;
  whatsApp: boolean | null;
  phone: string | null;
  farm: Invoice_client_farm;
  type: ClientType | null;
  billingEmail: string | null;
  vatNumber: string | null;
  remarks: string | null;
  group: Invoice_client_group;
  ibanNumbers: string[];
  bankTransactions: Invoice_client_bankTransactions[] | null;
  auth0userId: string | null;
  customerInfo: Invoice_client_customerInfo | null;
}

export interface Invoice_invoice_clientOrders_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface Invoice_invoice_clientOrders_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: Invoice_invoice_clientOrders_productOrders_product_category | null;
  name: string;
  unit: ProductUnit | null;
  amount: number | null;
  type: ProductType;
  avgWeight: number | null;
  availableAsAlternativeUnitFor: string[];
  alternativeUnit: ProductUnit | null;
  vat: Vat;
}

export interface Invoice_invoice_clientOrders_productOrders_deliveries {
  __typename: "ProductOrderDelivery";
  id: string;
}

export interface Invoice_invoice_clientOrders_productOrders {
  __typename: "ProductOrder";
  id: string;
  productDescription: string | null;
  priceExcl: number;
  product: Invoice_invoice_clientOrders_productOrders_product | null;
  ambassadorDiscount: boolean;
  quantity: number;
  deliveries: Invoice_invoice_clientOrders_productOrders_deliveries[] | null;
}

export interface Invoice_invoice_clientOrders {
  __typename: "ClientOrder";
  id: string;
  deliveryEndDate: any | null;
  deliveryDate: any;
  orderDiscount: number | null;
  delivery: boolean;
  productOrders: Invoice_invoice_clientOrders_productOrders[] | null;
}

export interface Invoice_invoice_payments {
  __typename: "Payment";
  id: string;
  paymentDate: any | null;
  amount: number;
  dueDate: any;
  paid: boolean | null;
  createdAt: any;
}

export interface Invoice_invoice {
  __typename: "Invoice";
  createdAt: any;
  data: any | null;
  filename: string;
  file: string | null;
  id: string;
  number: string;
  type: InvoiceType;
  clientOrders: Invoice_invoice_clientOrders[] | null;
  payments: Invoice_invoice_payments[] | null;
}

export interface Invoice {
  client: (Invoice_client | null)[];
  invoice: Invoice_invoice | null;
}

export interface InvoiceVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InvoiceUpdate
// ====================================================

export interface InvoiceUpdate_updateInvoice {
  __typename: "Invoice";
  id: string;
}

export interface InvoiceUpdate {
  updateInvoice: InvoiceUpdate_updateInvoice | null;
}

export interface InvoiceUpdateVariables {
  id: string;
  data: InvoiceUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteInvoice
// ====================================================

export interface DeleteInvoice_updateManyClientOrders {
  __typename: "BatchPayload";
  /**
   * The number of nodes that have been affected by the Batch operation.
   */
  count: any;
}

export interface DeleteInvoice_deleteManyPayments {
  __typename: "BatchPayload";
  /**
   * The number of nodes that have been affected by the Batch operation.
   */
  count: any;
}

export interface DeleteInvoice_deleteInvoice_clientOrders {
  __typename: "ClientOrder";
  id: string;
  status: ClientOrderStatus;
}

export interface DeleteInvoice_deleteInvoice {
  __typename: "Invoice";
  id: string;
  clientOrders: DeleteInvoice_deleteInvoice_clientOrders[] | null;
}

export interface DeleteInvoice {
  updateManyClientOrders: DeleteInvoice_updateManyClientOrders;
  deleteManyPayments: DeleteInvoice_deleteManyPayments;
  deleteInvoice: DeleteInvoice_deleteInvoice | null;
}

export interface DeleteInvoiceVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProducts
// ====================================================

export interface GetProducts_clientGroup {
  __typename: "ClientGroup";
  code: string | null;
  default: boolean | null;
  discount: number;
  id: string;
  name: string;
}

export interface GetProducts_products_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
  name: string;
  image: string | null;
}

export interface GetProducts_products_latestStock {
  __typename: "ProductStock";
  value: number;
}

export interface GetProducts_products_productAvailabilities_clientGroup {
  __typename: "ClientGroup";
  id: string;
  code: string | null;
}

export interface GetProducts_products_productAvailabilities {
  __typename: "ProductAvailability";
  id: string;
  clientGroup: GetProducts_products_productAvailabilities_clientGroup;
  startDate: any;
  endDate: any | null;
  note: string | null;
}

export interface GetProducts_products_productPrices_clientGroup {
  __typename: "ClientGroup";
  id: string;
  code: string | null;
}

export interface GetProducts_products_productPrices {
  __typename: "ProductPrice";
  id: string;
  clientGroup: GetProducts_products_productPrices_clientGroup | null;
  value: number;
  base: boolean | null;
  endDate: any | null;
  startDate: any;
  note: string | null;
  unit: PriceUnit;
}

export interface GetProducts_products_discounts_clientGroup {
  __typename: "ClientGroup";
  id: string;
}

export interface GetProducts_products_discounts_product {
  __typename: "Product";
  id: string;
}

export interface GetProducts_products_discounts {
  __typename: "ProductDiscount";
  id: string;
  clientGroup: GetProducts_products_discounts_clientGroup;
  discount: number;
  product: GetProducts_products_discounts_product;
}

export interface GetProducts_products_productPromos {
  __typename: "ProductPromo";
  id: string;
  startDate: any;
  endDate: any | null;
  type: ProductPromoType;
  value: number;
  secondValue: number | null;
}

export interface GetProducts_products {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: GetProducts_products_category | null;
  name: string;
  image: string | null;
  unit: ProductUnit | null;
  amount: number | null;
  availableAsAlternativeUnitFor: string[];
  showMinOrderQuantityByDefault: boolean | null;
  minOrderQuantity: number | null;
  alternativeUnit: ProductUnit | null;
  avgWeight: number | null;
  latestStock: GetProducts_products_latestStock[] | null;
  productAvailabilities: GetProducts_products_productAvailabilities[] | null;
  portionDirk: number | null;
  portionWillem: number | null;
  vat: Vat;
  productPrices: GetProducts_products_productPrices[] | null;
  discounts: GetProducts_products_discounts[] | null;
  productPromos: GetProducts_products_productPromos[] | null;
}

export interface GetProducts {
  clientGroup: GetProducts_clientGroup | null;
  products: (GetProducts_products | null)[];
}

export interface GetProductsVariables {
  where: ProductWhereInput;
  farmId: string;
  clientGroupCode?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProducts_Simple
// ====================================================

export interface GetProducts_Simple_products_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
  name: string;
  image: string | null;
}

export interface GetProducts_Simple_products_productPrices_clientGroup {
  __typename: "ClientGroup";
  id: string;
  code: string | null;
}

export interface GetProducts_Simple_products_productPrices {
  __typename: "ProductPrice";
  id: string;
  clientGroup: GetProducts_Simple_products_productPrices_clientGroup | null;
  value: number;
  base: boolean | null;
  endDate: any | null;
  startDate: any;
  note: string | null;
  unit: PriceUnit;
}

export interface GetProducts_Simple_products {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: GetProducts_Simple_products_category | null;
  name: string;
  image: string | null;
  unit: ProductUnit | null;
  amount: number | null;
  availableAsAlternativeUnitFor: string[];
  alternativeUnit: ProductUnit | null;
  avgWeight: number | null;
  minOrderQuantity: number | null;
  portionDirk: number | null;
  portionWillem: number | null;
  vat: Vat;
  productPrices: GetProducts_Simple_products_productPrices[] | null;
}

export interface GetProducts_Simple {
  products: (GetProducts_Simple_products | null)[];
}

export interface GetProducts_SimpleVariables {
  where: ProductWhereInput;
  farmId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Products
// ====================================================

export interface Products_products_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface Products_products_latestStock {
  __typename: "ProductStock";
  value: number;
}

export interface Products_products_productAvailabilities_clientGroup {
  __typename: "ClientGroup";
  id: string;
  code: string | null;
}

export interface Products_products_productAvailabilities {
  __typename: "ProductAvailability";
  id: string;
  clientGroup: Products_products_productAvailabilities_clientGroup;
  startDate: any;
  endDate: any | null;
  note: string | null;
}

export interface Products_products_productPrices_clientGroup {
  __typename: "ClientGroup";
  id: string;
}

export interface Products_products_productPrices {
  __typename: "ProductPrice";
  id: string;
  base: boolean | null;
  note: string | null;
  unit: PriceUnit;
  value: number;
  endDate: any | null;
  startDate: any;
  clientGroup: Products_products_productPrices_clientGroup | null;
}

export interface Products_products_productPromos {
  __typename: "ProductPromo";
  id: string;
  startDate: any;
  endDate: any | null;
}

export interface Products_products {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: Products_products_category | null;
  name: string;
  image: string | null;
  unit: ProductUnit | null;
  amount: number | null;
  availableAsAlternativeUnitFor: string[];
  showMinOrderQuantityByDefault: boolean | null;
  minOrderQuantity: number | null;
  alternativeUnit: ProductUnit | null;
  avgWeight: number | null;
  latestStock: Products_products_latestStock[] | null;
  productAvailabilities: Products_products_productAvailabilities[] | null;
  portionDirk: number | null;
  portionWillem: number | null;
  productPrices: Products_products_productPrices[] | null;
  status: ProductStatus;
  productPromos: Products_products_productPromos[] | null;
}

export interface Products {
  products: (Products_products | null)[];
}

export interface ProductsVariables {
  promoEndDate: any;
  farmId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Product
// ====================================================

export interface Product_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
  name: string;
}

export interface Product_product_latestStock {
  __typename: "ProductStock";
  value: number;
}

export interface Product_product_productAvailabilities_clientGroup {
  __typename: "ClientGroup";
  id: string;
  code: string | null;
}

export interface Product_product_productAvailabilities {
  __typename: "ProductAvailability";
  id: string;
  clientGroup: Product_product_productAvailabilities_clientGroup;
  startDate: any;
  endDate: any | null;
  note: string | null;
}

export interface Product_product_frequencies {
  __typename: "ProductFrequency";
  id: string;
  code: string;
}

export interface Product_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: Product_product_category | null;
  name: string;
  image: string | null;
  unit: ProductUnit | null;
  amount: number | null;
  availableAsAlternativeUnitFor: string[];
  showMinOrderQuantityByDefault: boolean | null;
  minOrderQuantity: number | null;
  alternativeUnit: ProductUnit | null;
  avgWeight: number | null;
  latestStock: Product_product_latestStock[] | null;
  productAvailabilities: Product_product_productAvailabilities[] | null;
  groupName: string | null;
  extra: string | null;
  portionWillem: number | null;
  portionDirk: number | null;
  status: ProductStatus;
  vat: Vat;
  type: ProductType;
  frequencies: Product_product_frequencies[] | null;
  duration: number | null;
}

export interface Product {
  product: Product_product | null;
}

export interface ProductVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductPrices
// ====================================================

export interface ProductPrices_productPrices_clientGroup {
  __typename: "ClientGroup";
  id: string;
  code: string | null;
  default: boolean | null;
}

export interface ProductPrices_productPrices {
  __typename: "ProductPrice";
  id: string;
  value: number;
  base: boolean | null;
  startDate: any;
  note: string | null;
  unit: PriceUnit;
  endDate: any | null;
  clientGroup: ProductPrices_productPrices_clientGroup | null;
}

export interface ProductPrices {
  productPrices: (ProductPrices_productPrices | null)[];
}

export interface ProductPricesVariables {
  productId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductFrequencies
// ====================================================

export interface ProductFrequencies_productFrequencies {
  __typename: "ProductFrequency";
  id: string;
  code: string;
  name: string;
}

export interface ProductFrequencies {
  productFrequencies: (ProductFrequencies_productFrequencies | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateProductPrice
// ====================================================

export interface CreateProductPrice_createProductPrice {
  __typename: "ProductPrice";
  id: string;
}

export interface CreateProductPrice {
  createProductPrice: CreateProductPrice_createProductPrice;
}

export interface CreateProductPriceVariables {
  data: ProductPriceCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateProductPrice
// ====================================================

export interface UpdateProductPrice_updateProductPrice {
  __typename: "ProductPrice";
  id: string;
}

export interface UpdateProductPrice {
  updateProductPrice: UpdateProductPrice_updateProductPrice | null;
}

export interface UpdateProductPriceVariables {
  id: string;
  data: ProductPriceUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductCategories
// ====================================================

export interface ProductCategories_productCategories_category {
  __typename: "ProductCategory";
  id: string;
}

export interface ProductCategories_productCategories {
  __typename: "ProductCategory";
  id: string;
  name: string;
  code: string | null;
  image: string | null;
  visibleToCustomer: boolean;
  category: ProductCategories_productCategories_category | null;
}

export interface ProductCategories {
  productCategories: (ProductCategories_productCategories | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProductCategoryUpsert
// ====================================================

export interface ProductCategoryUpsert_upsertProductCategory_category {
  __typename: "ProductCategory";
  id: string;
}

export interface ProductCategoryUpsert_upsertProductCategory {
  __typename: "ProductCategory";
  id: string;
  name: string;
  code: string | null;
  image: string | null;
  visibleToCustomer: boolean;
  category: ProductCategoryUpsert_upsertProductCategory_category | null;
}

export interface ProductCategoryUpsert {
  upsertProductCategory: ProductCategoryUpsert_upsertProductCategory;
}

export interface ProductCategoryUpsertVariables {
  id?: string | null;
  create: ProductCategoryCreateInput;
  update: ProductCategoryUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProductCategoryUpdate
// ====================================================

export interface ProductCategoryUpdate_updateProductCategory_category {
  __typename: "ProductCategory";
  id: string;
}

export interface ProductCategoryUpdate_updateProductCategory {
  __typename: "ProductCategory";
  id: string;
  name: string;
  code: string | null;
  image: string | null;
  visibleToCustomer: boolean;
  category: ProductCategoryUpdate_updateProductCategory_category | null;
}

export interface ProductCategoryUpdate {
  updateProductCategory: ProductCategoryUpdate_updateProductCategory | null;
}

export interface ProductCategoryUpdateVariables {
  id?: string | null;
  update: ProductCategoryUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProductsForSubscription
// ====================================================

export interface GetProductsForSubscription_products_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface GetProductsForSubscription_products_frequencies {
  __typename: "ProductFrequency";
  id: string;
  code: string;
  name: string;
}

export interface GetProductsForSubscription_products_productPrices {
  __typename: "ProductPrice";
  value: number;
  startDate: any;
  endDate: any | null;
}

export interface GetProductsForSubscription_products {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: GetProductsForSubscription_products_category | null;
  name: string;
  status: ProductStatus;
  type: ProductType;
  vat: Vat;
  frequencies: GetProductsForSubscription_products_frequencies[] | null;
  productPrices: GetProductsForSubscription_products_productPrices[] | null;
}

export interface GetProductsForSubscription {
  products: (GetProductsForSubscription_products | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductStockLatestValue
// ====================================================

export interface ProductStockLatestValue_productStocks {
  __typename: "ProductStock";
  value: number;
}

export interface ProductStockLatestValue {
  productStocks: (ProductStockLatestValue_productStocks | null)[];
}

export interface ProductStockLatestValueVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductOrder
// ====================================================

export interface ProductOrder_productOrders_deliveries_basketComposition {
  __typename: "BasketComposition";
  id: string;
}

export interface ProductOrder_productOrders_deliveries {
  __typename: "ProductOrderDelivery";
  plannedDeliveryDate: any;
  deliveryLocation: DeliveryLocation | null;
  basketComposition: ProductOrder_productOrders_deliveries_basketComposition | null;
}

export interface ProductOrder_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface ProductOrder_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: ProductOrder_productOrders_product_category | null;
}

export interface ProductOrder_productOrders_clientOrder_client {
  __typename: "Client";
  name: string | null;
  firstName: string | null;
}

export interface ProductOrder_productOrders_clientOrder {
  __typename: "ClientOrder";
  deliveryDate: any;
  deliveryEndDate: any | null;
  client: ProductOrder_productOrders_clientOrder_client;
}

export interface ProductOrder_productOrders {
  __typename: "ProductOrder";
  id: string;
  portions: number | null;
  usedPortions: number | null;
  deliveries: ProductOrder_productOrders_deliveries[] | null;
  product: ProductOrder_productOrders_product | null;
  clientOrder: ProductOrder_productOrders_clientOrder;
}

export interface ProductOrder {
  productOrders: (ProductOrder_productOrders | null)[];
}

export interface ProductOrderVariables {
  where: ProductOrderWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductOrdersForBasketComposition
// ====================================================

export interface ProductOrdersForBasketComposition_productOrders_deliveries_basketComposition {
  __typename: "BasketComposition";
  id: string;
}

export interface ProductOrdersForBasketComposition_productOrders_deliveries {
  __typename: "ProductOrderDelivery";
  id: string;
  plannedDeliveryDate: any;
  deliveryLocation: DeliveryLocation | null;
  deliveryDate: any | null;
  cancelled: boolean;
  basketComposition: ProductOrdersForBasketComposition_productOrders_deliveries_basketComposition | null;
}

export interface ProductOrdersForBasketComposition_productOrders_plannedDeliveryForBasketComposition_basketComposition_items_product {
  __typename: "Product";
  id: string;
}

export interface ProductOrdersForBasketComposition_productOrders_plannedDeliveryForBasketComposition_basketComposition_items {
  __typename: "BasketItem";
  product: ProductOrdersForBasketComposition_productOrders_plannedDeliveryForBasketComposition_basketComposition_items_product;
  quantitySmall: number;
  portionsSmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
}

export interface ProductOrdersForBasketComposition_productOrders_plannedDeliveryForBasketComposition_basketComposition {
  __typename: "BasketComposition";
  id: string;
  items: ProductOrdersForBasketComposition_productOrders_plannedDeliveryForBasketComposition_basketComposition_items[] | null;
}

export interface ProductOrdersForBasketComposition_productOrders_plannedDeliveryForBasketComposition {
  __typename: "ProductOrderDelivery";
  id: string;
  plannedDeliveryDate: any;
  deliveryLocation: DeliveryLocation | null;
  deliveryDate: any | null;
  cancelled: boolean;
  deliveredProductIds: string[];
  undeliverableProductIds: string[];
  basketComposition: ProductOrdersForBasketComposition_productOrders_plannedDeliveryForBasketComposition_basketComposition | null;
}

export interface ProductOrdersForBasketComposition_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface ProductOrdersForBasketComposition_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: ProductOrdersForBasketComposition_productOrders_product_category | null;
  name: string;
  portionWillem: number | null;
}

export interface ProductOrdersForBasketComposition_productOrders_clientOrder_client {
  __typename: "Client";
  firstName: string | null;
  name: string | null;
  email: string;
}

export interface ProductOrdersForBasketComposition_productOrders_clientOrder {
  __typename: "ClientOrder";
  id: string;
  client: ProductOrdersForBasketComposition_productOrders_clientOrder_client;
}

export interface ProductOrdersForBasketComposition_productOrders {
  __typename: "ProductOrder";
  id: string;
  portions: number | null;
  usedPortions: number | null;
  deliveries: ProductOrdersForBasketComposition_productOrders_deliveries[] | null;
  plannedDeliveryForBasketComposition: ProductOrdersForBasketComposition_productOrders_plannedDeliveryForBasketComposition[] | null;
  product: ProductOrdersForBasketComposition_productOrders_product | null;
  clientOrder: ProductOrdersForBasketComposition_productOrders_clientOrder;
}

export interface ProductOrdersForBasketComposition {
  productOrders: (ProductOrdersForBasketComposition_productOrders | null)[];
}

export interface ProductOrdersForBasketCompositionVariables {
  basketCompositionId?: string | null;
  plannedDeliveryStartDate: any;
  plannedDeliveryEndDate: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProductOrderUpdate
// ====================================================

export interface ProductOrderUpdate_updateProductOrder {
  __typename: "ProductOrder";
  id: string;
  portions: number | null;
  usedPortions: number | null;
}

export interface ProductOrderUpdate {
  updateProductOrder: ProductOrderUpdate_updateProductOrder | null;
}

export interface ProductOrderUpdateVariables {
  where: ProductOrderWhereUniqueInput;
  data: ProductOrderUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProductOrderUpsert
// ====================================================

export interface ProductOrderUpsert_upsertProductOrder {
  __typename: "ProductOrder";
  id: string;
  portions: number | null;
  usedPortions: number | null;
}

export interface ProductOrderUpsert {
  upsertProductOrder: ProductOrderUpsert_upsertProductOrder;
}

export interface ProductOrderUpsertVariables {
  where: ProductOrderWhereUniqueInput;
  create: ProductOrderCreateInput;
  update: ProductOrderUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProductOrderDelete
// ====================================================

export interface ProductOrderDelete_deleteManyProductOrderDeliveries {
  __typename: "BatchPayload";
  /**
   * The number of nodes that have been affected by the Batch operation.
   */
  count: any;
}

export interface ProductOrderDelete_deleteProductOrder {
  __typename: "ProductOrder";
  id: string;
}

export interface ProductOrderDelete {
  deleteManyProductOrderDeliveries: ProductOrderDelete_deleteManyProductOrderDeliveries;
  deleteProductOrder: ProductOrderDelete_deleteProductOrder | null;
}

export interface ProductOrderDeleteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeliveriesMove
// ====================================================

export interface DeliveriesMove_updateManyProductOrderDeliveries {
  __typename: "BatchPayload";
  /**
   * The number of nodes that have been affected by the Batch operation.
   */
  count: any;
}

export interface DeliveriesMove {
  updateManyProductOrderDeliveries: DeliveriesMove_updateManyProductOrderDeliveries;
}

export interface DeliveriesMoveVariables {
  deliveryIds: string[];
  plannedDeliveryDate: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DeliveriesForDeliveryWeek
// ====================================================

export interface DeliveriesForDeliveryWeek_basketCompositions_items_product {
  __typename: "Product";
  id: string;
}

export interface DeliveriesForDeliveryWeek_basketCompositions_items {
  __typename: "BasketItem";
  id: string;
  product: DeliveriesForDeliveryWeek_basketCompositions_items_product;
  packagingOrder: number;
  quantitySmall: number;
  portionsSmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
}

export interface DeliveriesForDeliveryWeek_basketCompositions {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
  status: BasketCompositionStatus;
  deliveryWeek: any | null;
  items: DeliveriesForDeliveryWeek_basketCompositions_items[] | null;
}

export interface DeliveriesForDeliveryWeek_shopOrders_shopClient {
  __typename: "ShopClient";
  id: string;
  phone: string;
  name: string;
  email: string;
  firstName: string;
}

export interface DeliveriesForDeliveryWeek_shopOrders_shopOrderItems_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
  name: string;
}

export interface DeliveriesForDeliveryWeek_shopOrders_shopOrderItems_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: DeliveriesForDeliveryWeek_shopOrders_shopOrderItems_product_category | null;
  image: string | null;
  name: string;
  amount: number | null;
  unit: ProductUnit | null;
  availableAsAlternativeUnitFor: string[];
  alternativeUnit: ProductUnit | null;
  avgWeight: number | null;
}

export interface DeliveriesForDeliveryWeek_shopOrders_shopOrderItems_currentPromo {
  __typename: "ProductPromo";
  id: string;
}

export interface DeliveriesForDeliveryWeek_shopOrders_shopOrderItems {
  __typename: "ShopOrderItem";
  id: string;
  delivered: boolean;
  product: DeliveriesForDeliveryWeek_shopOrders_shopOrderItems_product;
  currentPromo: DeliveriesForDeliveryWeek_shopOrders_shopOrderItems_currentPromo | null;
  amount: number;
  priceIncl: number;
}

export interface DeliveriesForDeliveryWeek_shopOrders {
  __typename: "ShopOrder";
  id: string;
  orderDate: any;
  deliveryDate: any;
  deliveryLocation: DeliveryLocation;
  paid: boolean;
  status: ShopOrderStatus;
  extraPackaging: boolean;
  promocode: string | null;
  createdAt: any;
  shopClient: DeliveriesForDeliveryWeek_shopOrders_shopClient;
  shopOrderItems: DeliveriesForDeliveryWeek_shopOrders_shopOrderItems[] | null;
}

export interface DeliveriesForDeliveryWeek_productOrderDeliveries_productOrder_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
  name: string;
}

export interface DeliveriesForDeliveryWeek_productOrderDeliveries_productOrder_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: DeliveriesForDeliveryWeek_productOrderDeliveries_productOrder_product_category | null;
  name: string;
  amount: number | null;
  unit: ProductUnit | null;
  availableAsAlternativeUnitFor: string[];
  alternativeUnit: ProductUnit | null;
  avgWeight: number | null;
}

export interface DeliveriesForDeliveryWeek_productOrderDeliveries_productOrder_clientOrder_client {
  __typename: "Client";
  id: string;
  name: string | null;
  firstName: string | null;
  communicationGoudenWortel: boolean;
}

export interface DeliveriesForDeliveryWeek_productOrderDeliveries_productOrder_clientOrder {
  __typename: "ClientOrder";
  client: DeliveriesForDeliveryWeek_productOrderDeliveries_productOrder_clientOrder_client;
}

export interface DeliveriesForDeliveryWeek_productOrderDeliveries_productOrder {
  __typename: "ProductOrder";
  quantity: number;
  product: DeliveriesForDeliveryWeek_productOrderDeliveries_productOrder_product | null;
  clientOrder: DeliveriesForDeliveryWeek_productOrderDeliveries_productOrder_clientOrder;
}

export interface DeliveriesForDeliveryWeek_productOrderDeliveries_basketComposition {
  __typename: "BasketComposition";
  id: string;
}

export interface DeliveriesForDeliveryWeek_productOrderDeliveries {
  __typename: "ProductOrderDelivery";
  id: string;
  plannedDeliveryDate: any;
  deliveryDate: any | null;
  deliveryLocation: DeliveryLocation | null;
  deliveredProductIds: string[];
  undeliverableProductIds: string[];
  productOrder: DeliveriesForDeliveryWeek_productOrderDeliveries_productOrder;
  basketComposition: DeliveriesForDeliveryWeek_productOrderDeliveries_basketComposition | null;
}

export interface DeliveriesForDeliveryWeek_clientOrders_client_group {
  __typename: "ClientGroup";
  id: string;
}

export interface DeliveriesForDeliveryWeek_clientOrders_client {
  __typename: "Client";
  id: string;
  name: string | null;
  firstName: string | null;
  group: DeliveriesForDeliveryWeek_clientOrders_client_group;
}

export interface DeliveriesForDeliveryWeek_clientOrders_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
  name: string;
}

export interface DeliveriesForDeliveryWeek_clientOrders_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: DeliveriesForDeliveryWeek_clientOrders_productOrders_product_category | null;
  name: string;
  amount: number | null;
  unit: ProductUnit | null;
  availableAsAlternativeUnitFor: string[];
  alternativeUnit: ProductUnit | null;
  avgWeight: number | null;
}

export interface DeliveriesForDeliveryWeek_clientOrders_productOrders {
  __typename: "ProductOrder";
  deliveryDate: any | null;
  quantity: number;
  product: DeliveriesForDeliveryWeek_clientOrders_productOrders_product | null;
}

export interface DeliveriesForDeliveryWeek_clientOrders {
  __typename: "ClientOrder";
  id: string;
  client: DeliveriesForDeliveryWeek_clientOrders_client;
  status: ClientOrderStatus;
  deliveryDate: any;
  productOrders: DeliveriesForDeliveryWeek_clientOrders_productOrders[] | null;
}

export interface DeliveriesForDeliveryWeek {
  basketCompositions: (DeliveriesForDeliveryWeek_basketCompositions | null)[];
  shopOrders: (DeliveriesForDeliveryWeek_shopOrders | null)[];
  productOrderDeliveries: (DeliveriesForDeliveryWeek_productOrderDeliveries | null)[];
  clientOrders: (DeliveriesForDeliveryWeek_clientOrders | null)[];
}

export interface DeliveriesForDeliveryWeekVariables {
  deliveryWeekStart: any;
  deliveryWeekEnd: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClientWithDeliveriesForPackaging
// ====================================================

export interface ClientWithDeliveriesForPackaging_client {
  __typename: "Client";
  id: string;
  name: string | null;
  firstName: string | null;
  communicationGoudenWortel: boolean;
  email: string;
  phone: string | null;
}

export interface ClientWithDeliveriesForPackaging_basketCompositions {
  __typename: "BasketComposition";
  id: string;
}

export interface ClientWithDeliveriesForPackaging_shopOrders_shopClient {
  __typename: "ShopClient";
  id: string;
  phone: string;
  name: string;
  email: string;
  firstName: string;
}

export interface ClientWithDeliveriesForPackaging_shopOrders_shopOrderItems_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
  name: string;
}

export interface ClientWithDeliveriesForPackaging_shopOrders_shopOrderItems_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: ClientWithDeliveriesForPackaging_shopOrders_shopOrderItems_product_category | null;
  image: string | null;
  name: string;
  amount: number | null;
  unit: ProductUnit | null;
}

export interface ClientWithDeliveriesForPackaging_shopOrders_shopOrderItems_currentPromo {
  __typename: "ProductPromo";
  id: string;
}

export interface ClientWithDeliveriesForPackaging_shopOrders_shopOrderItems {
  __typename: "ShopOrderItem";
  id: string;
  delivered: boolean;
  product: ClientWithDeliveriesForPackaging_shopOrders_shopOrderItems_product;
  currentPromo: ClientWithDeliveriesForPackaging_shopOrders_shopOrderItems_currentPromo | null;
  amount: number;
  priceIncl: number;
}

export interface ClientWithDeliveriesForPackaging_shopOrders {
  __typename: "ShopOrder";
  id: string;
  orderDate: any;
  deliveryDate: any;
  deliveryLocation: DeliveryLocation;
  paid: boolean;
  status: ShopOrderStatus;
  extraPackaging: boolean;
  createdAt: any;
  shopClient: ClientWithDeliveriesForPackaging_shopOrders_shopClient;
  shopOrderItems: ClientWithDeliveriesForPackaging_shopOrders_shopOrderItems[] | null;
}

export interface ClientWithDeliveriesForPackaging_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
  name: string;
}

export interface ClientWithDeliveriesForPackaging_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: ClientWithDeliveriesForPackaging_productOrders_product_category | null;
  type: ProductType;
  image: string | null;
  avgWeight: number | null;
  alternativeUnit: ProductUnit | null;
  availableAsAlternativeUnitFor: string[];
  name: string;
  amount: number | null;
  unit: ProductUnit | null;
}

export interface ClientWithDeliveriesForPackaging_productOrders_clientOrder_productOrders {
  __typename: "ProductOrder";
  id: string;
  deliveryDate: any | null;
}

export interface ClientWithDeliveriesForPackaging_productOrders_clientOrder_client_group {
  __typename: "ClientGroup";
  id: string;
}

export interface ClientWithDeliveriesForPackaging_productOrders_clientOrder_client {
  __typename: "Client";
  email: string;
  group: ClientWithDeliveriesForPackaging_productOrders_clientOrder_client_group;
}

export interface ClientWithDeliveriesForPackaging_productOrders_clientOrder {
  __typename: "ClientOrder";
  id: string;
  status: ClientOrderStatus;
  productOrders: ClientWithDeliveriesForPackaging_productOrders_clientOrder_productOrders[] | null;
  client: ClientWithDeliveriesForPackaging_productOrders_clientOrder_client;
}

export interface ClientWithDeliveriesForPackaging_productOrders_allDeliveries_basketComposition {
  __typename: "BasketComposition";
  id: string;
}

export interface ClientWithDeliveriesForPackaging_productOrders_allDeliveries {
  __typename: "ProductOrderDelivery";
  id: string;
  plannedDeliveryDate: any;
  deliveryDate: any | null;
  deliveryLocation: DeliveryLocation | null;
  cancelled: boolean;
  basketComposition: ClientWithDeliveriesForPackaging_productOrders_allDeliveries_basketComposition | null;
}

export interface ClientWithDeliveriesForPackaging_productOrders_nextDelivery_basketComposition_items_product {
  __typename: "Product";
  id: string;
}

export interface ClientWithDeliveriesForPackaging_productOrders_nextDelivery_basketComposition_items {
  __typename: "BasketItem";
  id: string;
  product: ClientWithDeliveriesForPackaging_productOrders_nextDelivery_basketComposition_items_product;
  packagingOrder: number;
  quantitySmall: number;
  portionsSmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
}

export interface ClientWithDeliveriesForPackaging_productOrders_nextDelivery_basketComposition {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
  status: BasketCompositionStatus;
  deliveryWeek: any | null;
  items: ClientWithDeliveriesForPackaging_productOrders_nextDelivery_basketComposition_items[] | null;
}

export interface ClientWithDeliveriesForPackaging_productOrders_nextDelivery {
  __typename: "ProductOrderDelivery";
  id: string;
  plannedDeliveryDate: any;
  deliveryDate: any | null;
  cancelled: boolean;
  deliveryLocation: DeliveryLocation | null;
  deliveredProductIds: string[];
  undeliverableProductIds: string[];
  basketComposition: ClientWithDeliveriesForPackaging_productOrders_nextDelivery_basketComposition | null;
}

export interface ClientWithDeliveriesForPackaging_productOrders {
  __typename: "ProductOrder";
  id: string;
  quantity: number;
  product: ClientWithDeliveriesForPackaging_productOrders_product | null;
  deliveryDate: any | null;
  usedPortions: number | null;
  portions: number | null;
  clientOrder: ClientWithDeliveriesForPackaging_productOrders_clientOrder;
  allDeliveries: ClientWithDeliveriesForPackaging_productOrders_allDeliveries[] | null;
  nextDelivery: ClientWithDeliveriesForPackaging_productOrders_nextDelivery[] | null;
}

export interface ClientWithDeliveriesForPackaging {
  client: ClientWithDeliveriesForPackaging_client | null;
  basketCompositions: (ClientWithDeliveriesForPackaging_basketCompositions | null)[];
  shopOrders: (ClientWithDeliveriesForPackaging_shopOrders | null)[];
  productOrders: (ClientWithDeliveriesForPackaging_productOrders | null)[];
}

export interface ClientWithDeliveriesForPackagingVariables {
  clientId: string;
  deliveryWeekStart: any;
  deliveryWeekEnd: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeliveryUpdate
// ====================================================

export interface DeliveryUpdate_updateProductOrderDelivery {
  __typename: "ProductOrderDelivery";
  id: string;
}

export interface DeliveryUpdate {
  updateProductOrderDelivery: DeliveryUpdate_updateProductOrderDelivery | null;
}

export interface DeliveryUpdateVariables {
  id: string;
  data: ProductOrderDeliveryUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeliveriesUpdate
// ====================================================

export interface DeliveriesUpdate_updateManyProductOrderDeliveries {
  __typename: "BatchPayload";
  /**
   * The number of nodes that have been affected by the Batch operation.
   */
  count: any;
}

export interface DeliveriesUpdate {
  updateManyProductOrderDeliveries: DeliveriesUpdate_updateManyProductOrderDeliveries;
}

export interface DeliveriesUpdateVariables {
  where: ProductOrderDeliveryWhereInput;
  data: ProductOrderDeliveryUpdateManyMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Sequences
// ====================================================

export interface Sequences_sequences {
  __typename: "Sequence";
  id: string;
  sequenceType: SequenceType;
  prefix: string;
  nextValue: number;
  season: string | null;
}

export interface Sequences {
  sequences: (Sequences_sequences | null)[];
}

export interface SequencesVariables {
  where: SequenceWhereInput;
  farmId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUser
// ====================================================

export interface CreateUser_createUser {
  __typename: "User";
  id: string;
}

export interface CreateUser {
  createUser: CreateUser_createUser;
}

export interface CreateUserVariables {
  data: UserCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserDetails
// ====================================================

export interface UserDetails_user {
  __typename: "User";
  id: string;
  auth0userId: string | null;
  name: string;
  firstName: string;
  email: string;
  secondaryEmail: string | null;
  street: string | null;
  streetNumber: string | null;
  city: string | null;
  cityPostalCode: string | null;
  phone: string | null;
  dateOfBirth: any | null;
}

export interface UserDetails {
  user: UserDetails_user | null;
}

export interface UserDetailsVariables {
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BankTransactionFields
// ====================================================

export interface BankTransactionFields_bankAccount {
  __typename: "BankAccount";
  id: string;
}

export interface BankTransactionFields_client {
  __typename: "Client";
  id: string;
}

export interface BankTransactionFields {
  __typename: "BankTransaction";
  id: string;
  date: any;
  amount: number;
  ibanNumber: string | null;
  bic: string | null;
  name: string | null;
  address: string | null;
  sct: string | null;
  description: string | null;
  bankAccount: BankTransactionFields_bankAccount | null;
  bankAccountBalance: number;
  client: BankTransactionFields_client | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BasketCompositionFields
// ====================================================

export interface BasketCompositionFields_items_product {
  __typename: "Product";
  id: string;
}

export interface BasketCompositionFields_items {
  __typename: "BasketItem";
  id: string;
  product: BasketCompositionFields_items_product;
  packagingOrder: number;
  quantitySmall: number;
  portionsSmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
}

export interface BasketCompositionFields {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
  status: BasketCompositionStatus;
  deliveryWeek: any | null;
  items: BasketCompositionFields_items[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientFields
// ====================================================

export interface ClientFields_farm {
  __typename: "Farm";
  id: string;
}

export interface ClientFields_group {
  __typename: "ClientGroup";
  id: string;
}

export interface ClientFields_bankTransactions {
  __typename: "BankTransaction";
  id: string;
  amount: number;
}

export interface ClientFields_customerInfo_members {
  __typename: "Member";
  id: string;
  name: string;
  firstName: string;
  dateOfBirth: any;
}

export interface ClientFields_customerInfo {
  __typename: "CustomerInfo";
  members: ClientFields_customerInfo_members[] | null;
}

export interface ClientFields {
  __typename: "Client";
  id: string;
  name: string | null;
  firstName: string | null;
  dateOfBirth: any | null;
  street: string | null;
  streetNumber: string | null;
  city: string | null;
  cityPostalCode: string | null;
  companyName: string | null;
  email: string;
  secondaryEmail: string | null;
  newsletter: boolean | null;
  whatsApp: boolean | null;
  phone: string | null;
  farm: ClientFields_farm;
  type: ClientType | null;
  billingEmail: string | null;
  vatNumber: string | null;
  remarks: string | null;
  group: ClientFields_group;
  ibanNumbers: string[];
  bankTransactions: ClientFields_bankTransactions[] | null;
  auth0userId: string | null;
  customerInfo: ClientFields_customerInfo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientOrderFields
// ====================================================

export interface ClientOrderFields_productOrders_deliveries_basketComposition_items_product {
  __typename: "Product";
  id: string;
}

export interface ClientOrderFields_productOrders_deliveries_basketComposition_items {
  __typename: "BasketItem";
  id: string;
  product: ClientOrderFields_productOrders_deliveries_basketComposition_items_product;
  packagingOrder: number;
  quantitySmall: number;
  portionsSmall: number;
  deliveryLocationsSmall: DeliveryLocation[];
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsLarge: DeliveryLocation[];
}

export interface ClientOrderFields_productOrders_deliveries_basketComposition {
  __typename: "BasketComposition";
  id: string;
  type: BasketCompositionType;
  status: BasketCompositionStatus;
  deliveryWeek: any | null;
  items: ClientOrderFields_productOrders_deliveries_basketComposition_items[] | null;
}

export interface ClientOrderFields_productOrders_deliveries {
  __typename: "ProductOrderDelivery";
  id: string;
  plannedDeliveryDate: any;
  deliveryDate: any | null;
  cancelled: boolean;
  deliveryLocation: DeliveryLocation | null;
  deliveredProductIds: string[];
  undeliverableProductIds: string[];
  basketComposition: ClientOrderFields_productOrders_deliveries_basketComposition | null;
}

export interface ClientOrderFields_productOrders_product_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface ClientOrderFields_productOrders_product_productPrices {
  __typename: "ProductPrice";
  value: number;
  startDate: any;
  endDate: any | null;
}

export interface ClientOrderFields_productOrders_product {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: ClientOrderFields_productOrders_product_category | null;
  name: string;
  vat: Vat;
  amount: number | null;
  type: ProductType;
  avgWeight: number | null;
  availableAsAlternativeUnitFor: string[];
  alternativeUnit: ProductUnit | null;
  unit: ProductUnit | null;
  image: string | null;
  productPrices: ClientOrderFields_productOrders_product_productPrices[] | null;
}

export interface ClientOrderFields_productOrders {
  __typename: "ProductOrder";
  id: string;
  quantity: number;
  remark: string | null;
  priceExcl: number;
  ambassadorDiscount: boolean;
  frequency: string | null;
  portions: number | null;
  usedPortions: number | null;
  productDescription: string | null;
  unit: ProductUnit | null;
  vat: Vat | null;
  deliveries: ClientOrderFields_productOrders_deliveries[] | null;
  product: ClientOrderFields_productOrders_product | null;
}

export interface ClientOrderFields_client_group {
  __typename: "ClientGroup";
  id: string;
}

export interface ClientOrderFields_client {
  __typename: "Client";
  id: string;
  type: ClientType | null;
  name: string | null;
  firstName: string | null;
  companyName: string | null;
  street: string | null;
  streetNumber: string | null;
  cityPostalCode: string | null;
  city: string | null;
  vatNumber: string | null;
  email: string;
  billingEmail: string | null;
  group: ClientOrderFields_client_group;
}

export interface ClientOrderFields_invoice_payments {
  __typename: "Payment";
  id: string;
  paymentDate: any | null;
  dueDate: any;
  amount: number;
  paid: boolean | null;
}

export interface ClientOrderFields_invoice {
  __typename: "Invoice";
  id: string;
  type: InvoiceType;
  number: string;
  filename: string;
  file: string | null;
  data: any | null;
  createdAt: any;
  payments: ClientOrderFields_invoice_payments[] | null;
}

export interface ClientOrderFields {
  __typename: "ClientOrder";
  id: string;
  createdAt: any;
  status: ClientOrderStatus;
  completionStatus: ClientOrderCompletionStatus | null;
  orderDiscount: number | null;
  orderDate: any;
  delivery: boolean;
  deliveryDate: any;
  deliveryEndDate: any | null;
  productOrders: ClientOrderFields_productOrders[] | null;
  client: ClientOrderFields_client;
  invoice: ClientOrderFields_invoice | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductFields_basic
// ====================================================

export interface ProductFields_basic_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface ProductFields_basic {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: ProductFields_basic_category | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductFields
// ====================================================

export interface ProductFields_category {
  __typename: "ProductCategory";
  id: string;
  code: string | null;
}

export interface ProductFields_latestStock {
  __typename: "ProductStock";
  value: number;
}

export interface ProductFields_productAvailabilities_clientGroup {
  __typename: "ClientGroup";
  id: string;
  code: string | null;
}

export interface ProductFields_productAvailabilities {
  __typename: "ProductAvailability";
  id: string;
  clientGroup: ProductFields_productAvailabilities_clientGroup;
  startDate: any;
  endDate: any | null;
  note: string | null;
}

export interface ProductFields {
  __typename: "Product";
  id: string;
  code: string | null;
  flex: boolean | null;
  category: ProductFields_category | null;
  name: string;
  image: string | null;
  unit: ProductUnit | null;
  amount: number | null;
  availableAsAlternativeUnitFor: string[];
  showMinOrderQuantityByDefault: boolean | null;
  minOrderQuantity: number | null;
  alternativeUnit: ProductUnit | null;
  avgWeight: number | null;
  latestStock: ProductFields_latestStock[] | null;
  productAvailabilities: ProductFields_productAvailabilities[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductOrderFields
// ====================================================

export interface ProductOrderFields {
  __typename: "ProductOrder";
  id: string;
  portions: number | null;
  usedPortions: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AmbassadorOption {
  AMBASSADOR_100 = "AMBASSADOR_100",
  AMBASSADOR_200 = "AMBASSADOR_200",
  AMBASSADOR_275 = "AMBASSADOR_275",
  AMBASSADOR_50 = "AMBASSADOR_50",
}

export enum BasketCompositionStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}

export enum BasketCompositionType {
  CUSTOMER_BASED = "CUSTOMER_BASED",
  FIXED = "FIXED",
}

export enum BasketSize {
  LARGE = "LARGE",
  SMALL = "SMALL",
}

export enum BasketType {
  LARGE = "LARGE",
  STANDARD = "STANDARD",
}

export enum BiWeek {
  EVEN = "EVEN",
  ODD = "ODD",
}

export enum ClientOrderCompletionStatus {
  RESUBSCRIBED = "RESUBSCRIBED",
  TERMINATED = "TERMINATED",
  UNSUBSCRIBED = "UNSUBSCRIBED",
}

export enum ClientOrderStatus {
  CREATED = "CREATED",
  DELIVERED = "DELIVERED",
  INVOICED = "INVOICED",
  INVOICE_SENT = "INVOICE_SENT",
  PAID = "PAID",
  PAID_PARTIALLY = "PAID_PARTIALLY",
  PAYMENT_OVERDUE = "PAYMENT_OVERDUE",
}

export enum ClientType {
  BUSINESS = "BUSINESS",
  CUSTOMER = "CUSTOMER",
}

export enum CultivationEventType {
  BED_PREP = "BED_PREP",
  FERTILIZE = "FERTILIZE",
  FLAMEWEED = "FLAMEWEED",
  HARVEST = "HARVEST",
  HARVEST_END = "HARVEST_END",
  PLANT = "PLANT",
  PRESEED = "PRESEED",
  SEED = "SEED",
  WEED = "WEED",
}

export enum DeliveryLocation {
  ARENDONK = "ARENDONK",
  BAARLE_NASSAU = "BAARLE_NASSAU",
  MERKSPLAS = "MERKSPLAS",
  MOSTERDZAADJE_BALEN = "MOSTERDZAADJE_BALEN",
  MOSTERDZAADJE_BALEN_BROODVERHAAL = "MOSTERDZAADJE_BALEN_BROODVERHAAL",
  MOSTERDZAADJE_GEEL = "MOSTERDZAADJE_GEEL",
  MOSTERDZAADJE_MILLEGEM = "MOSTERDZAADJE_MILLEGEM",
  MOSTERDZAADJE_MOL_ACHTERBOS = "MOSTERDZAADJE_MOL_ACHTERBOS",
  MOSTERDZAADJE_MOL_EUROPA = "MOSTERDZAADJE_MOL_EUROPA",
  MOSTERDZAADJE_MOL_OXFAM = "MOSTERDZAADJE_MOL_OXFAM",
  OUD_TURNHOUT = "OUD_TURNHOUT",
  RAVELS = "RAVELS",
  TURNHOUT = "TURNHOUT",
  WEELDE = "WEELDE",
}

export enum DiscountType {
  FIXED = "FIXED",
  PERCENTAGE = "PERCENTAGE",
}

export enum FieldType {
  GREENHOUSE = "GREENHOUSE",
  OPEN = "OPEN",
}

export enum Frequency {
  BI_WEEKLY = "BI_WEEKLY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
  YEARLY = "YEARLY",
}

export enum GoogleShopStatus {
  ARCHIVED = "ARCHIVED",
  DELIVERED = "DELIVERED",
  INVOICED = "INVOICED",
  PAID = "PAID",
  PAID_CASH = "PAID_CASH",
  SCHEDULED_FOR_DELIVERY = "SCHEDULED_FOR_DELIVERY",
  UNDELIVERED = "UNDELIVERED",
}

export enum Gridder {
  FOUR_WHEELS = "FOUR_WHEELS",
  SIX_WHEELS = "SIX_WHEELS",
  THREE_WHEELS = "THREE_WHEELS",
  TWO_WHEELS = "TWO_WHEELS",
}

export enum InvoiceType {
  BASKET = "BASKET",
  BASKET_TRIAL = "BASKET_TRIAL",
  CLIENT = "CLIENT",
  GOOGLE_SHOP = "GOOGLE_SHOP",
  PRO_FORMA_BASKET = "PRO_FORMA_BASKET",
  PRO_FORMA_SELF_HARVEST = "PRO_FORMA_SELF_HARVEST",
  SELF_HARVEST = "SELF_HARVEST",
  SELF_HARVEST_TRIAL = "SELF_HARVEST_TRIAL",
}

export enum MembershipExtraType {
  EGGS = "EGGS",
  FLOWERS = "FLOWERS",
  ONIONS = "ONIONS",
  POTATOES = "POTATOES",
}

export enum MembershipSubType {
  SEASONAL_BASKET = "SEASONAL_BASKET",
  SELF_HARVEST = "SELF_HARVEST",
  WINTER_BASKET = "WINTER_BASKET",
  YEAR_BASKET = "YEAR_BASKET",
}

export enum MembershipType {
  BASKETS = "BASKETS",
  SELF_HARVEST = "SELF_HARVEST",
}

export enum Permission {
  CLIENT_BASKET = "CLIENT_BASKET",
  CLIENT_SELF_HARVEST = "CLIENT_SELF_HARVEST",
  FARM_MANAGEMENT_ADMIN = "FARM_MANAGEMENT_ADMIN",
  PLANNER_ADMIN = "PLANNER_ADMIN",
  PLANNER_FARM_ADMIN = "PLANNER_FARM_ADMIN",
}

export enum PlantOrSeed {
  PLANT = "PLANT",
  SEED = "SEED",
}

export enum PriceUnit {
  CURRENCY = "CURRENCY",
  PORTION = "PORTION",
}

export enum ProductPromoType {
  DISCOUNT_PERCENTAGE = "DISCOUNT_PERCENTAGE",
  FIXED_PRICE = "FIXED_PRICE",
  PLUS_ONE = "PLUS_ONE",
}

export enum ProductStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  HIDDEN = "HIDDEN",
}

export enum ProductType {
  SIMPLE = "SIMPLE",
  SUBSCRIPTION = "SUBSCRIPTION",
}

export enum ProductUnit {
  BUNCH = "BUNCH",
  GRAMS = "GRAMS",
  KILOGRAMS = "KILOGRAMS",
  PIECE = "PIECE",
}

export enum Season {
  S_2019_2020 = "S_2019_2020",
  S_2020_2021 = "S_2020_2021",
  S_2021_2022 = "S_2021_2022",
  S_2022_2023 = "S_2022_2023",
  S_2023_2024 = "S_2023_2024",
  S_2024_2025 = "S_2024_2025",
}

export enum SequenceType {
  INVOICE_BASKET = "INVOICE_BASKET",
  INVOICE_CLIENT = "INVOICE_CLIENT",
  INVOICE_GOOGLE_SHOP = "INVOICE_GOOGLE_SHOP",
  INVOICE_SELF_HARVEST = "INVOICE_SELF_HARVEST",
  PRO_FORMA_INVOICE_BASKET = "PRO_FORMA_INVOICE_BASKET",
  PRO_FORMA_INVOICE_SELF_HARVEST = "PRO_FORMA_INVOICE_SELF_HARVEST",
  SHOP_ORDER = "SHOP_ORDER",
}

export enum ShopOrderStatus {
  DELIVERED = "DELIVERED",
  NEW = "NEW",
  UNDELIVERED = "UNDELIVERED",
}

export enum Source {
  GROENTEGEWELD = "GROENTEGEWELD",
  INAGRO = "INAGRO",
}

export enum Target {
  BASKETS = "BASKETS",
  MIXED = "MIXED",
  SELF_HARVEST = "SELF_HARVEST",
}

export enum TaskStatus {
  DONE = "DONE",
  IN_PROGRESS = "IN_PROGRESS",
  TODO = "TODO",
}

export enum TaskType {
  FERTILISATION = "FERTILISATION",
  FLAMEWEEDING = "FLAMEWEEDING",
  MINERALISATION = "MINERALISATION",
  PLANTING = "PLANTING",
  PRESEEDING = "PRESEEDING",
  SEEDING = "SEEDING",
}

export enum Vat {
  VAT_0 = "VAT_0",
  VAT_12 = "VAT_12",
  VAT_21 = "VAT_21",
  VAT_6 = "VAT_6",
}

export interface BankAccountCreateOneWithoutTransactionsInput {
  create?: BankAccountCreateWithoutTransactionsInput | null;
  connect?: BankAccountWhereUniqueInput | null;
}

export interface BankAccountCreateWithoutTransactionsInput {
  id?: string | null;
  ibanNumber: string;
  name: string;
}

export interface BankAccountUpdateOneWithoutTransactionsInput {
  create?: BankAccountCreateWithoutTransactionsInput | null;
  connect?: BankAccountWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: BankAccountUpdateWithoutTransactionsDataInput | null;
  upsert?: BankAccountUpsertWithoutTransactionsInput | null;
}

export interface BankAccountUpdateWithoutTransactionsDataInput {
  ibanNumber?: string | null;
  name?: string | null;
}

export interface BankAccountUpsertWithoutTransactionsInput {
  update: BankAccountUpdateWithoutTransactionsDataInput;
  create: BankAccountCreateWithoutTransactionsInput;
}

export interface BankAccountWhereInput {
  AND?: BankAccountWhereInput[] | null;
  OR?: BankAccountWhereInput[] | null;
  NOT?: BankAccountWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  ibanNumber?: string | null;
  ibanNumber_not?: string | null;
  ibanNumber_in?: string[] | null;
  ibanNumber_not_in?: string[] | null;
  ibanNumber_lt?: string | null;
  ibanNumber_lte?: string | null;
  ibanNumber_gt?: string | null;
  ibanNumber_gte?: string | null;
  ibanNumber_contains?: string | null;
  ibanNumber_not_contains?: string | null;
  ibanNumber_starts_with?: string | null;
  ibanNumber_not_starts_with?: string | null;
  ibanNumber_ends_with?: string | null;
  ibanNumber_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  transactions_every?: BankTransactionWhereInput | null;
  transactions_some?: BankTransactionWhereInput | null;
  transactions_none?: BankTransactionWhereInput | null;
}

export interface BankAccountWhereUniqueInput {
  id?: string | null;
  ibanNumber?: string | null;
}

export interface BankTransactionCreateManyWithoutClientInput {
  create?: BankTransactionCreateWithoutClientInput[] | null;
  connect?: BankTransactionWhereUniqueInput[] | null;
}

export interface BankTransactionCreateWithoutBankAccountInput {
  id?: string | null;
  date: any;
  amount: number;
  ibanNumber?: string | null;
  bic?: string | null;
  name?: string | null;
  address?: string | null;
  sct?: string | null;
  description?: string | null;
  bankAccountBalance: number;
  client?: ClientCreateOneWithoutBankTransactionsInput | null;
}

export interface BankTransactionCreateWithoutClientInput {
  id?: string | null;
  date: any;
  amount: number;
  ibanNumber?: string | null;
  bic?: string | null;
  name?: string | null;
  address?: string | null;
  sct?: string | null;
  description?: string | null;
  bankAccountBalance: number;
  bankAccount?: BankAccountCreateOneWithoutTransactionsInput | null;
}

export interface BankTransactionScalarWhereInput {
  AND?: BankTransactionScalarWhereInput[] | null;
  OR?: BankTransactionScalarWhereInput[] | null;
  NOT?: BankTransactionScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  date?: any | null;
  date_not?: any | null;
  date_in?: any[] | null;
  date_not_in?: any[] | null;
  date_lt?: any | null;
  date_lte?: any | null;
  date_gt?: any | null;
  date_gte?: any | null;
  amount?: number | null;
  amount_not?: number | null;
  amount_in?: number[] | null;
  amount_not_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  ibanNumber?: string | null;
  ibanNumber_not?: string | null;
  ibanNumber_in?: string[] | null;
  ibanNumber_not_in?: string[] | null;
  ibanNumber_lt?: string | null;
  ibanNumber_lte?: string | null;
  ibanNumber_gt?: string | null;
  ibanNumber_gte?: string | null;
  ibanNumber_contains?: string | null;
  ibanNumber_not_contains?: string | null;
  ibanNumber_starts_with?: string | null;
  ibanNumber_not_starts_with?: string | null;
  ibanNumber_ends_with?: string | null;
  ibanNumber_not_ends_with?: string | null;
  bic?: string | null;
  bic_not?: string | null;
  bic_in?: string[] | null;
  bic_not_in?: string[] | null;
  bic_lt?: string | null;
  bic_lte?: string | null;
  bic_gt?: string | null;
  bic_gte?: string | null;
  bic_contains?: string | null;
  bic_not_contains?: string | null;
  bic_starts_with?: string | null;
  bic_not_starts_with?: string | null;
  bic_ends_with?: string | null;
  bic_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  address?: string | null;
  address_not?: string | null;
  address_in?: string[] | null;
  address_not_in?: string[] | null;
  address_lt?: string | null;
  address_lte?: string | null;
  address_gt?: string | null;
  address_gte?: string | null;
  address_contains?: string | null;
  address_not_contains?: string | null;
  address_starts_with?: string | null;
  address_not_starts_with?: string | null;
  address_ends_with?: string | null;
  address_not_ends_with?: string | null;
  sct?: string | null;
  sct_not?: string | null;
  sct_in?: string[] | null;
  sct_not_in?: string[] | null;
  sct_lt?: string | null;
  sct_lte?: string | null;
  sct_gt?: string | null;
  sct_gte?: string | null;
  sct_contains?: string | null;
  sct_not_contains?: string | null;
  sct_starts_with?: string | null;
  sct_not_starts_with?: string | null;
  sct_ends_with?: string | null;
  sct_not_ends_with?: string | null;
  description?: string | null;
  description_not?: string | null;
  description_in?: string[] | null;
  description_not_in?: string[] | null;
  description_lt?: string | null;
  description_lte?: string | null;
  description_gt?: string | null;
  description_gte?: string | null;
  description_contains?: string | null;
  description_not_contains?: string | null;
  description_starts_with?: string | null;
  description_not_starts_with?: string | null;
  description_ends_with?: string | null;
  description_not_ends_with?: string | null;
  bankAccountBalance?: number | null;
  bankAccountBalance_not?: number | null;
  bankAccountBalance_in?: number[] | null;
  bankAccountBalance_not_in?: number[] | null;
  bankAccountBalance_lt?: number | null;
  bankAccountBalance_lte?: number | null;
  bankAccountBalance_gt?: number | null;
  bankAccountBalance_gte?: number | null;
}

export interface BankTransactionUpdateManyDataInput {
  date?: any | null;
  amount?: number | null;
  ibanNumber?: string | null;
  bic?: string | null;
  name?: string | null;
  address?: string | null;
  sct?: string | null;
  description?: string | null;
  bankAccountBalance?: number | null;
}

export interface BankTransactionUpdateManyWithWhereNestedInput {
  where: BankTransactionScalarWhereInput;
  data: BankTransactionUpdateManyDataInput;
}

export interface BankTransactionUpdateManyWithoutClientInput {
  create?: BankTransactionCreateWithoutClientInput[] | null;
  connect?: BankTransactionWhereUniqueInput[] | null;
  set?: BankTransactionWhereUniqueInput[] | null;
  disconnect?: BankTransactionWhereUniqueInput[] | null;
  delete?: BankTransactionWhereUniqueInput[] | null;
  update?: BankTransactionUpdateWithWhereUniqueWithoutClientInput[] | null;
  updateMany?: BankTransactionUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: BankTransactionScalarWhereInput[] | null;
  upsert?: BankTransactionUpsertWithWhereUniqueWithoutClientInput[] | null;
}

export interface BankTransactionUpdateWithWhereUniqueWithoutClientInput {
  where: BankTransactionWhereUniqueInput;
  data: BankTransactionUpdateWithoutClientDataInput;
}

export interface BankTransactionUpdateWithoutClientDataInput {
  date?: any | null;
  amount?: number | null;
  ibanNumber?: string | null;
  bic?: string | null;
  name?: string | null;
  address?: string | null;
  sct?: string | null;
  description?: string | null;
  bankAccountBalance?: number | null;
  bankAccount?: BankAccountUpdateOneWithoutTransactionsInput | null;
}

export interface BankTransactionUpsertWithWhereUniqueWithoutClientInput {
  where: BankTransactionWhereUniqueInput;
  update: BankTransactionUpdateWithoutClientDataInput;
  create: BankTransactionCreateWithoutClientInput;
}

export interface BankTransactionWhereInput {
  AND?: BankTransactionWhereInput[] | null;
  OR?: BankTransactionWhereInput[] | null;
  NOT?: BankTransactionWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  date?: any | null;
  date_not?: any | null;
  date_in?: any[] | null;
  date_not_in?: any[] | null;
  date_lt?: any | null;
  date_lte?: any | null;
  date_gt?: any | null;
  date_gte?: any | null;
  amount?: number | null;
  amount_not?: number | null;
  amount_in?: number[] | null;
  amount_not_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  ibanNumber?: string | null;
  ibanNumber_not?: string | null;
  ibanNumber_in?: string[] | null;
  ibanNumber_not_in?: string[] | null;
  ibanNumber_lt?: string | null;
  ibanNumber_lte?: string | null;
  ibanNumber_gt?: string | null;
  ibanNumber_gte?: string | null;
  ibanNumber_contains?: string | null;
  ibanNumber_not_contains?: string | null;
  ibanNumber_starts_with?: string | null;
  ibanNumber_not_starts_with?: string | null;
  ibanNumber_ends_with?: string | null;
  ibanNumber_not_ends_with?: string | null;
  bic?: string | null;
  bic_not?: string | null;
  bic_in?: string[] | null;
  bic_not_in?: string[] | null;
  bic_lt?: string | null;
  bic_lte?: string | null;
  bic_gt?: string | null;
  bic_gte?: string | null;
  bic_contains?: string | null;
  bic_not_contains?: string | null;
  bic_starts_with?: string | null;
  bic_not_starts_with?: string | null;
  bic_ends_with?: string | null;
  bic_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  address?: string | null;
  address_not?: string | null;
  address_in?: string[] | null;
  address_not_in?: string[] | null;
  address_lt?: string | null;
  address_lte?: string | null;
  address_gt?: string | null;
  address_gte?: string | null;
  address_contains?: string | null;
  address_not_contains?: string | null;
  address_starts_with?: string | null;
  address_not_starts_with?: string | null;
  address_ends_with?: string | null;
  address_not_ends_with?: string | null;
  sct?: string | null;
  sct_not?: string | null;
  sct_in?: string[] | null;
  sct_not_in?: string[] | null;
  sct_lt?: string | null;
  sct_lte?: string | null;
  sct_gt?: string | null;
  sct_gte?: string | null;
  sct_contains?: string | null;
  sct_not_contains?: string | null;
  sct_starts_with?: string | null;
  sct_not_starts_with?: string | null;
  sct_ends_with?: string | null;
  sct_not_ends_with?: string | null;
  description?: string | null;
  description_not?: string | null;
  description_in?: string[] | null;
  description_not_in?: string[] | null;
  description_lt?: string | null;
  description_lte?: string | null;
  description_gt?: string | null;
  description_gte?: string | null;
  description_contains?: string | null;
  description_not_contains?: string | null;
  description_starts_with?: string | null;
  description_not_starts_with?: string | null;
  description_ends_with?: string | null;
  description_not_ends_with?: string | null;
  bankAccountBalance?: number | null;
  bankAccountBalance_not?: number | null;
  bankAccountBalance_in?: number[] | null;
  bankAccountBalance_not_in?: number[] | null;
  bankAccountBalance_lt?: number | null;
  bankAccountBalance_lte?: number | null;
  bankAccountBalance_gt?: number | null;
  bankAccountBalance_gte?: number | null;
  client?: ClientWhereInput | null;
  bankAccount?: BankAccountWhereInput | null;
}

export interface BankTransactionWhereUniqueInput {
  id?: string | null;
}

export interface BasketCompositionCreateInput {
  id?: string | null;
  deliveryWeek?: any | null;
  type?: BasketCompositionType | null;
  status?: BasketCompositionStatus | null;
  deliveryLocation?: DeliveryLocation | null;
  basketSize?: BasketSize | null;
  totalPortions?: number | null;
  items?: BasketItemCreateManyWithoutCompositionInput | null;
  deliveries?: ProductOrderDeliveryCreateManyWithoutBasketCompositionInput | null;
  basketCompositionSet?: BasketCompositionSetCreateOneWithoutBasketCompositionsInput | null;
  farm?: FarmCreateOneWithoutBasketCompositionsInput | null;
}

export interface BasketCompositionCreateManyWithoutBasketCompositionSetInput {
  create?: BasketCompositionCreateWithoutBasketCompositionSetInput[] | null;
  connect?: BasketCompositionWhereUniqueInput[] | null;
}

export interface BasketCompositionCreateManyWithoutFarmInput {
  create?: BasketCompositionCreateWithoutFarmInput[] | null;
  connect?: BasketCompositionWhereUniqueInput[] | null;
}

export interface BasketCompositionCreateOneWithoutDeliveriesInput {
  create?: BasketCompositionCreateWithoutDeliveriesInput | null;
  connect?: BasketCompositionWhereUniqueInput | null;
}

export interface BasketCompositionCreateOneWithoutItemsInput {
  create?: BasketCompositionCreateWithoutItemsInput | null;
  connect?: BasketCompositionWhereUniqueInput | null;
}

export interface BasketCompositionCreateWithoutBasketCompositionSetInput {
  id?: string | null;
  deliveryWeek?: any | null;
  type?: BasketCompositionType | null;
  status?: BasketCompositionStatus | null;
  deliveryLocation?: DeliveryLocation | null;
  basketSize?: BasketSize | null;
  totalPortions?: number | null;
  items?: BasketItemCreateManyWithoutCompositionInput | null;
  deliveries?: ProductOrderDeliveryCreateManyWithoutBasketCompositionInput | null;
  farm?: FarmCreateOneWithoutBasketCompositionsInput | null;
}

export interface BasketCompositionCreateWithoutDeliveriesInput {
  id?: string | null;
  deliveryWeek?: any | null;
  type?: BasketCompositionType | null;
  status?: BasketCompositionStatus | null;
  deliveryLocation?: DeliveryLocation | null;
  basketSize?: BasketSize | null;
  totalPortions?: number | null;
  items?: BasketItemCreateManyWithoutCompositionInput | null;
  basketCompositionSet?: BasketCompositionSetCreateOneWithoutBasketCompositionsInput | null;
  farm?: FarmCreateOneWithoutBasketCompositionsInput | null;
}

export interface BasketCompositionCreateWithoutFarmInput {
  id?: string | null;
  deliveryWeek?: any | null;
  type?: BasketCompositionType | null;
  status?: BasketCompositionStatus | null;
  deliveryLocation?: DeliveryLocation | null;
  basketSize?: BasketSize | null;
  totalPortions?: number | null;
  items?: BasketItemCreateManyWithoutCompositionInput | null;
  deliveries?: ProductOrderDeliveryCreateManyWithoutBasketCompositionInput | null;
  basketCompositionSet?: BasketCompositionSetCreateOneWithoutBasketCompositionsInput | null;
}

export interface BasketCompositionCreateWithoutItemsInput {
  id?: string | null;
  deliveryWeek?: any | null;
  type?: BasketCompositionType | null;
  status?: BasketCompositionStatus | null;
  deliveryLocation?: DeliveryLocation | null;
  basketSize?: BasketSize | null;
  totalPortions?: number | null;
  deliveries?: ProductOrderDeliveryCreateManyWithoutBasketCompositionInput | null;
  basketCompositionSet?: BasketCompositionSetCreateOneWithoutBasketCompositionsInput | null;
  farm?: FarmCreateOneWithoutBasketCompositionsInput | null;
}

export interface BasketCompositionScalarWhereInput {
  AND?: BasketCompositionScalarWhereInput[] | null;
  OR?: BasketCompositionScalarWhereInput[] | null;
  NOT?: BasketCompositionScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  deliveryWeek?: any | null;
  deliveryWeek_not?: any | null;
  deliveryWeek_in?: any[] | null;
  deliveryWeek_not_in?: any[] | null;
  deliveryWeek_lt?: any | null;
  deliveryWeek_lte?: any | null;
  deliveryWeek_gt?: any | null;
  deliveryWeek_gte?: any | null;
  type?: BasketCompositionType | null;
  type_not?: BasketCompositionType | null;
  type_in?: BasketCompositionType[] | null;
  type_not_in?: BasketCompositionType[] | null;
  status?: BasketCompositionStatus | null;
  status_not?: BasketCompositionStatus | null;
  status_in?: BasketCompositionStatus[] | null;
  status_not_in?: BasketCompositionStatus[] | null;
  deliveryLocation?: DeliveryLocation | null;
  deliveryLocation_not?: DeliveryLocation | null;
  deliveryLocation_in?: DeliveryLocation[] | null;
  deliveryLocation_not_in?: DeliveryLocation[] | null;
  basketSize?: BasketSize | null;
  basketSize_not?: BasketSize | null;
  basketSize_in?: BasketSize[] | null;
  basketSize_not_in?: BasketSize[] | null;
  totalPortions?: number | null;
  totalPortions_not?: number | null;
  totalPortions_in?: number[] | null;
  totalPortions_not_in?: number[] | null;
  totalPortions_lt?: number | null;
  totalPortions_lte?: number | null;
  totalPortions_gt?: number | null;
  totalPortions_gte?: number | null;
}

export interface BasketCompositionSetCreateInput {
  id?: string | null;
  deliveryWeek: any;
  status?: BasketCompositionStatus | null;
  basketCompositions?: BasketCompositionCreateManyWithoutBasketCompositionSetInput | null;
}

export interface BasketCompositionSetCreateOneWithoutBasketCompositionsInput {
  create?: BasketCompositionSetCreateWithoutBasketCompositionsInput | null;
  connect?: BasketCompositionSetWhereUniqueInput | null;
}

export interface BasketCompositionSetCreateWithoutBasketCompositionsInput {
  id?: string | null;
  deliveryWeek: any;
  status?: BasketCompositionStatus | null;
}

export interface BasketCompositionSetUpdateOneWithoutBasketCompositionsInput {
  create?: BasketCompositionSetCreateWithoutBasketCompositionsInput | null;
  connect?: BasketCompositionSetWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: BasketCompositionSetUpdateWithoutBasketCompositionsDataInput | null;
  upsert?: BasketCompositionSetUpsertWithoutBasketCompositionsInput | null;
}

export interface BasketCompositionSetUpdateWithoutBasketCompositionsDataInput {
  deliveryWeek?: any | null;
  status?: BasketCompositionStatus | null;
}

export interface BasketCompositionSetUpsertWithoutBasketCompositionsInput {
  update: BasketCompositionSetUpdateWithoutBasketCompositionsDataInput;
  create: BasketCompositionSetCreateWithoutBasketCompositionsInput;
}

export interface BasketCompositionSetWhereInput {
  AND?: BasketCompositionSetWhereInput[] | null;
  OR?: BasketCompositionSetWhereInput[] | null;
  NOT?: BasketCompositionSetWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  deliveryWeek?: any | null;
  deliveryWeek_not?: any | null;
  deliveryWeek_in?: any[] | null;
  deliveryWeek_not_in?: any[] | null;
  deliveryWeek_lt?: any | null;
  deliveryWeek_lte?: any | null;
  deliveryWeek_gt?: any | null;
  deliveryWeek_gte?: any | null;
  status?: BasketCompositionStatus | null;
  status_not?: BasketCompositionStatus | null;
  status_in?: BasketCompositionStatus[] | null;
  status_not_in?: BasketCompositionStatus[] | null;
  basketCompositions_every?: BasketCompositionWhereInput | null;
  basketCompositions_some?: BasketCompositionWhereInput | null;
  basketCompositions_none?: BasketCompositionWhereInput | null;
}

export interface BasketCompositionSetWhereUniqueInput {
  id?: string | null;
}

export interface BasketCompositionUpdateInput {
  deliveryWeek?: any | null;
  type?: BasketCompositionType | null;
  status?: BasketCompositionStatus | null;
  deliveryLocation?: DeliveryLocation | null;
  basketSize?: BasketSize | null;
  totalPortions?: number | null;
  items?: BasketItemUpdateManyWithoutCompositionInput | null;
  deliveries?: ProductOrderDeliveryUpdateManyWithoutBasketCompositionInput | null;
  basketCompositionSet?: BasketCompositionSetUpdateOneWithoutBasketCompositionsInput | null;
  farm?: FarmUpdateOneWithoutBasketCompositionsInput | null;
}

export interface BasketCompositionUpdateManyDataInput {
  deliveryWeek?: any | null;
  type?: BasketCompositionType | null;
  status?: BasketCompositionStatus | null;
  deliveryLocation?: DeliveryLocation | null;
  basketSize?: BasketSize | null;
  totalPortions?: number | null;
}

export interface BasketCompositionUpdateManyWithWhereNestedInput {
  where: BasketCompositionScalarWhereInput;
  data: BasketCompositionUpdateManyDataInput;
}

export interface BasketCompositionUpdateManyWithoutFarmInput {
  create?: BasketCompositionCreateWithoutFarmInput[] | null;
  connect?: BasketCompositionWhereUniqueInput[] | null;
  set?: BasketCompositionWhereUniqueInput[] | null;
  disconnect?: BasketCompositionWhereUniqueInput[] | null;
  delete?: BasketCompositionWhereUniqueInput[] | null;
  update?: BasketCompositionUpdateWithWhereUniqueWithoutFarmInput[] | null;
  updateMany?: BasketCompositionUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: BasketCompositionScalarWhereInput[] | null;
  upsert?: BasketCompositionUpsertWithWhereUniqueWithoutFarmInput[] | null;
}

export interface BasketCompositionUpdateOneRequiredWithoutItemsInput {
  create?: BasketCompositionCreateWithoutItemsInput | null;
  connect?: BasketCompositionWhereUniqueInput | null;
  update?: BasketCompositionUpdateWithoutItemsDataInput | null;
  upsert?: BasketCompositionUpsertWithoutItemsInput | null;
}

export interface BasketCompositionUpdateOneWithoutDeliveriesInput {
  create?: BasketCompositionCreateWithoutDeliveriesInput | null;
  connect?: BasketCompositionWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: BasketCompositionUpdateWithoutDeliveriesDataInput | null;
  upsert?: BasketCompositionUpsertWithoutDeliveriesInput | null;
}

export interface BasketCompositionUpdateWithWhereUniqueWithoutFarmInput {
  where: BasketCompositionWhereUniqueInput;
  data: BasketCompositionUpdateWithoutFarmDataInput;
}

export interface BasketCompositionUpdateWithoutDeliveriesDataInput {
  deliveryWeek?: any | null;
  type?: BasketCompositionType | null;
  status?: BasketCompositionStatus | null;
  deliveryLocation?: DeliveryLocation | null;
  basketSize?: BasketSize | null;
  totalPortions?: number | null;
  items?: BasketItemUpdateManyWithoutCompositionInput | null;
  basketCompositionSet?: BasketCompositionSetUpdateOneWithoutBasketCompositionsInput | null;
  farm?: FarmUpdateOneWithoutBasketCompositionsInput | null;
}

export interface BasketCompositionUpdateWithoutFarmDataInput {
  deliveryWeek?: any | null;
  type?: BasketCompositionType | null;
  status?: BasketCompositionStatus | null;
  deliveryLocation?: DeliveryLocation | null;
  basketSize?: BasketSize | null;
  totalPortions?: number | null;
  items?: BasketItemUpdateManyWithoutCompositionInput | null;
  deliveries?: ProductOrderDeliveryUpdateManyWithoutBasketCompositionInput | null;
  basketCompositionSet?: BasketCompositionSetUpdateOneWithoutBasketCompositionsInput | null;
}

export interface BasketCompositionUpdateWithoutItemsDataInput {
  deliveryWeek?: any | null;
  type?: BasketCompositionType | null;
  status?: BasketCompositionStatus | null;
  deliveryLocation?: DeliveryLocation | null;
  basketSize?: BasketSize | null;
  totalPortions?: number | null;
  deliveries?: ProductOrderDeliveryUpdateManyWithoutBasketCompositionInput | null;
  basketCompositionSet?: BasketCompositionSetUpdateOneWithoutBasketCompositionsInput | null;
  farm?: FarmUpdateOneWithoutBasketCompositionsInput | null;
}

export interface BasketCompositionUpsertWithWhereUniqueWithoutFarmInput {
  where: BasketCompositionWhereUniqueInput;
  update: BasketCompositionUpdateWithoutFarmDataInput;
  create: BasketCompositionCreateWithoutFarmInput;
}

export interface BasketCompositionUpsertWithoutDeliveriesInput {
  update: BasketCompositionUpdateWithoutDeliveriesDataInput;
  create: BasketCompositionCreateWithoutDeliveriesInput;
}

export interface BasketCompositionUpsertWithoutItemsInput {
  update: BasketCompositionUpdateWithoutItemsDataInput;
  create: BasketCompositionCreateWithoutItemsInput;
}

export interface BasketCompositionWhereInput {
  AND?: BasketCompositionWhereInput[] | null;
  OR?: BasketCompositionWhereInput[] | null;
  NOT?: BasketCompositionWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  deliveryWeek?: any | null;
  deliveryWeek_not?: any | null;
  deliveryWeek_in?: any[] | null;
  deliveryWeek_not_in?: any[] | null;
  deliveryWeek_lt?: any | null;
  deliveryWeek_lte?: any | null;
  deliveryWeek_gt?: any | null;
  deliveryWeek_gte?: any | null;
  type?: BasketCompositionType | null;
  type_not?: BasketCompositionType | null;
  type_in?: BasketCompositionType[] | null;
  type_not_in?: BasketCompositionType[] | null;
  status?: BasketCompositionStatus | null;
  status_not?: BasketCompositionStatus | null;
  status_in?: BasketCompositionStatus[] | null;
  status_not_in?: BasketCompositionStatus[] | null;
  deliveryLocation?: DeliveryLocation | null;
  deliveryLocation_not?: DeliveryLocation | null;
  deliveryLocation_in?: DeliveryLocation[] | null;
  deliveryLocation_not_in?: DeliveryLocation[] | null;
  basketSize?: BasketSize | null;
  basketSize_not?: BasketSize | null;
  basketSize_in?: BasketSize[] | null;
  basketSize_not_in?: BasketSize[] | null;
  totalPortions?: number | null;
  totalPortions_not?: number | null;
  totalPortions_in?: number[] | null;
  totalPortions_not_in?: number[] | null;
  totalPortions_lt?: number | null;
  totalPortions_lte?: number | null;
  totalPortions_gt?: number | null;
  totalPortions_gte?: number | null;
  items_every?: BasketItemWhereInput | null;
  items_some?: BasketItemWhereInput | null;
  items_none?: BasketItemWhereInput | null;
  deliveries_every?: ProductOrderDeliveryWhereInput | null;
  deliveries_some?: ProductOrderDeliveryWhereInput | null;
  deliveries_none?: ProductOrderDeliveryWhereInput | null;
  basketCompositionSet?: BasketCompositionSetWhereInput | null;
  farm?: FarmWhereInput | null;
}

export interface BasketCompositionWhereUniqueInput {
  id?: string | null;
}

export interface BasketItemCreateManyWithoutCompositionInput {
  create?: BasketItemCreateWithoutCompositionInput[] | null;
  connect?: BasketItemWhereUniqueInput[] | null;
}

export interface BasketItemCreateManyWithoutProductInput {
  create?: BasketItemCreateWithoutProductInput[] | null;
  connect?: BasketItemWhereUniqueInput[] | null;
}

export interface BasketItemCreateWithoutCompositionInput {
  id?: string | null;
  packagingOrder?: number | null;
  quantity?: number | null;
  quantitySmall: number;
  portionsSmall: number;
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsSmall?: BasketItemCreatedeliveryLocationsSmallInput | null;
  deliveryLocationsLarge?: BasketItemCreatedeliveryLocationsLargeInput | null;
  product: ProductCreateOneWithoutBasketItemsInput;
}

export interface BasketItemCreateWithoutProductInput {
  id?: string | null;
  packagingOrder?: number | null;
  quantity?: number | null;
  quantitySmall: number;
  portionsSmall: number;
  quantityLarge: number;
  portionsLarge: number;
  deliveryLocationsSmall?: BasketItemCreatedeliveryLocationsSmallInput | null;
  deliveryLocationsLarge?: BasketItemCreatedeliveryLocationsLargeInput | null;
  composition: BasketCompositionCreateOneWithoutItemsInput;
}

export interface BasketItemCreatedeliveryLocationsLargeInput {
  set?: DeliveryLocation[] | null;
}

export interface BasketItemCreatedeliveryLocationsSmallInput {
  set?: DeliveryLocation[] | null;
}

export interface BasketItemScalarWhereInput {
  AND?: BasketItemScalarWhereInput[] | null;
  OR?: BasketItemScalarWhereInput[] | null;
  NOT?: BasketItemScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  packagingOrder?: number | null;
  packagingOrder_not?: number | null;
  packagingOrder_in?: number[] | null;
  packagingOrder_not_in?: number[] | null;
  packagingOrder_lt?: number | null;
  packagingOrder_lte?: number | null;
  packagingOrder_gt?: number | null;
  packagingOrder_gte?: number | null;
  quantity?: number | null;
  quantity_not?: number | null;
  quantity_in?: number[] | null;
  quantity_not_in?: number[] | null;
  quantity_lt?: number | null;
  quantity_lte?: number | null;
  quantity_gt?: number | null;
  quantity_gte?: number | null;
  quantitySmall?: number | null;
  quantitySmall_not?: number | null;
  quantitySmall_in?: number[] | null;
  quantitySmall_not_in?: number[] | null;
  quantitySmall_lt?: number | null;
  quantitySmall_lte?: number | null;
  quantitySmall_gt?: number | null;
  quantitySmall_gte?: number | null;
  portionsSmall?: number | null;
  portionsSmall_not?: number | null;
  portionsSmall_in?: number[] | null;
  portionsSmall_not_in?: number[] | null;
  portionsSmall_lt?: number | null;
  portionsSmall_lte?: number | null;
  portionsSmall_gt?: number | null;
  portionsSmall_gte?: number | null;
  quantityLarge?: number | null;
  quantityLarge_not?: number | null;
  quantityLarge_in?: number[] | null;
  quantityLarge_not_in?: number[] | null;
  quantityLarge_lt?: number | null;
  quantityLarge_lte?: number | null;
  quantityLarge_gt?: number | null;
  quantityLarge_gte?: number | null;
  portionsLarge?: number | null;
  portionsLarge_not?: number | null;
  portionsLarge_in?: number[] | null;
  portionsLarge_not_in?: number[] | null;
  portionsLarge_lt?: number | null;
  portionsLarge_lte?: number | null;
  portionsLarge_gt?: number | null;
  portionsLarge_gte?: number | null;
}

export interface BasketItemUpdateManyDataInput {
  packagingOrder?: number | null;
  quantity?: number | null;
  quantitySmall?: number | null;
  portionsSmall?: number | null;
  quantityLarge?: number | null;
  portionsLarge?: number | null;
  deliveryLocationsSmall?: BasketItemUpdatedeliveryLocationsSmallInput | null;
  deliveryLocationsLarge?: BasketItemUpdatedeliveryLocationsLargeInput | null;
}

export interface BasketItemUpdateManyWithWhereNestedInput {
  where: BasketItemScalarWhereInput;
  data: BasketItemUpdateManyDataInput;
}

export interface BasketItemUpdateManyWithoutCompositionInput {
  create?: BasketItemCreateWithoutCompositionInput[] | null;
  connect?: BasketItemWhereUniqueInput[] | null;
  set?: BasketItemWhereUniqueInput[] | null;
  disconnect?: BasketItemWhereUniqueInput[] | null;
  delete?: BasketItemWhereUniqueInput[] | null;
  update?: BasketItemUpdateWithWhereUniqueWithoutCompositionInput[] | null;
  updateMany?: BasketItemUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: BasketItemScalarWhereInput[] | null;
  upsert?: BasketItemUpsertWithWhereUniqueWithoutCompositionInput[] | null;
}

export interface BasketItemUpdateManyWithoutProductInput {
  create?: BasketItemCreateWithoutProductInput[] | null;
  connect?: BasketItemWhereUniqueInput[] | null;
  set?: BasketItemWhereUniqueInput[] | null;
  disconnect?: BasketItemWhereUniqueInput[] | null;
  delete?: BasketItemWhereUniqueInput[] | null;
  update?: BasketItemUpdateWithWhereUniqueWithoutProductInput[] | null;
  updateMany?: BasketItemUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: BasketItemScalarWhereInput[] | null;
  upsert?: BasketItemUpsertWithWhereUniqueWithoutProductInput[] | null;
}

export interface BasketItemUpdateWithWhereUniqueWithoutCompositionInput {
  where: BasketItemWhereUniqueInput;
  data: BasketItemUpdateWithoutCompositionDataInput;
}

export interface BasketItemUpdateWithWhereUniqueWithoutProductInput {
  where: BasketItemWhereUniqueInput;
  data: BasketItemUpdateWithoutProductDataInput;
}

export interface BasketItemUpdateWithoutCompositionDataInput {
  packagingOrder?: number | null;
  quantity?: number | null;
  quantitySmall?: number | null;
  portionsSmall?: number | null;
  quantityLarge?: number | null;
  portionsLarge?: number | null;
  deliveryLocationsSmall?: BasketItemUpdatedeliveryLocationsSmallInput | null;
  deliveryLocationsLarge?: BasketItemUpdatedeliveryLocationsLargeInput | null;
  product?: ProductUpdateOneRequiredWithoutBasketItemsInput | null;
}

export interface BasketItemUpdateWithoutProductDataInput {
  packagingOrder?: number | null;
  quantity?: number | null;
  quantitySmall?: number | null;
  portionsSmall?: number | null;
  quantityLarge?: number | null;
  portionsLarge?: number | null;
  deliveryLocationsSmall?: BasketItemUpdatedeliveryLocationsSmallInput | null;
  deliveryLocationsLarge?: BasketItemUpdatedeliveryLocationsLargeInput | null;
  composition?: BasketCompositionUpdateOneRequiredWithoutItemsInput | null;
}

export interface BasketItemUpdatedeliveryLocationsLargeInput {
  set?: DeliveryLocation[] | null;
}

export interface BasketItemUpdatedeliveryLocationsSmallInput {
  set?: DeliveryLocation[] | null;
}

export interface BasketItemUpsertWithWhereUniqueWithoutCompositionInput {
  where: BasketItemWhereUniqueInput;
  update: BasketItemUpdateWithoutCompositionDataInput;
  create: BasketItemCreateWithoutCompositionInput;
}

export interface BasketItemUpsertWithWhereUniqueWithoutProductInput {
  where: BasketItemWhereUniqueInput;
  update: BasketItemUpdateWithoutProductDataInput;
  create: BasketItemCreateWithoutProductInput;
}

export interface BasketItemWhereInput {
  AND?: BasketItemWhereInput[] | null;
  OR?: BasketItemWhereInput[] | null;
  NOT?: BasketItemWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  packagingOrder?: number | null;
  packagingOrder_not?: number | null;
  packagingOrder_in?: number[] | null;
  packagingOrder_not_in?: number[] | null;
  packagingOrder_lt?: number | null;
  packagingOrder_lte?: number | null;
  packagingOrder_gt?: number | null;
  packagingOrder_gte?: number | null;
  quantity?: number | null;
  quantity_not?: number | null;
  quantity_in?: number[] | null;
  quantity_not_in?: number[] | null;
  quantity_lt?: number | null;
  quantity_lte?: number | null;
  quantity_gt?: number | null;
  quantity_gte?: number | null;
  quantitySmall?: number | null;
  quantitySmall_not?: number | null;
  quantitySmall_in?: number[] | null;
  quantitySmall_not_in?: number[] | null;
  quantitySmall_lt?: number | null;
  quantitySmall_lte?: number | null;
  quantitySmall_gt?: number | null;
  quantitySmall_gte?: number | null;
  portionsSmall?: number | null;
  portionsSmall_not?: number | null;
  portionsSmall_in?: number[] | null;
  portionsSmall_not_in?: number[] | null;
  portionsSmall_lt?: number | null;
  portionsSmall_lte?: number | null;
  portionsSmall_gt?: number | null;
  portionsSmall_gte?: number | null;
  quantityLarge?: number | null;
  quantityLarge_not?: number | null;
  quantityLarge_in?: number[] | null;
  quantityLarge_not_in?: number[] | null;
  quantityLarge_lt?: number | null;
  quantityLarge_lte?: number | null;
  quantityLarge_gt?: number | null;
  quantityLarge_gte?: number | null;
  portionsLarge?: number | null;
  portionsLarge_not?: number | null;
  portionsLarge_in?: number[] | null;
  portionsLarge_not_in?: number[] | null;
  portionsLarge_lt?: number | null;
  portionsLarge_lte?: number | null;
  portionsLarge_gt?: number | null;
  portionsLarge_gte?: number | null;
  composition?: BasketCompositionWhereInput | null;
  product?: ProductWhereInput | null;
}

export interface BasketItemWhereUniqueInput {
  id?: string | null;
}

export interface ClientCreateInput {
  id?: string | null;
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  email: string;
  newsletter?: boolean | null;
  remarks?: string | null;
  type?: ClientType | null;
  secondaryEmail?: string | null;
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  billingEmail?: string | null;
  vatNumber?: string | null;
  companyName?: string | null;
  whatsApp?: boolean | null;
  communicationGoudenWortel?: boolean | null;
  auth0userId?: string | null;
  ibanNumbers?: ClientCreateibanNumbersInput | null;
  group: ClientGroupCreateOneWithoutClientsInput;
  clientOrders?: ClientOrderCreateManyWithoutClientInput | null;
  membership?: MembershipCreateOneInput | null;
  farm: FarmCreateOneWithoutClientsInput;
  customerInfo?: CustomerInfoCreateOneInput | null;
  bankTransactions?: BankTransactionCreateManyWithoutClientInput | null;
}

export interface ClientCreateManyWithoutFarmInput {
  create?: ClientCreateWithoutFarmInput[] | null;
  connect?: ClientWhereUniqueInput[] | null;
}

export interface ClientCreateManyWithoutGroupInput {
  create?: ClientCreateWithoutGroupInput[] | null;
  connect?: ClientWhereUniqueInput[] | null;
}

export interface ClientCreateOneWithoutBankTransactionsInput {
  create?: ClientCreateWithoutBankTransactionsInput | null;
  connect?: ClientWhereUniqueInput | null;
}

export interface ClientCreateOneWithoutClientOrdersInput {
  create?: ClientCreateWithoutClientOrdersInput | null;
  connect?: ClientWhereUniqueInput | null;
}

export interface ClientCreateWithoutBankTransactionsInput {
  id?: string | null;
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  email: string;
  newsletter?: boolean | null;
  remarks?: string | null;
  type?: ClientType | null;
  secondaryEmail?: string | null;
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  billingEmail?: string | null;
  vatNumber?: string | null;
  companyName?: string | null;
  whatsApp?: boolean | null;
  communicationGoudenWortel?: boolean | null;
  auth0userId?: string | null;
  ibanNumbers?: ClientCreateibanNumbersInput | null;
  group: ClientGroupCreateOneWithoutClientsInput;
  clientOrders?: ClientOrderCreateManyWithoutClientInput | null;
  membership?: MembershipCreateOneInput | null;
  farm: FarmCreateOneWithoutClientsInput;
  customerInfo?: CustomerInfoCreateOneInput | null;
}

export interface ClientCreateWithoutClientOrdersInput {
  id?: string | null;
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  email: string;
  newsletter?: boolean | null;
  remarks?: string | null;
  type?: ClientType | null;
  secondaryEmail?: string | null;
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  billingEmail?: string | null;
  vatNumber?: string | null;
  companyName?: string | null;
  whatsApp?: boolean | null;
  communicationGoudenWortel?: boolean | null;
  auth0userId?: string | null;
  ibanNumbers?: ClientCreateibanNumbersInput | null;
  group: ClientGroupCreateOneWithoutClientsInput;
  membership?: MembershipCreateOneInput | null;
  farm: FarmCreateOneWithoutClientsInput;
  customerInfo?: CustomerInfoCreateOneInput | null;
  bankTransactions?: BankTransactionCreateManyWithoutClientInput | null;
}

export interface ClientCreateWithoutFarmInput {
  id?: string | null;
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  email: string;
  newsletter?: boolean | null;
  remarks?: string | null;
  type?: ClientType | null;
  secondaryEmail?: string | null;
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  billingEmail?: string | null;
  vatNumber?: string | null;
  companyName?: string | null;
  whatsApp?: boolean | null;
  communicationGoudenWortel?: boolean | null;
  auth0userId?: string | null;
  ibanNumbers?: ClientCreateibanNumbersInput | null;
  group: ClientGroupCreateOneWithoutClientsInput;
  clientOrders?: ClientOrderCreateManyWithoutClientInput | null;
  membership?: MembershipCreateOneInput | null;
  customerInfo?: CustomerInfoCreateOneInput | null;
  bankTransactions?: BankTransactionCreateManyWithoutClientInput | null;
}

export interface ClientCreateWithoutGroupInput {
  id?: string | null;
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  email: string;
  newsletter?: boolean | null;
  remarks?: string | null;
  type?: ClientType | null;
  secondaryEmail?: string | null;
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  billingEmail?: string | null;
  vatNumber?: string | null;
  companyName?: string | null;
  whatsApp?: boolean | null;
  communicationGoudenWortel?: boolean | null;
  auth0userId?: string | null;
  ibanNumbers?: ClientCreateibanNumbersInput | null;
  clientOrders?: ClientOrderCreateManyWithoutClientInput | null;
  membership?: MembershipCreateOneInput | null;
  farm: FarmCreateOneWithoutClientsInput;
  customerInfo?: CustomerInfoCreateOneInput | null;
  bankTransactions?: BankTransactionCreateManyWithoutClientInput | null;
}

export interface ClientCreateibanNumbersInput {
  set?: string[] | null;
}

export interface ClientGroupCreateInput {
  id?: string | null;
  name: string;
  code?: string | null;
  mailchimpTag?: string | null;
  discount: number;
  profitMargin: number;
  default?: boolean | null;
  showInclVat?: boolean | null;
  useForPricing?: boolean | null;
  priceAsPoints?: boolean | null;
  secondaryGroup?: boolean | null;
  order: number;
  pointsBase?: ClientGroupCreateOneInput | null;
  clients?: ClientCreateManyWithoutGroupInput | null;
  discounts?: ProductDiscountCreateManyWithoutClientGroupInput | null;
  productPrices?: ProductPriceCreateManyWithoutClientGroupInput | null;
  productAvailablities?: ProductAvailabilityCreateManyWithoutClientGroupInput | null;
  farm?: FarmCreateOneWithoutClientGroupsInput | null;
}

export interface ClientGroupCreateManyWithoutFarmInput {
  create?: ClientGroupCreateWithoutFarmInput[] | null;
  connect?: ClientGroupWhereUniqueInput[] | null;
}

export interface ClientGroupCreateOneInput {
  create?: ClientGroupCreateInput | null;
  connect?: ClientGroupWhereUniqueInput | null;
}

export interface ClientGroupCreateOneWithoutClientsInput {
  create?: ClientGroupCreateWithoutClientsInput | null;
  connect?: ClientGroupWhereUniqueInput | null;
}

export interface ClientGroupCreateOneWithoutDiscountsInput {
  create?: ClientGroupCreateWithoutDiscountsInput | null;
  connect?: ClientGroupWhereUniqueInput | null;
}

export interface ClientGroupCreateOneWithoutProductAvailablitiesInput {
  create?: ClientGroupCreateWithoutProductAvailablitiesInput | null;
  connect?: ClientGroupWhereUniqueInput | null;
}

export interface ClientGroupCreateOneWithoutProductPricesInput {
  create?: ClientGroupCreateWithoutProductPricesInput | null;
  connect?: ClientGroupWhereUniqueInput | null;
}

export interface ClientGroupCreateWithoutClientsInput {
  id?: string | null;
  name: string;
  code?: string | null;
  mailchimpTag?: string | null;
  discount: number;
  profitMargin: number;
  default?: boolean | null;
  showInclVat?: boolean | null;
  useForPricing?: boolean | null;
  priceAsPoints?: boolean | null;
  secondaryGroup?: boolean | null;
  order: number;
  pointsBase?: ClientGroupCreateOneInput | null;
  discounts?: ProductDiscountCreateManyWithoutClientGroupInput | null;
  productPrices?: ProductPriceCreateManyWithoutClientGroupInput | null;
  productAvailablities?: ProductAvailabilityCreateManyWithoutClientGroupInput | null;
  farm?: FarmCreateOneWithoutClientGroupsInput | null;
}

export interface ClientGroupCreateWithoutDiscountsInput {
  id?: string | null;
  name: string;
  code?: string | null;
  mailchimpTag?: string | null;
  discount: number;
  profitMargin: number;
  default?: boolean | null;
  showInclVat?: boolean | null;
  useForPricing?: boolean | null;
  priceAsPoints?: boolean | null;
  secondaryGroup?: boolean | null;
  order: number;
  pointsBase?: ClientGroupCreateOneInput | null;
  clients?: ClientCreateManyWithoutGroupInput | null;
  productPrices?: ProductPriceCreateManyWithoutClientGroupInput | null;
  productAvailablities?: ProductAvailabilityCreateManyWithoutClientGroupInput | null;
  farm?: FarmCreateOneWithoutClientGroupsInput | null;
}

export interface ClientGroupCreateWithoutFarmInput {
  id?: string | null;
  name: string;
  code?: string | null;
  mailchimpTag?: string | null;
  discount: number;
  profitMargin: number;
  default?: boolean | null;
  showInclVat?: boolean | null;
  useForPricing?: boolean | null;
  priceAsPoints?: boolean | null;
  secondaryGroup?: boolean | null;
  order: number;
  pointsBase?: ClientGroupCreateOneInput | null;
  clients?: ClientCreateManyWithoutGroupInput | null;
  discounts?: ProductDiscountCreateManyWithoutClientGroupInput | null;
  productPrices?: ProductPriceCreateManyWithoutClientGroupInput | null;
  productAvailablities?: ProductAvailabilityCreateManyWithoutClientGroupInput | null;
}

export interface ClientGroupCreateWithoutProductAvailablitiesInput {
  id?: string | null;
  name: string;
  code?: string | null;
  mailchimpTag?: string | null;
  discount: number;
  profitMargin: number;
  default?: boolean | null;
  showInclVat?: boolean | null;
  useForPricing?: boolean | null;
  priceAsPoints?: boolean | null;
  secondaryGroup?: boolean | null;
  order: number;
  pointsBase?: ClientGroupCreateOneInput | null;
  clients?: ClientCreateManyWithoutGroupInput | null;
  discounts?: ProductDiscountCreateManyWithoutClientGroupInput | null;
  productPrices?: ProductPriceCreateManyWithoutClientGroupInput | null;
  farm?: FarmCreateOneWithoutClientGroupsInput | null;
}

export interface ClientGroupCreateWithoutProductPricesInput {
  id?: string | null;
  name: string;
  code?: string | null;
  mailchimpTag?: string | null;
  discount: number;
  profitMargin: number;
  default?: boolean | null;
  showInclVat?: boolean | null;
  useForPricing?: boolean | null;
  priceAsPoints?: boolean | null;
  secondaryGroup?: boolean | null;
  order: number;
  pointsBase?: ClientGroupCreateOneInput | null;
  clients?: ClientCreateManyWithoutGroupInput | null;
  discounts?: ProductDiscountCreateManyWithoutClientGroupInput | null;
  productAvailablities?: ProductAvailabilityCreateManyWithoutClientGroupInput | null;
  farm?: FarmCreateOneWithoutClientGroupsInput | null;
}

export interface ClientGroupScalarWhereInput {
  AND?: ClientGroupScalarWhereInput[] | null;
  OR?: ClientGroupScalarWhereInput[] | null;
  NOT?: ClientGroupScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  code?: string | null;
  code_not?: string | null;
  code_in?: string[] | null;
  code_not_in?: string[] | null;
  code_lt?: string | null;
  code_lte?: string | null;
  code_gt?: string | null;
  code_gte?: string | null;
  code_contains?: string | null;
  code_not_contains?: string | null;
  code_starts_with?: string | null;
  code_not_starts_with?: string | null;
  code_ends_with?: string | null;
  code_not_ends_with?: string | null;
  mailchimpTag?: string | null;
  mailchimpTag_not?: string | null;
  mailchimpTag_in?: string[] | null;
  mailchimpTag_not_in?: string[] | null;
  mailchimpTag_lt?: string | null;
  mailchimpTag_lte?: string | null;
  mailchimpTag_gt?: string | null;
  mailchimpTag_gte?: string | null;
  mailchimpTag_contains?: string | null;
  mailchimpTag_not_contains?: string | null;
  mailchimpTag_starts_with?: string | null;
  mailchimpTag_not_starts_with?: string | null;
  mailchimpTag_ends_with?: string | null;
  mailchimpTag_not_ends_with?: string | null;
  discount?: number | null;
  discount_not?: number | null;
  discount_in?: number[] | null;
  discount_not_in?: number[] | null;
  discount_lt?: number | null;
  discount_lte?: number | null;
  discount_gt?: number | null;
  discount_gte?: number | null;
  profitMargin?: number | null;
  profitMargin_not?: number | null;
  profitMargin_in?: number[] | null;
  profitMargin_not_in?: number[] | null;
  profitMargin_lt?: number | null;
  profitMargin_lte?: number | null;
  profitMargin_gt?: number | null;
  profitMargin_gte?: number | null;
  default?: boolean | null;
  default_not?: boolean | null;
  showInclVat?: boolean | null;
  showInclVat_not?: boolean | null;
  useForPricing?: boolean | null;
  useForPricing_not?: boolean | null;
  priceAsPoints?: boolean | null;
  priceAsPoints_not?: boolean | null;
  secondaryGroup?: boolean | null;
  secondaryGroup_not?: boolean | null;
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
}

export interface ClientGroupUpdateDataInput {
  name?: string | null;
  code?: string | null;
  mailchimpTag?: string | null;
  discount?: number | null;
  profitMargin?: number | null;
  default?: boolean | null;
  showInclVat?: boolean | null;
  useForPricing?: boolean | null;
  priceAsPoints?: boolean | null;
  secondaryGroup?: boolean | null;
  order?: number | null;
  pointsBase?: ClientGroupUpdateOneInput | null;
  clients?: ClientUpdateManyWithoutGroupInput | null;
  discounts?: ProductDiscountUpdateManyWithoutClientGroupInput | null;
  productPrices?: ProductPriceUpdateManyWithoutClientGroupInput | null;
  productAvailablities?: ProductAvailabilityUpdateManyWithoutClientGroupInput | null;
  farm?: FarmUpdateOneWithoutClientGroupsInput | null;
}

export interface ClientGroupUpdateInput {
  name?: string | null;
  code?: string | null;
  mailchimpTag?: string | null;
  discount?: number | null;
  profitMargin?: number | null;
  default?: boolean | null;
  showInclVat?: boolean | null;
  useForPricing?: boolean | null;
  priceAsPoints?: boolean | null;
  secondaryGroup?: boolean | null;
  order?: number | null;
  pointsBase?: ClientGroupUpdateOneInput | null;
  clients?: ClientUpdateManyWithoutGroupInput | null;
  discounts?: ProductDiscountUpdateManyWithoutClientGroupInput | null;
  productPrices?: ProductPriceUpdateManyWithoutClientGroupInput | null;
  productAvailablities?: ProductAvailabilityUpdateManyWithoutClientGroupInput | null;
  farm?: FarmUpdateOneWithoutClientGroupsInput | null;
}

export interface ClientGroupUpdateManyDataInput {
  name?: string | null;
  code?: string | null;
  mailchimpTag?: string | null;
  discount?: number | null;
  profitMargin?: number | null;
  default?: boolean | null;
  showInclVat?: boolean | null;
  useForPricing?: boolean | null;
  priceAsPoints?: boolean | null;
  secondaryGroup?: boolean | null;
  order?: number | null;
}

export interface ClientGroupUpdateManyWithWhereNestedInput {
  where: ClientGroupScalarWhereInput;
  data: ClientGroupUpdateManyDataInput;
}

export interface ClientGroupUpdateManyWithoutFarmInput {
  create?: ClientGroupCreateWithoutFarmInput[] | null;
  connect?: ClientGroupWhereUniqueInput[] | null;
  set?: ClientGroupWhereUniqueInput[] | null;
  disconnect?: ClientGroupWhereUniqueInput[] | null;
  delete?: ClientGroupWhereUniqueInput[] | null;
  update?: ClientGroupUpdateWithWhereUniqueWithoutFarmInput[] | null;
  updateMany?: ClientGroupUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ClientGroupScalarWhereInput[] | null;
  upsert?: ClientGroupUpsertWithWhereUniqueWithoutFarmInput[] | null;
}

export interface ClientGroupUpdateOneInput {
  create?: ClientGroupCreateInput | null;
  connect?: ClientGroupWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ClientGroupUpdateDataInput | null;
  upsert?: ClientGroupUpsertNestedInput | null;
}

export interface ClientGroupUpdateOneRequiredWithoutClientsInput {
  create?: ClientGroupCreateWithoutClientsInput | null;
  connect?: ClientGroupWhereUniqueInput | null;
  update?: ClientGroupUpdateWithoutClientsDataInput | null;
  upsert?: ClientGroupUpsertWithoutClientsInput | null;
}

export interface ClientGroupUpdateOneRequiredWithoutDiscountsInput {
  create?: ClientGroupCreateWithoutDiscountsInput | null;
  connect?: ClientGroupWhereUniqueInput | null;
  update?: ClientGroupUpdateWithoutDiscountsDataInput | null;
  upsert?: ClientGroupUpsertWithoutDiscountsInput | null;
}

export interface ClientGroupUpdateOneRequiredWithoutProductAvailablitiesInput {
  create?: ClientGroupCreateWithoutProductAvailablitiesInput | null;
  connect?: ClientGroupWhereUniqueInput | null;
  update?: ClientGroupUpdateWithoutProductAvailablitiesDataInput | null;
  upsert?: ClientGroupUpsertWithoutProductAvailablitiesInput | null;
}

export interface ClientGroupUpdateOneWithoutProductPricesInput {
  create?: ClientGroupCreateWithoutProductPricesInput | null;
  connect?: ClientGroupWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ClientGroupUpdateWithoutProductPricesDataInput | null;
  upsert?: ClientGroupUpsertWithoutProductPricesInput | null;
}

export interface ClientGroupUpdateWithWhereUniqueWithoutFarmInput {
  where: ClientGroupWhereUniqueInput;
  data: ClientGroupUpdateWithoutFarmDataInput;
}

export interface ClientGroupUpdateWithoutClientsDataInput {
  name?: string | null;
  code?: string | null;
  mailchimpTag?: string | null;
  discount?: number | null;
  profitMargin?: number | null;
  default?: boolean | null;
  showInclVat?: boolean | null;
  useForPricing?: boolean | null;
  priceAsPoints?: boolean | null;
  secondaryGroup?: boolean | null;
  order?: number | null;
  pointsBase?: ClientGroupUpdateOneInput | null;
  discounts?: ProductDiscountUpdateManyWithoutClientGroupInput | null;
  productPrices?: ProductPriceUpdateManyWithoutClientGroupInput | null;
  productAvailablities?: ProductAvailabilityUpdateManyWithoutClientGroupInput | null;
  farm?: FarmUpdateOneWithoutClientGroupsInput | null;
}

export interface ClientGroupUpdateWithoutDiscountsDataInput {
  name?: string | null;
  code?: string | null;
  mailchimpTag?: string | null;
  discount?: number | null;
  profitMargin?: number | null;
  default?: boolean | null;
  showInclVat?: boolean | null;
  useForPricing?: boolean | null;
  priceAsPoints?: boolean | null;
  secondaryGroup?: boolean | null;
  order?: number | null;
  pointsBase?: ClientGroupUpdateOneInput | null;
  clients?: ClientUpdateManyWithoutGroupInput | null;
  productPrices?: ProductPriceUpdateManyWithoutClientGroupInput | null;
  productAvailablities?: ProductAvailabilityUpdateManyWithoutClientGroupInput | null;
  farm?: FarmUpdateOneWithoutClientGroupsInput | null;
}

export interface ClientGroupUpdateWithoutFarmDataInput {
  name?: string | null;
  code?: string | null;
  mailchimpTag?: string | null;
  discount?: number | null;
  profitMargin?: number | null;
  default?: boolean | null;
  showInclVat?: boolean | null;
  useForPricing?: boolean | null;
  priceAsPoints?: boolean | null;
  secondaryGroup?: boolean | null;
  order?: number | null;
  pointsBase?: ClientGroupUpdateOneInput | null;
  clients?: ClientUpdateManyWithoutGroupInput | null;
  discounts?: ProductDiscountUpdateManyWithoutClientGroupInput | null;
  productPrices?: ProductPriceUpdateManyWithoutClientGroupInput | null;
  productAvailablities?: ProductAvailabilityUpdateManyWithoutClientGroupInput | null;
}

export interface ClientGroupUpdateWithoutProductAvailablitiesDataInput {
  name?: string | null;
  code?: string | null;
  mailchimpTag?: string | null;
  discount?: number | null;
  profitMargin?: number | null;
  default?: boolean | null;
  showInclVat?: boolean | null;
  useForPricing?: boolean | null;
  priceAsPoints?: boolean | null;
  secondaryGroup?: boolean | null;
  order?: number | null;
  pointsBase?: ClientGroupUpdateOneInput | null;
  clients?: ClientUpdateManyWithoutGroupInput | null;
  discounts?: ProductDiscountUpdateManyWithoutClientGroupInput | null;
  productPrices?: ProductPriceUpdateManyWithoutClientGroupInput | null;
  farm?: FarmUpdateOneWithoutClientGroupsInput | null;
}

export interface ClientGroupUpdateWithoutProductPricesDataInput {
  name?: string | null;
  code?: string | null;
  mailchimpTag?: string | null;
  discount?: number | null;
  profitMargin?: number | null;
  default?: boolean | null;
  showInclVat?: boolean | null;
  useForPricing?: boolean | null;
  priceAsPoints?: boolean | null;
  secondaryGroup?: boolean | null;
  order?: number | null;
  pointsBase?: ClientGroupUpdateOneInput | null;
  clients?: ClientUpdateManyWithoutGroupInput | null;
  discounts?: ProductDiscountUpdateManyWithoutClientGroupInput | null;
  productAvailablities?: ProductAvailabilityUpdateManyWithoutClientGroupInput | null;
  farm?: FarmUpdateOneWithoutClientGroupsInput | null;
}

export interface ClientGroupUpsertNestedInput {
  update: ClientGroupUpdateDataInput;
  create: ClientGroupCreateInput;
}

export interface ClientGroupUpsertWithWhereUniqueWithoutFarmInput {
  where: ClientGroupWhereUniqueInput;
  update: ClientGroupUpdateWithoutFarmDataInput;
  create: ClientGroupCreateWithoutFarmInput;
}

export interface ClientGroupUpsertWithoutClientsInput {
  update: ClientGroupUpdateWithoutClientsDataInput;
  create: ClientGroupCreateWithoutClientsInput;
}

export interface ClientGroupUpsertWithoutDiscountsInput {
  update: ClientGroupUpdateWithoutDiscountsDataInput;
  create: ClientGroupCreateWithoutDiscountsInput;
}

export interface ClientGroupUpsertWithoutProductAvailablitiesInput {
  update: ClientGroupUpdateWithoutProductAvailablitiesDataInput;
  create: ClientGroupCreateWithoutProductAvailablitiesInput;
}

export interface ClientGroupUpsertWithoutProductPricesInput {
  update: ClientGroupUpdateWithoutProductPricesDataInput;
  create: ClientGroupCreateWithoutProductPricesInput;
}

export interface ClientGroupWhereInput {
  AND?: ClientGroupWhereInput[] | null;
  OR?: ClientGroupWhereInput[] | null;
  NOT?: ClientGroupWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  code?: string | null;
  code_not?: string | null;
  code_in?: string[] | null;
  code_not_in?: string[] | null;
  code_lt?: string | null;
  code_lte?: string | null;
  code_gt?: string | null;
  code_gte?: string | null;
  code_contains?: string | null;
  code_not_contains?: string | null;
  code_starts_with?: string | null;
  code_not_starts_with?: string | null;
  code_ends_with?: string | null;
  code_not_ends_with?: string | null;
  mailchimpTag?: string | null;
  mailchimpTag_not?: string | null;
  mailchimpTag_in?: string[] | null;
  mailchimpTag_not_in?: string[] | null;
  mailchimpTag_lt?: string | null;
  mailchimpTag_lte?: string | null;
  mailchimpTag_gt?: string | null;
  mailchimpTag_gte?: string | null;
  mailchimpTag_contains?: string | null;
  mailchimpTag_not_contains?: string | null;
  mailchimpTag_starts_with?: string | null;
  mailchimpTag_not_starts_with?: string | null;
  mailchimpTag_ends_with?: string | null;
  mailchimpTag_not_ends_with?: string | null;
  discount?: number | null;
  discount_not?: number | null;
  discount_in?: number[] | null;
  discount_not_in?: number[] | null;
  discount_lt?: number | null;
  discount_lte?: number | null;
  discount_gt?: number | null;
  discount_gte?: number | null;
  profitMargin?: number | null;
  profitMargin_not?: number | null;
  profitMargin_in?: number[] | null;
  profitMargin_not_in?: number[] | null;
  profitMargin_lt?: number | null;
  profitMargin_lte?: number | null;
  profitMargin_gt?: number | null;
  profitMargin_gte?: number | null;
  default?: boolean | null;
  default_not?: boolean | null;
  showInclVat?: boolean | null;
  showInclVat_not?: boolean | null;
  useForPricing?: boolean | null;
  useForPricing_not?: boolean | null;
  priceAsPoints?: boolean | null;
  priceAsPoints_not?: boolean | null;
  secondaryGroup?: boolean | null;
  secondaryGroup_not?: boolean | null;
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  pointsBase?: ClientGroupWhereInput | null;
  clients_every?: ClientWhereInput | null;
  clients_some?: ClientWhereInput | null;
  clients_none?: ClientWhereInput | null;
  discounts_every?: ProductDiscountWhereInput | null;
  discounts_some?: ProductDiscountWhereInput | null;
  discounts_none?: ProductDiscountWhereInput | null;
  productPrices_every?: ProductPriceWhereInput | null;
  productPrices_some?: ProductPriceWhereInput | null;
  productPrices_none?: ProductPriceWhereInput | null;
  productAvailablities_every?: ProductAvailabilityWhereInput | null;
  productAvailablities_some?: ProductAvailabilityWhereInput | null;
  productAvailablities_none?: ProductAvailabilityWhereInput | null;
  farm?: FarmWhereInput | null;
}

export interface ClientGroupWhereUniqueInput {
  id?: string | null;
  code?: string | null;
}

export interface ClientOrderCreateInput {
  id?: string | null;
  orderDate: any;
  deliveryDate: any;
  deliveryEndDate?: any | null;
  delivery: boolean;
  orderDiscount?: number | null;
  status: ClientOrderStatus;
  completionStatus?: ClientOrderCompletionStatus | null;
  completionMetadata?: any | null;
  client: ClientCreateOneWithoutClientOrdersInput;
  productOrders?: ProductOrderCreateManyWithoutClientOrderInput | null;
  invoice?: InvoiceCreateOneWithoutClientOrdersInput | null;
}

export interface ClientOrderCreateManyWithoutClientInput {
  create?: ClientOrderCreateWithoutClientInput[] | null;
  connect?: ClientOrderWhereUniqueInput[] | null;
}

export interface ClientOrderCreateManyWithoutInvoiceInput {
  create?: ClientOrderCreateWithoutInvoiceInput[] | null;
  connect?: ClientOrderWhereUniqueInput[] | null;
}

export interface ClientOrderCreateOneWithoutProductOrdersInput {
  create?: ClientOrderCreateWithoutProductOrdersInput | null;
  connect?: ClientOrderWhereUniqueInput | null;
}

export interface ClientOrderCreateWithoutClientInput {
  id?: string | null;
  orderDate: any;
  deliveryDate: any;
  deliveryEndDate?: any | null;
  delivery: boolean;
  orderDiscount?: number | null;
  status: ClientOrderStatus;
  completionStatus?: ClientOrderCompletionStatus | null;
  completionMetadata?: any | null;
  productOrders?: ProductOrderCreateManyWithoutClientOrderInput | null;
  invoice?: InvoiceCreateOneWithoutClientOrdersInput | null;
}

export interface ClientOrderCreateWithoutInvoiceInput {
  id?: string | null;
  orderDate: any;
  deliveryDate: any;
  deliveryEndDate?: any | null;
  delivery: boolean;
  orderDiscount?: number | null;
  status: ClientOrderStatus;
  completionStatus?: ClientOrderCompletionStatus | null;
  completionMetadata?: any | null;
  client: ClientCreateOneWithoutClientOrdersInput;
  productOrders?: ProductOrderCreateManyWithoutClientOrderInput | null;
}

export interface ClientOrderCreateWithoutProductOrdersInput {
  id?: string | null;
  orderDate: any;
  deliveryDate: any;
  deliveryEndDate?: any | null;
  delivery: boolean;
  orderDiscount?: number | null;
  status: ClientOrderStatus;
  completionStatus?: ClientOrderCompletionStatus | null;
  completionMetadata?: any | null;
  client: ClientCreateOneWithoutClientOrdersInput;
  invoice?: InvoiceCreateOneWithoutClientOrdersInput | null;
}

export interface ClientOrderScalarWhereInput {
  AND?: ClientOrderScalarWhereInput[] | null;
  OR?: ClientOrderScalarWhereInput[] | null;
  NOT?: ClientOrderScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  orderDate?: any | null;
  orderDate_not?: any | null;
  orderDate_in?: any[] | null;
  orderDate_not_in?: any[] | null;
  orderDate_lt?: any | null;
  orderDate_lte?: any | null;
  orderDate_gt?: any | null;
  orderDate_gte?: any | null;
  deliveryDate?: any | null;
  deliveryDate_not?: any | null;
  deliveryDate_in?: any[] | null;
  deliveryDate_not_in?: any[] | null;
  deliveryDate_lt?: any | null;
  deliveryDate_lte?: any | null;
  deliveryDate_gt?: any | null;
  deliveryDate_gte?: any | null;
  deliveryEndDate?: any | null;
  deliveryEndDate_not?: any | null;
  deliveryEndDate_in?: any[] | null;
  deliveryEndDate_not_in?: any[] | null;
  deliveryEndDate_lt?: any | null;
  deliveryEndDate_lte?: any | null;
  deliveryEndDate_gt?: any | null;
  deliveryEndDate_gte?: any | null;
  delivery?: boolean | null;
  delivery_not?: boolean | null;
  orderDiscount?: number | null;
  orderDiscount_not?: number | null;
  orderDiscount_in?: number[] | null;
  orderDiscount_not_in?: number[] | null;
  orderDiscount_lt?: number | null;
  orderDiscount_lte?: number | null;
  orderDiscount_gt?: number | null;
  orderDiscount_gte?: number | null;
  status?: ClientOrderStatus | null;
  status_not?: ClientOrderStatus | null;
  status_in?: ClientOrderStatus[] | null;
  status_not_in?: ClientOrderStatus[] | null;
  completionStatus?: ClientOrderCompletionStatus | null;
  completionStatus_not?: ClientOrderCompletionStatus | null;
  completionStatus_in?: ClientOrderCompletionStatus[] | null;
  completionStatus_not_in?: ClientOrderCompletionStatus[] | null;
}

export interface ClientOrderUpdateInput {
  orderDate?: any | null;
  deliveryDate?: any | null;
  deliveryEndDate?: any | null;
  delivery?: boolean | null;
  orderDiscount?: number | null;
  status?: ClientOrderStatus | null;
  completionStatus?: ClientOrderCompletionStatus | null;
  completionMetadata?: any | null;
  client?: ClientUpdateOneRequiredWithoutClientOrdersInput | null;
  productOrders?: ProductOrderUpdateManyWithoutClientOrderInput | null;
  invoice?: InvoiceUpdateOneWithoutClientOrdersInput | null;
}

export interface ClientOrderUpdateManyDataInput {
  orderDate?: any | null;
  deliveryDate?: any | null;
  deliveryEndDate?: any | null;
  delivery?: boolean | null;
  orderDiscount?: number | null;
  status?: ClientOrderStatus | null;
  completionStatus?: ClientOrderCompletionStatus | null;
  completionMetadata?: any | null;
}

export interface ClientOrderUpdateManyWithWhereNestedInput {
  where: ClientOrderScalarWhereInput;
  data: ClientOrderUpdateManyDataInput;
}

export interface ClientOrderUpdateManyWithoutClientInput {
  create?: ClientOrderCreateWithoutClientInput[] | null;
  connect?: ClientOrderWhereUniqueInput[] | null;
  set?: ClientOrderWhereUniqueInput[] | null;
  disconnect?: ClientOrderWhereUniqueInput[] | null;
  delete?: ClientOrderWhereUniqueInput[] | null;
  update?: ClientOrderUpdateWithWhereUniqueWithoutClientInput[] | null;
  updateMany?: ClientOrderUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ClientOrderScalarWhereInput[] | null;
  upsert?: ClientOrderUpsertWithWhereUniqueWithoutClientInput[] | null;
}

export interface ClientOrderUpdateManyWithoutInvoiceInput {
  create?: ClientOrderCreateWithoutInvoiceInput[] | null;
  connect?: ClientOrderWhereUniqueInput[] | null;
  set?: ClientOrderWhereUniqueInput[] | null;
  disconnect?: ClientOrderWhereUniqueInput[] | null;
  delete?: ClientOrderWhereUniqueInput[] | null;
  update?: ClientOrderUpdateWithWhereUniqueWithoutInvoiceInput[] | null;
  updateMany?: ClientOrderUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ClientOrderScalarWhereInput[] | null;
  upsert?: ClientOrderUpsertWithWhereUniqueWithoutInvoiceInput[] | null;
}

export interface ClientOrderUpdateOneRequiredWithoutProductOrdersInput {
  create?: ClientOrderCreateWithoutProductOrdersInput | null;
  connect?: ClientOrderWhereUniqueInput | null;
  update?: ClientOrderUpdateWithoutProductOrdersDataInput | null;
  upsert?: ClientOrderUpsertWithoutProductOrdersInput | null;
}

export interface ClientOrderUpdateWithWhereUniqueWithoutClientInput {
  where: ClientOrderWhereUniqueInput;
  data: ClientOrderUpdateWithoutClientDataInput;
}

export interface ClientOrderUpdateWithWhereUniqueWithoutInvoiceInput {
  where: ClientOrderWhereUniqueInput;
  data: ClientOrderUpdateWithoutInvoiceDataInput;
}

export interface ClientOrderUpdateWithoutClientDataInput {
  orderDate?: any | null;
  deliveryDate?: any | null;
  deliveryEndDate?: any | null;
  delivery?: boolean | null;
  orderDiscount?: number | null;
  status?: ClientOrderStatus | null;
  completionStatus?: ClientOrderCompletionStatus | null;
  completionMetadata?: any | null;
  productOrders?: ProductOrderUpdateManyWithoutClientOrderInput | null;
  invoice?: InvoiceUpdateOneWithoutClientOrdersInput | null;
}

export interface ClientOrderUpdateWithoutInvoiceDataInput {
  orderDate?: any | null;
  deliveryDate?: any | null;
  deliveryEndDate?: any | null;
  delivery?: boolean | null;
  orderDiscount?: number | null;
  status?: ClientOrderStatus | null;
  completionStatus?: ClientOrderCompletionStatus | null;
  completionMetadata?: any | null;
  client?: ClientUpdateOneRequiredWithoutClientOrdersInput | null;
  productOrders?: ProductOrderUpdateManyWithoutClientOrderInput | null;
}

export interface ClientOrderUpdateWithoutProductOrdersDataInput {
  orderDate?: any | null;
  deliveryDate?: any | null;
  deliveryEndDate?: any | null;
  delivery?: boolean | null;
  orderDiscount?: number | null;
  status?: ClientOrderStatus | null;
  completionStatus?: ClientOrderCompletionStatus | null;
  completionMetadata?: any | null;
  client?: ClientUpdateOneRequiredWithoutClientOrdersInput | null;
  invoice?: InvoiceUpdateOneWithoutClientOrdersInput | null;
}

export interface ClientOrderUpsertWithWhereUniqueWithoutClientInput {
  where: ClientOrderWhereUniqueInput;
  update: ClientOrderUpdateWithoutClientDataInput;
  create: ClientOrderCreateWithoutClientInput;
}

export interface ClientOrderUpsertWithWhereUniqueWithoutInvoiceInput {
  where: ClientOrderWhereUniqueInput;
  update: ClientOrderUpdateWithoutInvoiceDataInput;
  create: ClientOrderCreateWithoutInvoiceInput;
}

export interface ClientOrderUpsertWithoutProductOrdersInput {
  update: ClientOrderUpdateWithoutProductOrdersDataInput;
  create: ClientOrderCreateWithoutProductOrdersInput;
}

export interface ClientOrderWhereInput {
  AND?: ClientOrderWhereInput[] | null;
  OR?: ClientOrderWhereInput[] | null;
  NOT?: ClientOrderWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  orderDate?: any | null;
  orderDate_not?: any | null;
  orderDate_in?: any[] | null;
  orderDate_not_in?: any[] | null;
  orderDate_lt?: any | null;
  orderDate_lte?: any | null;
  orderDate_gt?: any | null;
  orderDate_gte?: any | null;
  deliveryDate?: any | null;
  deliveryDate_not?: any | null;
  deliveryDate_in?: any[] | null;
  deliveryDate_not_in?: any[] | null;
  deliveryDate_lt?: any | null;
  deliveryDate_lte?: any | null;
  deliveryDate_gt?: any | null;
  deliveryDate_gte?: any | null;
  deliveryEndDate?: any | null;
  deliveryEndDate_not?: any | null;
  deliveryEndDate_in?: any[] | null;
  deliveryEndDate_not_in?: any[] | null;
  deliveryEndDate_lt?: any | null;
  deliveryEndDate_lte?: any | null;
  deliveryEndDate_gt?: any | null;
  deliveryEndDate_gte?: any | null;
  delivery?: boolean | null;
  delivery_not?: boolean | null;
  orderDiscount?: number | null;
  orderDiscount_not?: number | null;
  orderDiscount_in?: number[] | null;
  orderDiscount_not_in?: number[] | null;
  orderDiscount_lt?: number | null;
  orderDiscount_lte?: number | null;
  orderDiscount_gt?: number | null;
  orderDiscount_gte?: number | null;
  status?: ClientOrderStatus | null;
  status_not?: ClientOrderStatus | null;
  status_in?: ClientOrderStatus[] | null;
  status_not_in?: ClientOrderStatus[] | null;
  completionStatus?: ClientOrderCompletionStatus | null;
  completionStatus_not?: ClientOrderCompletionStatus | null;
  completionStatus_in?: ClientOrderCompletionStatus[] | null;
  completionStatus_not_in?: ClientOrderCompletionStatus[] | null;
  client?: ClientWhereInput | null;
  productOrders_every?: ProductOrderWhereInput | null;
  productOrders_some?: ProductOrderWhereInput | null;
  productOrders_none?: ProductOrderWhereInput | null;
  invoice?: InvoiceWhereInput | null;
}

export interface ClientOrderWhereUniqueInput {
  id?: string | null;
}

export interface ClientScalarWhereInput {
  AND?: ClientScalarWhereInput[] | null;
  OR?: ClientScalarWhereInput[] | null;
  NOT?: ClientScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  phone?: string | null;
  phone_not?: string | null;
  phone_in?: string[] | null;
  phone_not_in?: string[] | null;
  phone_lt?: string | null;
  phone_lte?: string | null;
  phone_gt?: string | null;
  phone_gte?: string | null;
  phone_contains?: string | null;
  phone_not_contains?: string | null;
  phone_starts_with?: string | null;
  phone_not_starts_with?: string | null;
  phone_ends_with?: string | null;
  phone_not_ends_with?: string | null;
  street?: string | null;
  street_not?: string | null;
  street_in?: string[] | null;
  street_not_in?: string[] | null;
  street_lt?: string | null;
  street_lte?: string | null;
  street_gt?: string | null;
  street_gte?: string | null;
  street_contains?: string | null;
  street_not_contains?: string | null;
  street_starts_with?: string | null;
  street_not_starts_with?: string | null;
  street_ends_with?: string | null;
  street_not_ends_with?: string | null;
  streetNumber?: string | null;
  streetNumber_not?: string | null;
  streetNumber_in?: string[] | null;
  streetNumber_not_in?: string[] | null;
  streetNumber_lt?: string | null;
  streetNumber_lte?: string | null;
  streetNumber_gt?: string | null;
  streetNumber_gte?: string | null;
  streetNumber_contains?: string | null;
  streetNumber_not_contains?: string | null;
  streetNumber_starts_with?: string | null;
  streetNumber_not_starts_with?: string | null;
  streetNumber_ends_with?: string | null;
  streetNumber_not_ends_with?: string | null;
  city?: string | null;
  city_not?: string | null;
  city_in?: string[] | null;
  city_not_in?: string[] | null;
  city_lt?: string | null;
  city_lte?: string | null;
  city_gt?: string | null;
  city_gte?: string | null;
  city_contains?: string | null;
  city_not_contains?: string | null;
  city_starts_with?: string | null;
  city_not_starts_with?: string | null;
  city_ends_with?: string | null;
  city_not_ends_with?: string | null;
  cityPostalCode?: string | null;
  cityPostalCode_not?: string | null;
  cityPostalCode_in?: string[] | null;
  cityPostalCode_not_in?: string[] | null;
  cityPostalCode_lt?: string | null;
  cityPostalCode_lte?: string | null;
  cityPostalCode_gt?: string | null;
  cityPostalCode_gte?: string | null;
  cityPostalCode_contains?: string | null;
  cityPostalCode_not_contains?: string | null;
  cityPostalCode_starts_with?: string | null;
  cityPostalCode_not_starts_with?: string | null;
  cityPostalCode_ends_with?: string | null;
  cityPostalCode_not_ends_with?: string | null;
  email?: string | null;
  email_not?: string | null;
  email_in?: string[] | null;
  email_not_in?: string[] | null;
  email_lt?: string | null;
  email_lte?: string | null;
  email_gt?: string | null;
  email_gte?: string | null;
  email_contains?: string | null;
  email_not_contains?: string | null;
  email_starts_with?: string | null;
  email_not_starts_with?: string | null;
  email_ends_with?: string | null;
  email_not_ends_with?: string | null;
  newsletter?: boolean | null;
  newsletter_not?: boolean | null;
  remarks?: string | null;
  remarks_not?: string | null;
  remarks_in?: string[] | null;
  remarks_not_in?: string[] | null;
  remarks_lt?: string | null;
  remarks_lte?: string | null;
  remarks_gt?: string | null;
  remarks_gte?: string | null;
  remarks_contains?: string | null;
  remarks_not_contains?: string | null;
  remarks_starts_with?: string | null;
  remarks_not_starts_with?: string | null;
  remarks_ends_with?: string | null;
  remarks_not_ends_with?: string | null;
  type?: ClientType | null;
  type_not?: ClientType | null;
  type_in?: ClientType[] | null;
  type_not_in?: ClientType[] | null;
  secondaryEmail?: string | null;
  secondaryEmail_not?: string | null;
  secondaryEmail_in?: string[] | null;
  secondaryEmail_not_in?: string[] | null;
  secondaryEmail_lt?: string | null;
  secondaryEmail_lte?: string | null;
  secondaryEmail_gt?: string | null;
  secondaryEmail_gte?: string | null;
  secondaryEmail_contains?: string | null;
  secondaryEmail_not_contains?: string | null;
  secondaryEmail_starts_with?: string | null;
  secondaryEmail_not_starts_with?: string | null;
  secondaryEmail_ends_with?: string | null;
  secondaryEmail_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  firstName?: string | null;
  firstName_not?: string | null;
  firstName_in?: string[] | null;
  firstName_not_in?: string[] | null;
  firstName_lt?: string | null;
  firstName_lte?: string | null;
  firstName_gt?: string | null;
  firstName_gte?: string | null;
  firstName_contains?: string | null;
  firstName_not_contains?: string | null;
  firstName_starts_with?: string | null;
  firstName_not_starts_with?: string | null;
  firstName_ends_with?: string | null;
  firstName_not_ends_with?: string | null;
  dateOfBirth?: any | null;
  dateOfBirth_not?: any | null;
  dateOfBirth_in?: any[] | null;
  dateOfBirth_not_in?: any[] | null;
  dateOfBirth_lt?: any | null;
  dateOfBirth_lte?: any | null;
  dateOfBirth_gt?: any | null;
  dateOfBirth_gte?: any | null;
  billingEmail?: string | null;
  billingEmail_not?: string | null;
  billingEmail_in?: string[] | null;
  billingEmail_not_in?: string[] | null;
  billingEmail_lt?: string | null;
  billingEmail_lte?: string | null;
  billingEmail_gt?: string | null;
  billingEmail_gte?: string | null;
  billingEmail_contains?: string | null;
  billingEmail_not_contains?: string | null;
  billingEmail_starts_with?: string | null;
  billingEmail_not_starts_with?: string | null;
  billingEmail_ends_with?: string | null;
  billingEmail_not_ends_with?: string | null;
  vatNumber?: string | null;
  vatNumber_not?: string | null;
  vatNumber_in?: string[] | null;
  vatNumber_not_in?: string[] | null;
  vatNumber_lt?: string | null;
  vatNumber_lte?: string | null;
  vatNumber_gt?: string | null;
  vatNumber_gte?: string | null;
  vatNumber_contains?: string | null;
  vatNumber_not_contains?: string | null;
  vatNumber_starts_with?: string | null;
  vatNumber_not_starts_with?: string | null;
  vatNumber_ends_with?: string | null;
  vatNumber_not_ends_with?: string | null;
  companyName?: string | null;
  companyName_not?: string | null;
  companyName_in?: string[] | null;
  companyName_not_in?: string[] | null;
  companyName_lt?: string | null;
  companyName_lte?: string | null;
  companyName_gt?: string | null;
  companyName_gte?: string | null;
  companyName_contains?: string | null;
  companyName_not_contains?: string | null;
  companyName_starts_with?: string | null;
  companyName_not_starts_with?: string | null;
  companyName_ends_with?: string | null;
  companyName_not_ends_with?: string | null;
  whatsApp?: boolean | null;
  whatsApp_not?: boolean | null;
  communicationGoudenWortel?: boolean | null;
  communicationGoudenWortel_not?: boolean | null;
  auth0userId?: string | null;
  auth0userId_not?: string | null;
  auth0userId_in?: string[] | null;
  auth0userId_not_in?: string[] | null;
  auth0userId_lt?: string | null;
  auth0userId_lte?: string | null;
  auth0userId_gt?: string | null;
  auth0userId_gte?: string | null;
  auth0userId_contains?: string | null;
  auth0userId_not_contains?: string | null;
  auth0userId_starts_with?: string | null;
  auth0userId_not_starts_with?: string | null;
  auth0userId_ends_with?: string | null;
  auth0userId_not_ends_with?: string | null;
}

export interface ClientUpdateInput {
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  email?: string | null;
  newsletter?: boolean | null;
  remarks?: string | null;
  type?: ClientType | null;
  secondaryEmail?: string | null;
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  billingEmail?: string | null;
  vatNumber?: string | null;
  companyName?: string | null;
  whatsApp?: boolean | null;
  communicationGoudenWortel?: boolean | null;
  auth0userId?: string | null;
  ibanNumbers?: ClientUpdateibanNumbersInput | null;
  group?: ClientGroupUpdateOneRequiredWithoutClientsInput | null;
  clientOrders?: ClientOrderUpdateManyWithoutClientInput | null;
  membership?: MembershipUpdateOneInput | null;
  farm?: FarmUpdateOneRequiredWithoutClientsInput | null;
  customerInfo?: CustomerInfoUpdateOneInput | null;
  bankTransactions?: BankTransactionUpdateManyWithoutClientInput | null;
}

export interface ClientUpdateManyDataInput {
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  email?: string | null;
  newsletter?: boolean | null;
  remarks?: string | null;
  type?: ClientType | null;
  secondaryEmail?: string | null;
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  billingEmail?: string | null;
  vatNumber?: string | null;
  companyName?: string | null;
  whatsApp?: boolean | null;
  communicationGoudenWortel?: boolean | null;
  auth0userId?: string | null;
  ibanNumbers?: ClientUpdateibanNumbersInput | null;
}

export interface ClientUpdateManyWithWhereNestedInput {
  where: ClientScalarWhereInput;
  data: ClientUpdateManyDataInput;
}

export interface ClientUpdateManyWithoutFarmInput {
  create?: ClientCreateWithoutFarmInput[] | null;
  connect?: ClientWhereUniqueInput[] | null;
  set?: ClientWhereUniqueInput[] | null;
  disconnect?: ClientWhereUniqueInput[] | null;
  delete?: ClientWhereUniqueInput[] | null;
  update?: ClientUpdateWithWhereUniqueWithoutFarmInput[] | null;
  updateMany?: ClientUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ClientScalarWhereInput[] | null;
  upsert?: ClientUpsertWithWhereUniqueWithoutFarmInput[] | null;
}

export interface ClientUpdateManyWithoutGroupInput {
  create?: ClientCreateWithoutGroupInput[] | null;
  connect?: ClientWhereUniqueInput[] | null;
  set?: ClientWhereUniqueInput[] | null;
  disconnect?: ClientWhereUniqueInput[] | null;
  delete?: ClientWhereUniqueInput[] | null;
  update?: ClientUpdateWithWhereUniqueWithoutGroupInput[] | null;
  updateMany?: ClientUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ClientScalarWhereInput[] | null;
  upsert?: ClientUpsertWithWhereUniqueWithoutGroupInput[] | null;
}

export interface ClientUpdateOneRequiredWithoutClientOrdersInput {
  create?: ClientCreateWithoutClientOrdersInput | null;
  connect?: ClientWhereUniqueInput | null;
  update?: ClientUpdateWithoutClientOrdersDataInput | null;
  upsert?: ClientUpsertWithoutClientOrdersInput | null;
}

export interface ClientUpdateWithWhereUniqueWithoutFarmInput {
  where: ClientWhereUniqueInput;
  data: ClientUpdateWithoutFarmDataInput;
}

export interface ClientUpdateWithWhereUniqueWithoutGroupInput {
  where: ClientWhereUniqueInput;
  data: ClientUpdateWithoutGroupDataInput;
}

export interface ClientUpdateWithoutClientOrdersDataInput {
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  email?: string | null;
  newsletter?: boolean | null;
  remarks?: string | null;
  type?: ClientType | null;
  secondaryEmail?: string | null;
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  billingEmail?: string | null;
  vatNumber?: string | null;
  companyName?: string | null;
  whatsApp?: boolean | null;
  communicationGoudenWortel?: boolean | null;
  auth0userId?: string | null;
  ibanNumbers?: ClientUpdateibanNumbersInput | null;
  group?: ClientGroupUpdateOneRequiredWithoutClientsInput | null;
  membership?: MembershipUpdateOneInput | null;
  farm?: FarmUpdateOneRequiredWithoutClientsInput | null;
  customerInfo?: CustomerInfoUpdateOneInput | null;
  bankTransactions?: BankTransactionUpdateManyWithoutClientInput | null;
}

export interface ClientUpdateWithoutFarmDataInput {
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  email?: string | null;
  newsletter?: boolean | null;
  remarks?: string | null;
  type?: ClientType | null;
  secondaryEmail?: string | null;
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  billingEmail?: string | null;
  vatNumber?: string | null;
  companyName?: string | null;
  whatsApp?: boolean | null;
  communicationGoudenWortel?: boolean | null;
  auth0userId?: string | null;
  ibanNumbers?: ClientUpdateibanNumbersInput | null;
  group?: ClientGroupUpdateOneRequiredWithoutClientsInput | null;
  clientOrders?: ClientOrderUpdateManyWithoutClientInput | null;
  membership?: MembershipUpdateOneInput | null;
  customerInfo?: CustomerInfoUpdateOneInput | null;
  bankTransactions?: BankTransactionUpdateManyWithoutClientInput | null;
}

export interface ClientUpdateWithoutGroupDataInput {
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  email?: string | null;
  newsletter?: boolean | null;
  remarks?: string | null;
  type?: ClientType | null;
  secondaryEmail?: string | null;
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  billingEmail?: string | null;
  vatNumber?: string | null;
  companyName?: string | null;
  whatsApp?: boolean | null;
  communicationGoudenWortel?: boolean | null;
  auth0userId?: string | null;
  ibanNumbers?: ClientUpdateibanNumbersInput | null;
  clientOrders?: ClientOrderUpdateManyWithoutClientInput | null;
  membership?: MembershipUpdateOneInput | null;
  farm?: FarmUpdateOneRequiredWithoutClientsInput | null;
  customerInfo?: CustomerInfoUpdateOneInput | null;
  bankTransactions?: BankTransactionUpdateManyWithoutClientInput | null;
}

export interface ClientUpdateibanNumbersInput {
  set?: string[] | null;
}

export interface ClientUpsertWithWhereUniqueWithoutFarmInput {
  where: ClientWhereUniqueInput;
  update: ClientUpdateWithoutFarmDataInput;
  create: ClientCreateWithoutFarmInput;
}

export interface ClientUpsertWithWhereUniqueWithoutGroupInput {
  where: ClientWhereUniqueInput;
  update: ClientUpdateWithoutGroupDataInput;
  create: ClientCreateWithoutGroupInput;
}

export interface ClientUpsertWithoutClientOrdersInput {
  update: ClientUpdateWithoutClientOrdersDataInput;
  create: ClientCreateWithoutClientOrdersInput;
}

export interface ClientWhereInput {
  AND?: ClientWhereInput[] | null;
  OR?: ClientWhereInput[] | null;
  NOT?: ClientWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  phone?: string | null;
  phone_not?: string | null;
  phone_in?: string[] | null;
  phone_not_in?: string[] | null;
  phone_lt?: string | null;
  phone_lte?: string | null;
  phone_gt?: string | null;
  phone_gte?: string | null;
  phone_contains?: string | null;
  phone_not_contains?: string | null;
  phone_starts_with?: string | null;
  phone_not_starts_with?: string | null;
  phone_ends_with?: string | null;
  phone_not_ends_with?: string | null;
  street?: string | null;
  street_not?: string | null;
  street_in?: string[] | null;
  street_not_in?: string[] | null;
  street_lt?: string | null;
  street_lte?: string | null;
  street_gt?: string | null;
  street_gte?: string | null;
  street_contains?: string | null;
  street_not_contains?: string | null;
  street_starts_with?: string | null;
  street_not_starts_with?: string | null;
  street_ends_with?: string | null;
  street_not_ends_with?: string | null;
  streetNumber?: string | null;
  streetNumber_not?: string | null;
  streetNumber_in?: string[] | null;
  streetNumber_not_in?: string[] | null;
  streetNumber_lt?: string | null;
  streetNumber_lte?: string | null;
  streetNumber_gt?: string | null;
  streetNumber_gte?: string | null;
  streetNumber_contains?: string | null;
  streetNumber_not_contains?: string | null;
  streetNumber_starts_with?: string | null;
  streetNumber_not_starts_with?: string | null;
  streetNumber_ends_with?: string | null;
  streetNumber_not_ends_with?: string | null;
  city?: string | null;
  city_not?: string | null;
  city_in?: string[] | null;
  city_not_in?: string[] | null;
  city_lt?: string | null;
  city_lte?: string | null;
  city_gt?: string | null;
  city_gte?: string | null;
  city_contains?: string | null;
  city_not_contains?: string | null;
  city_starts_with?: string | null;
  city_not_starts_with?: string | null;
  city_ends_with?: string | null;
  city_not_ends_with?: string | null;
  cityPostalCode?: string | null;
  cityPostalCode_not?: string | null;
  cityPostalCode_in?: string[] | null;
  cityPostalCode_not_in?: string[] | null;
  cityPostalCode_lt?: string | null;
  cityPostalCode_lte?: string | null;
  cityPostalCode_gt?: string | null;
  cityPostalCode_gte?: string | null;
  cityPostalCode_contains?: string | null;
  cityPostalCode_not_contains?: string | null;
  cityPostalCode_starts_with?: string | null;
  cityPostalCode_not_starts_with?: string | null;
  cityPostalCode_ends_with?: string | null;
  cityPostalCode_not_ends_with?: string | null;
  email?: string | null;
  email_not?: string | null;
  email_in?: string[] | null;
  email_not_in?: string[] | null;
  email_lt?: string | null;
  email_lte?: string | null;
  email_gt?: string | null;
  email_gte?: string | null;
  email_contains?: string | null;
  email_not_contains?: string | null;
  email_starts_with?: string | null;
  email_not_starts_with?: string | null;
  email_ends_with?: string | null;
  email_not_ends_with?: string | null;
  newsletter?: boolean | null;
  newsletter_not?: boolean | null;
  remarks?: string | null;
  remarks_not?: string | null;
  remarks_in?: string[] | null;
  remarks_not_in?: string[] | null;
  remarks_lt?: string | null;
  remarks_lte?: string | null;
  remarks_gt?: string | null;
  remarks_gte?: string | null;
  remarks_contains?: string | null;
  remarks_not_contains?: string | null;
  remarks_starts_with?: string | null;
  remarks_not_starts_with?: string | null;
  remarks_ends_with?: string | null;
  remarks_not_ends_with?: string | null;
  type?: ClientType | null;
  type_not?: ClientType | null;
  type_in?: ClientType[] | null;
  type_not_in?: ClientType[] | null;
  secondaryEmail?: string | null;
  secondaryEmail_not?: string | null;
  secondaryEmail_in?: string[] | null;
  secondaryEmail_not_in?: string[] | null;
  secondaryEmail_lt?: string | null;
  secondaryEmail_lte?: string | null;
  secondaryEmail_gt?: string | null;
  secondaryEmail_gte?: string | null;
  secondaryEmail_contains?: string | null;
  secondaryEmail_not_contains?: string | null;
  secondaryEmail_starts_with?: string | null;
  secondaryEmail_not_starts_with?: string | null;
  secondaryEmail_ends_with?: string | null;
  secondaryEmail_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  firstName?: string | null;
  firstName_not?: string | null;
  firstName_in?: string[] | null;
  firstName_not_in?: string[] | null;
  firstName_lt?: string | null;
  firstName_lte?: string | null;
  firstName_gt?: string | null;
  firstName_gte?: string | null;
  firstName_contains?: string | null;
  firstName_not_contains?: string | null;
  firstName_starts_with?: string | null;
  firstName_not_starts_with?: string | null;
  firstName_ends_with?: string | null;
  firstName_not_ends_with?: string | null;
  dateOfBirth?: any | null;
  dateOfBirth_not?: any | null;
  dateOfBirth_in?: any[] | null;
  dateOfBirth_not_in?: any[] | null;
  dateOfBirth_lt?: any | null;
  dateOfBirth_lte?: any | null;
  dateOfBirth_gt?: any | null;
  dateOfBirth_gte?: any | null;
  billingEmail?: string | null;
  billingEmail_not?: string | null;
  billingEmail_in?: string[] | null;
  billingEmail_not_in?: string[] | null;
  billingEmail_lt?: string | null;
  billingEmail_lte?: string | null;
  billingEmail_gt?: string | null;
  billingEmail_gte?: string | null;
  billingEmail_contains?: string | null;
  billingEmail_not_contains?: string | null;
  billingEmail_starts_with?: string | null;
  billingEmail_not_starts_with?: string | null;
  billingEmail_ends_with?: string | null;
  billingEmail_not_ends_with?: string | null;
  vatNumber?: string | null;
  vatNumber_not?: string | null;
  vatNumber_in?: string[] | null;
  vatNumber_not_in?: string[] | null;
  vatNumber_lt?: string | null;
  vatNumber_lte?: string | null;
  vatNumber_gt?: string | null;
  vatNumber_gte?: string | null;
  vatNumber_contains?: string | null;
  vatNumber_not_contains?: string | null;
  vatNumber_starts_with?: string | null;
  vatNumber_not_starts_with?: string | null;
  vatNumber_ends_with?: string | null;
  vatNumber_not_ends_with?: string | null;
  companyName?: string | null;
  companyName_not?: string | null;
  companyName_in?: string[] | null;
  companyName_not_in?: string[] | null;
  companyName_lt?: string | null;
  companyName_lte?: string | null;
  companyName_gt?: string | null;
  companyName_gte?: string | null;
  companyName_contains?: string | null;
  companyName_not_contains?: string | null;
  companyName_starts_with?: string | null;
  companyName_not_starts_with?: string | null;
  companyName_ends_with?: string | null;
  companyName_not_ends_with?: string | null;
  whatsApp?: boolean | null;
  whatsApp_not?: boolean | null;
  communicationGoudenWortel?: boolean | null;
  communicationGoudenWortel_not?: boolean | null;
  auth0userId?: string | null;
  auth0userId_not?: string | null;
  auth0userId_in?: string[] | null;
  auth0userId_not_in?: string[] | null;
  auth0userId_lt?: string | null;
  auth0userId_lte?: string | null;
  auth0userId_gt?: string | null;
  auth0userId_gte?: string | null;
  auth0userId_contains?: string | null;
  auth0userId_not_contains?: string | null;
  auth0userId_starts_with?: string | null;
  auth0userId_not_starts_with?: string | null;
  auth0userId_ends_with?: string | null;
  auth0userId_not_ends_with?: string | null;
  group?: ClientGroupWhereInput | null;
  clientOrders_every?: ClientOrderWhereInput | null;
  clientOrders_some?: ClientOrderWhereInput | null;
  clientOrders_none?: ClientOrderWhereInput | null;
  membership?: MembershipWhereInput | null;
  farm?: FarmWhereInput | null;
  customerInfo?: CustomerInfoWhereInput | null;
  bankTransactions_every?: BankTransactionWhereInput | null;
  bankTransactions_some?: BankTransactionWhereInput | null;
  bankTransactions_none?: BankTransactionWhereInput | null;
}

export interface ClientWhereUniqueInput {
  id?: string | null;
}

export interface CropCreateInput {
  id?: string | null;
  name: string;
  daysToTransplant?: number | null;
  rootDepth?: number | null;
  notes?: string | null;
  cropTimings?: CropTimingCreateManyWithoutCropInput | null;
  group?: CropGroupCreateOneWithoutCropsInput | null;
  family?: CropFamilyCreateOneWithoutCropsInput | null;
  cultivations?: CultivationCreateManyWithoutCropInput | null;
  varieties?: VarietyCreateManyWithoutCropInput | null;
  seedSchema?: SeedSchemaCreateOneWithoutCropsInput | null;
  plantSchemas?: CropPlantSchemaCreateManyWithoutCropInput | null;
}

export interface CropCreateManyInput {
  create?: CropCreateInput[] | null;
  connect?: CropWhereUniqueInput[] | null;
}

export interface CropCreateManyWithoutFamilyInput {
  create?: CropCreateWithoutFamilyInput[] | null;
  connect?: CropWhereUniqueInput[] | null;
}

export interface CropCreateManyWithoutGroupInput {
  create?: CropCreateWithoutGroupInput[] | null;
  connect?: CropWhereUniqueInput[] | null;
}

export interface CropCreateOneWithoutCropTimingsInput {
  create?: CropCreateWithoutCropTimingsInput | null;
  connect?: CropWhereUniqueInput | null;
}

export interface CropCreateOneWithoutCultivationsInput {
  create?: CropCreateWithoutCultivationsInput | null;
  connect?: CropWhereUniqueInput | null;
}

export interface CropCreateOneWithoutPlantSchemasInput {
  create?: CropCreateWithoutPlantSchemasInput | null;
  connect?: CropWhereUniqueInput | null;
}

export interface CropCreateOneWithoutVarietiesInput {
  create?: CropCreateWithoutVarietiesInput | null;
  connect?: CropWhereUniqueInput | null;
}

export interface CropCreateWithoutCropTimingsInput {
  id?: string | null;
  name: string;
  daysToTransplant?: number | null;
  rootDepth?: number | null;
  notes?: string | null;
  group?: CropGroupCreateOneWithoutCropsInput | null;
  family?: CropFamilyCreateOneWithoutCropsInput | null;
  cultivations?: CultivationCreateManyWithoutCropInput | null;
  varieties?: VarietyCreateManyWithoutCropInput | null;
  seedSchema?: SeedSchemaCreateOneWithoutCropsInput | null;
  plantSchemas?: CropPlantSchemaCreateManyWithoutCropInput | null;
}

export interface CropCreateWithoutCultivationsInput {
  id?: string | null;
  name: string;
  daysToTransplant?: number | null;
  rootDepth?: number | null;
  notes?: string | null;
  cropTimings?: CropTimingCreateManyWithoutCropInput | null;
  group?: CropGroupCreateOneWithoutCropsInput | null;
  family?: CropFamilyCreateOneWithoutCropsInput | null;
  varieties?: VarietyCreateManyWithoutCropInput | null;
  seedSchema?: SeedSchemaCreateOneWithoutCropsInput | null;
  plantSchemas?: CropPlantSchemaCreateManyWithoutCropInput | null;
}

export interface CropCreateWithoutFamilyInput {
  id?: string | null;
  name: string;
  daysToTransplant?: number | null;
  rootDepth?: number | null;
  notes?: string | null;
  cropTimings?: CropTimingCreateManyWithoutCropInput | null;
  group?: CropGroupCreateOneWithoutCropsInput | null;
  cultivations?: CultivationCreateManyWithoutCropInput | null;
  varieties?: VarietyCreateManyWithoutCropInput | null;
  seedSchema?: SeedSchemaCreateOneWithoutCropsInput | null;
  plantSchemas?: CropPlantSchemaCreateManyWithoutCropInput | null;
}

export interface CropCreateWithoutGroupInput {
  id?: string | null;
  name: string;
  daysToTransplant?: number | null;
  rootDepth?: number | null;
  notes?: string | null;
  cropTimings?: CropTimingCreateManyWithoutCropInput | null;
  family?: CropFamilyCreateOneWithoutCropsInput | null;
  cultivations?: CultivationCreateManyWithoutCropInput | null;
  varieties?: VarietyCreateManyWithoutCropInput | null;
  seedSchema?: SeedSchemaCreateOneWithoutCropsInput | null;
  plantSchemas?: CropPlantSchemaCreateManyWithoutCropInput | null;
}

export interface CropCreateWithoutPlantSchemasInput {
  id?: string | null;
  name: string;
  daysToTransplant?: number | null;
  rootDepth?: number | null;
  notes?: string | null;
  cropTimings?: CropTimingCreateManyWithoutCropInput | null;
  group?: CropGroupCreateOneWithoutCropsInput | null;
  family?: CropFamilyCreateOneWithoutCropsInput | null;
  cultivations?: CultivationCreateManyWithoutCropInput | null;
  varieties?: VarietyCreateManyWithoutCropInput | null;
  seedSchema?: SeedSchemaCreateOneWithoutCropsInput | null;
}

export interface CropCreateWithoutVarietiesInput {
  id?: string | null;
  name: string;
  daysToTransplant?: number | null;
  rootDepth?: number | null;
  notes?: string | null;
  cropTimings?: CropTimingCreateManyWithoutCropInput | null;
  group?: CropGroupCreateOneWithoutCropsInput | null;
  family?: CropFamilyCreateOneWithoutCropsInput | null;
  cultivations?: CultivationCreateManyWithoutCropInput | null;
  seedSchema?: SeedSchemaCreateOneWithoutCropsInput | null;
  plantSchemas?: CropPlantSchemaCreateManyWithoutCropInput | null;
}

export interface CropFamilyCreateManyWithoutGroupsInput {
  create?: CropFamilyCreateWithoutGroupsInput[] | null;
  connect?: CropFamilyWhereUniqueInput[] | null;
}

export interface CropFamilyCreateOneWithoutCropsInput {
  create?: CropFamilyCreateWithoutCropsInput | null;
  connect?: CropFamilyWhereUniqueInput | null;
}

export interface CropFamilyCreateWithoutCropsInput {
  id?: string | null;
  name: string;
  groups?: CropGroupCreateManyWithoutFamiliesInput | null;
}

export interface CropFamilyCreateWithoutGroupsInput {
  id?: string | null;
  name: string;
  crops?: CropCreateManyWithoutFamilyInput | null;
}

export interface CropFamilyScalarWhereInput {
  AND?: CropFamilyScalarWhereInput[] | null;
  OR?: CropFamilyScalarWhereInput[] | null;
  NOT?: CropFamilyScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
}

export interface CropFamilyUpdateManyDataInput {
  name?: string | null;
}

export interface CropFamilyUpdateManyWithWhereNestedInput {
  where: CropFamilyScalarWhereInput;
  data: CropFamilyUpdateManyDataInput;
}

export interface CropFamilyUpdateManyWithoutGroupsInput {
  create?: CropFamilyCreateWithoutGroupsInput[] | null;
  connect?: CropFamilyWhereUniqueInput[] | null;
  set?: CropFamilyWhereUniqueInput[] | null;
  disconnect?: CropFamilyWhereUniqueInput[] | null;
  delete?: CropFamilyWhereUniqueInput[] | null;
  update?: CropFamilyUpdateWithWhereUniqueWithoutGroupsInput[] | null;
  updateMany?: CropFamilyUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: CropFamilyScalarWhereInput[] | null;
  upsert?: CropFamilyUpsertWithWhereUniqueWithoutGroupsInput[] | null;
}

export interface CropFamilyUpdateOneWithoutCropsInput {
  create?: CropFamilyCreateWithoutCropsInput | null;
  connect?: CropFamilyWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: CropFamilyUpdateWithoutCropsDataInput | null;
  upsert?: CropFamilyUpsertWithoutCropsInput | null;
}

export interface CropFamilyUpdateWithWhereUniqueWithoutGroupsInput {
  where: CropFamilyWhereUniqueInput;
  data: CropFamilyUpdateWithoutGroupsDataInput;
}

export interface CropFamilyUpdateWithoutCropsDataInput {
  name?: string | null;
  groups?: CropGroupUpdateManyWithoutFamiliesInput | null;
}

export interface CropFamilyUpdateWithoutGroupsDataInput {
  name?: string | null;
  crops?: CropUpdateManyWithoutFamilyInput | null;
}

export interface CropFamilyUpsertWithWhereUniqueWithoutGroupsInput {
  where: CropFamilyWhereUniqueInput;
  update: CropFamilyUpdateWithoutGroupsDataInput;
  create: CropFamilyCreateWithoutGroupsInput;
}

export interface CropFamilyUpsertWithoutCropsInput {
  update: CropFamilyUpdateWithoutCropsDataInput;
  create: CropFamilyCreateWithoutCropsInput;
}

export interface CropFamilyWhereInput {
  AND?: CropFamilyWhereInput[] | null;
  OR?: CropFamilyWhereInput[] | null;
  NOT?: CropFamilyWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  crops_every?: CropWhereInput | null;
  crops_some?: CropWhereInput | null;
  crops_none?: CropWhereInput | null;
  groups_every?: CropGroupWhereInput | null;
  groups_some?: CropGroupWhereInput | null;
  groups_none?: CropGroupWhereInput | null;
}

export interface CropFamilyWhereUniqueInput {
  id?: string | null;
  name?: string | null;
}

export interface CropGroupCreateManyWithoutFamiliesInput {
  create?: CropGroupCreateWithoutFamiliesInput[] | null;
  connect?: CropGroupWhereUniqueInput[] | null;
}

export interface CropGroupCreateManyWithoutRotationGroupsInput {
  create?: CropGroupCreateWithoutRotationGroupsInput[] | null;
  connect?: CropGroupWhereUniqueInput[] | null;
}

export interface CropGroupCreateOneWithoutCropsInput {
  create?: CropGroupCreateWithoutCropsInput | null;
  connect?: CropGroupWhereUniqueInput | null;
}

export interface CropGroupCreateWithoutCropsInput {
  id?: string | null;
  name: string;
  families?: CropFamilyCreateManyWithoutGroupsInput | null;
  rotationGroups?: RotationGroupCreateManyWithoutCropGroupsInput | null;
}

export interface CropGroupCreateWithoutFamiliesInput {
  id?: string | null;
  name: string;
  crops?: CropCreateManyWithoutGroupInput | null;
  rotationGroups?: RotationGroupCreateManyWithoutCropGroupsInput | null;
}

export interface CropGroupCreateWithoutRotationGroupsInput {
  id?: string | null;
  name: string;
  crops?: CropCreateManyWithoutGroupInput | null;
  families?: CropFamilyCreateManyWithoutGroupsInput | null;
}

export interface CropGroupScalarWhereInput {
  AND?: CropGroupScalarWhereInput[] | null;
  OR?: CropGroupScalarWhereInput[] | null;
  NOT?: CropGroupScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
}

export interface CropGroupUpdateManyDataInput {
  name?: string | null;
}

export interface CropGroupUpdateManyWithWhereNestedInput {
  where: CropGroupScalarWhereInput;
  data: CropGroupUpdateManyDataInput;
}

export interface CropGroupUpdateManyWithoutFamiliesInput {
  create?: CropGroupCreateWithoutFamiliesInput[] | null;
  connect?: CropGroupWhereUniqueInput[] | null;
  set?: CropGroupWhereUniqueInput[] | null;
  disconnect?: CropGroupWhereUniqueInput[] | null;
  delete?: CropGroupWhereUniqueInput[] | null;
  update?: CropGroupUpdateWithWhereUniqueWithoutFamiliesInput[] | null;
  updateMany?: CropGroupUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: CropGroupScalarWhereInput[] | null;
  upsert?: CropGroupUpsertWithWhereUniqueWithoutFamiliesInput[] | null;
}

export interface CropGroupUpdateManyWithoutRotationGroupsInput {
  create?: CropGroupCreateWithoutRotationGroupsInput[] | null;
  connect?: CropGroupWhereUniqueInput[] | null;
  set?: CropGroupWhereUniqueInput[] | null;
  disconnect?: CropGroupWhereUniqueInput[] | null;
  delete?: CropGroupWhereUniqueInput[] | null;
  update?: CropGroupUpdateWithWhereUniqueWithoutRotationGroupsInput[] | null;
  updateMany?: CropGroupUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: CropGroupScalarWhereInput[] | null;
  upsert?: CropGroupUpsertWithWhereUniqueWithoutRotationGroupsInput[] | null;
}

export interface CropGroupUpdateOneWithoutCropsInput {
  create?: CropGroupCreateWithoutCropsInput | null;
  connect?: CropGroupWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: CropGroupUpdateWithoutCropsDataInput | null;
  upsert?: CropGroupUpsertWithoutCropsInput | null;
}

export interface CropGroupUpdateWithWhereUniqueWithoutFamiliesInput {
  where: CropGroupWhereUniqueInput;
  data: CropGroupUpdateWithoutFamiliesDataInput;
}

export interface CropGroupUpdateWithWhereUniqueWithoutRotationGroupsInput {
  where: CropGroupWhereUniqueInput;
  data: CropGroupUpdateWithoutRotationGroupsDataInput;
}

export interface CropGroupUpdateWithoutCropsDataInput {
  name?: string | null;
  families?: CropFamilyUpdateManyWithoutGroupsInput | null;
  rotationGroups?: RotationGroupUpdateManyWithoutCropGroupsInput | null;
}

export interface CropGroupUpdateWithoutFamiliesDataInput {
  name?: string | null;
  crops?: CropUpdateManyWithoutGroupInput | null;
  rotationGroups?: RotationGroupUpdateManyWithoutCropGroupsInput | null;
}

export interface CropGroupUpdateWithoutRotationGroupsDataInput {
  name?: string | null;
  crops?: CropUpdateManyWithoutGroupInput | null;
  families?: CropFamilyUpdateManyWithoutGroupsInput | null;
}

export interface CropGroupUpsertWithWhereUniqueWithoutFamiliesInput {
  where: CropGroupWhereUniqueInput;
  update: CropGroupUpdateWithoutFamiliesDataInput;
  create: CropGroupCreateWithoutFamiliesInput;
}

export interface CropGroupUpsertWithWhereUniqueWithoutRotationGroupsInput {
  where: CropGroupWhereUniqueInput;
  update: CropGroupUpdateWithoutRotationGroupsDataInput;
  create: CropGroupCreateWithoutRotationGroupsInput;
}

export interface CropGroupUpsertWithoutCropsInput {
  update: CropGroupUpdateWithoutCropsDataInput;
  create: CropGroupCreateWithoutCropsInput;
}

export interface CropGroupWhereInput {
  AND?: CropGroupWhereInput[] | null;
  OR?: CropGroupWhereInput[] | null;
  NOT?: CropGroupWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  crops_every?: CropWhereInput | null;
  crops_some?: CropWhereInput | null;
  crops_none?: CropWhereInput | null;
  families_every?: CropFamilyWhereInput | null;
  families_some?: CropFamilyWhereInput | null;
  families_none?: CropFamilyWhereInput | null;
  rotationGroups_every?: RotationGroupWhereInput | null;
  rotationGroups_some?: RotationGroupWhereInput | null;
  rotationGroups_none?: RotationGroupWhereInput | null;
}

export interface CropGroupWhereUniqueInput {
  id?: string | null;
  name?: string | null;
}

export interface CropPlantSchemaCreateInput {
  id?: string | null;
  default?: boolean | null;
  description?: string | null;
  plantSchema: PlantSchemaCreateOneInput;
  crop: CropCreateOneWithoutPlantSchemasInput;
}

export interface CropPlantSchemaCreateManyWithoutCropInput {
  create?: CropPlantSchemaCreateWithoutCropInput[] | null;
  connect?: CropPlantSchemaWhereUniqueInput[] | null;
}

export interface CropPlantSchemaCreateOneInput {
  create?: CropPlantSchemaCreateInput | null;
  connect?: CropPlantSchemaWhereUniqueInput | null;
}

export interface CropPlantSchemaCreateWithoutCropInput {
  id?: string | null;
  default?: boolean | null;
  description?: string | null;
  plantSchema: PlantSchemaCreateOneInput;
}

export interface CropPlantSchemaScalarWhereInput {
  AND?: CropPlantSchemaScalarWhereInput[] | null;
  OR?: CropPlantSchemaScalarWhereInput[] | null;
  NOT?: CropPlantSchemaScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  default?: boolean | null;
  default_not?: boolean | null;
  description?: string | null;
  description_not?: string | null;
  description_in?: string[] | null;
  description_not_in?: string[] | null;
  description_lt?: string | null;
  description_lte?: string | null;
  description_gt?: string | null;
  description_gte?: string | null;
  description_contains?: string | null;
  description_not_contains?: string | null;
  description_starts_with?: string | null;
  description_not_starts_with?: string | null;
  description_ends_with?: string | null;
  description_not_ends_with?: string | null;
}

export interface CropPlantSchemaUpdateDataInput {
  default?: boolean | null;
  description?: string | null;
  plantSchema?: PlantSchemaUpdateOneRequiredInput | null;
  crop?: CropUpdateOneRequiredWithoutPlantSchemasInput | null;
}

export interface CropPlantSchemaUpdateManyDataInput {
  default?: boolean | null;
  description?: string | null;
}

export interface CropPlantSchemaUpdateManyWithWhereNestedInput {
  where: CropPlantSchemaScalarWhereInput;
  data: CropPlantSchemaUpdateManyDataInput;
}

export interface CropPlantSchemaUpdateManyWithoutCropInput {
  create?: CropPlantSchemaCreateWithoutCropInput[] | null;
  connect?: CropPlantSchemaWhereUniqueInput[] | null;
  set?: CropPlantSchemaWhereUniqueInput[] | null;
  disconnect?: CropPlantSchemaWhereUniqueInput[] | null;
  delete?: CropPlantSchemaWhereUniqueInput[] | null;
  update?: CropPlantSchemaUpdateWithWhereUniqueWithoutCropInput[] | null;
  updateMany?: CropPlantSchemaUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: CropPlantSchemaScalarWhereInput[] | null;
  upsert?: CropPlantSchemaUpsertWithWhereUniqueWithoutCropInput[] | null;
}

export interface CropPlantSchemaUpdateOneInput {
  create?: CropPlantSchemaCreateInput | null;
  connect?: CropPlantSchemaWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: CropPlantSchemaUpdateDataInput | null;
  upsert?: CropPlantSchemaUpsertNestedInput | null;
}

export interface CropPlantSchemaUpdateWithWhereUniqueWithoutCropInput {
  where: CropPlantSchemaWhereUniqueInput;
  data: CropPlantSchemaUpdateWithoutCropDataInput;
}

export interface CropPlantSchemaUpdateWithoutCropDataInput {
  default?: boolean | null;
  description?: string | null;
  plantSchema?: PlantSchemaUpdateOneRequiredInput | null;
}

export interface CropPlantSchemaUpsertNestedInput {
  update: CropPlantSchemaUpdateDataInput;
  create: CropPlantSchemaCreateInput;
}

export interface CropPlantSchemaUpsertWithWhereUniqueWithoutCropInput {
  where: CropPlantSchemaWhereUniqueInput;
  update: CropPlantSchemaUpdateWithoutCropDataInput;
  create: CropPlantSchemaCreateWithoutCropInput;
}

export interface CropPlantSchemaWhereInput {
  AND?: CropPlantSchemaWhereInput[] | null;
  OR?: CropPlantSchemaWhereInput[] | null;
  NOT?: CropPlantSchemaWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  default?: boolean | null;
  default_not?: boolean | null;
  description?: string | null;
  description_not?: string | null;
  description_in?: string[] | null;
  description_not_in?: string[] | null;
  description_lt?: string | null;
  description_lte?: string | null;
  description_gt?: string | null;
  description_gte?: string | null;
  description_contains?: string | null;
  description_not_contains?: string | null;
  description_starts_with?: string | null;
  description_not_starts_with?: string | null;
  description_ends_with?: string | null;
  description_not_ends_with?: string | null;
  plantSchema?: PlantSchemaWhereInput | null;
  crop?: CropWhereInput | null;
}

export interface CropPlantSchemaWhereUniqueInput {
  id?: string | null;
}

export interface CropScalarWhereInput {
  AND?: CropScalarWhereInput[] | null;
  OR?: CropScalarWhereInput[] | null;
  NOT?: CropScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  daysToTransplant?: number | null;
  daysToTransplant_not?: number | null;
  daysToTransplant_in?: number[] | null;
  daysToTransplant_not_in?: number[] | null;
  daysToTransplant_lt?: number | null;
  daysToTransplant_lte?: number | null;
  daysToTransplant_gt?: number | null;
  daysToTransplant_gte?: number | null;
  rootDepth?: number | null;
  rootDepth_not?: number | null;
  rootDepth_in?: number[] | null;
  rootDepth_not_in?: number[] | null;
  rootDepth_lt?: number | null;
  rootDepth_lte?: number | null;
  rootDepth_gt?: number | null;
  rootDepth_gte?: number | null;
  notes?: string | null;
  notes_not?: string | null;
  notes_in?: string[] | null;
  notes_not_in?: string[] | null;
  notes_lt?: string | null;
  notes_lte?: string | null;
  notes_gt?: string | null;
  notes_gte?: string | null;
  notes_contains?: string | null;
  notes_not_contains?: string | null;
  notes_starts_with?: string | null;
  notes_not_starts_with?: string | null;
  notes_ends_with?: string | null;
  notes_not_ends_with?: string | null;
}

export interface CropTimingCreateManyWithoutCropInput {
  create?: CropTimingCreateWithoutCropInput[] | null;
  connect?: CropTimingWhereUniqueInput[] | null;
}

export interface CropTimingCreateManyWithoutVarietiesInput {
  create?: CropTimingCreateWithoutVarietiesInput[] | null;
  connect?: CropTimingWhereUniqueInput[] | null;
}

export interface CropTimingCreateOneWithoutCultivationsInput {
  create?: CropTimingCreateWithoutCultivationsInput | null;
  connect?: CropTimingWhereUniqueInput | null;
}

export interface CropTimingCreateWithoutCropInput {
  id?: string | null;
  minDuration: number;
  maxDuration: number;
  startWeekNumberFrom: number;
  startWeekNumberUntil: number;
  endWeekNumberFrom: number;
  endWeekNumberUntil: number;
  type: PlantOrSeed;
  cultivationMethod: string;
  source: Source;
  remarks?: CropTimingCreateremarksInput | null;
  cultivations?: CultivationCreateManyWithoutCropTimingInput | null;
  varieties?: VarietyCreateManyWithoutCropTimingsInput | null;
}

export interface CropTimingCreateWithoutCultivationsInput {
  id?: string | null;
  minDuration: number;
  maxDuration: number;
  startWeekNumberFrom: number;
  startWeekNumberUntil: number;
  endWeekNumberFrom: number;
  endWeekNumberUntil: number;
  type: PlantOrSeed;
  cultivationMethod: string;
  source: Source;
  remarks?: CropTimingCreateremarksInput | null;
  crop: CropCreateOneWithoutCropTimingsInput;
  varieties?: VarietyCreateManyWithoutCropTimingsInput | null;
}

export interface CropTimingCreateWithoutVarietiesInput {
  id?: string | null;
  minDuration: number;
  maxDuration: number;
  startWeekNumberFrom: number;
  startWeekNumberUntil: number;
  endWeekNumberFrom: number;
  endWeekNumberUntil: number;
  type: PlantOrSeed;
  cultivationMethod: string;
  source: Source;
  remarks?: CropTimingCreateremarksInput | null;
  crop: CropCreateOneWithoutCropTimingsInput;
  cultivations?: CultivationCreateManyWithoutCropTimingInput | null;
}

export interface CropTimingCreateremarksInput {
  set?: string[] | null;
}

export interface CropTimingScalarWhereInput {
  AND?: CropTimingScalarWhereInput[] | null;
  OR?: CropTimingScalarWhereInput[] | null;
  NOT?: CropTimingScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  minDuration?: number | null;
  minDuration_not?: number | null;
  minDuration_in?: number[] | null;
  minDuration_not_in?: number[] | null;
  minDuration_lt?: number | null;
  minDuration_lte?: number | null;
  minDuration_gt?: number | null;
  minDuration_gte?: number | null;
  maxDuration?: number | null;
  maxDuration_not?: number | null;
  maxDuration_in?: number[] | null;
  maxDuration_not_in?: number[] | null;
  maxDuration_lt?: number | null;
  maxDuration_lte?: number | null;
  maxDuration_gt?: number | null;
  maxDuration_gte?: number | null;
  startWeekNumberFrom?: number | null;
  startWeekNumberFrom_not?: number | null;
  startWeekNumberFrom_in?: number[] | null;
  startWeekNumberFrom_not_in?: number[] | null;
  startWeekNumberFrom_lt?: number | null;
  startWeekNumberFrom_lte?: number | null;
  startWeekNumberFrom_gt?: number | null;
  startWeekNumberFrom_gte?: number | null;
  startWeekNumberUntil?: number | null;
  startWeekNumberUntil_not?: number | null;
  startWeekNumberUntil_in?: number[] | null;
  startWeekNumberUntil_not_in?: number[] | null;
  startWeekNumberUntil_lt?: number | null;
  startWeekNumberUntil_lte?: number | null;
  startWeekNumberUntil_gt?: number | null;
  startWeekNumberUntil_gte?: number | null;
  endWeekNumberFrom?: number | null;
  endWeekNumberFrom_not?: number | null;
  endWeekNumberFrom_in?: number[] | null;
  endWeekNumberFrom_not_in?: number[] | null;
  endWeekNumberFrom_lt?: number | null;
  endWeekNumberFrom_lte?: number | null;
  endWeekNumberFrom_gt?: number | null;
  endWeekNumberFrom_gte?: number | null;
  endWeekNumberUntil?: number | null;
  endWeekNumberUntil_not?: number | null;
  endWeekNumberUntil_in?: number[] | null;
  endWeekNumberUntil_not_in?: number[] | null;
  endWeekNumberUntil_lt?: number | null;
  endWeekNumberUntil_lte?: number | null;
  endWeekNumberUntil_gt?: number | null;
  endWeekNumberUntil_gte?: number | null;
  type?: PlantOrSeed | null;
  type_not?: PlantOrSeed | null;
  type_in?: PlantOrSeed[] | null;
  type_not_in?: PlantOrSeed[] | null;
  cultivationMethod?: string | null;
  cultivationMethod_not?: string | null;
  cultivationMethod_in?: string[] | null;
  cultivationMethod_not_in?: string[] | null;
  cultivationMethod_lt?: string | null;
  cultivationMethod_lte?: string | null;
  cultivationMethod_gt?: string | null;
  cultivationMethod_gte?: string | null;
  cultivationMethod_contains?: string | null;
  cultivationMethod_not_contains?: string | null;
  cultivationMethod_starts_with?: string | null;
  cultivationMethod_not_starts_with?: string | null;
  cultivationMethod_ends_with?: string | null;
  cultivationMethod_not_ends_with?: string | null;
  source?: Source | null;
  source_not?: Source | null;
  source_in?: Source[] | null;
  source_not_in?: Source[] | null;
}

export interface CropTimingUpdateManyDataInput {
  minDuration?: number | null;
  maxDuration?: number | null;
  startWeekNumberFrom?: number | null;
  startWeekNumberUntil?: number | null;
  endWeekNumberFrom?: number | null;
  endWeekNumberUntil?: number | null;
  type?: PlantOrSeed | null;
  cultivationMethod?: string | null;
  source?: Source | null;
  remarks?: CropTimingUpdateremarksInput | null;
}

export interface CropTimingUpdateManyWithWhereNestedInput {
  where: CropTimingScalarWhereInput;
  data: CropTimingUpdateManyDataInput;
}

export interface CropTimingUpdateManyWithoutCropInput {
  create?: CropTimingCreateWithoutCropInput[] | null;
  connect?: CropTimingWhereUniqueInput[] | null;
  set?: CropTimingWhereUniqueInput[] | null;
  disconnect?: CropTimingWhereUniqueInput[] | null;
  delete?: CropTimingWhereUniqueInput[] | null;
  update?: CropTimingUpdateWithWhereUniqueWithoutCropInput[] | null;
  updateMany?: CropTimingUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: CropTimingScalarWhereInput[] | null;
  upsert?: CropTimingUpsertWithWhereUniqueWithoutCropInput[] | null;
}

export interface CropTimingUpdateManyWithoutVarietiesInput {
  create?: CropTimingCreateWithoutVarietiesInput[] | null;
  connect?: CropTimingWhereUniqueInput[] | null;
  set?: CropTimingWhereUniqueInput[] | null;
  disconnect?: CropTimingWhereUniqueInput[] | null;
  delete?: CropTimingWhereUniqueInput[] | null;
  update?: CropTimingUpdateWithWhereUniqueWithoutVarietiesInput[] | null;
  updateMany?: CropTimingUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: CropTimingScalarWhereInput[] | null;
  upsert?: CropTimingUpsertWithWhereUniqueWithoutVarietiesInput[] | null;
}

export interface CropTimingUpdateOneRequiredWithoutCultivationsInput {
  create?: CropTimingCreateWithoutCultivationsInput | null;
  connect?: CropTimingWhereUniqueInput | null;
  update?: CropTimingUpdateWithoutCultivationsDataInput | null;
  upsert?: CropTimingUpsertWithoutCultivationsInput | null;
}

export interface CropTimingUpdateWithWhereUniqueWithoutCropInput {
  where: CropTimingWhereUniqueInput;
  data: CropTimingUpdateWithoutCropDataInput;
}

export interface CropTimingUpdateWithWhereUniqueWithoutVarietiesInput {
  where: CropTimingWhereUniqueInput;
  data: CropTimingUpdateWithoutVarietiesDataInput;
}

export interface CropTimingUpdateWithoutCropDataInput {
  minDuration?: number | null;
  maxDuration?: number | null;
  startWeekNumberFrom?: number | null;
  startWeekNumberUntil?: number | null;
  endWeekNumberFrom?: number | null;
  endWeekNumberUntil?: number | null;
  type?: PlantOrSeed | null;
  cultivationMethod?: string | null;
  source?: Source | null;
  remarks?: CropTimingUpdateremarksInput | null;
  cultivations?: CultivationUpdateManyWithoutCropTimingInput | null;
  varieties?: VarietyUpdateManyWithoutCropTimingsInput | null;
}

export interface CropTimingUpdateWithoutCultivationsDataInput {
  minDuration?: number | null;
  maxDuration?: number | null;
  startWeekNumberFrom?: number | null;
  startWeekNumberUntil?: number | null;
  endWeekNumberFrom?: number | null;
  endWeekNumberUntil?: number | null;
  type?: PlantOrSeed | null;
  cultivationMethod?: string | null;
  source?: Source | null;
  remarks?: CropTimingUpdateremarksInput | null;
  crop?: CropUpdateOneRequiredWithoutCropTimingsInput | null;
  varieties?: VarietyUpdateManyWithoutCropTimingsInput | null;
}

export interface CropTimingUpdateWithoutVarietiesDataInput {
  minDuration?: number | null;
  maxDuration?: number | null;
  startWeekNumberFrom?: number | null;
  startWeekNumberUntil?: number | null;
  endWeekNumberFrom?: number | null;
  endWeekNumberUntil?: number | null;
  type?: PlantOrSeed | null;
  cultivationMethod?: string | null;
  source?: Source | null;
  remarks?: CropTimingUpdateremarksInput | null;
  crop?: CropUpdateOneRequiredWithoutCropTimingsInput | null;
  cultivations?: CultivationUpdateManyWithoutCropTimingInput | null;
}

export interface CropTimingUpdateremarksInput {
  set?: string[] | null;
}

export interface CropTimingUpsertWithWhereUniqueWithoutCropInput {
  where: CropTimingWhereUniqueInput;
  update: CropTimingUpdateWithoutCropDataInput;
  create: CropTimingCreateWithoutCropInput;
}

export interface CropTimingUpsertWithWhereUniqueWithoutVarietiesInput {
  where: CropTimingWhereUniqueInput;
  update: CropTimingUpdateWithoutVarietiesDataInput;
  create: CropTimingCreateWithoutVarietiesInput;
}

export interface CropTimingUpsertWithoutCultivationsInput {
  update: CropTimingUpdateWithoutCultivationsDataInput;
  create: CropTimingCreateWithoutCultivationsInput;
}

export interface CropTimingWhereInput {
  AND?: CropTimingWhereInput[] | null;
  OR?: CropTimingWhereInput[] | null;
  NOT?: CropTimingWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  minDuration?: number | null;
  minDuration_not?: number | null;
  minDuration_in?: number[] | null;
  minDuration_not_in?: number[] | null;
  minDuration_lt?: number | null;
  minDuration_lte?: number | null;
  minDuration_gt?: number | null;
  minDuration_gte?: number | null;
  maxDuration?: number | null;
  maxDuration_not?: number | null;
  maxDuration_in?: number[] | null;
  maxDuration_not_in?: number[] | null;
  maxDuration_lt?: number | null;
  maxDuration_lte?: number | null;
  maxDuration_gt?: number | null;
  maxDuration_gte?: number | null;
  startWeekNumberFrom?: number | null;
  startWeekNumberFrom_not?: number | null;
  startWeekNumberFrom_in?: number[] | null;
  startWeekNumberFrom_not_in?: number[] | null;
  startWeekNumberFrom_lt?: number | null;
  startWeekNumberFrom_lte?: number | null;
  startWeekNumberFrom_gt?: number | null;
  startWeekNumberFrom_gte?: number | null;
  startWeekNumberUntil?: number | null;
  startWeekNumberUntil_not?: number | null;
  startWeekNumberUntil_in?: number[] | null;
  startWeekNumberUntil_not_in?: number[] | null;
  startWeekNumberUntil_lt?: number | null;
  startWeekNumberUntil_lte?: number | null;
  startWeekNumberUntil_gt?: number | null;
  startWeekNumberUntil_gte?: number | null;
  endWeekNumberFrom?: number | null;
  endWeekNumberFrom_not?: number | null;
  endWeekNumberFrom_in?: number[] | null;
  endWeekNumberFrom_not_in?: number[] | null;
  endWeekNumberFrom_lt?: number | null;
  endWeekNumberFrom_lte?: number | null;
  endWeekNumberFrom_gt?: number | null;
  endWeekNumberFrom_gte?: number | null;
  endWeekNumberUntil?: number | null;
  endWeekNumberUntil_not?: number | null;
  endWeekNumberUntil_in?: number[] | null;
  endWeekNumberUntil_not_in?: number[] | null;
  endWeekNumberUntil_lt?: number | null;
  endWeekNumberUntil_lte?: number | null;
  endWeekNumberUntil_gt?: number | null;
  endWeekNumberUntil_gte?: number | null;
  type?: PlantOrSeed | null;
  type_not?: PlantOrSeed | null;
  type_in?: PlantOrSeed[] | null;
  type_not_in?: PlantOrSeed[] | null;
  cultivationMethod?: string | null;
  cultivationMethod_not?: string | null;
  cultivationMethod_in?: string[] | null;
  cultivationMethod_not_in?: string[] | null;
  cultivationMethod_lt?: string | null;
  cultivationMethod_lte?: string | null;
  cultivationMethod_gt?: string | null;
  cultivationMethod_gte?: string | null;
  cultivationMethod_contains?: string | null;
  cultivationMethod_not_contains?: string | null;
  cultivationMethod_starts_with?: string | null;
  cultivationMethod_not_starts_with?: string | null;
  cultivationMethod_ends_with?: string | null;
  cultivationMethod_not_ends_with?: string | null;
  source?: Source | null;
  source_not?: Source | null;
  source_in?: Source[] | null;
  source_not_in?: Source[] | null;
  crop?: CropWhereInput | null;
  cultivations_every?: CultivationWhereInput | null;
  cultivations_some?: CultivationWhereInput | null;
  cultivations_none?: CultivationWhereInput | null;
  varieties_every?: VarietyWhereInput | null;
  varieties_some?: VarietyWhereInput | null;
  varieties_none?: VarietyWhereInput | null;
}

export interface CropTimingWhereUniqueInput {
  id?: string | null;
}

export interface CropUpdateDataInput {
  name?: string | null;
  daysToTransplant?: number | null;
  rootDepth?: number | null;
  notes?: string | null;
  cropTimings?: CropTimingUpdateManyWithoutCropInput | null;
  group?: CropGroupUpdateOneWithoutCropsInput | null;
  family?: CropFamilyUpdateOneWithoutCropsInput | null;
  cultivations?: CultivationUpdateManyWithoutCropInput | null;
  varieties?: VarietyUpdateManyWithoutCropInput | null;
  seedSchema?: SeedSchemaUpdateOneWithoutCropsInput | null;
  plantSchemas?: CropPlantSchemaUpdateManyWithoutCropInput | null;
}

export interface CropUpdateManyDataInput {
  name?: string | null;
  daysToTransplant?: number | null;
  rootDepth?: number | null;
  notes?: string | null;
}

export interface CropUpdateManyInput {
  create?: CropCreateInput[] | null;
  connect?: CropWhereUniqueInput[] | null;
  set?: CropWhereUniqueInput[] | null;
  disconnect?: CropWhereUniqueInput[] | null;
  delete?: CropWhereUniqueInput[] | null;
  update?: CropUpdateWithWhereUniqueNestedInput[] | null;
  updateMany?: CropUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: CropScalarWhereInput[] | null;
  upsert?: CropUpsertWithWhereUniqueNestedInput[] | null;
}

export interface CropUpdateManyWithWhereNestedInput {
  where: CropScalarWhereInput;
  data: CropUpdateManyDataInput;
}

export interface CropUpdateManyWithoutFamilyInput {
  create?: CropCreateWithoutFamilyInput[] | null;
  connect?: CropWhereUniqueInput[] | null;
  set?: CropWhereUniqueInput[] | null;
  disconnect?: CropWhereUniqueInput[] | null;
  delete?: CropWhereUniqueInput[] | null;
  update?: CropUpdateWithWhereUniqueWithoutFamilyInput[] | null;
  updateMany?: CropUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: CropScalarWhereInput[] | null;
  upsert?: CropUpsertWithWhereUniqueWithoutFamilyInput[] | null;
}

export interface CropUpdateManyWithoutGroupInput {
  create?: CropCreateWithoutGroupInput[] | null;
  connect?: CropWhereUniqueInput[] | null;
  set?: CropWhereUniqueInput[] | null;
  disconnect?: CropWhereUniqueInput[] | null;
  delete?: CropWhereUniqueInput[] | null;
  update?: CropUpdateWithWhereUniqueWithoutGroupInput[] | null;
  updateMany?: CropUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: CropScalarWhereInput[] | null;
  upsert?: CropUpsertWithWhereUniqueWithoutGroupInput[] | null;
}

export interface CropUpdateOneRequiredWithoutCropTimingsInput {
  create?: CropCreateWithoutCropTimingsInput | null;
  connect?: CropWhereUniqueInput | null;
  update?: CropUpdateWithoutCropTimingsDataInput | null;
  upsert?: CropUpsertWithoutCropTimingsInput | null;
}

export interface CropUpdateOneRequiredWithoutCultivationsInput {
  create?: CropCreateWithoutCultivationsInput | null;
  connect?: CropWhereUniqueInput | null;
  update?: CropUpdateWithoutCultivationsDataInput | null;
  upsert?: CropUpsertWithoutCultivationsInput | null;
}

export interface CropUpdateOneRequiredWithoutPlantSchemasInput {
  create?: CropCreateWithoutPlantSchemasInput | null;
  connect?: CropWhereUniqueInput | null;
  update?: CropUpdateWithoutPlantSchemasDataInput | null;
  upsert?: CropUpsertWithoutPlantSchemasInput | null;
}

export interface CropUpdateOneRequiredWithoutVarietiesInput {
  create?: CropCreateWithoutVarietiesInput | null;
  connect?: CropWhereUniqueInput | null;
  update?: CropUpdateWithoutVarietiesDataInput | null;
  upsert?: CropUpsertWithoutVarietiesInput | null;
}

export interface CropUpdateWithWhereUniqueNestedInput {
  where: CropWhereUniqueInput;
  data: CropUpdateDataInput;
}

export interface CropUpdateWithWhereUniqueWithoutFamilyInput {
  where: CropWhereUniqueInput;
  data: CropUpdateWithoutFamilyDataInput;
}

export interface CropUpdateWithWhereUniqueWithoutGroupInput {
  where: CropWhereUniqueInput;
  data: CropUpdateWithoutGroupDataInput;
}

export interface CropUpdateWithoutCropTimingsDataInput {
  name?: string | null;
  daysToTransplant?: number | null;
  rootDepth?: number | null;
  notes?: string | null;
  group?: CropGroupUpdateOneWithoutCropsInput | null;
  family?: CropFamilyUpdateOneWithoutCropsInput | null;
  cultivations?: CultivationUpdateManyWithoutCropInput | null;
  varieties?: VarietyUpdateManyWithoutCropInput | null;
  seedSchema?: SeedSchemaUpdateOneWithoutCropsInput | null;
  plantSchemas?: CropPlantSchemaUpdateManyWithoutCropInput | null;
}

export interface CropUpdateWithoutCultivationsDataInput {
  name?: string | null;
  daysToTransplant?: number | null;
  rootDepth?: number | null;
  notes?: string | null;
  cropTimings?: CropTimingUpdateManyWithoutCropInput | null;
  group?: CropGroupUpdateOneWithoutCropsInput | null;
  family?: CropFamilyUpdateOneWithoutCropsInput | null;
  varieties?: VarietyUpdateManyWithoutCropInput | null;
  seedSchema?: SeedSchemaUpdateOneWithoutCropsInput | null;
  plantSchemas?: CropPlantSchemaUpdateManyWithoutCropInput | null;
}

export interface CropUpdateWithoutFamilyDataInput {
  name?: string | null;
  daysToTransplant?: number | null;
  rootDepth?: number | null;
  notes?: string | null;
  cropTimings?: CropTimingUpdateManyWithoutCropInput | null;
  group?: CropGroupUpdateOneWithoutCropsInput | null;
  cultivations?: CultivationUpdateManyWithoutCropInput | null;
  varieties?: VarietyUpdateManyWithoutCropInput | null;
  seedSchema?: SeedSchemaUpdateOneWithoutCropsInput | null;
  plantSchemas?: CropPlantSchemaUpdateManyWithoutCropInput | null;
}

export interface CropUpdateWithoutGroupDataInput {
  name?: string | null;
  daysToTransplant?: number | null;
  rootDepth?: number | null;
  notes?: string | null;
  cropTimings?: CropTimingUpdateManyWithoutCropInput | null;
  family?: CropFamilyUpdateOneWithoutCropsInput | null;
  cultivations?: CultivationUpdateManyWithoutCropInput | null;
  varieties?: VarietyUpdateManyWithoutCropInput | null;
  seedSchema?: SeedSchemaUpdateOneWithoutCropsInput | null;
  plantSchemas?: CropPlantSchemaUpdateManyWithoutCropInput | null;
}

export interface CropUpdateWithoutPlantSchemasDataInput {
  name?: string | null;
  daysToTransplant?: number | null;
  rootDepth?: number | null;
  notes?: string | null;
  cropTimings?: CropTimingUpdateManyWithoutCropInput | null;
  group?: CropGroupUpdateOneWithoutCropsInput | null;
  family?: CropFamilyUpdateOneWithoutCropsInput | null;
  cultivations?: CultivationUpdateManyWithoutCropInput | null;
  varieties?: VarietyUpdateManyWithoutCropInput | null;
  seedSchema?: SeedSchemaUpdateOneWithoutCropsInput | null;
}

export interface CropUpdateWithoutVarietiesDataInput {
  name?: string | null;
  daysToTransplant?: number | null;
  rootDepth?: number | null;
  notes?: string | null;
  cropTimings?: CropTimingUpdateManyWithoutCropInput | null;
  group?: CropGroupUpdateOneWithoutCropsInput | null;
  family?: CropFamilyUpdateOneWithoutCropsInput | null;
  cultivations?: CultivationUpdateManyWithoutCropInput | null;
  seedSchema?: SeedSchemaUpdateOneWithoutCropsInput | null;
  plantSchemas?: CropPlantSchemaUpdateManyWithoutCropInput | null;
}

export interface CropUpsertWithWhereUniqueNestedInput {
  where: CropWhereUniqueInput;
  update: CropUpdateDataInput;
  create: CropCreateInput;
}

export interface CropUpsertWithWhereUniqueWithoutFamilyInput {
  where: CropWhereUniqueInput;
  update: CropUpdateWithoutFamilyDataInput;
  create: CropCreateWithoutFamilyInput;
}

export interface CropUpsertWithWhereUniqueWithoutGroupInput {
  where: CropWhereUniqueInput;
  update: CropUpdateWithoutGroupDataInput;
  create: CropCreateWithoutGroupInput;
}

export interface CropUpsertWithoutCropTimingsInput {
  update: CropUpdateWithoutCropTimingsDataInput;
  create: CropCreateWithoutCropTimingsInput;
}

export interface CropUpsertWithoutCultivationsInput {
  update: CropUpdateWithoutCultivationsDataInput;
  create: CropCreateWithoutCultivationsInput;
}

export interface CropUpsertWithoutPlantSchemasInput {
  update: CropUpdateWithoutPlantSchemasDataInput;
  create: CropCreateWithoutPlantSchemasInput;
}

export interface CropUpsertWithoutVarietiesInput {
  update: CropUpdateWithoutVarietiesDataInput;
  create: CropCreateWithoutVarietiesInput;
}

export interface CropWhereInput {
  AND?: CropWhereInput[] | null;
  OR?: CropWhereInput[] | null;
  NOT?: CropWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  daysToTransplant?: number | null;
  daysToTransplant_not?: number | null;
  daysToTransplant_in?: number[] | null;
  daysToTransplant_not_in?: number[] | null;
  daysToTransplant_lt?: number | null;
  daysToTransplant_lte?: number | null;
  daysToTransplant_gt?: number | null;
  daysToTransplant_gte?: number | null;
  rootDepth?: number | null;
  rootDepth_not?: number | null;
  rootDepth_in?: number[] | null;
  rootDepth_not_in?: number[] | null;
  rootDepth_lt?: number | null;
  rootDepth_lte?: number | null;
  rootDepth_gt?: number | null;
  rootDepth_gte?: number | null;
  notes?: string | null;
  notes_not?: string | null;
  notes_in?: string[] | null;
  notes_not_in?: string[] | null;
  notes_lt?: string | null;
  notes_lte?: string | null;
  notes_gt?: string | null;
  notes_gte?: string | null;
  notes_contains?: string | null;
  notes_not_contains?: string | null;
  notes_starts_with?: string | null;
  notes_not_starts_with?: string | null;
  notes_ends_with?: string | null;
  notes_not_ends_with?: string | null;
  cropTimings_every?: CropTimingWhereInput | null;
  cropTimings_some?: CropTimingWhereInput | null;
  cropTimings_none?: CropTimingWhereInput | null;
  group?: CropGroupWhereInput | null;
  family?: CropFamilyWhereInput | null;
  cultivations_every?: CultivationWhereInput | null;
  cultivations_some?: CultivationWhereInput | null;
  cultivations_none?: CultivationWhereInput | null;
  varieties_every?: VarietyWhereInput | null;
  varieties_some?: VarietyWhereInput | null;
  varieties_none?: VarietyWhereInput | null;
  seedSchema?: SeedSchemaWhereInput | null;
  plantSchemas_every?: CropPlantSchemaWhereInput | null;
  plantSchemas_some?: CropPlantSchemaWhereInput | null;
  plantSchemas_none?: CropPlantSchemaWhereInput | null;
}

export interface CropWhereUniqueInput {
  id?: string | null;
  name?: string | null;
}

export interface CultivationCreateInput {
  id?: string | null;
  pinned?: boolean | null;
  season: Season;
  startDate: any;
  harvestEndDate: any;
  length: number;
  remark?: string | null;
  transplant?: boolean | null;
  interPlanting?: boolean | null;
  jiraTaskId?: string | null;
  plot: PlotCreateOneWithoutCultivationsInput;
  crop: CropCreateOneWithoutCultivationsInput;
  cropTiming: CropTimingCreateOneWithoutCultivationsInput;
  events?: CultivationEventCreateManyWithoutCultivationInput | null;
  variety?: VarietyCreateOneWithoutCultivationsInput | null;
  varieties?: CultivationVarietyCreateManyInput | null;
  plantSchema?: CropPlantSchemaCreateOneInput | null;
  notes?: NoteCreateManyWithoutCultivationInput | null;
  tasks?: TaskCreateManyWithoutCultivationInput | null;
  parent?: CultivationCreateOneInput | null;
}

export interface CultivationCreateManyWithoutCropInput {
  create?: CultivationCreateWithoutCropInput[] | null;
  connect?: CultivationWhereUniqueInput[] | null;
}

export interface CultivationCreateManyWithoutCropTimingInput {
  create?: CultivationCreateWithoutCropTimingInput[] | null;
  connect?: CultivationWhereUniqueInput[] | null;
}

export interface CultivationCreateManyWithoutPlotInput {
  create?: CultivationCreateWithoutPlotInput[] | null;
  connect?: CultivationWhereUniqueInput[] | null;
}

export interface CultivationCreateManyWithoutVarietyInput {
  create?: CultivationCreateWithoutVarietyInput[] | null;
  connect?: CultivationWhereUniqueInput[] | null;
}

export interface CultivationCreateOneInput {
  create?: CultivationCreateInput | null;
  connect?: CultivationWhereUniqueInput | null;
}

export interface CultivationCreateOneWithoutEventsInput {
  create?: CultivationCreateWithoutEventsInput | null;
  connect?: CultivationWhereUniqueInput | null;
}

export interface CultivationCreateOneWithoutNotesInput {
  create?: CultivationCreateWithoutNotesInput | null;
  connect?: CultivationWhereUniqueInput | null;
}

export interface CultivationCreateWithoutCropInput {
  id?: string | null;
  pinned?: boolean | null;
  season: Season;
  startDate: any;
  harvestEndDate: any;
  length: number;
  remark?: string | null;
  transplant?: boolean | null;
  interPlanting?: boolean | null;
  jiraTaskId?: string | null;
  plot: PlotCreateOneWithoutCultivationsInput;
  cropTiming: CropTimingCreateOneWithoutCultivationsInput;
  events?: CultivationEventCreateManyWithoutCultivationInput | null;
  variety?: VarietyCreateOneWithoutCultivationsInput | null;
  varieties?: CultivationVarietyCreateManyInput | null;
  plantSchema?: CropPlantSchemaCreateOneInput | null;
  notes?: NoteCreateManyWithoutCultivationInput | null;
  tasks?: TaskCreateManyWithoutCultivationInput | null;
  parent?: CultivationCreateOneInput | null;
}

export interface CultivationCreateWithoutCropTimingInput {
  id?: string | null;
  pinned?: boolean | null;
  season: Season;
  startDate: any;
  harvestEndDate: any;
  length: number;
  remark?: string | null;
  transplant?: boolean | null;
  interPlanting?: boolean | null;
  jiraTaskId?: string | null;
  plot: PlotCreateOneWithoutCultivationsInput;
  crop: CropCreateOneWithoutCultivationsInput;
  events?: CultivationEventCreateManyWithoutCultivationInput | null;
  variety?: VarietyCreateOneWithoutCultivationsInput | null;
  varieties?: CultivationVarietyCreateManyInput | null;
  plantSchema?: CropPlantSchemaCreateOneInput | null;
  notes?: NoteCreateManyWithoutCultivationInput | null;
  tasks?: TaskCreateManyWithoutCultivationInput | null;
  parent?: CultivationCreateOneInput | null;
}

export interface CultivationCreateWithoutEventsInput {
  id?: string | null;
  pinned?: boolean | null;
  season: Season;
  startDate: any;
  harvestEndDate: any;
  length: number;
  remark?: string | null;
  transplant?: boolean | null;
  interPlanting?: boolean | null;
  jiraTaskId?: string | null;
  plot: PlotCreateOneWithoutCultivationsInput;
  crop: CropCreateOneWithoutCultivationsInput;
  cropTiming: CropTimingCreateOneWithoutCultivationsInput;
  variety?: VarietyCreateOneWithoutCultivationsInput | null;
  varieties?: CultivationVarietyCreateManyInput | null;
  plantSchema?: CropPlantSchemaCreateOneInput | null;
  notes?: NoteCreateManyWithoutCultivationInput | null;
  tasks?: TaskCreateManyWithoutCultivationInput | null;
  parent?: CultivationCreateOneInput | null;
}

export interface CultivationCreateWithoutNotesInput {
  id?: string | null;
  pinned?: boolean | null;
  season: Season;
  startDate: any;
  harvestEndDate: any;
  length: number;
  remark?: string | null;
  transplant?: boolean | null;
  interPlanting?: boolean | null;
  jiraTaskId?: string | null;
  plot: PlotCreateOneWithoutCultivationsInput;
  crop: CropCreateOneWithoutCultivationsInput;
  cropTiming: CropTimingCreateOneWithoutCultivationsInput;
  events?: CultivationEventCreateManyWithoutCultivationInput | null;
  variety?: VarietyCreateOneWithoutCultivationsInput | null;
  varieties?: CultivationVarietyCreateManyInput | null;
  plantSchema?: CropPlantSchemaCreateOneInput | null;
  tasks?: TaskCreateManyWithoutCultivationInput | null;
  parent?: CultivationCreateOneInput | null;
}

export interface CultivationCreateWithoutPlotInput {
  id?: string | null;
  pinned?: boolean | null;
  season: Season;
  startDate: any;
  harvestEndDate: any;
  length: number;
  remark?: string | null;
  transplant?: boolean | null;
  interPlanting?: boolean | null;
  jiraTaskId?: string | null;
  crop: CropCreateOneWithoutCultivationsInput;
  cropTiming: CropTimingCreateOneWithoutCultivationsInput;
  events?: CultivationEventCreateManyWithoutCultivationInput | null;
  variety?: VarietyCreateOneWithoutCultivationsInput | null;
  varieties?: CultivationVarietyCreateManyInput | null;
  plantSchema?: CropPlantSchemaCreateOneInput | null;
  notes?: NoteCreateManyWithoutCultivationInput | null;
  tasks?: TaskCreateManyWithoutCultivationInput | null;
  parent?: CultivationCreateOneInput | null;
}

export interface CultivationCreateWithoutVarietyInput {
  id?: string | null;
  pinned?: boolean | null;
  season: Season;
  startDate: any;
  harvestEndDate: any;
  length: number;
  remark?: string | null;
  transplant?: boolean | null;
  interPlanting?: boolean | null;
  jiraTaskId?: string | null;
  plot: PlotCreateOneWithoutCultivationsInput;
  crop: CropCreateOneWithoutCultivationsInput;
  cropTiming: CropTimingCreateOneWithoutCultivationsInput;
  events?: CultivationEventCreateManyWithoutCultivationInput | null;
  varieties?: CultivationVarietyCreateManyInput | null;
  plantSchema?: CropPlantSchemaCreateOneInput | null;
  notes?: NoteCreateManyWithoutCultivationInput | null;
  tasks?: TaskCreateManyWithoutCultivationInput | null;
  parent?: CultivationCreateOneInput | null;
}

export interface CultivationEventCreateManyWithoutCultivationInput {
  create?: CultivationEventCreateWithoutCultivationInput[] | null;
  connect?: CultivationEventWhereUniqueInput[] | null;
}

export interface CultivationEventCreateOneWithoutNoteInput {
  create?: CultivationEventCreateWithoutNoteInput | null;
  connect?: CultivationEventWhereUniqueInput | null;
}

export interface CultivationEventCreateWithoutCultivationInput {
  id?: string | null;
  type: CultivationEventType;
  date: any;
  metadata?: any | null;
  skipped?: boolean | null;
  note?: NoteCreateOneWithoutCultivationEventInput | null;
}

export interface CultivationEventCreateWithoutNoteInput {
  id?: string | null;
  type: CultivationEventType;
  date: any;
  metadata?: any | null;
  skipped?: boolean | null;
  cultivation?: CultivationCreateOneWithoutEventsInput | null;
}

export interface CultivationEventScalarWhereInput {
  AND?: CultivationEventScalarWhereInput[] | null;
  OR?: CultivationEventScalarWhereInput[] | null;
  NOT?: CultivationEventScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  type?: CultivationEventType | null;
  type_not?: CultivationEventType | null;
  type_in?: CultivationEventType[] | null;
  type_not_in?: CultivationEventType[] | null;
  date?: any | null;
  date_not?: any | null;
  date_in?: any[] | null;
  date_not_in?: any[] | null;
  date_lt?: any | null;
  date_lte?: any | null;
  date_gt?: any | null;
  date_gte?: any | null;
  skipped?: boolean | null;
  skipped_not?: boolean | null;
}

export interface CultivationEventUpdateManyDataInput {
  type?: CultivationEventType | null;
  date?: any | null;
  metadata?: any | null;
  skipped?: boolean | null;
}

export interface CultivationEventUpdateManyWithWhereNestedInput {
  where: CultivationEventScalarWhereInput;
  data: CultivationEventUpdateManyDataInput;
}

export interface CultivationEventUpdateManyWithoutCultivationInput {
  create?: CultivationEventCreateWithoutCultivationInput[] | null;
  connect?: CultivationEventWhereUniqueInput[] | null;
  set?: CultivationEventWhereUniqueInput[] | null;
  disconnect?: CultivationEventWhereUniqueInput[] | null;
  delete?: CultivationEventWhereUniqueInput[] | null;
  update?: CultivationEventUpdateWithWhereUniqueWithoutCultivationInput[] | null;
  updateMany?: CultivationEventUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: CultivationEventScalarWhereInput[] | null;
  upsert?: CultivationEventUpsertWithWhereUniqueWithoutCultivationInput[] | null;
}

export interface CultivationEventUpdateOneWithoutNoteInput {
  create?: CultivationEventCreateWithoutNoteInput | null;
  connect?: CultivationEventWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: CultivationEventUpdateWithoutNoteDataInput | null;
  upsert?: CultivationEventUpsertWithoutNoteInput | null;
}

export interface CultivationEventUpdateWithWhereUniqueWithoutCultivationInput {
  where: CultivationEventWhereUniqueInput;
  data: CultivationEventUpdateWithoutCultivationDataInput;
}

export interface CultivationEventUpdateWithoutCultivationDataInput {
  type?: CultivationEventType | null;
  date?: any | null;
  metadata?: any | null;
  skipped?: boolean | null;
  note?: NoteUpdateOneWithoutCultivationEventInput | null;
}

export interface CultivationEventUpdateWithoutNoteDataInput {
  type?: CultivationEventType | null;
  date?: any | null;
  metadata?: any | null;
  skipped?: boolean | null;
  cultivation?: CultivationUpdateOneWithoutEventsInput | null;
}

export interface CultivationEventUpsertWithWhereUniqueWithoutCultivationInput {
  where: CultivationEventWhereUniqueInput;
  update: CultivationEventUpdateWithoutCultivationDataInput;
  create: CultivationEventCreateWithoutCultivationInput;
}

export interface CultivationEventUpsertWithoutNoteInput {
  update: CultivationEventUpdateWithoutNoteDataInput;
  create: CultivationEventCreateWithoutNoteInput;
}

export interface CultivationEventWhereInput {
  AND?: CultivationEventWhereInput[] | null;
  OR?: CultivationEventWhereInput[] | null;
  NOT?: CultivationEventWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  type?: CultivationEventType | null;
  type_not?: CultivationEventType | null;
  type_in?: CultivationEventType[] | null;
  type_not_in?: CultivationEventType[] | null;
  date?: any | null;
  date_not?: any | null;
  date_in?: any[] | null;
  date_not_in?: any[] | null;
  date_lt?: any | null;
  date_lte?: any | null;
  date_gt?: any | null;
  date_gte?: any | null;
  skipped?: boolean | null;
  skipped_not?: boolean | null;
  cultivation?: CultivationWhereInput | null;
  note?: NoteWhereInput | null;
}

export interface CultivationEventWhereUniqueInput {
  id?: string | null;
}

export interface CultivationScalarWhereInput {
  AND?: CultivationScalarWhereInput[] | null;
  OR?: CultivationScalarWhereInput[] | null;
  NOT?: CultivationScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  pinned?: boolean | null;
  pinned_not?: boolean | null;
  season?: Season | null;
  season_not?: Season | null;
  season_in?: Season[] | null;
  season_not_in?: Season[] | null;
  startDate?: any | null;
  startDate_not?: any | null;
  startDate_in?: any[] | null;
  startDate_not_in?: any[] | null;
  startDate_lt?: any | null;
  startDate_lte?: any | null;
  startDate_gt?: any | null;
  startDate_gte?: any | null;
  harvestEndDate?: any | null;
  harvestEndDate_not?: any | null;
  harvestEndDate_in?: any[] | null;
  harvestEndDate_not_in?: any[] | null;
  harvestEndDate_lt?: any | null;
  harvestEndDate_lte?: any | null;
  harvestEndDate_gt?: any | null;
  harvestEndDate_gte?: any | null;
  length?: number | null;
  length_not?: number | null;
  length_in?: number[] | null;
  length_not_in?: number[] | null;
  length_lt?: number | null;
  length_lte?: number | null;
  length_gt?: number | null;
  length_gte?: number | null;
  remark?: string | null;
  remark_not?: string | null;
  remark_in?: string[] | null;
  remark_not_in?: string[] | null;
  remark_lt?: string | null;
  remark_lte?: string | null;
  remark_gt?: string | null;
  remark_gte?: string | null;
  remark_contains?: string | null;
  remark_not_contains?: string | null;
  remark_starts_with?: string | null;
  remark_not_starts_with?: string | null;
  remark_ends_with?: string | null;
  remark_not_ends_with?: string | null;
  transplant?: boolean | null;
  transplant_not?: boolean | null;
  interPlanting?: boolean | null;
  interPlanting_not?: boolean | null;
  jiraTaskId?: string | null;
  jiraTaskId_not?: string | null;
  jiraTaskId_in?: string[] | null;
  jiraTaskId_not_in?: string[] | null;
  jiraTaskId_lt?: string | null;
  jiraTaskId_lte?: string | null;
  jiraTaskId_gt?: string | null;
  jiraTaskId_gte?: string | null;
  jiraTaskId_contains?: string | null;
  jiraTaskId_not_contains?: string | null;
  jiraTaskId_starts_with?: string | null;
  jiraTaskId_not_starts_with?: string | null;
  jiraTaskId_ends_with?: string | null;
  jiraTaskId_not_ends_with?: string | null;
}

export interface CultivationUpdateDataInput {
  pinned?: boolean | null;
  season?: Season | null;
  startDate?: any | null;
  harvestEndDate?: any | null;
  length?: number | null;
  remark?: string | null;
  transplant?: boolean | null;
  interPlanting?: boolean | null;
  jiraTaskId?: string | null;
  plot?: PlotUpdateOneRequiredWithoutCultivationsInput | null;
  crop?: CropUpdateOneRequiredWithoutCultivationsInput | null;
  cropTiming?: CropTimingUpdateOneRequiredWithoutCultivationsInput | null;
  events?: CultivationEventUpdateManyWithoutCultivationInput | null;
  variety?: VarietyUpdateOneWithoutCultivationsInput | null;
  varieties?: CultivationVarietyUpdateManyInput | null;
  plantSchema?: CropPlantSchemaUpdateOneInput | null;
  notes?: NoteUpdateManyWithoutCultivationInput | null;
  tasks?: TaskUpdateManyWithoutCultivationInput | null;
  parent?: CultivationUpdateOneInput | null;
}

export interface CultivationUpdateManyDataInput {
  pinned?: boolean | null;
  season?: Season | null;
  startDate?: any | null;
  harvestEndDate?: any | null;
  length?: number | null;
  remark?: string | null;
  transplant?: boolean | null;
  interPlanting?: boolean | null;
  jiraTaskId?: string | null;
}

export interface CultivationUpdateManyWithWhereNestedInput {
  where: CultivationScalarWhereInput;
  data: CultivationUpdateManyDataInput;
}

export interface CultivationUpdateManyWithoutCropInput {
  create?: CultivationCreateWithoutCropInput[] | null;
  connect?: CultivationWhereUniqueInput[] | null;
  set?: CultivationWhereUniqueInput[] | null;
  disconnect?: CultivationWhereUniqueInput[] | null;
  delete?: CultivationWhereUniqueInput[] | null;
  update?: CultivationUpdateWithWhereUniqueWithoutCropInput[] | null;
  updateMany?: CultivationUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: CultivationScalarWhereInput[] | null;
  upsert?: CultivationUpsertWithWhereUniqueWithoutCropInput[] | null;
}

export interface CultivationUpdateManyWithoutCropTimingInput {
  create?: CultivationCreateWithoutCropTimingInput[] | null;
  connect?: CultivationWhereUniqueInput[] | null;
  set?: CultivationWhereUniqueInput[] | null;
  disconnect?: CultivationWhereUniqueInput[] | null;
  delete?: CultivationWhereUniqueInput[] | null;
  update?: CultivationUpdateWithWhereUniqueWithoutCropTimingInput[] | null;
  updateMany?: CultivationUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: CultivationScalarWhereInput[] | null;
  upsert?: CultivationUpsertWithWhereUniqueWithoutCropTimingInput[] | null;
}

export interface CultivationUpdateManyWithoutPlotInput {
  create?: CultivationCreateWithoutPlotInput[] | null;
  connect?: CultivationWhereUniqueInput[] | null;
  set?: CultivationWhereUniqueInput[] | null;
  disconnect?: CultivationWhereUniqueInput[] | null;
  delete?: CultivationWhereUniqueInput[] | null;
  update?: CultivationUpdateWithWhereUniqueWithoutPlotInput[] | null;
  updateMany?: CultivationUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: CultivationScalarWhereInput[] | null;
  upsert?: CultivationUpsertWithWhereUniqueWithoutPlotInput[] | null;
}

export interface CultivationUpdateManyWithoutVarietyInput {
  create?: CultivationCreateWithoutVarietyInput[] | null;
  connect?: CultivationWhereUniqueInput[] | null;
  set?: CultivationWhereUniqueInput[] | null;
  disconnect?: CultivationWhereUniqueInput[] | null;
  delete?: CultivationWhereUniqueInput[] | null;
  update?: CultivationUpdateWithWhereUniqueWithoutVarietyInput[] | null;
  updateMany?: CultivationUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: CultivationScalarWhereInput[] | null;
  upsert?: CultivationUpsertWithWhereUniqueWithoutVarietyInput[] | null;
}

export interface CultivationUpdateOneInput {
  create?: CultivationCreateInput | null;
  connect?: CultivationWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: CultivationUpdateDataInput | null;
  upsert?: CultivationUpsertNestedInput | null;
}

export interface CultivationUpdateOneWithoutEventsInput {
  create?: CultivationCreateWithoutEventsInput | null;
  connect?: CultivationWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: CultivationUpdateWithoutEventsDataInput | null;
  upsert?: CultivationUpsertWithoutEventsInput | null;
}

export interface CultivationUpdateOneWithoutNotesInput {
  create?: CultivationCreateWithoutNotesInput | null;
  connect?: CultivationWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: CultivationUpdateWithoutNotesDataInput | null;
  upsert?: CultivationUpsertWithoutNotesInput | null;
}

export interface CultivationUpdateWithWhereUniqueWithoutCropInput {
  where: CultivationWhereUniqueInput;
  data: CultivationUpdateWithoutCropDataInput;
}

export interface CultivationUpdateWithWhereUniqueWithoutCropTimingInput {
  where: CultivationWhereUniqueInput;
  data: CultivationUpdateWithoutCropTimingDataInput;
}

export interface CultivationUpdateWithWhereUniqueWithoutPlotInput {
  where: CultivationWhereUniqueInput;
  data: CultivationUpdateWithoutPlotDataInput;
}

export interface CultivationUpdateWithWhereUniqueWithoutVarietyInput {
  where: CultivationWhereUniqueInput;
  data: CultivationUpdateWithoutVarietyDataInput;
}

export interface CultivationUpdateWithoutCropDataInput {
  pinned?: boolean | null;
  season?: Season | null;
  startDate?: any | null;
  harvestEndDate?: any | null;
  length?: number | null;
  remark?: string | null;
  transplant?: boolean | null;
  interPlanting?: boolean | null;
  jiraTaskId?: string | null;
  plot?: PlotUpdateOneRequiredWithoutCultivationsInput | null;
  cropTiming?: CropTimingUpdateOneRequiredWithoutCultivationsInput | null;
  events?: CultivationEventUpdateManyWithoutCultivationInput | null;
  variety?: VarietyUpdateOneWithoutCultivationsInput | null;
  varieties?: CultivationVarietyUpdateManyInput | null;
  plantSchema?: CropPlantSchemaUpdateOneInput | null;
  notes?: NoteUpdateManyWithoutCultivationInput | null;
  tasks?: TaskUpdateManyWithoutCultivationInput | null;
  parent?: CultivationUpdateOneInput | null;
}

export interface CultivationUpdateWithoutCropTimingDataInput {
  pinned?: boolean | null;
  season?: Season | null;
  startDate?: any | null;
  harvestEndDate?: any | null;
  length?: number | null;
  remark?: string | null;
  transplant?: boolean | null;
  interPlanting?: boolean | null;
  jiraTaskId?: string | null;
  plot?: PlotUpdateOneRequiredWithoutCultivationsInput | null;
  crop?: CropUpdateOneRequiredWithoutCultivationsInput | null;
  events?: CultivationEventUpdateManyWithoutCultivationInput | null;
  variety?: VarietyUpdateOneWithoutCultivationsInput | null;
  varieties?: CultivationVarietyUpdateManyInput | null;
  plantSchema?: CropPlantSchemaUpdateOneInput | null;
  notes?: NoteUpdateManyWithoutCultivationInput | null;
  tasks?: TaskUpdateManyWithoutCultivationInput | null;
  parent?: CultivationUpdateOneInput | null;
}

export interface CultivationUpdateWithoutEventsDataInput {
  pinned?: boolean | null;
  season?: Season | null;
  startDate?: any | null;
  harvestEndDate?: any | null;
  length?: number | null;
  remark?: string | null;
  transplant?: boolean | null;
  interPlanting?: boolean | null;
  jiraTaskId?: string | null;
  plot?: PlotUpdateOneRequiredWithoutCultivationsInput | null;
  crop?: CropUpdateOneRequiredWithoutCultivationsInput | null;
  cropTiming?: CropTimingUpdateOneRequiredWithoutCultivationsInput | null;
  variety?: VarietyUpdateOneWithoutCultivationsInput | null;
  varieties?: CultivationVarietyUpdateManyInput | null;
  plantSchema?: CropPlantSchemaUpdateOneInput | null;
  notes?: NoteUpdateManyWithoutCultivationInput | null;
  tasks?: TaskUpdateManyWithoutCultivationInput | null;
  parent?: CultivationUpdateOneInput | null;
}

export interface CultivationUpdateWithoutNotesDataInput {
  pinned?: boolean | null;
  season?: Season | null;
  startDate?: any | null;
  harvestEndDate?: any | null;
  length?: number | null;
  remark?: string | null;
  transplant?: boolean | null;
  interPlanting?: boolean | null;
  jiraTaskId?: string | null;
  plot?: PlotUpdateOneRequiredWithoutCultivationsInput | null;
  crop?: CropUpdateOneRequiredWithoutCultivationsInput | null;
  cropTiming?: CropTimingUpdateOneRequiredWithoutCultivationsInput | null;
  events?: CultivationEventUpdateManyWithoutCultivationInput | null;
  variety?: VarietyUpdateOneWithoutCultivationsInput | null;
  varieties?: CultivationVarietyUpdateManyInput | null;
  plantSchema?: CropPlantSchemaUpdateOneInput | null;
  tasks?: TaskUpdateManyWithoutCultivationInput | null;
  parent?: CultivationUpdateOneInput | null;
}

export interface CultivationUpdateWithoutPlotDataInput {
  pinned?: boolean | null;
  season?: Season | null;
  startDate?: any | null;
  harvestEndDate?: any | null;
  length?: number | null;
  remark?: string | null;
  transplant?: boolean | null;
  interPlanting?: boolean | null;
  jiraTaskId?: string | null;
  crop?: CropUpdateOneRequiredWithoutCultivationsInput | null;
  cropTiming?: CropTimingUpdateOneRequiredWithoutCultivationsInput | null;
  events?: CultivationEventUpdateManyWithoutCultivationInput | null;
  variety?: VarietyUpdateOneWithoutCultivationsInput | null;
  varieties?: CultivationVarietyUpdateManyInput | null;
  plantSchema?: CropPlantSchemaUpdateOneInput | null;
  notes?: NoteUpdateManyWithoutCultivationInput | null;
  tasks?: TaskUpdateManyWithoutCultivationInput | null;
  parent?: CultivationUpdateOneInput | null;
}

export interface CultivationUpdateWithoutVarietyDataInput {
  pinned?: boolean | null;
  season?: Season | null;
  startDate?: any | null;
  harvestEndDate?: any | null;
  length?: number | null;
  remark?: string | null;
  transplant?: boolean | null;
  interPlanting?: boolean | null;
  jiraTaskId?: string | null;
  plot?: PlotUpdateOneRequiredWithoutCultivationsInput | null;
  crop?: CropUpdateOneRequiredWithoutCultivationsInput | null;
  cropTiming?: CropTimingUpdateOneRequiredWithoutCultivationsInput | null;
  events?: CultivationEventUpdateManyWithoutCultivationInput | null;
  varieties?: CultivationVarietyUpdateManyInput | null;
  plantSchema?: CropPlantSchemaUpdateOneInput | null;
  notes?: NoteUpdateManyWithoutCultivationInput | null;
  tasks?: TaskUpdateManyWithoutCultivationInput | null;
  parent?: CultivationUpdateOneInput | null;
}

export interface CultivationUpsertNestedInput {
  update: CultivationUpdateDataInput;
  create: CultivationCreateInput;
}

export interface CultivationUpsertWithWhereUniqueWithoutCropInput {
  where: CultivationWhereUniqueInput;
  update: CultivationUpdateWithoutCropDataInput;
  create: CultivationCreateWithoutCropInput;
}

export interface CultivationUpsertWithWhereUniqueWithoutCropTimingInput {
  where: CultivationWhereUniqueInput;
  update: CultivationUpdateWithoutCropTimingDataInput;
  create: CultivationCreateWithoutCropTimingInput;
}

export interface CultivationUpsertWithWhereUniqueWithoutPlotInput {
  where: CultivationWhereUniqueInput;
  update: CultivationUpdateWithoutPlotDataInput;
  create: CultivationCreateWithoutPlotInput;
}

export interface CultivationUpsertWithWhereUniqueWithoutVarietyInput {
  where: CultivationWhereUniqueInput;
  update: CultivationUpdateWithoutVarietyDataInput;
  create: CultivationCreateWithoutVarietyInput;
}

export interface CultivationUpsertWithoutEventsInput {
  update: CultivationUpdateWithoutEventsDataInput;
  create: CultivationCreateWithoutEventsInput;
}

export interface CultivationUpsertWithoutNotesInput {
  update: CultivationUpdateWithoutNotesDataInput;
  create: CultivationCreateWithoutNotesInput;
}

export interface CultivationVarietyCreateInput {
  id?: string | null;
  percentage: number;
  cultivation?: CultivationCreateOneInput | null;
  variety: VarietyCreateOneInput;
}

export interface CultivationVarietyCreateManyInput {
  create?: CultivationVarietyCreateInput[] | null;
  connect?: CultivationVarietyWhereUniqueInput[] | null;
}

export interface CultivationVarietyScalarWhereInput {
  AND?: CultivationVarietyScalarWhereInput[] | null;
  OR?: CultivationVarietyScalarWhereInput[] | null;
  NOT?: CultivationVarietyScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  percentage?: number | null;
  percentage_not?: number | null;
  percentage_in?: number[] | null;
  percentage_not_in?: number[] | null;
  percentage_lt?: number | null;
  percentage_lte?: number | null;
  percentage_gt?: number | null;
  percentage_gte?: number | null;
}

export interface CultivationVarietyUpdateDataInput {
  percentage?: number | null;
  cultivation?: CultivationUpdateOneInput | null;
  variety?: VarietyUpdateOneRequiredInput | null;
}

export interface CultivationVarietyUpdateManyDataInput {
  percentage?: number | null;
}

export interface CultivationVarietyUpdateManyInput {
  create?: CultivationVarietyCreateInput[] | null;
  connect?: CultivationVarietyWhereUniqueInput[] | null;
  set?: CultivationVarietyWhereUniqueInput[] | null;
  disconnect?: CultivationVarietyWhereUniqueInput[] | null;
  delete?: CultivationVarietyWhereUniqueInput[] | null;
  update?: CultivationVarietyUpdateWithWhereUniqueNestedInput[] | null;
  updateMany?: CultivationVarietyUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: CultivationVarietyScalarWhereInput[] | null;
  upsert?: CultivationVarietyUpsertWithWhereUniqueNestedInput[] | null;
}

export interface CultivationVarietyUpdateManyWithWhereNestedInput {
  where: CultivationVarietyScalarWhereInput;
  data: CultivationVarietyUpdateManyDataInput;
}

export interface CultivationVarietyUpdateWithWhereUniqueNestedInput {
  where: CultivationVarietyWhereUniqueInput;
  data: CultivationVarietyUpdateDataInput;
}

export interface CultivationVarietyUpsertWithWhereUniqueNestedInput {
  where: CultivationVarietyWhereUniqueInput;
  update: CultivationVarietyUpdateDataInput;
  create: CultivationVarietyCreateInput;
}

export interface CultivationVarietyWhereInput {
  AND?: CultivationVarietyWhereInput[] | null;
  OR?: CultivationVarietyWhereInput[] | null;
  NOT?: CultivationVarietyWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  percentage?: number | null;
  percentage_not?: number | null;
  percentage_in?: number[] | null;
  percentage_not_in?: number[] | null;
  percentage_lt?: number | null;
  percentage_lte?: number | null;
  percentage_gt?: number | null;
  percentage_gte?: number | null;
  cultivation?: CultivationWhereInput | null;
  variety?: VarietyWhereInput | null;
}

export interface CultivationVarietyWhereUniqueInput {
  id?: string | null;
}

export interface CultivationWhereInput {
  AND?: CultivationWhereInput[] | null;
  OR?: CultivationWhereInput[] | null;
  NOT?: CultivationWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  pinned?: boolean | null;
  pinned_not?: boolean | null;
  season?: Season | null;
  season_not?: Season | null;
  season_in?: Season[] | null;
  season_not_in?: Season[] | null;
  startDate?: any | null;
  startDate_not?: any | null;
  startDate_in?: any[] | null;
  startDate_not_in?: any[] | null;
  startDate_lt?: any | null;
  startDate_lte?: any | null;
  startDate_gt?: any | null;
  startDate_gte?: any | null;
  harvestEndDate?: any | null;
  harvestEndDate_not?: any | null;
  harvestEndDate_in?: any[] | null;
  harvestEndDate_not_in?: any[] | null;
  harvestEndDate_lt?: any | null;
  harvestEndDate_lte?: any | null;
  harvestEndDate_gt?: any | null;
  harvestEndDate_gte?: any | null;
  length?: number | null;
  length_not?: number | null;
  length_in?: number[] | null;
  length_not_in?: number[] | null;
  length_lt?: number | null;
  length_lte?: number | null;
  length_gt?: number | null;
  length_gte?: number | null;
  remark?: string | null;
  remark_not?: string | null;
  remark_in?: string[] | null;
  remark_not_in?: string[] | null;
  remark_lt?: string | null;
  remark_lte?: string | null;
  remark_gt?: string | null;
  remark_gte?: string | null;
  remark_contains?: string | null;
  remark_not_contains?: string | null;
  remark_starts_with?: string | null;
  remark_not_starts_with?: string | null;
  remark_ends_with?: string | null;
  remark_not_ends_with?: string | null;
  transplant?: boolean | null;
  transplant_not?: boolean | null;
  interPlanting?: boolean | null;
  interPlanting_not?: boolean | null;
  jiraTaskId?: string | null;
  jiraTaskId_not?: string | null;
  jiraTaskId_in?: string[] | null;
  jiraTaskId_not_in?: string[] | null;
  jiraTaskId_lt?: string | null;
  jiraTaskId_lte?: string | null;
  jiraTaskId_gt?: string | null;
  jiraTaskId_gte?: string | null;
  jiraTaskId_contains?: string | null;
  jiraTaskId_not_contains?: string | null;
  jiraTaskId_starts_with?: string | null;
  jiraTaskId_not_starts_with?: string | null;
  jiraTaskId_ends_with?: string | null;
  jiraTaskId_not_ends_with?: string | null;
  plot?: PlotWhereInput | null;
  crop?: CropWhereInput | null;
  cropTiming?: CropTimingWhereInput | null;
  events_every?: CultivationEventWhereInput | null;
  events_some?: CultivationEventWhereInput | null;
  events_none?: CultivationEventWhereInput | null;
  variety?: VarietyWhereInput | null;
  varieties_every?: CultivationVarietyWhereInput | null;
  varieties_some?: CultivationVarietyWhereInput | null;
  varieties_none?: CultivationVarietyWhereInput | null;
  plantSchema?: CropPlantSchemaWhereInput | null;
  notes_every?: NoteWhereInput | null;
  notes_some?: NoteWhereInput | null;
  notes_none?: NoteWhereInput | null;
  tasks_every?: TaskWhereInput | null;
  tasks_some?: TaskWhereInput | null;
  tasks_none?: TaskWhereInput | null;
  parent?: CultivationWhereInput | null;
}

export interface CultivationWhereUniqueInput {
  id?: string | null;
}

export interface CustomerInfoCreateInput {
  id?: string | null;
  whatsApp: boolean;
  members?: MemberCreateManyWithoutCustomerInfoInput | null;
}

export interface CustomerInfoCreateOneInput {
  create?: CustomerInfoCreateInput | null;
  connect?: CustomerInfoWhereUniqueInput | null;
}

export interface CustomerInfoCreateOneWithoutMembersInput {
  create?: CustomerInfoCreateWithoutMembersInput | null;
  connect?: CustomerInfoWhereUniqueInput | null;
}

export interface CustomerInfoCreateWithoutMembersInput {
  id?: string | null;
  whatsApp: boolean;
}

export interface CustomerInfoUpdateDataInput {
  whatsApp?: boolean | null;
  members?: MemberUpdateManyWithoutCustomerInfoInput | null;
}

export interface CustomerInfoUpdateOneInput {
  create?: CustomerInfoCreateInput | null;
  connect?: CustomerInfoWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: CustomerInfoUpdateDataInput | null;
  upsert?: CustomerInfoUpsertNestedInput | null;
}

export interface CustomerInfoUpdateOneWithoutMembersInput {
  create?: CustomerInfoCreateWithoutMembersInput | null;
  connect?: CustomerInfoWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: CustomerInfoUpdateWithoutMembersDataInput | null;
  upsert?: CustomerInfoUpsertWithoutMembersInput | null;
}

export interface CustomerInfoUpdateWithoutMembersDataInput {
  whatsApp?: boolean | null;
}

export interface CustomerInfoUpsertNestedInput {
  update: CustomerInfoUpdateDataInput;
  create: CustomerInfoCreateInput;
}

export interface CustomerInfoUpsertWithoutMembersInput {
  update: CustomerInfoUpdateWithoutMembersDataInput;
  create: CustomerInfoCreateWithoutMembersInput;
}

export interface CustomerInfoWhereInput {
  AND?: CustomerInfoWhereInput[] | null;
  OR?: CustomerInfoWhereInput[] | null;
  NOT?: CustomerInfoWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  whatsApp?: boolean | null;
  whatsApp_not?: boolean | null;
  members_every?: MemberWhereInput | null;
  members_some?: MemberWhereInput | null;
  members_none?: MemberWhereInput | null;
}

export interface CustomerInfoWhereUniqueInput {
  id?: string | null;
}

export interface FarmConfigCreateInput {
  id?: string | null;
  code: string;
  value?: any | null;
  farm: FarmCreateOneWithoutConfigurationInput;
}

export interface FarmConfigCreateManyWithoutFarmInput {
  create?: FarmConfigCreateWithoutFarmInput[] | null;
  connect?: FarmConfigWhereUniqueInput[] | null;
}

export interface FarmConfigCreateWithoutFarmInput {
  id?: string | null;
  code: string;
  value?: any | null;
}

export interface FarmConfigScalarWhereInput {
  AND?: FarmConfigScalarWhereInput[] | null;
  OR?: FarmConfigScalarWhereInput[] | null;
  NOT?: FarmConfigScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  code?: string | null;
  code_not?: string | null;
  code_in?: string[] | null;
  code_not_in?: string[] | null;
  code_lt?: string | null;
  code_lte?: string | null;
  code_gt?: string | null;
  code_gte?: string | null;
  code_contains?: string | null;
  code_not_contains?: string | null;
  code_starts_with?: string | null;
  code_not_starts_with?: string | null;
  code_ends_with?: string | null;
  code_not_ends_with?: string | null;
}

export interface FarmConfigUpdateInput {
  code?: string | null;
  value?: any | null;
  farm?: FarmUpdateOneRequiredWithoutConfigurationInput | null;
}

export interface FarmConfigUpdateManyDataInput {
  code?: string | null;
  value?: any | null;
}

export interface FarmConfigUpdateManyWithWhereNestedInput {
  where: FarmConfigScalarWhereInput;
  data: FarmConfigUpdateManyDataInput;
}

export interface FarmConfigUpdateManyWithoutFarmInput {
  create?: FarmConfigCreateWithoutFarmInput[] | null;
  connect?: FarmConfigWhereUniqueInput[] | null;
  set?: FarmConfigWhereUniqueInput[] | null;
  disconnect?: FarmConfigWhereUniqueInput[] | null;
  delete?: FarmConfigWhereUniqueInput[] | null;
  update?: FarmConfigUpdateWithWhereUniqueWithoutFarmInput[] | null;
  updateMany?: FarmConfigUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: FarmConfigScalarWhereInput[] | null;
  upsert?: FarmConfigUpsertWithWhereUniqueWithoutFarmInput[] | null;
}

export interface FarmConfigUpdateWithWhereUniqueWithoutFarmInput {
  where: FarmConfigWhereUniqueInput;
  data: FarmConfigUpdateWithoutFarmDataInput;
}

export interface FarmConfigUpdateWithoutFarmDataInput {
  code?: string | null;
  value?: any | null;
}

export interface FarmConfigUpsertWithWhereUniqueWithoutFarmInput {
  where: FarmConfigWhereUniqueInput;
  update: FarmConfigUpdateWithoutFarmDataInput;
  create: FarmConfigCreateWithoutFarmInput;
}

export interface FarmConfigWhereInput {
  AND?: FarmConfigWhereInput[] | null;
  OR?: FarmConfigWhereInput[] | null;
  NOT?: FarmConfigWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  code?: string | null;
  code_not?: string | null;
  code_in?: string[] | null;
  code_not_in?: string[] | null;
  code_lt?: string | null;
  code_lte?: string | null;
  code_gt?: string | null;
  code_gte?: string | null;
  code_contains?: string | null;
  code_not_contains?: string | null;
  code_starts_with?: string | null;
  code_not_starts_with?: string | null;
  code_ends_with?: string | null;
  code_not_ends_with?: string | null;
  farm?: FarmWhereInput | null;
}

export interface FarmConfigWhereUniqueInput {
  id?: string | null;
}

export interface FarmCreateManyWithoutUsersInput {
  create?: FarmCreateWithoutUsersInput[] | null;
  connect?: FarmWhereUniqueInput[] | null;
}

export interface FarmCreateOneWithoutBasketCompositionsInput {
  create?: FarmCreateWithoutBasketCompositionsInput | null;
  connect?: FarmWhereUniqueInput | null;
}

export interface FarmCreateOneWithoutClientGroupsInput {
  create?: FarmCreateWithoutClientGroupsInput | null;
  connect?: FarmWhereUniqueInput | null;
}

export interface FarmCreateOneWithoutClientsInput {
  create?: FarmCreateWithoutClientsInput | null;
  connect?: FarmWhereUniqueInput | null;
}

export interface FarmCreateOneWithoutConfigurationInput {
  create?: FarmCreateWithoutConfigurationInput | null;
  connect?: FarmWhereUniqueInput | null;
}

export interface FarmCreateOneWithoutFarmPermissionsInput {
  create?: FarmCreateWithoutFarmPermissionsInput | null;
  connect?: FarmWhereUniqueInput | null;
}

export interface FarmCreateOneWithoutLocationsInput {
  create?: FarmCreateWithoutLocationsInput | null;
  connect?: FarmWhereUniqueInput | null;
}

export interface FarmCreateOneWithoutProductsInput {
  create?: FarmCreateWithoutProductsInput | null;
  connect?: FarmWhereUniqueInput | null;
}

export interface FarmCreateOneWithoutRotationGroupsInput {
  create?: FarmCreateWithoutRotationGroupsInput | null;
  connect?: FarmWhereUniqueInput | null;
}

export interface FarmCreateWithoutBasketCompositionsInput {
  id?: string | null;
  name: string;
  code: string;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  locations?: FarmLocationCreateManyWithoutFarmInput | null;
  users?: UserCreateManyWithoutFarmsInput | null;
  rotationGroups?: RotationGroupCreateManyWithoutFarmInput | null;
  orderItems?: OrderItemCreateManyWithoutFarmInput | null;
  clientGroups?: ClientGroupCreateManyWithoutFarmInput | null;
  clients?: ClientCreateManyWithoutFarmInput | null;
  configuration?: FarmConfigCreateManyWithoutFarmInput | null;
  products?: ProductCreateManyWithoutFarmInput | null;
  sequences?: SequenceCreateManyWithoutFarmInput | null;
  farmPermissions?: FarmPermissionCreateManyWithoutFarmInput | null;
}

export interface FarmCreateWithoutClientGroupsInput {
  id?: string | null;
  name: string;
  code: string;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  locations?: FarmLocationCreateManyWithoutFarmInput | null;
  users?: UserCreateManyWithoutFarmsInput | null;
  rotationGroups?: RotationGroupCreateManyWithoutFarmInput | null;
  orderItems?: OrderItemCreateManyWithoutFarmInput | null;
  clients?: ClientCreateManyWithoutFarmInput | null;
  configuration?: FarmConfigCreateManyWithoutFarmInput | null;
  products?: ProductCreateManyWithoutFarmInput | null;
  sequences?: SequenceCreateManyWithoutFarmInput | null;
  basketCompositions?: BasketCompositionCreateManyWithoutFarmInput | null;
  farmPermissions?: FarmPermissionCreateManyWithoutFarmInput | null;
}

export interface FarmCreateWithoutClientsInput {
  id?: string | null;
  name: string;
  code: string;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  locations?: FarmLocationCreateManyWithoutFarmInput | null;
  users?: UserCreateManyWithoutFarmsInput | null;
  rotationGroups?: RotationGroupCreateManyWithoutFarmInput | null;
  orderItems?: OrderItemCreateManyWithoutFarmInput | null;
  clientGroups?: ClientGroupCreateManyWithoutFarmInput | null;
  configuration?: FarmConfigCreateManyWithoutFarmInput | null;
  products?: ProductCreateManyWithoutFarmInput | null;
  sequences?: SequenceCreateManyWithoutFarmInput | null;
  basketCompositions?: BasketCompositionCreateManyWithoutFarmInput | null;
  farmPermissions?: FarmPermissionCreateManyWithoutFarmInput | null;
}

export interface FarmCreateWithoutConfigurationInput {
  id?: string | null;
  name: string;
  code: string;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  locations?: FarmLocationCreateManyWithoutFarmInput | null;
  users?: UserCreateManyWithoutFarmsInput | null;
  rotationGroups?: RotationGroupCreateManyWithoutFarmInput | null;
  orderItems?: OrderItemCreateManyWithoutFarmInput | null;
  clientGroups?: ClientGroupCreateManyWithoutFarmInput | null;
  clients?: ClientCreateManyWithoutFarmInput | null;
  products?: ProductCreateManyWithoutFarmInput | null;
  sequences?: SequenceCreateManyWithoutFarmInput | null;
  basketCompositions?: BasketCompositionCreateManyWithoutFarmInput | null;
  farmPermissions?: FarmPermissionCreateManyWithoutFarmInput | null;
}

export interface FarmCreateWithoutFarmPermissionsInput {
  id?: string | null;
  name: string;
  code: string;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  locations?: FarmLocationCreateManyWithoutFarmInput | null;
  users?: UserCreateManyWithoutFarmsInput | null;
  rotationGroups?: RotationGroupCreateManyWithoutFarmInput | null;
  orderItems?: OrderItemCreateManyWithoutFarmInput | null;
  clientGroups?: ClientGroupCreateManyWithoutFarmInput | null;
  clients?: ClientCreateManyWithoutFarmInput | null;
  configuration?: FarmConfigCreateManyWithoutFarmInput | null;
  products?: ProductCreateManyWithoutFarmInput | null;
  sequences?: SequenceCreateManyWithoutFarmInput | null;
  basketCompositions?: BasketCompositionCreateManyWithoutFarmInput | null;
}

export interface FarmCreateWithoutLocationsInput {
  id?: string | null;
  name: string;
  code: string;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  users?: UserCreateManyWithoutFarmsInput | null;
  rotationGroups?: RotationGroupCreateManyWithoutFarmInput | null;
  orderItems?: OrderItemCreateManyWithoutFarmInput | null;
  clientGroups?: ClientGroupCreateManyWithoutFarmInput | null;
  clients?: ClientCreateManyWithoutFarmInput | null;
  configuration?: FarmConfigCreateManyWithoutFarmInput | null;
  products?: ProductCreateManyWithoutFarmInput | null;
  sequences?: SequenceCreateManyWithoutFarmInput | null;
  basketCompositions?: BasketCompositionCreateManyWithoutFarmInput | null;
  farmPermissions?: FarmPermissionCreateManyWithoutFarmInput | null;
}

export interface FarmCreateWithoutProductsInput {
  id?: string | null;
  name: string;
  code: string;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  locations?: FarmLocationCreateManyWithoutFarmInput | null;
  users?: UserCreateManyWithoutFarmsInput | null;
  rotationGroups?: RotationGroupCreateManyWithoutFarmInput | null;
  orderItems?: OrderItemCreateManyWithoutFarmInput | null;
  clientGroups?: ClientGroupCreateManyWithoutFarmInput | null;
  clients?: ClientCreateManyWithoutFarmInput | null;
  configuration?: FarmConfigCreateManyWithoutFarmInput | null;
  sequences?: SequenceCreateManyWithoutFarmInput | null;
  basketCompositions?: BasketCompositionCreateManyWithoutFarmInput | null;
  farmPermissions?: FarmPermissionCreateManyWithoutFarmInput | null;
}

export interface FarmCreateWithoutRotationGroupsInput {
  id?: string | null;
  name: string;
  code: string;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  locations?: FarmLocationCreateManyWithoutFarmInput | null;
  users?: UserCreateManyWithoutFarmsInput | null;
  orderItems?: OrderItemCreateManyWithoutFarmInput | null;
  clientGroups?: ClientGroupCreateManyWithoutFarmInput | null;
  clients?: ClientCreateManyWithoutFarmInput | null;
  configuration?: FarmConfigCreateManyWithoutFarmInput | null;
  products?: ProductCreateManyWithoutFarmInput | null;
  sequences?: SequenceCreateManyWithoutFarmInput | null;
  basketCompositions?: BasketCompositionCreateManyWithoutFarmInput | null;
  farmPermissions?: FarmPermissionCreateManyWithoutFarmInput | null;
}

export interface FarmCreateWithoutUsersInput {
  id?: string | null;
  name: string;
  code: string;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  locations?: FarmLocationCreateManyWithoutFarmInput | null;
  rotationGroups?: RotationGroupCreateManyWithoutFarmInput | null;
  orderItems?: OrderItemCreateManyWithoutFarmInput | null;
  clientGroups?: ClientGroupCreateManyWithoutFarmInput | null;
  clients?: ClientCreateManyWithoutFarmInput | null;
  configuration?: FarmConfigCreateManyWithoutFarmInput | null;
  products?: ProductCreateManyWithoutFarmInput | null;
  sequences?: SequenceCreateManyWithoutFarmInput | null;
  basketCompositions?: BasketCompositionCreateManyWithoutFarmInput | null;
  farmPermissions?: FarmPermissionCreateManyWithoutFarmInput | null;
}

export interface FarmLocationCreateManyWithoutFarmInput {
  create?: FarmLocationCreateWithoutFarmInput[] | null;
  connect?: FarmLocationWhereUniqueInput[] | null;
}

export interface FarmLocationCreateOneWithoutFieldsInput {
  create?: FarmLocationCreateWithoutFieldsInput | null;
  connect?: FarmLocationWhereUniqueInput | null;
}

export interface FarmLocationCreateWithoutFarmInput {
  id?: string | null;
  name: string;
  fields?: FieldCreateManyWithoutLocationInput | null;
}

export interface FarmLocationCreateWithoutFieldsInput {
  id?: string | null;
  name: string;
  farm: FarmCreateOneWithoutLocationsInput;
}

export interface FarmLocationScalarWhereInput {
  AND?: FarmLocationScalarWhereInput[] | null;
  OR?: FarmLocationScalarWhereInput[] | null;
  NOT?: FarmLocationScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
}

export interface FarmLocationUpdateManyDataInput {
  name?: string | null;
}

export interface FarmLocationUpdateManyWithWhereNestedInput {
  where: FarmLocationScalarWhereInput;
  data: FarmLocationUpdateManyDataInput;
}

export interface FarmLocationUpdateManyWithoutFarmInput {
  create?: FarmLocationCreateWithoutFarmInput[] | null;
  connect?: FarmLocationWhereUniqueInput[] | null;
  set?: FarmLocationWhereUniqueInput[] | null;
  disconnect?: FarmLocationWhereUniqueInput[] | null;
  delete?: FarmLocationWhereUniqueInput[] | null;
  update?: FarmLocationUpdateWithWhereUniqueWithoutFarmInput[] | null;
  updateMany?: FarmLocationUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: FarmLocationScalarWhereInput[] | null;
  upsert?: FarmLocationUpsertWithWhereUniqueWithoutFarmInput[] | null;
}

export interface FarmLocationUpdateOneWithoutFieldsInput {
  create?: FarmLocationCreateWithoutFieldsInput | null;
  connect?: FarmLocationWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: FarmLocationUpdateWithoutFieldsDataInput | null;
  upsert?: FarmLocationUpsertWithoutFieldsInput | null;
}

export interface FarmLocationUpdateWithWhereUniqueWithoutFarmInput {
  where: FarmLocationWhereUniqueInput;
  data: FarmLocationUpdateWithoutFarmDataInput;
}

export interface FarmLocationUpdateWithoutFarmDataInput {
  name?: string | null;
  fields?: FieldUpdateManyWithoutLocationInput | null;
}

export interface FarmLocationUpdateWithoutFieldsDataInput {
  name?: string | null;
  farm?: FarmUpdateOneRequiredWithoutLocationsInput | null;
}

export interface FarmLocationUpsertWithWhereUniqueWithoutFarmInput {
  where: FarmLocationWhereUniqueInput;
  update: FarmLocationUpdateWithoutFarmDataInput;
  create: FarmLocationCreateWithoutFarmInput;
}

export interface FarmLocationUpsertWithoutFieldsInput {
  update: FarmLocationUpdateWithoutFieldsDataInput;
  create: FarmLocationCreateWithoutFieldsInput;
}

export interface FarmLocationWhereInput {
  AND?: FarmLocationWhereInput[] | null;
  OR?: FarmLocationWhereInput[] | null;
  NOT?: FarmLocationWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  farm?: FarmWhereInput | null;
  fields_every?: FieldWhereInput | null;
  fields_some?: FieldWhereInput | null;
  fields_none?: FieldWhereInput | null;
}

export interface FarmLocationWhereUniqueInput {
  id?: string | null;
}

export interface FarmPermissionCreateManyWithoutFarmInput {
  create?: FarmPermissionCreateWithoutFarmInput[] | null;
  connect?: FarmPermissionWhereUniqueInput[] | null;
}

export interface FarmPermissionCreateManyWithoutUserInput {
  create?: FarmPermissionCreateWithoutUserInput[] | null;
  connect?: FarmPermissionWhereUniqueInput[] | null;
}

export interface FarmPermissionCreateWithoutFarmInput {
  id?: string | null;
  permission: Permission;
  user: UserCreateOneWithoutFarmPermissionsInput;
}

export interface FarmPermissionCreateWithoutUserInput {
  id?: string | null;
  permission: Permission;
  farm: FarmCreateOneWithoutFarmPermissionsInput;
}

export interface FarmPermissionScalarWhereInput {
  AND?: FarmPermissionScalarWhereInput[] | null;
  OR?: FarmPermissionScalarWhereInput[] | null;
  NOT?: FarmPermissionScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  permission?: Permission | null;
  permission_not?: Permission | null;
  permission_in?: Permission[] | null;
  permission_not_in?: Permission[] | null;
}

export interface FarmPermissionUpdateManyDataInput {
  permission?: Permission | null;
}

export interface FarmPermissionUpdateManyWithWhereNestedInput {
  where: FarmPermissionScalarWhereInput;
  data: FarmPermissionUpdateManyDataInput;
}

export interface FarmPermissionUpdateManyWithoutFarmInput {
  create?: FarmPermissionCreateWithoutFarmInput[] | null;
  connect?: FarmPermissionWhereUniqueInput[] | null;
  set?: FarmPermissionWhereUniqueInput[] | null;
  disconnect?: FarmPermissionWhereUniqueInput[] | null;
  delete?: FarmPermissionWhereUniqueInput[] | null;
  update?: FarmPermissionUpdateWithWhereUniqueWithoutFarmInput[] | null;
  updateMany?: FarmPermissionUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: FarmPermissionScalarWhereInput[] | null;
  upsert?: FarmPermissionUpsertWithWhereUniqueWithoutFarmInput[] | null;
}

export interface FarmPermissionUpdateManyWithoutUserInput {
  create?: FarmPermissionCreateWithoutUserInput[] | null;
  connect?: FarmPermissionWhereUniqueInput[] | null;
  set?: FarmPermissionWhereUniqueInput[] | null;
  disconnect?: FarmPermissionWhereUniqueInput[] | null;
  delete?: FarmPermissionWhereUniqueInput[] | null;
  update?: FarmPermissionUpdateWithWhereUniqueWithoutUserInput[] | null;
  updateMany?: FarmPermissionUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: FarmPermissionScalarWhereInput[] | null;
  upsert?: FarmPermissionUpsertWithWhereUniqueWithoutUserInput[] | null;
}

export interface FarmPermissionUpdateWithWhereUniqueWithoutFarmInput {
  where: FarmPermissionWhereUniqueInput;
  data: FarmPermissionUpdateWithoutFarmDataInput;
}

export interface FarmPermissionUpdateWithWhereUniqueWithoutUserInput {
  where: FarmPermissionWhereUniqueInput;
  data: FarmPermissionUpdateWithoutUserDataInput;
}

export interface FarmPermissionUpdateWithoutFarmDataInput {
  permission?: Permission | null;
  user?: UserUpdateOneRequiredWithoutFarmPermissionsInput | null;
}

export interface FarmPermissionUpdateWithoutUserDataInput {
  permission?: Permission | null;
  farm?: FarmUpdateOneRequiredWithoutFarmPermissionsInput | null;
}

export interface FarmPermissionUpsertWithWhereUniqueWithoutFarmInput {
  where: FarmPermissionWhereUniqueInput;
  update: FarmPermissionUpdateWithoutFarmDataInput;
  create: FarmPermissionCreateWithoutFarmInput;
}

export interface FarmPermissionUpsertWithWhereUniqueWithoutUserInput {
  where: FarmPermissionWhereUniqueInput;
  update: FarmPermissionUpdateWithoutUserDataInput;
  create: FarmPermissionCreateWithoutUserInput;
}

export interface FarmPermissionWhereInput {
  AND?: FarmPermissionWhereInput[] | null;
  OR?: FarmPermissionWhereInput[] | null;
  NOT?: FarmPermissionWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  permission?: Permission | null;
  permission_not?: Permission | null;
  permission_in?: Permission[] | null;
  permission_not_in?: Permission[] | null;
  user?: UserWhereInput | null;
  farm?: FarmWhereInput | null;
}

export interface FarmPermissionWhereUniqueInput {
  id?: string | null;
}

export interface FarmScalarWhereInput {
  AND?: FarmScalarWhereInput[] | null;
  OR?: FarmScalarWhereInput[] | null;
  NOT?: FarmScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  code?: string | null;
  code_not?: string | null;
  code_in?: string[] | null;
  code_not_in?: string[] | null;
  code_lt?: string | null;
  code_lte?: string | null;
  code_gt?: string | null;
  code_gte?: string | null;
  code_contains?: string | null;
  code_not_contains?: string | null;
  code_starts_with?: string | null;
  code_not_starts_with?: string | null;
  code_ends_with?: string | null;
  code_not_ends_with?: string | null;
  email?: string | null;
  email_not?: string | null;
  email_in?: string[] | null;
  email_not_in?: string[] | null;
  email_lt?: string | null;
  email_lte?: string | null;
  email_gt?: string | null;
  email_gte?: string | null;
  email_contains?: string | null;
  email_not_contains?: string | null;
  email_starts_with?: string | null;
  email_not_starts_with?: string | null;
  email_ends_with?: string | null;
  email_not_ends_with?: string | null;
  description?: string | null;
  description_not?: string | null;
  description_in?: string[] | null;
  description_not_in?: string[] | null;
  description_lt?: string | null;
  description_lte?: string | null;
  description_gt?: string | null;
  description_gte?: string | null;
  description_contains?: string | null;
  description_not_contains?: string | null;
  description_starts_with?: string | null;
  description_not_starts_with?: string | null;
  description_ends_with?: string | null;
  description_not_ends_with?: string | null;
  bankAccountNumber?: string | null;
  bankAccountNumber_not?: string | null;
  bankAccountNumber_in?: string[] | null;
  bankAccountNumber_not_in?: string[] | null;
  bankAccountNumber_lt?: string | null;
  bankAccountNumber_lte?: string | null;
  bankAccountNumber_gt?: string | null;
  bankAccountNumber_gte?: string | null;
  bankAccountNumber_contains?: string | null;
  bankAccountNumber_not_contains?: string | null;
  bankAccountNumber_starts_with?: string | null;
  bankAccountNumber_not_starts_with?: string | null;
  bankAccountNumber_ends_with?: string | null;
  bankAccountNumber_not_ends_with?: string | null;
  logo?: string | null;
  logo_not?: string | null;
  logo_in?: string[] | null;
  logo_not_in?: string[] | null;
  logo_lt?: string | null;
  logo_lte?: string | null;
  logo_gt?: string | null;
  logo_gte?: string | null;
  logo_contains?: string | null;
  logo_not_contains?: string | null;
  logo_starts_with?: string | null;
  logo_not_starts_with?: string | null;
  logo_ends_with?: string | null;
  logo_not_ends_with?: string | null;
}

export interface FarmUpdateManyDataInput {
  name?: string | null;
  code?: string | null;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
}

export interface FarmUpdateManyWithWhereNestedInput {
  where: FarmScalarWhereInput;
  data: FarmUpdateManyDataInput;
}

export interface FarmUpdateManyWithoutUsersInput {
  create?: FarmCreateWithoutUsersInput[] | null;
  connect?: FarmWhereUniqueInput[] | null;
  set?: FarmWhereUniqueInput[] | null;
  disconnect?: FarmWhereUniqueInput[] | null;
  delete?: FarmWhereUniqueInput[] | null;
  update?: FarmUpdateWithWhereUniqueWithoutUsersInput[] | null;
  updateMany?: FarmUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: FarmScalarWhereInput[] | null;
  upsert?: FarmUpsertWithWhereUniqueWithoutUsersInput[] | null;
}

export interface FarmUpdateOneRequiredWithoutClientsInput {
  create?: FarmCreateWithoutClientsInput | null;
  connect?: FarmWhereUniqueInput | null;
  update?: FarmUpdateWithoutClientsDataInput | null;
  upsert?: FarmUpsertWithoutClientsInput | null;
}

export interface FarmUpdateOneRequiredWithoutConfigurationInput {
  create?: FarmCreateWithoutConfigurationInput | null;
  connect?: FarmWhereUniqueInput | null;
  update?: FarmUpdateWithoutConfigurationDataInput | null;
  upsert?: FarmUpsertWithoutConfigurationInput | null;
}

export interface FarmUpdateOneRequiredWithoutFarmPermissionsInput {
  create?: FarmCreateWithoutFarmPermissionsInput | null;
  connect?: FarmWhereUniqueInput | null;
  update?: FarmUpdateWithoutFarmPermissionsDataInput | null;
  upsert?: FarmUpsertWithoutFarmPermissionsInput | null;
}

export interface FarmUpdateOneRequiredWithoutLocationsInput {
  create?: FarmCreateWithoutLocationsInput | null;
  connect?: FarmWhereUniqueInput | null;
  update?: FarmUpdateWithoutLocationsDataInput | null;
  upsert?: FarmUpsertWithoutLocationsInput | null;
}

export interface FarmUpdateOneRequiredWithoutProductsInput {
  create?: FarmCreateWithoutProductsInput | null;
  connect?: FarmWhereUniqueInput | null;
  update?: FarmUpdateWithoutProductsDataInput | null;
  upsert?: FarmUpsertWithoutProductsInput | null;
}

export interface FarmUpdateOneRequiredWithoutRotationGroupsInput {
  create?: FarmCreateWithoutRotationGroupsInput | null;
  connect?: FarmWhereUniqueInput | null;
  update?: FarmUpdateWithoutRotationGroupsDataInput | null;
  upsert?: FarmUpsertWithoutRotationGroupsInput | null;
}

export interface FarmUpdateOneWithoutBasketCompositionsInput {
  create?: FarmCreateWithoutBasketCompositionsInput | null;
  connect?: FarmWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: FarmUpdateWithoutBasketCompositionsDataInput | null;
  upsert?: FarmUpsertWithoutBasketCompositionsInput | null;
}

export interface FarmUpdateOneWithoutClientGroupsInput {
  create?: FarmCreateWithoutClientGroupsInput | null;
  connect?: FarmWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: FarmUpdateWithoutClientGroupsDataInput | null;
  upsert?: FarmUpsertWithoutClientGroupsInput | null;
}

export interface FarmUpdateWithWhereUniqueWithoutUsersInput {
  where: FarmWhereUniqueInput;
  data: FarmUpdateWithoutUsersDataInput;
}

export interface FarmUpdateWithoutBasketCompositionsDataInput {
  name?: string | null;
  code?: string | null;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  locations?: FarmLocationUpdateManyWithoutFarmInput | null;
  users?: UserUpdateManyWithoutFarmsInput | null;
  rotationGroups?: RotationGroupUpdateManyWithoutFarmInput | null;
  orderItems?: OrderItemUpdateManyWithoutFarmInput | null;
  clientGroups?: ClientGroupUpdateManyWithoutFarmInput | null;
  clients?: ClientUpdateManyWithoutFarmInput | null;
  configuration?: FarmConfigUpdateManyWithoutFarmInput | null;
  products?: ProductUpdateManyWithoutFarmInput | null;
  sequences?: SequenceUpdateManyWithoutFarmInput | null;
  farmPermissions?: FarmPermissionUpdateManyWithoutFarmInput | null;
}

export interface FarmUpdateWithoutClientGroupsDataInput {
  name?: string | null;
  code?: string | null;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  locations?: FarmLocationUpdateManyWithoutFarmInput | null;
  users?: UserUpdateManyWithoutFarmsInput | null;
  rotationGroups?: RotationGroupUpdateManyWithoutFarmInput | null;
  orderItems?: OrderItemUpdateManyWithoutFarmInput | null;
  clients?: ClientUpdateManyWithoutFarmInput | null;
  configuration?: FarmConfigUpdateManyWithoutFarmInput | null;
  products?: ProductUpdateManyWithoutFarmInput | null;
  sequences?: SequenceUpdateManyWithoutFarmInput | null;
  basketCompositions?: BasketCompositionUpdateManyWithoutFarmInput | null;
  farmPermissions?: FarmPermissionUpdateManyWithoutFarmInput | null;
}

export interface FarmUpdateWithoutClientsDataInput {
  name?: string | null;
  code?: string | null;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  locations?: FarmLocationUpdateManyWithoutFarmInput | null;
  users?: UserUpdateManyWithoutFarmsInput | null;
  rotationGroups?: RotationGroupUpdateManyWithoutFarmInput | null;
  orderItems?: OrderItemUpdateManyWithoutFarmInput | null;
  clientGroups?: ClientGroupUpdateManyWithoutFarmInput | null;
  configuration?: FarmConfigUpdateManyWithoutFarmInput | null;
  products?: ProductUpdateManyWithoutFarmInput | null;
  sequences?: SequenceUpdateManyWithoutFarmInput | null;
  basketCompositions?: BasketCompositionUpdateManyWithoutFarmInput | null;
  farmPermissions?: FarmPermissionUpdateManyWithoutFarmInput | null;
}

export interface FarmUpdateWithoutConfigurationDataInput {
  name?: string | null;
  code?: string | null;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  locations?: FarmLocationUpdateManyWithoutFarmInput | null;
  users?: UserUpdateManyWithoutFarmsInput | null;
  rotationGroups?: RotationGroupUpdateManyWithoutFarmInput | null;
  orderItems?: OrderItemUpdateManyWithoutFarmInput | null;
  clientGroups?: ClientGroupUpdateManyWithoutFarmInput | null;
  clients?: ClientUpdateManyWithoutFarmInput | null;
  products?: ProductUpdateManyWithoutFarmInput | null;
  sequences?: SequenceUpdateManyWithoutFarmInput | null;
  basketCompositions?: BasketCompositionUpdateManyWithoutFarmInput | null;
  farmPermissions?: FarmPermissionUpdateManyWithoutFarmInput | null;
}

export interface FarmUpdateWithoutFarmPermissionsDataInput {
  name?: string | null;
  code?: string | null;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  locations?: FarmLocationUpdateManyWithoutFarmInput | null;
  users?: UserUpdateManyWithoutFarmsInput | null;
  rotationGroups?: RotationGroupUpdateManyWithoutFarmInput | null;
  orderItems?: OrderItemUpdateManyWithoutFarmInput | null;
  clientGroups?: ClientGroupUpdateManyWithoutFarmInput | null;
  clients?: ClientUpdateManyWithoutFarmInput | null;
  configuration?: FarmConfigUpdateManyWithoutFarmInput | null;
  products?: ProductUpdateManyWithoutFarmInput | null;
  sequences?: SequenceUpdateManyWithoutFarmInput | null;
  basketCompositions?: BasketCompositionUpdateManyWithoutFarmInput | null;
}

export interface FarmUpdateWithoutLocationsDataInput {
  name?: string | null;
  code?: string | null;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  users?: UserUpdateManyWithoutFarmsInput | null;
  rotationGroups?: RotationGroupUpdateManyWithoutFarmInput | null;
  orderItems?: OrderItemUpdateManyWithoutFarmInput | null;
  clientGroups?: ClientGroupUpdateManyWithoutFarmInput | null;
  clients?: ClientUpdateManyWithoutFarmInput | null;
  configuration?: FarmConfigUpdateManyWithoutFarmInput | null;
  products?: ProductUpdateManyWithoutFarmInput | null;
  sequences?: SequenceUpdateManyWithoutFarmInput | null;
  basketCompositions?: BasketCompositionUpdateManyWithoutFarmInput | null;
  farmPermissions?: FarmPermissionUpdateManyWithoutFarmInput | null;
}

export interface FarmUpdateWithoutProductsDataInput {
  name?: string | null;
  code?: string | null;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  locations?: FarmLocationUpdateManyWithoutFarmInput | null;
  users?: UserUpdateManyWithoutFarmsInput | null;
  rotationGroups?: RotationGroupUpdateManyWithoutFarmInput | null;
  orderItems?: OrderItemUpdateManyWithoutFarmInput | null;
  clientGroups?: ClientGroupUpdateManyWithoutFarmInput | null;
  clients?: ClientUpdateManyWithoutFarmInput | null;
  configuration?: FarmConfigUpdateManyWithoutFarmInput | null;
  sequences?: SequenceUpdateManyWithoutFarmInput | null;
  basketCompositions?: BasketCompositionUpdateManyWithoutFarmInput | null;
  farmPermissions?: FarmPermissionUpdateManyWithoutFarmInput | null;
}

export interface FarmUpdateWithoutRotationGroupsDataInput {
  name?: string | null;
  code?: string | null;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  locations?: FarmLocationUpdateManyWithoutFarmInput | null;
  users?: UserUpdateManyWithoutFarmsInput | null;
  orderItems?: OrderItemUpdateManyWithoutFarmInput | null;
  clientGroups?: ClientGroupUpdateManyWithoutFarmInput | null;
  clients?: ClientUpdateManyWithoutFarmInput | null;
  configuration?: FarmConfigUpdateManyWithoutFarmInput | null;
  products?: ProductUpdateManyWithoutFarmInput | null;
  sequences?: SequenceUpdateManyWithoutFarmInput | null;
  basketCompositions?: BasketCompositionUpdateManyWithoutFarmInput | null;
  farmPermissions?: FarmPermissionUpdateManyWithoutFarmInput | null;
}

export interface FarmUpdateWithoutUsersDataInput {
  name?: string | null;
  code?: string | null;
  email?: string | null;
  description?: string | null;
  bankAccountNumber?: string | null;
  logo?: string | null;
  locations?: FarmLocationUpdateManyWithoutFarmInput | null;
  rotationGroups?: RotationGroupUpdateManyWithoutFarmInput | null;
  orderItems?: OrderItemUpdateManyWithoutFarmInput | null;
  clientGroups?: ClientGroupUpdateManyWithoutFarmInput | null;
  clients?: ClientUpdateManyWithoutFarmInput | null;
  configuration?: FarmConfigUpdateManyWithoutFarmInput | null;
  products?: ProductUpdateManyWithoutFarmInput | null;
  sequences?: SequenceUpdateManyWithoutFarmInput | null;
  basketCompositions?: BasketCompositionUpdateManyWithoutFarmInput | null;
  farmPermissions?: FarmPermissionUpdateManyWithoutFarmInput | null;
}

export interface FarmUpsertWithWhereUniqueWithoutUsersInput {
  where: FarmWhereUniqueInput;
  update: FarmUpdateWithoutUsersDataInput;
  create: FarmCreateWithoutUsersInput;
}

export interface FarmUpsertWithoutBasketCompositionsInput {
  update: FarmUpdateWithoutBasketCompositionsDataInput;
  create: FarmCreateWithoutBasketCompositionsInput;
}

export interface FarmUpsertWithoutClientGroupsInput {
  update: FarmUpdateWithoutClientGroupsDataInput;
  create: FarmCreateWithoutClientGroupsInput;
}

export interface FarmUpsertWithoutClientsInput {
  update: FarmUpdateWithoutClientsDataInput;
  create: FarmCreateWithoutClientsInput;
}

export interface FarmUpsertWithoutConfigurationInput {
  update: FarmUpdateWithoutConfigurationDataInput;
  create: FarmCreateWithoutConfigurationInput;
}

export interface FarmUpsertWithoutFarmPermissionsInput {
  update: FarmUpdateWithoutFarmPermissionsDataInput;
  create: FarmCreateWithoutFarmPermissionsInput;
}

export interface FarmUpsertWithoutLocationsInput {
  update: FarmUpdateWithoutLocationsDataInput;
  create: FarmCreateWithoutLocationsInput;
}

export interface FarmUpsertWithoutProductsInput {
  update: FarmUpdateWithoutProductsDataInput;
  create: FarmCreateWithoutProductsInput;
}

export interface FarmUpsertWithoutRotationGroupsInput {
  update: FarmUpdateWithoutRotationGroupsDataInput;
  create: FarmCreateWithoutRotationGroupsInput;
}

export interface FarmWhereInput {
  AND?: FarmWhereInput[] | null;
  OR?: FarmWhereInput[] | null;
  NOT?: FarmWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  code?: string | null;
  code_not?: string | null;
  code_in?: string[] | null;
  code_not_in?: string[] | null;
  code_lt?: string | null;
  code_lte?: string | null;
  code_gt?: string | null;
  code_gte?: string | null;
  code_contains?: string | null;
  code_not_contains?: string | null;
  code_starts_with?: string | null;
  code_not_starts_with?: string | null;
  code_ends_with?: string | null;
  code_not_ends_with?: string | null;
  email?: string | null;
  email_not?: string | null;
  email_in?: string[] | null;
  email_not_in?: string[] | null;
  email_lt?: string | null;
  email_lte?: string | null;
  email_gt?: string | null;
  email_gte?: string | null;
  email_contains?: string | null;
  email_not_contains?: string | null;
  email_starts_with?: string | null;
  email_not_starts_with?: string | null;
  email_ends_with?: string | null;
  email_not_ends_with?: string | null;
  description?: string | null;
  description_not?: string | null;
  description_in?: string[] | null;
  description_not_in?: string[] | null;
  description_lt?: string | null;
  description_lte?: string | null;
  description_gt?: string | null;
  description_gte?: string | null;
  description_contains?: string | null;
  description_not_contains?: string | null;
  description_starts_with?: string | null;
  description_not_starts_with?: string | null;
  description_ends_with?: string | null;
  description_not_ends_with?: string | null;
  bankAccountNumber?: string | null;
  bankAccountNumber_not?: string | null;
  bankAccountNumber_in?: string[] | null;
  bankAccountNumber_not_in?: string[] | null;
  bankAccountNumber_lt?: string | null;
  bankAccountNumber_lte?: string | null;
  bankAccountNumber_gt?: string | null;
  bankAccountNumber_gte?: string | null;
  bankAccountNumber_contains?: string | null;
  bankAccountNumber_not_contains?: string | null;
  bankAccountNumber_starts_with?: string | null;
  bankAccountNumber_not_starts_with?: string | null;
  bankAccountNumber_ends_with?: string | null;
  bankAccountNumber_not_ends_with?: string | null;
  logo?: string | null;
  logo_not?: string | null;
  logo_in?: string[] | null;
  logo_not_in?: string[] | null;
  logo_lt?: string | null;
  logo_lte?: string | null;
  logo_gt?: string | null;
  logo_gte?: string | null;
  logo_contains?: string | null;
  logo_not_contains?: string | null;
  logo_starts_with?: string | null;
  logo_not_starts_with?: string | null;
  logo_ends_with?: string | null;
  logo_not_ends_with?: string | null;
  locations_every?: FarmLocationWhereInput | null;
  locations_some?: FarmLocationWhereInput | null;
  locations_none?: FarmLocationWhereInput | null;
  users_every?: UserWhereInput | null;
  users_some?: UserWhereInput | null;
  users_none?: UserWhereInput | null;
  rotationGroups_every?: RotationGroupWhereInput | null;
  rotationGroups_some?: RotationGroupWhereInput | null;
  rotationGroups_none?: RotationGroupWhereInput | null;
  orderItems_every?: OrderItemWhereInput | null;
  orderItems_some?: OrderItemWhereInput | null;
  orderItems_none?: OrderItemWhereInput | null;
  clientGroups_every?: ClientGroupWhereInput | null;
  clientGroups_some?: ClientGroupWhereInput | null;
  clientGroups_none?: ClientGroupWhereInput | null;
  clients_every?: ClientWhereInput | null;
  clients_some?: ClientWhereInput | null;
  clients_none?: ClientWhereInput | null;
  configuration_every?: FarmConfigWhereInput | null;
  configuration_some?: FarmConfigWhereInput | null;
  configuration_none?: FarmConfigWhereInput | null;
  products_every?: ProductWhereInput | null;
  products_some?: ProductWhereInput | null;
  products_none?: ProductWhereInput | null;
  sequences_every?: SequenceWhereInput | null;
  sequences_some?: SequenceWhereInput | null;
  sequences_none?: SequenceWhereInput | null;
  basketCompositions_every?: BasketCompositionWhereInput | null;
  basketCompositions_some?: BasketCompositionWhereInput | null;
  basketCompositions_none?: BasketCompositionWhereInput | null;
  farmPermissions_every?: FarmPermissionWhereInput | null;
  farmPermissions_some?: FarmPermissionWhereInput | null;
  farmPermissions_none?: FarmPermissionWhereInput | null;
}

export interface FarmWhereUniqueInput {
  id?: string | null;
  code?: string | null;
}

export interface FieldCreateManyWithoutLocationInput {
  create?: FieldCreateWithoutLocationInput[] | null;
  connect?: FieldWhereUniqueInput[] | null;
}

export interface FieldCreateOneWithoutRotationAreasInput {
  create?: FieldCreateWithoutRotationAreasInput | null;
  connect?: FieldWhereUniqueInput | null;
}

export interface FieldCreateWithoutLocationInput {
  id?: string | null;
  name: string;
  prefix: string;
  maxRotationAreas: number;
  rotationAreaSize: number;
  target: Target;
  type: FieldType;
  bedLength: number;
  bedWidth?: number | null;
  orderNumber?: number | null;
  active?: boolean | null;
  rotationAreas?: RotationAreaCreateManyWithoutFieldInput | null;
}

export interface FieldCreateWithoutRotationAreasInput {
  id?: string | null;
  name: string;
  prefix: string;
  maxRotationAreas: number;
  rotationAreaSize: number;
  target: Target;
  type: FieldType;
  bedLength: number;
  bedWidth?: number | null;
  orderNumber?: number | null;
  active?: boolean | null;
  location?: FarmLocationCreateOneWithoutFieldsInput | null;
}

export interface FieldScalarWhereInput {
  AND?: FieldScalarWhereInput[] | null;
  OR?: FieldScalarWhereInput[] | null;
  NOT?: FieldScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  prefix?: string | null;
  prefix_not?: string | null;
  prefix_in?: string[] | null;
  prefix_not_in?: string[] | null;
  prefix_lt?: string | null;
  prefix_lte?: string | null;
  prefix_gt?: string | null;
  prefix_gte?: string | null;
  prefix_contains?: string | null;
  prefix_not_contains?: string | null;
  prefix_starts_with?: string | null;
  prefix_not_starts_with?: string | null;
  prefix_ends_with?: string | null;
  prefix_not_ends_with?: string | null;
  maxRotationAreas?: number | null;
  maxRotationAreas_not?: number | null;
  maxRotationAreas_in?: number[] | null;
  maxRotationAreas_not_in?: number[] | null;
  maxRotationAreas_lt?: number | null;
  maxRotationAreas_lte?: number | null;
  maxRotationAreas_gt?: number | null;
  maxRotationAreas_gte?: number | null;
  rotationAreaSize?: number | null;
  rotationAreaSize_not?: number | null;
  rotationAreaSize_in?: number[] | null;
  rotationAreaSize_not_in?: number[] | null;
  rotationAreaSize_lt?: number | null;
  rotationAreaSize_lte?: number | null;
  rotationAreaSize_gt?: number | null;
  rotationAreaSize_gte?: number | null;
  target?: Target | null;
  target_not?: Target | null;
  target_in?: Target[] | null;
  target_not_in?: Target[] | null;
  type?: FieldType | null;
  type_not?: FieldType | null;
  type_in?: FieldType[] | null;
  type_not_in?: FieldType[] | null;
  bedLength?: number | null;
  bedLength_not?: number | null;
  bedLength_in?: number[] | null;
  bedLength_not_in?: number[] | null;
  bedLength_lt?: number | null;
  bedLength_lte?: number | null;
  bedLength_gt?: number | null;
  bedLength_gte?: number | null;
  bedWidth?: number | null;
  bedWidth_not?: number | null;
  bedWidth_in?: number[] | null;
  bedWidth_not_in?: number[] | null;
  bedWidth_lt?: number | null;
  bedWidth_lte?: number | null;
  bedWidth_gt?: number | null;
  bedWidth_gte?: number | null;
  orderNumber?: number | null;
  orderNumber_not?: number | null;
  orderNumber_in?: number[] | null;
  orderNumber_not_in?: number[] | null;
  orderNumber_lt?: number | null;
  orderNumber_lte?: number | null;
  orderNumber_gt?: number | null;
  orderNumber_gte?: number | null;
  active?: boolean | null;
  active_not?: boolean | null;
}

export interface FieldUpdateManyDataInput {
  name?: string | null;
  prefix?: string | null;
  maxRotationAreas?: number | null;
  rotationAreaSize?: number | null;
  target?: Target | null;
  type?: FieldType | null;
  bedLength?: number | null;
  bedWidth?: number | null;
  orderNumber?: number | null;
  active?: boolean | null;
}

export interface FieldUpdateManyWithWhereNestedInput {
  where: FieldScalarWhereInput;
  data: FieldUpdateManyDataInput;
}

export interface FieldUpdateManyWithoutLocationInput {
  create?: FieldCreateWithoutLocationInput[] | null;
  connect?: FieldWhereUniqueInput[] | null;
  set?: FieldWhereUniqueInput[] | null;
  disconnect?: FieldWhereUniqueInput[] | null;
  delete?: FieldWhereUniqueInput[] | null;
  update?: FieldUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: FieldUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: FieldScalarWhereInput[] | null;
  upsert?: FieldUpsertWithWhereUniqueWithoutLocationInput[] | null;
}

export interface FieldUpdateOneRequiredWithoutRotationAreasInput {
  create?: FieldCreateWithoutRotationAreasInput | null;
  connect?: FieldWhereUniqueInput | null;
  update?: FieldUpdateWithoutRotationAreasDataInput | null;
  upsert?: FieldUpsertWithoutRotationAreasInput | null;
}

export interface FieldUpdateWithWhereUniqueWithoutLocationInput {
  where: FieldWhereUniqueInput;
  data: FieldUpdateWithoutLocationDataInput;
}

export interface FieldUpdateWithoutLocationDataInput {
  name?: string | null;
  prefix?: string | null;
  maxRotationAreas?: number | null;
  rotationAreaSize?: number | null;
  target?: Target | null;
  type?: FieldType | null;
  bedLength?: number | null;
  bedWidth?: number | null;
  orderNumber?: number | null;
  active?: boolean | null;
  rotationAreas?: RotationAreaUpdateManyWithoutFieldInput | null;
}

export interface FieldUpdateWithoutRotationAreasDataInput {
  name?: string | null;
  prefix?: string | null;
  maxRotationAreas?: number | null;
  rotationAreaSize?: number | null;
  target?: Target | null;
  type?: FieldType | null;
  bedLength?: number | null;
  bedWidth?: number | null;
  orderNumber?: number | null;
  active?: boolean | null;
  location?: FarmLocationUpdateOneWithoutFieldsInput | null;
}

export interface FieldUpsertWithWhereUniqueWithoutLocationInput {
  where: FieldWhereUniqueInput;
  update: FieldUpdateWithoutLocationDataInput;
  create: FieldCreateWithoutLocationInput;
}

export interface FieldUpsertWithoutRotationAreasInput {
  update: FieldUpdateWithoutRotationAreasDataInput;
  create: FieldCreateWithoutRotationAreasInput;
}

export interface FieldWhereInput {
  AND?: FieldWhereInput[] | null;
  OR?: FieldWhereInput[] | null;
  NOT?: FieldWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  prefix?: string | null;
  prefix_not?: string | null;
  prefix_in?: string[] | null;
  prefix_not_in?: string[] | null;
  prefix_lt?: string | null;
  prefix_lte?: string | null;
  prefix_gt?: string | null;
  prefix_gte?: string | null;
  prefix_contains?: string | null;
  prefix_not_contains?: string | null;
  prefix_starts_with?: string | null;
  prefix_not_starts_with?: string | null;
  prefix_ends_with?: string | null;
  prefix_not_ends_with?: string | null;
  maxRotationAreas?: number | null;
  maxRotationAreas_not?: number | null;
  maxRotationAreas_in?: number[] | null;
  maxRotationAreas_not_in?: number[] | null;
  maxRotationAreas_lt?: number | null;
  maxRotationAreas_lte?: number | null;
  maxRotationAreas_gt?: number | null;
  maxRotationAreas_gte?: number | null;
  rotationAreaSize?: number | null;
  rotationAreaSize_not?: number | null;
  rotationAreaSize_in?: number[] | null;
  rotationAreaSize_not_in?: number[] | null;
  rotationAreaSize_lt?: number | null;
  rotationAreaSize_lte?: number | null;
  rotationAreaSize_gt?: number | null;
  rotationAreaSize_gte?: number | null;
  target?: Target | null;
  target_not?: Target | null;
  target_in?: Target[] | null;
  target_not_in?: Target[] | null;
  type?: FieldType | null;
  type_not?: FieldType | null;
  type_in?: FieldType[] | null;
  type_not_in?: FieldType[] | null;
  bedLength?: number | null;
  bedLength_not?: number | null;
  bedLength_in?: number[] | null;
  bedLength_not_in?: number[] | null;
  bedLength_lt?: number | null;
  bedLength_lte?: number | null;
  bedLength_gt?: number | null;
  bedLength_gte?: number | null;
  bedWidth?: number | null;
  bedWidth_not?: number | null;
  bedWidth_in?: number[] | null;
  bedWidth_not_in?: number[] | null;
  bedWidth_lt?: number | null;
  bedWidth_lte?: number | null;
  bedWidth_gt?: number | null;
  bedWidth_gte?: number | null;
  orderNumber?: number | null;
  orderNumber_not?: number | null;
  orderNumber_in?: number[] | null;
  orderNumber_not_in?: number[] | null;
  orderNumber_lt?: number | null;
  orderNumber_lte?: number | null;
  orderNumber_gt?: number | null;
  orderNumber_gte?: number | null;
  active?: boolean | null;
  active_not?: boolean | null;
  location?: FarmLocationWhereInput | null;
  rotationAreas_every?: RotationAreaWhereInput | null;
  rotationAreas_some?: RotationAreaWhereInput | null;
  rotationAreas_none?: RotationAreaWhereInput | null;
}

export interface FieldWhereUniqueInput {
  id?: string | null;
  name?: string | null;
  prefix?: string | null;
}

export interface GoogleShopOrderCreateInput {
  id?: string | null;
  orderDate: any;
  deliveryDate: any;
  amount: number;
  status: GoogleShopStatus;
  membership: MembershipCreateOneWithoutGoogleShopOrdersInput;
  product: GoogleShopProductCreateOneWithoutOrdersInput;
  invoice?: InvoiceCreateOneInput | null;
}

export interface GoogleShopOrderCreateManyInput {
  create?: GoogleShopOrderCreateInput[] | null;
  connect?: GoogleShopOrderWhereUniqueInput[] | null;
}

export interface GoogleShopOrderCreateManyWithoutMembershipInput {
  create?: GoogleShopOrderCreateWithoutMembershipInput[] | null;
  connect?: GoogleShopOrderWhereUniqueInput[] | null;
}

export interface GoogleShopOrderCreateWithoutMembershipInput {
  id?: string | null;
  orderDate: any;
  deliveryDate: any;
  amount: number;
  status: GoogleShopStatus;
  product: GoogleShopProductCreateOneWithoutOrdersInput;
  invoice?: InvoiceCreateOneInput | null;
}

export interface GoogleShopOrderScalarWhereInput {
  AND?: GoogleShopOrderScalarWhereInput[] | null;
  OR?: GoogleShopOrderScalarWhereInput[] | null;
  NOT?: GoogleShopOrderScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  orderDate?: any | null;
  orderDate_not?: any | null;
  orderDate_in?: any[] | null;
  orderDate_not_in?: any[] | null;
  orderDate_lt?: any | null;
  orderDate_lte?: any | null;
  orderDate_gt?: any | null;
  orderDate_gte?: any | null;
  deliveryDate?: any | null;
  deliveryDate_not?: any | null;
  deliveryDate_in?: any[] | null;
  deliveryDate_not_in?: any[] | null;
  deliveryDate_lt?: any | null;
  deliveryDate_lte?: any | null;
  deliveryDate_gt?: any | null;
  deliveryDate_gte?: any | null;
  amount?: number | null;
  amount_not?: number | null;
  amount_in?: number[] | null;
  amount_not_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  status?: GoogleShopStatus | null;
  status_not?: GoogleShopStatus | null;
  status_in?: GoogleShopStatus[] | null;
  status_not_in?: GoogleShopStatus[] | null;
}

export interface GoogleShopOrderUpdateDataInput {
  orderDate?: any | null;
  deliveryDate?: any | null;
  amount?: number | null;
  status?: GoogleShopStatus | null;
  membership?: MembershipUpdateOneRequiredWithoutGoogleShopOrdersInput | null;
  product?: GoogleShopProductUpdateOneRequiredWithoutOrdersInput | null;
  invoice?: InvoiceUpdateOneInput | null;
}

export interface GoogleShopOrderUpdateManyDataInput {
  orderDate?: any | null;
  deliveryDate?: any | null;
  amount?: number | null;
  status?: GoogleShopStatus | null;
}

export interface GoogleShopOrderUpdateManyInput {
  create?: GoogleShopOrderCreateInput[] | null;
  connect?: GoogleShopOrderWhereUniqueInput[] | null;
  set?: GoogleShopOrderWhereUniqueInput[] | null;
  disconnect?: GoogleShopOrderWhereUniqueInput[] | null;
  delete?: GoogleShopOrderWhereUniqueInput[] | null;
  update?: GoogleShopOrderUpdateWithWhereUniqueNestedInput[] | null;
  updateMany?: GoogleShopOrderUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: GoogleShopOrderScalarWhereInput[] | null;
  upsert?: GoogleShopOrderUpsertWithWhereUniqueNestedInput[] | null;
}

export interface GoogleShopOrderUpdateManyWithWhereNestedInput {
  where: GoogleShopOrderScalarWhereInput;
  data: GoogleShopOrderUpdateManyDataInput;
}

export interface GoogleShopOrderUpdateManyWithoutMembershipInput {
  create?: GoogleShopOrderCreateWithoutMembershipInput[] | null;
  connect?: GoogleShopOrderWhereUniqueInput[] | null;
  set?: GoogleShopOrderWhereUniqueInput[] | null;
  disconnect?: GoogleShopOrderWhereUniqueInput[] | null;
  delete?: GoogleShopOrderWhereUniqueInput[] | null;
  update?: GoogleShopOrderUpdateWithWhereUniqueWithoutMembershipInput[] | null;
  updateMany?: GoogleShopOrderUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: GoogleShopOrderScalarWhereInput[] | null;
  upsert?: GoogleShopOrderUpsertWithWhereUniqueWithoutMembershipInput[] | null;
}

export interface GoogleShopOrderUpdateWithWhereUniqueNestedInput {
  where: GoogleShopOrderWhereUniqueInput;
  data: GoogleShopOrderUpdateDataInput;
}

export interface GoogleShopOrderUpdateWithWhereUniqueWithoutMembershipInput {
  where: GoogleShopOrderWhereUniqueInput;
  data: GoogleShopOrderUpdateWithoutMembershipDataInput;
}

export interface GoogleShopOrderUpdateWithoutMembershipDataInput {
  orderDate?: any | null;
  deliveryDate?: any | null;
  amount?: number | null;
  status?: GoogleShopStatus | null;
  product?: GoogleShopProductUpdateOneRequiredWithoutOrdersInput | null;
  invoice?: InvoiceUpdateOneInput | null;
}

export interface GoogleShopOrderUpsertWithWhereUniqueNestedInput {
  where: GoogleShopOrderWhereUniqueInput;
  update: GoogleShopOrderUpdateDataInput;
  create: GoogleShopOrderCreateInput;
}

export interface GoogleShopOrderUpsertWithWhereUniqueWithoutMembershipInput {
  where: GoogleShopOrderWhereUniqueInput;
  update: GoogleShopOrderUpdateWithoutMembershipDataInput;
  create: GoogleShopOrderCreateWithoutMembershipInput;
}

export interface GoogleShopOrderWhereInput {
  AND?: GoogleShopOrderWhereInput[] | null;
  OR?: GoogleShopOrderWhereInput[] | null;
  NOT?: GoogleShopOrderWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  orderDate?: any | null;
  orderDate_not?: any | null;
  orderDate_in?: any[] | null;
  orderDate_not_in?: any[] | null;
  orderDate_lt?: any | null;
  orderDate_lte?: any | null;
  orderDate_gt?: any | null;
  orderDate_gte?: any | null;
  deliveryDate?: any | null;
  deliveryDate_not?: any | null;
  deliveryDate_in?: any[] | null;
  deliveryDate_not_in?: any[] | null;
  deliveryDate_lt?: any | null;
  deliveryDate_lte?: any | null;
  deliveryDate_gt?: any | null;
  deliveryDate_gte?: any | null;
  amount?: number | null;
  amount_not?: number | null;
  amount_in?: number[] | null;
  amount_not_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  status?: GoogleShopStatus | null;
  status_not?: GoogleShopStatus | null;
  status_in?: GoogleShopStatus[] | null;
  status_not_in?: GoogleShopStatus[] | null;
  membership?: MembershipWhereInput | null;
  product?: GoogleShopProductWhereInput | null;
  invoice?: InvoiceWhereInput | null;
}

export interface GoogleShopOrderWhereUniqueInput {
  id?: string | null;
}

export interface GoogleShopProductCreateOneWithoutOrdersInput {
  create?: GoogleShopProductCreateWithoutOrdersInput | null;
  connect?: GoogleShopProductWhereUniqueInput | null;
}

export interface GoogleShopProductCreateWithoutOrdersInput {
  id?: string | null;
  name: string;
  frequency: string;
  price: number;
  end?: string | null;
}

export interface GoogleShopProductUpdateOneRequiredWithoutOrdersInput {
  create?: GoogleShopProductCreateWithoutOrdersInput | null;
  connect?: GoogleShopProductWhereUniqueInput | null;
  update?: GoogleShopProductUpdateWithoutOrdersDataInput | null;
  upsert?: GoogleShopProductUpsertWithoutOrdersInput | null;
}

export interface GoogleShopProductUpdateWithoutOrdersDataInput {
  name?: string | null;
  frequency?: string | null;
  price?: number | null;
  end?: string | null;
}

export interface GoogleShopProductUpsertWithoutOrdersInput {
  update: GoogleShopProductUpdateWithoutOrdersDataInput;
  create: GoogleShopProductCreateWithoutOrdersInput;
}

export interface GoogleShopProductWhereInput {
  AND?: GoogleShopProductWhereInput[] | null;
  OR?: GoogleShopProductWhereInput[] | null;
  NOT?: GoogleShopProductWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  frequency?: string | null;
  frequency_not?: string | null;
  frequency_in?: string[] | null;
  frequency_not_in?: string[] | null;
  frequency_lt?: string | null;
  frequency_lte?: string | null;
  frequency_gt?: string | null;
  frequency_gte?: string | null;
  frequency_contains?: string | null;
  frequency_not_contains?: string | null;
  frequency_starts_with?: string | null;
  frequency_not_starts_with?: string | null;
  frequency_ends_with?: string | null;
  frequency_not_ends_with?: string | null;
  price?: number | null;
  price_not?: number | null;
  price_in?: number[] | null;
  price_not_in?: number[] | null;
  price_lt?: number | null;
  price_lte?: number | null;
  price_gt?: number | null;
  price_gte?: number | null;
  end?: string | null;
  end_not?: string | null;
  end_in?: string[] | null;
  end_not_in?: string[] | null;
  end_lt?: string | null;
  end_lte?: string | null;
  end_gt?: string | null;
  end_gte?: string | null;
  end_contains?: string | null;
  end_not_contains?: string | null;
  end_starts_with?: string | null;
  end_not_starts_with?: string | null;
  end_ends_with?: string | null;
  end_not_ends_with?: string | null;
  orders_every?: GoogleShopOrderWhereInput | null;
  orders_some?: GoogleShopOrderWhereInput | null;
  orders_none?: GoogleShopOrderWhereInput | null;
}

export interface GoogleShopProductWhereUniqueInput {
  id?: string | null;
  name?: string | null;
}

export interface InvoiceCreateInput {
  id?: string | null;
  number: string;
  filename: string;
  date?: any | null;
  file?: string | null;
  type: InvoiceType;
  data?: any | null;
  payments?: PaymentCreateManyWithoutInvoiceInput | null;
  googleshopOrders?: GoogleShopOrderCreateManyInput | null;
  clientOrders?: ClientOrderCreateManyWithoutInvoiceInput | null;
}

export interface InvoiceCreateManyInput {
  create?: InvoiceCreateInput[] | null;
  connect?: InvoiceWhereUniqueInput[] | null;
}

export interface InvoiceCreateOneInput {
  create?: InvoiceCreateInput | null;
  connect?: InvoiceWhereUniqueInput | null;
}

export interface InvoiceCreateOneWithoutClientOrdersInput {
  create?: InvoiceCreateWithoutClientOrdersInput | null;
  connect?: InvoiceWhereUniqueInput | null;
}

export interface InvoiceCreateWithoutClientOrdersInput {
  id?: string | null;
  number: string;
  filename: string;
  date?: any | null;
  file?: string | null;
  type: InvoiceType;
  data?: any | null;
  payments?: PaymentCreateManyWithoutInvoiceInput | null;
  googleshopOrders?: GoogleShopOrderCreateManyInput | null;
}

export interface InvoiceScalarWhereInput {
  AND?: InvoiceScalarWhereInput[] | null;
  OR?: InvoiceScalarWhereInput[] | null;
  NOT?: InvoiceScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  number?: string | null;
  number_not?: string | null;
  number_in?: string[] | null;
  number_not_in?: string[] | null;
  number_lt?: string | null;
  number_lte?: string | null;
  number_gt?: string | null;
  number_gte?: string | null;
  number_contains?: string | null;
  number_not_contains?: string | null;
  number_starts_with?: string | null;
  number_not_starts_with?: string | null;
  number_ends_with?: string | null;
  number_not_ends_with?: string | null;
  filename?: string | null;
  filename_not?: string | null;
  filename_in?: string[] | null;
  filename_not_in?: string[] | null;
  filename_lt?: string | null;
  filename_lte?: string | null;
  filename_gt?: string | null;
  filename_gte?: string | null;
  filename_contains?: string | null;
  filename_not_contains?: string | null;
  filename_starts_with?: string | null;
  filename_not_starts_with?: string | null;
  filename_ends_with?: string | null;
  filename_not_ends_with?: string | null;
  date?: any | null;
  date_not?: any | null;
  date_in?: any[] | null;
  date_not_in?: any[] | null;
  date_lt?: any | null;
  date_lte?: any | null;
  date_gt?: any | null;
  date_gte?: any | null;
  file?: string | null;
  file_not?: string | null;
  file_in?: string[] | null;
  file_not_in?: string[] | null;
  file_lt?: string | null;
  file_lte?: string | null;
  file_gt?: string | null;
  file_gte?: string | null;
  file_contains?: string | null;
  file_not_contains?: string | null;
  file_starts_with?: string | null;
  file_not_starts_with?: string | null;
  file_ends_with?: string | null;
  file_not_ends_with?: string | null;
  type?: InvoiceType | null;
  type_not?: InvoiceType | null;
  type_in?: InvoiceType[] | null;
  type_not_in?: InvoiceType[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
}

export interface InvoiceUpdateDataInput {
  number?: string | null;
  filename?: string | null;
  date?: any | null;
  file?: string | null;
  type?: InvoiceType | null;
  data?: any | null;
  payments?: PaymentUpdateManyWithoutInvoiceInput | null;
  googleshopOrders?: GoogleShopOrderUpdateManyInput | null;
  clientOrders?: ClientOrderUpdateManyWithoutInvoiceInput | null;
}

export interface InvoiceUpdateInput {
  number?: string | null;
  filename?: string | null;
  date?: any | null;
  file?: string | null;
  type?: InvoiceType | null;
  data?: any | null;
  payments?: PaymentUpdateManyWithoutInvoiceInput | null;
  googleshopOrders?: GoogleShopOrderUpdateManyInput | null;
  clientOrders?: ClientOrderUpdateManyWithoutInvoiceInput | null;
}

export interface InvoiceUpdateManyDataInput {
  number?: string | null;
  filename?: string | null;
  date?: any | null;
  file?: string | null;
  type?: InvoiceType | null;
  data?: any | null;
}

export interface InvoiceUpdateManyInput {
  create?: InvoiceCreateInput[] | null;
  connect?: InvoiceWhereUniqueInput[] | null;
  set?: InvoiceWhereUniqueInput[] | null;
  disconnect?: InvoiceWhereUniqueInput[] | null;
  delete?: InvoiceWhereUniqueInput[] | null;
  update?: InvoiceUpdateWithWhereUniqueNestedInput[] | null;
  updateMany?: InvoiceUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: InvoiceScalarWhereInput[] | null;
  upsert?: InvoiceUpsertWithWhereUniqueNestedInput[] | null;
}

export interface InvoiceUpdateManyWithWhereNestedInput {
  where: InvoiceScalarWhereInput;
  data: InvoiceUpdateManyDataInput;
}

export interface InvoiceUpdateOneInput {
  create?: InvoiceCreateInput | null;
  connect?: InvoiceWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: InvoiceUpdateDataInput | null;
  upsert?: InvoiceUpsertNestedInput | null;
}

export interface InvoiceUpdateOneWithoutClientOrdersInput {
  create?: InvoiceCreateWithoutClientOrdersInput | null;
  connect?: InvoiceWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: InvoiceUpdateWithoutClientOrdersDataInput | null;
  upsert?: InvoiceUpsertWithoutClientOrdersInput | null;
}

export interface InvoiceUpdateWithWhereUniqueNestedInput {
  where: InvoiceWhereUniqueInput;
  data: InvoiceUpdateDataInput;
}

export interface InvoiceUpdateWithoutClientOrdersDataInput {
  number?: string | null;
  filename?: string | null;
  date?: any | null;
  file?: string | null;
  type?: InvoiceType | null;
  data?: any | null;
  payments?: PaymentUpdateManyWithoutInvoiceInput | null;
  googleshopOrders?: GoogleShopOrderUpdateManyInput | null;
}

export interface InvoiceUpsertNestedInput {
  update: InvoiceUpdateDataInput;
  create: InvoiceCreateInput;
}

export interface InvoiceUpsertWithWhereUniqueNestedInput {
  where: InvoiceWhereUniqueInput;
  update: InvoiceUpdateDataInput;
  create: InvoiceCreateInput;
}

export interface InvoiceUpsertWithoutClientOrdersInput {
  update: InvoiceUpdateWithoutClientOrdersDataInput;
  create: InvoiceCreateWithoutClientOrdersInput;
}

export interface InvoiceWhereInput {
  AND?: InvoiceWhereInput[] | null;
  OR?: InvoiceWhereInput[] | null;
  NOT?: InvoiceWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  number?: string | null;
  number_not?: string | null;
  number_in?: string[] | null;
  number_not_in?: string[] | null;
  number_lt?: string | null;
  number_lte?: string | null;
  number_gt?: string | null;
  number_gte?: string | null;
  number_contains?: string | null;
  number_not_contains?: string | null;
  number_starts_with?: string | null;
  number_not_starts_with?: string | null;
  number_ends_with?: string | null;
  number_not_ends_with?: string | null;
  filename?: string | null;
  filename_not?: string | null;
  filename_in?: string[] | null;
  filename_not_in?: string[] | null;
  filename_lt?: string | null;
  filename_lte?: string | null;
  filename_gt?: string | null;
  filename_gte?: string | null;
  filename_contains?: string | null;
  filename_not_contains?: string | null;
  filename_starts_with?: string | null;
  filename_not_starts_with?: string | null;
  filename_ends_with?: string | null;
  filename_not_ends_with?: string | null;
  date?: any | null;
  date_not?: any | null;
  date_in?: any[] | null;
  date_not_in?: any[] | null;
  date_lt?: any | null;
  date_lte?: any | null;
  date_gt?: any | null;
  date_gte?: any | null;
  file?: string | null;
  file_not?: string | null;
  file_in?: string[] | null;
  file_not_in?: string[] | null;
  file_lt?: string | null;
  file_lte?: string | null;
  file_gt?: string | null;
  file_gte?: string | null;
  file_contains?: string | null;
  file_not_contains?: string | null;
  file_starts_with?: string | null;
  file_not_starts_with?: string | null;
  file_ends_with?: string | null;
  file_not_ends_with?: string | null;
  type?: InvoiceType | null;
  type_not?: InvoiceType | null;
  type_in?: InvoiceType[] | null;
  type_not_in?: InvoiceType[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  payments_every?: PaymentWhereInput | null;
  payments_some?: PaymentWhereInput | null;
  payments_none?: PaymentWhereInput | null;
  googleshopOrders_every?: GoogleShopOrderWhereInput | null;
  googleshopOrders_some?: GoogleShopOrderWhereInput | null;
  googleshopOrders_none?: GoogleShopOrderWhereInput | null;
  clientOrders_every?: ClientOrderWhereInput | null;
  clientOrders_some?: ClientOrderWhereInput | null;
  clientOrders_none?: ClientOrderWhereInput | null;
}

export interface InvoiceWhereUniqueInput {
  id?: string | null;
  number?: string | null;
}

export interface MemberCreateInput {
  id?: string | null;
  name: string;
  firstName: string;
  dateOfBirth: any;
  remarks?: string | null;
  child?: boolean | null;
  customerInfo?: CustomerInfoCreateOneWithoutMembersInput | null;
}

export interface MemberCreateManyInput {
  create?: MemberCreateInput[] | null;
  connect?: MemberWhereUniqueInput[] | null;
}

export interface MemberCreateManyWithoutCustomerInfoInput {
  create?: MemberCreateWithoutCustomerInfoInput[] | null;
  connect?: MemberWhereUniqueInput[] | null;
}

export interface MemberCreateWithoutCustomerInfoInput {
  id?: string | null;
  name: string;
  firstName: string;
  dateOfBirth: any;
  remarks?: string | null;
  child?: boolean | null;
}

export interface MemberScalarWhereInput {
  AND?: MemberScalarWhereInput[] | null;
  OR?: MemberScalarWhereInput[] | null;
  NOT?: MemberScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  firstName?: string | null;
  firstName_not?: string | null;
  firstName_in?: string[] | null;
  firstName_not_in?: string[] | null;
  firstName_lt?: string | null;
  firstName_lte?: string | null;
  firstName_gt?: string | null;
  firstName_gte?: string | null;
  firstName_contains?: string | null;
  firstName_not_contains?: string | null;
  firstName_starts_with?: string | null;
  firstName_not_starts_with?: string | null;
  firstName_ends_with?: string | null;
  firstName_not_ends_with?: string | null;
  dateOfBirth?: any | null;
  dateOfBirth_not?: any | null;
  dateOfBirth_in?: any[] | null;
  dateOfBirth_not_in?: any[] | null;
  dateOfBirth_lt?: any | null;
  dateOfBirth_lte?: any | null;
  dateOfBirth_gt?: any | null;
  dateOfBirth_gte?: any | null;
  remarks?: string | null;
  remarks_not?: string | null;
  remarks_in?: string[] | null;
  remarks_not_in?: string[] | null;
  remarks_lt?: string | null;
  remarks_lte?: string | null;
  remarks_gt?: string | null;
  remarks_gte?: string | null;
  remarks_contains?: string | null;
  remarks_not_contains?: string | null;
  remarks_starts_with?: string | null;
  remarks_not_starts_with?: string | null;
  remarks_ends_with?: string | null;
  remarks_not_ends_with?: string | null;
  child?: boolean | null;
  child_not?: boolean | null;
}

export interface MemberUpdateDataInput {
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  remarks?: string | null;
  child?: boolean | null;
  customerInfo?: CustomerInfoUpdateOneWithoutMembersInput | null;
}

export interface MemberUpdateManyDataInput {
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  remarks?: string | null;
  child?: boolean | null;
}

export interface MemberUpdateManyInput {
  create?: MemberCreateInput[] | null;
  connect?: MemberWhereUniqueInput[] | null;
  set?: MemberWhereUniqueInput[] | null;
  disconnect?: MemberWhereUniqueInput[] | null;
  delete?: MemberWhereUniqueInput[] | null;
  update?: MemberUpdateWithWhereUniqueNestedInput[] | null;
  updateMany?: MemberUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: MemberScalarWhereInput[] | null;
  upsert?: MemberUpsertWithWhereUniqueNestedInput[] | null;
}

export interface MemberUpdateManyWithWhereNestedInput {
  where: MemberScalarWhereInput;
  data: MemberUpdateManyDataInput;
}

export interface MemberUpdateManyWithoutCustomerInfoInput {
  create?: MemberCreateWithoutCustomerInfoInput[] | null;
  connect?: MemberWhereUniqueInput[] | null;
  set?: MemberWhereUniqueInput[] | null;
  disconnect?: MemberWhereUniqueInput[] | null;
  delete?: MemberWhereUniqueInput[] | null;
  update?: MemberUpdateWithWhereUniqueWithoutCustomerInfoInput[] | null;
  updateMany?: MemberUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: MemberScalarWhereInput[] | null;
  upsert?: MemberUpsertWithWhereUniqueWithoutCustomerInfoInput[] | null;
}

export interface MemberUpdateWithWhereUniqueNestedInput {
  where: MemberWhereUniqueInput;
  data: MemberUpdateDataInput;
}

export interface MemberUpdateWithWhereUniqueWithoutCustomerInfoInput {
  where: MemberWhereUniqueInput;
  data: MemberUpdateWithoutCustomerInfoDataInput;
}

export interface MemberUpdateWithoutCustomerInfoDataInput {
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  remarks?: string | null;
  child?: boolean | null;
}

export interface MemberUpsertWithWhereUniqueNestedInput {
  where: MemberWhereUniqueInput;
  update: MemberUpdateDataInput;
  create: MemberCreateInput;
}

export interface MemberUpsertWithWhereUniqueWithoutCustomerInfoInput {
  where: MemberWhereUniqueInput;
  update: MemberUpdateWithoutCustomerInfoDataInput;
  create: MemberCreateWithoutCustomerInfoInput;
}

export interface MemberWhereInput {
  AND?: MemberWhereInput[] | null;
  OR?: MemberWhereInput[] | null;
  NOT?: MemberWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  firstName?: string | null;
  firstName_not?: string | null;
  firstName_in?: string[] | null;
  firstName_not_in?: string[] | null;
  firstName_lt?: string | null;
  firstName_lte?: string | null;
  firstName_gt?: string | null;
  firstName_gte?: string | null;
  firstName_contains?: string | null;
  firstName_not_contains?: string | null;
  firstName_starts_with?: string | null;
  firstName_not_starts_with?: string | null;
  firstName_ends_with?: string | null;
  firstName_not_ends_with?: string | null;
  dateOfBirth?: any | null;
  dateOfBirth_not?: any | null;
  dateOfBirth_in?: any[] | null;
  dateOfBirth_not_in?: any[] | null;
  dateOfBirth_lt?: any | null;
  dateOfBirth_lte?: any | null;
  dateOfBirth_gt?: any | null;
  dateOfBirth_gte?: any | null;
  remarks?: string | null;
  remarks_not?: string | null;
  remarks_in?: string[] | null;
  remarks_not_in?: string[] | null;
  remarks_lt?: string | null;
  remarks_lte?: string | null;
  remarks_gt?: string | null;
  remarks_gte?: string | null;
  remarks_contains?: string | null;
  remarks_not_contains?: string | null;
  remarks_starts_with?: string | null;
  remarks_not_starts_with?: string | null;
  remarks_ends_with?: string | null;
  remarks_not_ends_with?: string | null;
  child?: boolean | null;
  child_not?: boolean | null;
  customerInfo?: CustomerInfoWhereInput | null;
}

export interface MemberWhereUniqueInput {
  id?: string | null;
}

export interface MembershipCreateInput {
  id?: string | null;
  email: string;
  secondaryEmail?: string | null;
  type: MembershipType;
  name: string;
  firstName: string;
  dateOfBirth?: any | null;
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  price?: number | null;
  basketType?: BasketType | null;
  deliveryLocation?: DeliveryLocation | null;
  engagement: boolean;
  familyCorrectness?: boolean | null;
  newsletter: boolean;
  whatsApp: boolean;
  remarks?: string | null;
  active?: boolean | null;
  simulation?: boolean | null;
  homeDelivery?: boolean | null;
  biWeekly?: boolean | null;
  trial?: boolean | null;
  unsubscribed?: boolean | null;
  startDate?: any | null;
  endDate?: any | null;
  vat?: string | null;
  biWeek?: BiWeek | null;
  firstBasket?: any | null;
  season?: string | null;
  ambassador?: AmbassadorOption | null;
  membershipSubType?: MembershipSubType | null;
  waitingList?: boolean | null;
  members?: MemberCreateManyInput | null;
  invoices?: InvoiceCreateManyInput | null;
  googleShopOrders?: GoogleShopOrderCreateManyWithoutMembershipInput | null;
  previousMembership?: MembershipCreateOneInput | null;
  extras?: MembershipExtraCreateManyWithoutMembershipInput | null;
  shopClient?: ShopClientCreateOneInput | null;
  user?: UserCreateOneInput | null;
}

export interface MembershipCreateOneInput {
  create?: MembershipCreateInput | null;
  connect?: MembershipWhereUniqueInput | null;
}

export interface MembershipCreateOneWithoutGoogleShopOrdersInput {
  create?: MembershipCreateWithoutGoogleShopOrdersInput | null;
  connect?: MembershipWhereUniqueInput | null;
}

export interface MembershipCreateWithoutGoogleShopOrdersInput {
  id?: string | null;
  email: string;
  secondaryEmail?: string | null;
  type: MembershipType;
  name: string;
  firstName: string;
  dateOfBirth?: any | null;
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  price?: number | null;
  basketType?: BasketType | null;
  deliveryLocation?: DeliveryLocation | null;
  engagement: boolean;
  familyCorrectness?: boolean | null;
  newsletter: boolean;
  whatsApp: boolean;
  remarks?: string | null;
  active?: boolean | null;
  simulation?: boolean | null;
  homeDelivery?: boolean | null;
  biWeekly?: boolean | null;
  trial?: boolean | null;
  unsubscribed?: boolean | null;
  startDate?: any | null;
  endDate?: any | null;
  vat?: string | null;
  biWeek?: BiWeek | null;
  firstBasket?: any | null;
  season?: string | null;
  ambassador?: AmbassadorOption | null;
  membershipSubType?: MembershipSubType | null;
  waitingList?: boolean | null;
  members?: MemberCreateManyInput | null;
  invoices?: InvoiceCreateManyInput | null;
  previousMembership?: MembershipCreateOneInput | null;
  extras?: MembershipExtraCreateManyWithoutMembershipInput | null;
  shopClient?: ShopClientCreateOneInput | null;
  user?: UserCreateOneInput | null;
}

export interface MembershipExtraCreateManyWithoutMembershipInput {
  create?: MembershipExtraCreateWithoutMembershipInput[] | null;
  connect?: MembershipExtraWhereUniqueInput[] | null;
}

export interface MembershipExtraCreateWithoutMembershipInput {
  id?: string | null;
  startDate: any;
  endDate: any;
  option: MembershipExtraProductCreateOneInput;
}

export interface MembershipExtraProductCreateInput {
  id?: string | null;
  type: MembershipExtraType;
  price: number;
  frequency: Frequency;
  name: string;
  allowedForBiWeeklyBasket: boolean;
}

export interface MembershipExtraProductCreateOneInput {
  create?: MembershipExtraProductCreateInput | null;
  connect?: MembershipExtraProductWhereUniqueInput | null;
}

export interface MembershipExtraProductUpdateDataInput {
  type?: MembershipExtraType | null;
  price?: number | null;
  frequency?: Frequency | null;
  name?: string | null;
  allowedForBiWeeklyBasket?: boolean | null;
}

export interface MembershipExtraProductUpdateOneRequiredInput {
  create?: MembershipExtraProductCreateInput | null;
  connect?: MembershipExtraProductWhereUniqueInput | null;
  update?: MembershipExtraProductUpdateDataInput | null;
  upsert?: MembershipExtraProductUpsertNestedInput | null;
}

export interface MembershipExtraProductUpsertNestedInput {
  update: MembershipExtraProductUpdateDataInput;
  create: MembershipExtraProductCreateInput;
}

export interface MembershipExtraProductWhereInput {
  AND?: MembershipExtraProductWhereInput[] | null;
  OR?: MembershipExtraProductWhereInput[] | null;
  NOT?: MembershipExtraProductWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  type?: MembershipExtraType | null;
  type_not?: MembershipExtraType | null;
  type_in?: MembershipExtraType[] | null;
  type_not_in?: MembershipExtraType[] | null;
  price?: number | null;
  price_not?: number | null;
  price_in?: number[] | null;
  price_not_in?: number[] | null;
  price_lt?: number | null;
  price_lte?: number | null;
  price_gt?: number | null;
  price_gte?: number | null;
  frequency?: Frequency | null;
  frequency_not?: Frequency | null;
  frequency_in?: Frequency[] | null;
  frequency_not_in?: Frequency[] | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  allowedForBiWeeklyBasket?: boolean | null;
  allowedForBiWeeklyBasket_not?: boolean | null;
}

export interface MembershipExtraProductWhereUniqueInput {
  id?: string | null;
}

export interface MembershipExtraScalarWhereInput {
  AND?: MembershipExtraScalarWhereInput[] | null;
  OR?: MembershipExtraScalarWhereInput[] | null;
  NOT?: MembershipExtraScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  startDate?: any | null;
  startDate_not?: any | null;
  startDate_in?: any[] | null;
  startDate_not_in?: any[] | null;
  startDate_lt?: any | null;
  startDate_lte?: any | null;
  startDate_gt?: any | null;
  startDate_gte?: any | null;
  endDate?: any | null;
  endDate_not?: any | null;
  endDate_in?: any[] | null;
  endDate_not_in?: any[] | null;
  endDate_lt?: any | null;
  endDate_lte?: any | null;
  endDate_gt?: any | null;
  endDate_gte?: any | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
}

export interface MembershipExtraUpdateManyDataInput {
  startDate?: any | null;
  endDate?: any | null;
}

export interface MembershipExtraUpdateManyWithWhereNestedInput {
  where: MembershipExtraScalarWhereInput;
  data: MembershipExtraUpdateManyDataInput;
}

export interface MembershipExtraUpdateManyWithoutMembershipInput {
  create?: MembershipExtraCreateWithoutMembershipInput[] | null;
  connect?: MembershipExtraWhereUniqueInput[] | null;
  set?: MembershipExtraWhereUniqueInput[] | null;
  disconnect?: MembershipExtraWhereUniqueInput[] | null;
  delete?: MembershipExtraWhereUniqueInput[] | null;
  update?: MembershipExtraUpdateWithWhereUniqueWithoutMembershipInput[] | null;
  updateMany?: MembershipExtraUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: MembershipExtraScalarWhereInput[] | null;
  upsert?: MembershipExtraUpsertWithWhereUniqueWithoutMembershipInput[] | null;
}

export interface MembershipExtraUpdateWithWhereUniqueWithoutMembershipInput {
  where: MembershipExtraWhereUniqueInput;
  data: MembershipExtraUpdateWithoutMembershipDataInput;
}

export interface MembershipExtraUpdateWithoutMembershipDataInput {
  startDate?: any | null;
  endDate?: any | null;
  option?: MembershipExtraProductUpdateOneRequiredInput | null;
}

export interface MembershipExtraUpsertWithWhereUniqueWithoutMembershipInput {
  where: MembershipExtraWhereUniqueInput;
  update: MembershipExtraUpdateWithoutMembershipDataInput;
  create: MembershipExtraCreateWithoutMembershipInput;
}

export interface MembershipExtraWhereInput {
  AND?: MembershipExtraWhereInput[] | null;
  OR?: MembershipExtraWhereInput[] | null;
  NOT?: MembershipExtraWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  startDate?: any | null;
  startDate_not?: any | null;
  startDate_in?: any[] | null;
  startDate_not_in?: any[] | null;
  startDate_lt?: any | null;
  startDate_lte?: any | null;
  startDate_gt?: any | null;
  startDate_gte?: any | null;
  endDate?: any | null;
  endDate_not?: any | null;
  endDate_in?: any[] | null;
  endDate_not_in?: any[] | null;
  endDate_lt?: any | null;
  endDate_lte?: any | null;
  endDate_gt?: any | null;
  endDate_gte?: any | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  option?: MembershipExtraProductWhereInput | null;
  membership?: MembershipWhereInput | null;
}

export interface MembershipExtraWhereUniqueInput {
  id?: string | null;
}

export interface MembershipUpdateDataInput {
  email?: string | null;
  secondaryEmail?: string | null;
  type?: MembershipType | null;
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  price?: number | null;
  basketType?: BasketType | null;
  deliveryLocation?: DeliveryLocation | null;
  engagement?: boolean | null;
  familyCorrectness?: boolean | null;
  newsletter?: boolean | null;
  whatsApp?: boolean | null;
  remarks?: string | null;
  active?: boolean | null;
  simulation?: boolean | null;
  homeDelivery?: boolean | null;
  biWeekly?: boolean | null;
  trial?: boolean | null;
  unsubscribed?: boolean | null;
  startDate?: any | null;
  endDate?: any | null;
  vat?: string | null;
  biWeek?: BiWeek | null;
  firstBasket?: any | null;
  season?: string | null;
  ambassador?: AmbassadorOption | null;
  membershipSubType?: MembershipSubType | null;
  waitingList?: boolean | null;
  members?: MemberUpdateManyInput | null;
  invoices?: InvoiceUpdateManyInput | null;
  googleShopOrders?: GoogleShopOrderUpdateManyWithoutMembershipInput | null;
  previousMembership?: MembershipUpdateOneInput | null;
  extras?: MembershipExtraUpdateManyWithoutMembershipInput | null;
  shopClient?: ShopClientUpdateOneInput | null;
  user?: UserUpdateOneInput | null;
}

export interface MembershipUpdateOneInput {
  create?: MembershipCreateInput | null;
  connect?: MembershipWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: MembershipUpdateDataInput | null;
  upsert?: MembershipUpsertNestedInput | null;
}

export interface MembershipUpdateOneRequiredWithoutGoogleShopOrdersInput {
  create?: MembershipCreateWithoutGoogleShopOrdersInput | null;
  connect?: MembershipWhereUniqueInput | null;
  update?: MembershipUpdateWithoutGoogleShopOrdersDataInput | null;
  upsert?: MembershipUpsertWithoutGoogleShopOrdersInput | null;
}

export interface MembershipUpdateWithoutGoogleShopOrdersDataInput {
  email?: string | null;
  secondaryEmail?: string | null;
  type?: MembershipType | null;
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  price?: number | null;
  basketType?: BasketType | null;
  deliveryLocation?: DeliveryLocation | null;
  engagement?: boolean | null;
  familyCorrectness?: boolean | null;
  newsletter?: boolean | null;
  whatsApp?: boolean | null;
  remarks?: string | null;
  active?: boolean | null;
  simulation?: boolean | null;
  homeDelivery?: boolean | null;
  biWeekly?: boolean | null;
  trial?: boolean | null;
  unsubscribed?: boolean | null;
  startDate?: any | null;
  endDate?: any | null;
  vat?: string | null;
  biWeek?: BiWeek | null;
  firstBasket?: any | null;
  season?: string | null;
  ambassador?: AmbassadorOption | null;
  membershipSubType?: MembershipSubType | null;
  waitingList?: boolean | null;
  members?: MemberUpdateManyInput | null;
  invoices?: InvoiceUpdateManyInput | null;
  previousMembership?: MembershipUpdateOneInput | null;
  extras?: MembershipExtraUpdateManyWithoutMembershipInput | null;
  shopClient?: ShopClientUpdateOneInput | null;
  user?: UserUpdateOneInput | null;
}

export interface MembershipUpsertNestedInput {
  update: MembershipUpdateDataInput;
  create: MembershipCreateInput;
}

export interface MembershipUpsertWithoutGoogleShopOrdersInput {
  update: MembershipUpdateWithoutGoogleShopOrdersDataInput;
  create: MembershipCreateWithoutGoogleShopOrdersInput;
}

export interface MembershipWhereInput {
  AND?: MembershipWhereInput[] | null;
  OR?: MembershipWhereInput[] | null;
  NOT?: MembershipWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  email?: string | null;
  email_not?: string | null;
  email_in?: string[] | null;
  email_not_in?: string[] | null;
  email_lt?: string | null;
  email_lte?: string | null;
  email_gt?: string | null;
  email_gte?: string | null;
  email_contains?: string | null;
  email_not_contains?: string | null;
  email_starts_with?: string | null;
  email_not_starts_with?: string | null;
  email_ends_with?: string | null;
  email_not_ends_with?: string | null;
  secondaryEmail?: string | null;
  secondaryEmail_not?: string | null;
  secondaryEmail_in?: string[] | null;
  secondaryEmail_not_in?: string[] | null;
  secondaryEmail_lt?: string | null;
  secondaryEmail_lte?: string | null;
  secondaryEmail_gt?: string | null;
  secondaryEmail_gte?: string | null;
  secondaryEmail_contains?: string | null;
  secondaryEmail_not_contains?: string | null;
  secondaryEmail_starts_with?: string | null;
  secondaryEmail_not_starts_with?: string | null;
  secondaryEmail_ends_with?: string | null;
  secondaryEmail_not_ends_with?: string | null;
  type?: MembershipType | null;
  type_not?: MembershipType | null;
  type_in?: MembershipType[] | null;
  type_not_in?: MembershipType[] | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  firstName?: string | null;
  firstName_not?: string | null;
  firstName_in?: string[] | null;
  firstName_not_in?: string[] | null;
  firstName_lt?: string | null;
  firstName_lte?: string | null;
  firstName_gt?: string | null;
  firstName_gte?: string | null;
  firstName_contains?: string | null;
  firstName_not_contains?: string | null;
  firstName_starts_with?: string | null;
  firstName_not_starts_with?: string | null;
  firstName_ends_with?: string | null;
  firstName_not_ends_with?: string | null;
  dateOfBirth?: any | null;
  dateOfBirth_not?: any | null;
  dateOfBirth_in?: any[] | null;
  dateOfBirth_not_in?: any[] | null;
  dateOfBirth_lt?: any | null;
  dateOfBirth_lte?: any | null;
  dateOfBirth_gt?: any | null;
  dateOfBirth_gte?: any | null;
  phone?: string | null;
  phone_not?: string | null;
  phone_in?: string[] | null;
  phone_not_in?: string[] | null;
  phone_lt?: string | null;
  phone_lte?: string | null;
  phone_gt?: string | null;
  phone_gte?: string | null;
  phone_contains?: string | null;
  phone_not_contains?: string | null;
  phone_starts_with?: string | null;
  phone_not_starts_with?: string | null;
  phone_ends_with?: string | null;
  phone_not_ends_with?: string | null;
  street?: string | null;
  street_not?: string | null;
  street_in?: string[] | null;
  street_not_in?: string[] | null;
  street_lt?: string | null;
  street_lte?: string | null;
  street_gt?: string | null;
  street_gte?: string | null;
  street_contains?: string | null;
  street_not_contains?: string | null;
  street_starts_with?: string | null;
  street_not_starts_with?: string | null;
  street_ends_with?: string | null;
  street_not_ends_with?: string | null;
  streetNumber?: string | null;
  streetNumber_not?: string | null;
  streetNumber_in?: string[] | null;
  streetNumber_not_in?: string[] | null;
  streetNumber_lt?: string | null;
  streetNumber_lte?: string | null;
  streetNumber_gt?: string | null;
  streetNumber_gte?: string | null;
  streetNumber_contains?: string | null;
  streetNumber_not_contains?: string | null;
  streetNumber_starts_with?: string | null;
  streetNumber_not_starts_with?: string | null;
  streetNumber_ends_with?: string | null;
  streetNumber_not_ends_with?: string | null;
  city?: string | null;
  city_not?: string | null;
  city_in?: string[] | null;
  city_not_in?: string[] | null;
  city_lt?: string | null;
  city_lte?: string | null;
  city_gt?: string | null;
  city_gte?: string | null;
  city_contains?: string | null;
  city_not_contains?: string | null;
  city_starts_with?: string | null;
  city_not_starts_with?: string | null;
  city_ends_with?: string | null;
  city_not_ends_with?: string | null;
  cityPostalCode?: string | null;
  cityPostalCode_not?: string | null;
  cityPostalCode_in?: string[] | null;
  cityPostalCode_not_in?: string[] | null;
  cityPostalCode_lt?: string | null;
  cityPostalCode_lte?: string | null;
  cityPostalCode_gt?: string | null;
  cityPostalCode_gte?: string | null;
  cityPostalCode_contains?: string | null;
  cityPostalCode_not_contains?: string | null;
  cityPostalCode_starts_with?: string | null;
  cityPostalCode_not_starts_with?: string | null;
  cityPostalCode_ends_with?: string | null;
  cityPostalCode_not_ends_with?: string | null;
  price?: number | null;
  price_not?: number | null;
  price_in?: number[] | null;
  price_not_in?: number[] | null;
  price_lt?: number | null;
  price_lte?: number | null;
  price_gt?: number | null;
  price_gte?: number | null;
  basketType?: BasketType | null;
  basketType_not?: BasketType | null;
  basketType_in?: BasketType[] | null;
  basketType_not_in?: BasketType[] | null;
  deliveryLocation?: DeliveryLocation | null;
  deliveryLocation_not?: DeliveryLocation | null;
  deliveryLocation_in?: DeliveryLocation[] | null;
  deliveryLocation_not_in?: DeliveryLocation[] | null;
  engagement?: boolean | null;
  engagement_not?: boolean | null;
  familyCorrectness?: boolean | null;
  familyCorrectness_not?: boolean | null;
  newsletter?: boolean | null;
  newsletter_not?: boolean | null;
  whatsApp?: boolean | null;
  whatsApp_not?: boolean | null;
  remarks?: string | null;
  remarks_not?: string | null;
  remarks_in?: string[] | null;
  remarks_not_in?: string[] | null;
  remarks_lt?: string | null;
  remarks_lte?: string | null;
  remarks_gt?: string | null;
  remarks_gte?: string | null;
  remarks_contains?: string | null;
  remarks_not_contains?: string | null;
  remarks_starts_with?: string | null;
  remarks_not_starts_with?: string | null;
  remarks_ends_with?: string | null;
  remarks_not_ends_with?: string | null;
  active?: boolean | null;
  active_not?: boolean | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  simulation?: boolean | null;
  simulation_not?: boolean | null;
  homeDelivery?: boolean | null;
  homeDelivery_not?: boolean | null;
  biWeekly?: boolean | null;
  biWeekly_not?: boolean | null;
  trial?: boolean | null;
  trial_not?: boolean | null;
  unsubscribed?: boolean | null;
  unsubscribed_not?: boolean | null;
  startDate?: any | null;
  startDate_not?: any | null;
  startDate_in?: any[] | null;
  startDate_not_in?: any[] | null;
  startDate_lt?: any | null;
  startDate_lte?: any | null;
  startDate_gt?: any | null;
  startDate_gte?: any | null;
  endDate?: any | null;
  endDate_not?: any | null;
  endDate_in?: any[] | null;
  endDate_not_in?: any[] | null;
  endDate_lt?: any | null;
  endDate_lte?: any | null;
  endDate_gt?: any | null;
  endDate_gte?: any | null;
  vat?: string | null;
  vat_not?: string | null;
  vat_in?: string[] | null;
  vat_not_in?: string[] | null;
  vat_lt?: string | null;
  vat_lte?: string | null;
  vat_gt?: string | null;
  vat_gte?: string | null;
  vat_contains?: string | null;
  vat_not_contains?: string | null;
  vat_starts_with?: string | null;
  vat_not_starts_with?: string | null;
  vat_ends_with?: string | null;
  vat_not_ends_with?: string | null;
  biWeek?: BiWeek | null;
  biWeek_not?: BiWeek | null;
  biWeek_in?: BiWeek[] | null;
  biWeek_not_in?: BiWeek[] | null;
  firstBasket?: any | null;
  firstBasket_not?: any | null;
  firstBasket_in?: any[] | null;
  firstBasket_not_in?: any[] | null;
  firstBasket_lt?: any | null;
  firstBasket_lte?: any | null;
  firstBasket_gt?: any | null;
  firstBasket_gte?: any | null;
  season?: string | null;
  season_not?: string | null;
  season_in?: string[] | null;
  season_not_in?: string[] | null;
  season_lt?: string | null;
  season_lte?: string | null;
  season_gt?: string | null;
  season_gte?: string | null;
  season_contains?: string | null;
  season_not_contains?: string | null;
  season_starts_with?: string | null;
  season_not_starts_with?: string | null;
  season_ends_with?: string | null;
  season_not_ends_with?: string | null;
  ambassador?: AmbassadorOption | null;
  ambassador_not?: AmbassadorOption | null;
  ambassador_in?: AmbassadorOption[] | null;
  ambassador_not_in?: AmbassadorOption[] | null;
  membershipSubType?: MembershipSubType | null;
  membershipSubType_not?: MembershipSubType | null;
  membershipSubType_in?: MembershipSubType[] | null;
  membershipSubType_not_in?: MembershipSubType[] | null;
  waitingList?: boolean | null;
  waitingList_not?: boolean | null;
  members_every?: MemberWhereInput | null;
  members_some?: MemberWhereInput | null;
  members_none?: MemberWhereInput | null;
  invoices_every?: InvoiceWhereInput | null;
  invoices_some?: InvoiceWhereInput | null;
  invoices_none?: InvoiceWhereInput | null;
  googleShopOrders_every?: GoogleShopOrderWhereInput | null;
  googleShopOrders_some?: GoogleShopOrderWhereInput | null;
  googleShopOrders_none?: GoogleShopOrderWhereInput | null;
  previousMembership?: MembershipWhereInput | null;
  extras_every?: MembershipExtraWhereInput | null;
  extras_some?: MembershipExtraWhereInput | null;
  extras_none?: MembershipExtraWhereInput | null;
  shopClient?: ShopClientWhereInput | null;
  user?: UserWhereInput | null;
}

export interface MembershipWhereUniqueInput {
  id?: string | null;
}

export interface NoteCreateManyWithoutCultivationInput {
  create?: NoteCreateWithoutCultivationInput[] | null;
  connect?: NoteWhereUniqueInput[] | null;
}

export interface NoteCreateManyWithoutPlotInput {
  create?: NoteCreateWithoutPlotInput[] | null;
  connect?: NoteWhereUniqueInput[] | null;
}

export interface NoteCreateOneWithoutCultivationEventInput {
  create?: NoteCreateWithoutCultivationEventInput | null;
  connect?: NoteWhereUniqueInput | null;
}

export interface NoteCreateWithoutCultivationEventInput {
  id?: string | null;
  type?: CultivationEventType | null;
  text?: string | null;
  cultivation?: CultivationCreateOneWithoutNotesInput | null;
  plot?: PlotCreateOneWithoutNotesInput | null;
}

export interface NoteCreateWithoutCultivationInput {
  id?: string | null;
  type?: CultivationEventType | null;
  text?: string | null;
  cultivationEvent?: CultivationEventCreateOneWithoutNoteInput | null;
  plot?: PlotCreateOneWithoutNotesInput | null;
}

export interface NoteCreateWithoutPlotInput {
  id?: string | null;
  type?: CultivationEventType | null;
  text?: string | null;
  cultivation?: CultivationCreateOneWithoutNotesInput | null;
  cultivationEvent?: CultivationEventCreateOneWithoutNoteInput | null;
}

export interface NoteScalarWhereInput {
  AND?: NoteScalarWhereInput[] | null;
  OR?: NoteScalarWhereInput[] | null;
  NOT?: NoteScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  type?: CultivationEventType | null;
  type_not?: CultivationEventType | null;
  type_in?: CultivationEventType[] | null;
  type_not_in?: CultivationEventType[] | null;
  text?: string | null;
  text_not?: string | null;
  text_in?: string[] | null;
  text_not_in?: string[] | null;
  text_lt?: string | null;
  text_lte?: string | null;
  text_gt?: string | null;
  text_gte?: string | null;
  text_contains?: string | null;
  text_not_contains?: string | null;
  text_starts_with?: string | null;
  text_not_starts_with?: string | null;
  text_ends_with?: string | null;
  text_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
}

export interface NoteUpdateManyDataInput {
  type?: CultivationEventType | null;
  text?: string | null;
}

export interface NoteUpdateManyWithWhereNestedInput {
  where: NoteScalarWhereInput;
  data: NoteUpdateManyDataInput;
}

export interface NoteUpdateManyWithoutCultivationInput {
  create?: NoteCreateWithoutCultivationInput[] | null;
  connect?: NoteWhereUniqueInput[] | null;
  set?: NoteWhereUniqueInput[] | null;
  disconnect?: NoteWhereUniqueInput[] | null;
  delete?: NoteWhereUniqueInput[] | null;
  update?: NoteUpdateWithWhereUniqueWithoutCultivationInput[] | null;
  updateMany?: NoteUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: NoteScalarWhereInput[] | null;
  upsert?: NoteUpsertWithWhereUniqueWithoutCultivationInput[] | null;
}

export interface NoteUpdateManyWithoutPlotInput {
  create?: NoteCreateWithoutPlotInput[] | null;
  connect?: NoteWhereUniqueInput[] | null;
  set?: NoteWhereUniqueInput[] | null;
  disconnect?: NoteWhereUniqueInput[] | null;
  delete?: NoteWhereUniqueInput[] | null;
  update?: NoteUpdateWithWhereUniqueWithoutPlotInput[] | null;
  updateMany?: NoteUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: NoteScalarWhereInput[] | null;
  upsert?: NoteUpsertWithWhereUniqueWithoutPlotInput[] | null;
}

export interface NoteUpdateOneWithoutCultivationEventInput {
  create?: NoteCreateWithoutCultivationEventInput | null;
  connect?: NoteWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: NoteUpdateWithoutCultivationEventDataInput | null;
  upsert?: NoteUpsertWithoutCultivationEventInput | null;
}

export interface NoteUpdateWithWhereUniqueWithoutCultivationInput {
  where: NoteWhereUniqueInput;
  data: NoteUpdateWithoutCultivationDataInput;
}

export interface NoteUpdateWithWhereUniqueWithoutPlotInput {
  where: NoteWhereUniqueInput;
  data: NoteUpdateWithoutPlotDataInput;
}

export interface NoteUpdateWithoutCultivationDataInput {
  type?: CultivationEventType | null;
  text?: string | null;
  cultivationEvent?: CultivationEventUpdateOneWithoutNoteInput | null;
  plot?: PlotUpdateOneWithoutNotesInput | null;
}

export interface NoteUpdateWithoutCultivationEventDataInput {
  type?: CultivationEventType | null;
  text?: string | null;
  cultivation?: CultivationUpdateOneWithoutNotesInput | null;
  plot?: PlotUpdateOneWithoutNotesInput | null;
}

export interface NoteUpdateWithoutPlotDataInput {
  type?: CultivationEventType | null;
  text?: string | null;
  cultivation?: CultivationUpdateOneWithoutNotesInput | null;
  cultivationEvent?: CultivationEventUpdateOneWithoutNoteInput | null;
}

export interface NoteUpsertWithWhereUniqueWithoutCultivationInput {
  where: NoteWhereUniqueInput;
  update: NoteUpdateWithoutCultivationDataInput;
  create: NoteCreateWithoutCultivationInput;
}

export interface NoteUpsertWithWhereUniqueWithoutPlotInput {
  where: NoteWhereUniqueInput;
  update: NoteUpdateWithoutPlotDataInput;
  create: NoteCreateWithoutPlotInput;
}

export interface NoteUpsertWithoutCultivationEventInput {
  update: NoteUpdateWithoutCultivationEventDataInput;
  create: NoteCreateWithoutCultivationEventInput;
}

export interface NoteWhereInput {
  AND?: NoteWhereInput[] | null;
  OR?: NoteWhereInput[] | null;
  NOT?: NoteWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  type?: CultivationEventType | null;
  type_not?: CultivationEventType | null;
  type_in?: CultivationEventType[] | null;
  type_not_in?: CultivationEventType[] | null;
  text?: string | null;
  text_not?: string | null;
  text_in?: string[] | null;
  text_not_in?: string[] | null;
  text_lt?: string | null;
  text_lte?: string | null;
  text_gt?: string | null;
  text_gte?: string | null;
  text_contains?: string | null;
  text_not_contains?: string | null;
  text_starts_with?: string | null;
  text_not_starts_with?: string | null;
  text_ends_with?: string | null;
  text_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  cultivation?: CultivationWhereInput | null;
  cultivationEvent?: CultivationEventWhereInput | null;
  plot?: PlotWhereInput | null;
}

export interface NoteWhereUniqueInput {
  id?: string | null;
}

export interface OrderItemCreateManyWithoutFarmInput {
  create?: OrderItemCreateWithoutFarmInput[] | null;
  connect?: OrderItemWhereUniqueInput[] | null;
}

export interface OrderItemCreateWithoutFarmInput {
  id?: string | null;
  code: string;
  name: string;
  cropName: string;
  amount: number;
  filename: string;
  deliveryWeek: number;
  deliveryDay: number;
  deliveryYear: number;
  soilBlockType?: string | null;
  soldTo?: string | null;
}

export interface OrderItemScalarWhereInput {
  AND?: OrderItemScalarWhereInput[] | null;
  OR?: OrderItemScalarWhereInput[] | null;
  NOT?: OrderItemScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  code?: string | null;
  code_not?: string | null;
  code_in?: string[] | null;
  code_not_in?: string[] | null;
  code_lt?: string | null;
  code_lte?: string | null;
  code_gt?: string | null;
  code_gte?: string | null;
  code_contains?: string | null;
  code_not_contains?: string | null;
  code_starts_with?: string | null;
  code_not_starts_with?: string | null;
  code_ends_with?: string | null;
  code_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  cropName?: string | null;
  cropName_not?: string | null;
  cropName_in?: string[] | null;
  cropName_not_in?: string[] | null;
  cropName_lt?: string | null;
  cropName_lte?: string | null;
  cropName_gt?: string | null;
  cropName_gte?: string | null;
  cropName_contains?: string | null;
  cropName_not_contains?: string | null;
  cropName_starts_with?: string | null;
  cropName_not_starts_with?: string | null;
  cropName_ends_with?: string | null;
  cropName_not_ends_with?: string | null;
  amount?: number | null;
  amount_not?: number | null;
  amount_in?: number[] | null;
  amount_not_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  filename?: string | null;
  filename_not?: string | null;
  filename_in?: string[] | null;
  filename_not_in?: string[] | null;
  filename_lt?: string | null;
  filename_lte?: string | null;
  filename_gt?: string | null;
  filename_gte?: string | null;
  filename_contains?: string | null;
  filename_not_contains?: string | null;
  filename_starts_with?: string | null;
  filename_not_starts_with?: string | null;
  filename_ends_with?: string | null;
  filename_not_ends_with?: string | null;
  deliveryWeek?: number | null;
  deliveryWeek_not?: number | null;
  deliveryWeek_in?: number[] | null;
  deliveryWeek_not_in?: number[] | null;
  deliveryWeek_lt?: number | null;
  deliveryWeek_lte?: number | null;
  deliveryWeek_gt?: number | null;
  deliveryWeek_gte?: number | null;
  deliveryDay?: number | null;
  deliveryDay_not?: number | null;
  deliveryDay_in?: number[] | null;
  deliveryDay_not_in?: number[] | null;
  deliveryDay_lt?: number | null;
  deliveryDay_lte?: number | null;
  deliveryDay_gt?: number | null;
  deliveryDay_gte?: number | null;
  deliveryYear?: number | null;
  deliveryYear_not?: number | null;
  deliveryYear_in?: number[] | null;
  deliveryYear_not_in?: number[] | null;
  deliveryYear_lt?: number | null;
  deliveryYear_lte?: number | null;
  deliveryYear_gt?: number | null;
  deliveryYear_gte?: number | null;
  soilBlockType?: string | null;
  soilBlockType_not?: string | null;
  soilBlockType_in?: string[] | null;
  soilBlockType_not_in?: string[] | null;
  soilBlockType_lt?: string | null;
  soilBlockType_lte?: string | null;
  soilBlockType_gt?: string | null;
  soilBlockType_gte?: string | null;
  soilBlockType_contains?: string | null;
  soilBlockType_not_contains?: string | null;
  soilBlockType_starts_with?: string | null;
  soilBlockType_not_starts_with?: string | null;
  soilBlockType_ends_with?: string | null;
  soilBlockType_not_ends_with?: string | null;
  soldTo?: string | null;
  soldTo_not?: string | null;
  soldTo_in?: string[] | null;
  soldTo_not_in?: string[] | null;
  soldTo_lt?: string | null;
  soldTo_lte?: string | null;
  soldTo_gt?: string | null;
  soldTo_gte?: string | null;
  soldTo_contains?: string | null;
  soldTo_not_contains?: string | null;
  soldTo_starts_with?: string | null;
  soldTo_not_starts_with?: string | null;
  soldTo_ends_with?: string | null;
  soldTo_not_ends_with?: string | null;
}

export interface OrderItemUpdateManyDataInput {
  code?: string | null;
  name?: string | null;
  cropName?: string | null;
  amount?: number | null;
  filename?: string | null;
  deliveryWeek?: number | null;
  deliveryDay?: number | null;
  deliveryYear?: number | null;
  soilBlockType?: string | null;
  soldTo?: string | null;
}

export interface OrderItemUpdateManyWithWhereNestedInput {
  where: OrderItemScalarWhereInput;
  data: OrderItemUpdateManyDataInput;
}

export interface OrderItemUpdateManyWithoutFarmInput {
  create?: OrderItemCreateWithoutFarmInput[] | null;
  connect?: OrderItemWhereUniqueInput[] | null;
  set?: OrderItemWhereUniqueInput[] | null;
  disconnect?: OrderItemWhereUniqueInput[] | null;
  delete?: OrderItemWhereUniqueInput[] | null;
  update?: OrderItemUpdateWithWhereUniqueWithoutFarmInput[] | null;
  updateMany?: OrderItemUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: OrderItemScalarWhereInput[] | null;
  upsert?: OrderItemUpsertWithWhereUniqueWithoutFarmInput[] | null;
}

export interface OrderItemUpdateWithWhereUniqueWithoutFarmInput {
  where: OrderItemWhereUniqueInput;
  data: OrderItemUpdateWithoutFarmDataInput;
}

export interface OrderItemUpdateWithoutFarmDataInput {
  code?: string | null;
  name?: string | null;
  cropName?: string | null;
  amount?: number | null;
  filename?: string | null;
  deliveryWeek?: number | null;
  deliveryDay?: number | null;
  deliveryYear?: number | null;
  soilBlockType?: string | null;
  soldTo?: string | null;
}

export interface OrderItemUpsertWithWhereUniqueWithoutFarmInput {
  where: OrderItemWhereUniqueInput;
  update: OrderItemUpdateWithoutFarmDataInput;
  create: OrderItemCreateWithoutFarmInput;
}

export interface OrderItemWhereInput {
  AND?: OrderItemWhereInput[] | null;
  OR?: OrderItemWhereInput[] | null;
  NOT?: OrderItemWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  code?: string | null;
  code_not?: string | null;
  code_in?: string[] | null;
  code_not_in?: string[] | null;
  code_lt?: string | null;
  code_lte?: string | null;
  code_gt?: string | null;
  code_gte?: string | null;
  code_contains?: string | null;
  code_not_contains?: string | null;
  code_starts_with?: string | null;
  code_not_starts_with?: string | null;
  code_ends_with?: string | null;
  code_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  cropName?: string | null;
  cropName_not?: string | null;
  cropName_in?: string[] | null;
  cropName_not_in?: string[] | null;
  cropName_lt?: string | null;
  cropName_lte?: string | null;
  cropName_gt?: string | null;
  cropName_gte?: string | null;
  cropName_contains?: string | null;
  cropName_not_contains?: string | null;
  cropName_starts_with?: string | null;
  cropName_not_starts_with?: string | null;
  cropName_ends_with?: string | null;
  cropName_not_ends_with?: string | null;
  amount?: number | null;
  amount_not?: number | null;
  amount_in?: number[] | null;
  amount_not_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  filename?: string | null;
  filename_not?: string | null;
  filename_in?: string[] | null;
  filename_not_in?: string[] | null;
  filename_lt?: string | null;
  filename_lte?: string | null;
  filename_gt?: string | null;
  filename_gte?: string | null;
  filename_contains?: string | null;
  filename_not_contains?: string | null;
  filename_starts_with?: string | null;
  filename_not_starts_with?: string | null;
  filename_ends_with?: string | null;
  filename_not_ends_with?: string | null;
  deliveryWeek?: number | null;
  deliveryWeek_not?: number | null;
  deliveryWeek_in?: number[] | null;
  deliveryWeek_not_in?: number[] | null;
  deliveryWeek_lt?: number | null;
  deliveryWeek_lte?: number | null;
  deliveryWeek_gt?: number | null;
  deliveryWeek_gte?: number | null;
  deliveryDay?: number | null;
  deliveryDay_not?: number | null;
  deliveryDay_in?: number[] | null;
  deliveryDay_not_in?: number[] | null;
  deliveryDay_lt?: number | null;
  deliveryDay_lte?: number | null;
  deliveryDay_gt?: number | null;
  deliveryDay_gte?: number | null;
  deliveryYear?: number | null;
  deliveryYear_not?: number | null;
  deliveryYear_in?: number[] | null;
  deliveryYear_not_in?: number[] | null;
  deliveryYear_lt?: number | null;
  deliveryYear_lte?: number | null;
  deliveryYear_gt?: number | null;
  deliveryYear_gte?: number | null;
  soilBlockType?: string | null;
  soilBlockType_not?: string | null;
  soilBlockType_in?: string[] | null;
  soilBlockType_not_in?: string[] | null;
  soilBlockType_lt?: string | null;
  soilBlockType_lte?: string | null;
  soilBlockType_gt?: string | null;
  soilBlockType_gte?: string | null;
  soilBlockType_contains?: string | null;
  soilBlockType_not_contains?: string | null;
  soilBlockType_starts_with?: string | null;
  soilBlockType_not_starts_with?: string | null;
  soilBlockType_ends_with?: string | null;
  soilBlockType_not_ends_with?: string | null;
  soldTo?: string | null;
  soldTo_not?: string | null;
  soldTo_in?: string[] | null;
  soldTo_not_in?: string[] | null;
  soldTo_lt?: string | null;
  soldTo_lte?: string | null;
  soldTo_gt?: string | null;
  soldTo_gte?: string | null;
  soldTo_contains?: string | null;
  soldTo_not_contains?: string | null;
  soldTo_starts_with?: string | null;
  soldTo_not_starts_with?: string | null;
  soldTo_ends_with?: string | null;
  soldTo_not_ends_with?: string | null;
  farm?: FarmWhereInput | null;
}

export interface OrderItemWhereUniqueInput {
  id?: string | null;
}

export interface PaymentCreateManyWithoutInvoiceInput {
  create?: PaymentCreateWithoutInvoiceInput[] | null;
  connect?: PaymentWhereUniqueInput[] | null;
}

export interface PaymentCreateWithoutInvoiceInput {
  id?: string | null;
  paymentDate?: any | null;
  molliePaymentLinkId?: string | null;
  emailNotificationSent?: boolean | null;
  amount: number;
  dueDate: any;
  paid?: boolean | null;
}

export interface PaymentScalarWhereInput {
  AND?: PaymentScalarWhereInput[] | null;
  OR?: PaymentScalarWhereInput[] | null;
  NOT?: PaymentScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  paymentDate?: any | null;
  paymentDate_not?: any | null;
  paymentDate_in?: any[] | null;
  paymentDate_not_in?: any[] | null;
  paymentDate_lt?: any | null;
  paymentDate_lte?: any | null;
  paymentDate_gt?: any | null;
  paymentDate_gte?: any | null;
  molliePaymentLinkId?: string | null;
  molliePaymentLinkId_not?: string | null;
  molliePaymentLinkId_in?: string[] | null;
  molliePaymentLinkId_not_in?: string[] | null;
  molliePaymentLinkId_lt?: string | null;
  molliePaymentLinkId_lte?: string | null;
  molliePaymentLinkId_gt?: string | null;
  molliePaymentLinkId_gte?: string | null;
  molliePaymentLinkId_contains?: string | null;
  molliePaymentLinkId_not_contains?: string | null;
  molliePaymentLinkId_starts_with?: string | null;
  molliePaymentLinkId_not_starts_with?: string | null;
  molliePaymentLinkId_ends_with?: string | null;
  molliePaymentLinkId_not_ends_with?: string | null;
  emailNotificationSent?: boolean | null;
  emailNotificationSent_not?: boolean | null;
  amount?: number | null;
  amount_not?: number | null;
  amount_in?: number[] | null;
  amount_not_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  dueDate?: any | null;
  dueDate_not?: any | null;
  dueDate_in?: any[] | null;
  dueDate_not_in?: any[] | null;
  dueDate_lt?: any | null;
  dueDate_lte?: any | null;
  dueDate_gt?: any | null;
  dueDate_gte?: any | null;
  paid?: boolean | null;
  paid_not?: boolean | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
}

export interface PaymentUpdateManyDataInput {
  paymentDate?: any | null;
  molliePaymentLinkId?: string | null;
  emailNotificationSent?: boolean | null;
  amount?: number | null;
  dueDate?: any | null;
  paid?: boolean | null;
}

export interface PaymentUpdateManyWithWhereNestedInput {
  where: PaymentScalarWhereInput;
  data: PaymentUpdateManyDataInput;
}

export interface PaymentUpdateManyWithoutInvoiceInput {
  create?: PaymentCreateWithoutInvoiceInput[] | null;
  connect?: PaymentWhereUniqueInput[] | null;
  set?: PaymentWhereUniqueInput[] | null;
  disconnect?: PaymentWhereUniqueInput[] | null;
  delete?: PaymentWhereUniqueInput[] | null;
  update?: PaymentUpdateWithWhereUniqueWithoutInvoiceInput[] | null;
  updateMany?: PaymentUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: PaymentScalarWhereInput[] | null;
  upsert?: PaymentUpsertWithWhereUniqueWithoutInvoiceInput[] | null;
}

export interface PaymentUpdateWithWhereUniqueWithoutInvoiceInput {
  where: PaymentWhereUniqueInput;
  data: PaymentUpdateWithoutInvoiceDataInput;
}

export interface PaymentUpdateWithoutInvoiceDataInput {
  paymentDate?: any | null;
  molliePaymentLinkId?: string | null;
  emailNotificationSent?: boolean | null;
  amount?: number | null;
  dueDate?: any | null;
  paid?: boolean | null;
}

export interface PaymentUpsertWithWhereUniqueWithoutInvoiceInput {
  where: PaymentWhereUniqueInput;
  update: PaymentUpdateWithoutInvoiceDataInput;
  create: PaymentCreateWithoutInvoiceInput;
}

export interface PaymentWhereInput {
  AND?: PaymentWhereInput[] | null;
  OR?: PaymentWhereInput[] | null;
  NOT?: PaymentWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  paymentDate?: any | null;
  paymentDate_not?: any | null;
  paymentDate_in?: any[] | null;
  paymentDate_not_in?: any[] | null;
  paymentDate_lt?: any | null;
  paymentDate_lte?: any | null;
  paymentDate_gt?: any | null;
  paymentDate_gte?: any | null;
  molliePaymentLinkId?: string | null;
  molliePaymentLinkId_not?: string | null;
  molliePaymentLinkId_in?: string[] | null;
  molliePaymentLinkId_not_in?: string[] | null;
  molliePaymentLinkId_lt?: string | null;
  molliePaymentLinkId_lte?: string | null;
  molliePaymentLinkId_gt?: string | null;
  molliePaymentLinkId_gte?: string | null;
  molliePaymentLinkId_contains?: string | null;
  molliePaymentLinkId_not_contains?: string | null;
  molliePaymentLinkId_starts_with?: string | null;
  molliePaymentLinkId_not_starts_with?: string | null;
  molliePaymentLinkId_ends_with?: string | null;
  molliePaymentLinkId_not_ends_with?: string | null;
  emailNotificationSent?: boolean | null;
  emailNotificationSent_not?: boolean | null;
  amount?: number | null;
  amount_not?: number | null;
  amount_in?: number[] | null;
  amount_not_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  dueDate?: any | null;
  dueDate_not?: any | null;
  dueDate_in?: any[] | null;
  dueDate_not_in?: any[] | null;
  dueDate_lt?: any | null;
  dueDate_lte?: any | null;
  dueDate_gt?: any | null;
  dueDate_gte?: any | null;
  paid?: boolean | null;
  paid_not?: boolean | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  invoice?: InvoiceWhereInput | null;
}

export interface PaymentWhereUniqueInput {
  id?: string | null;
}

export interface PlantSchemaCreateInput {
  id?: string | null;
  distanceInRow: number;
  distanceBetweenRows: number;
  gridder?: Gridder | null;
  offset: boolean;
  crops?: CropCreateManyInput | null;
}

export interface PlantSchemaCreateOneInput {
  create?: PlantSchemaCreateInput | null;
  connect?: PlantSchemaWhereUniqueInput | null;
}

export interface PlantSchemaUpdateDataInput {
  distanceInRow?: number | null;
  distanceBetweenRows?: number | null;
  gridder?: Gridder | null;
  offset?: boolean | null;
  crops?: CropUpdateManyInput | null;
}

export interface PlantSchemaUpdateOneRequiredInput {
  create?: PlantSchemaCreateInput | null;
  connect?: PlantSchemaWhereUniqueInput | null;
  update?: PlantSchemaUpdateDataInput | null;
  upsert?: PlantSchemaUpsertNestedInput | null;
}

export interface PlantSchemaUpsertNestedInput {
  update: PlantSchemaUpdateDataInput;
  create: PlantSchemaCreateInput;
}

export interface PlantSchemaWhereInput {
  AND?: PlantSchemaWhereInput[] | null;
  OR?: PlantSchemaWhereInput[] | null;
  NOT?: PlantSchemaWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  distanceInRow?: number | null;
  distanceInRow_not?: number | null;
  distanceInRow_in?: number[] | null;
  distanceInRow_not_in?: number[] | null;
  distanceInRow_lt?: number | null;
  distanceInRow_lte?: number | null;
  distanceInRow_gt?: number | null;
  distanceInRow_gte?: number | null;
  distanceBetweenRows?: number | null;
  distanceBetweenRows_not?: number | null;
  distanceBetweenRows_in?: number[] | null;
  distanceBetweenRows_not_in?: number[] | null;
  distanceBetweenRows_lt?: number | null;
  distanceBetweenRows_lte?: number | null;
  distanceBetweenRows_gt?: number | null;
  distanceBetweenRows_gte?: number | null;
  gridder?: Gridder | null;
  gridder_not?: Gridder | null;
  gridder_in?: Gridder[] | null;
  gridder_not_in?: Gridder[] | null;
  offset?: boolean | null;
  offset_not?: boolean | null;
  crops_every?: CropWhereInput | null;
  crops_some?: CropWhereInput | null;
  crops_none?: CropWhereInput | null;
}

export interface PlantSchemaWhereUniqueInput {
  id?: string | null;
}

export interface PlotCreateManyWithoutRotationAreaInput {
  create?: PlotCreateWithoutRotationAreaInput[] | null;
  connect?: PlotWhereUniqueInput[] | null;
}

export interface PlotCreateOneWithoutCultivationsInput {
  create?: PlotCreateWithoutCultivationsInput | null;
  connect?: PlotWhereUniqueInput | null;
}

export interface PlotCreateOneWithoutNotesInput {
  create?: PlotCreateWithoutNotesInput | null;
  connect?: PlotWhereUniqueInput | null;
}

export interface PlotCreateWithoutCultivationsInput {
  id?: string | null;
  number: number;
  active?: boolean | null;
  rotationArea: RotationAreaCreateOneWithoutPlotsInput;
  notes?: NoteCreateManyWithoutPlotInput | null;
}

export interface PlotCreateWithoutNotesInput {
  id?: string | null;
  number: number;
  active?: boolean | null;
  rotationArea: RotationAreaCreateOneWithoutPlotsInput;
  cultivations?: CultivationCreateManyWithoutPlotInput | null;
}

export interface PlotCreateWithoutRotationAreaInput {
  id?: string | null;
  number: number;
  active?: boolean | null;
  cultivations?: CultivationCreateManyWithoutPlotInput | null;
  notes?: NoteCreateManyWithoutPlotInput | null;
}

export interface PlotScalarWhereInput {
  AND?: PlotScalarWhereInput[] | null;
  OR?: PlotScalarWhereInput[] | null;
  NOT?: PlotScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  number?: number | null;
  number_not?: number | null;
  number_in?: number[] | null;
  number_not_in?: number[] | null;
  number_lt?: number | null;
  number_lte?: number | null;
  number_gt?: number | null;
  number_gte?: number | null;
  active?: boolean | null;
  active_not?: boolean | null;
}

export interface PlotUpdateManyDataInput {
  number?: number | null;
  active?: boolean | null;
}

export interface PlotUpdateManyWithWhereNestedInput {
  where: PlotScalarWhereInput;
  data: PlotUpdateManyDataInput;
}

export interface PlotUpdateManyWithoutRotationAreaInput {
  create?: PlotCreateWithoutRotationAreaInput[] | null;
  connect?: PlotWhereUniqueInput[] | null;
  set?: PlotWhereUniqueInput[] | null;
  disconnect?: PlotWhereUniqueInput[] | null;
  delete?: PlotWhereUniqueInput[] | null;
  update?: PlotUpdateWithWhereUniqueWithoutRotationAreaInput[] | null;
  updateMany?: PlotUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: PlotScalarWhereInput[] | null;
  upsert?: PlotUpsertWithWhereUniqueWithoutRotationAreaInput[] | null;
}

export interface PlotUpdateOneRequiredWithoutCultivationsInput {
  create?: PlotCreateWithoutCultivationsInput | null;
  connect?: PlotWhereUniqueInput | null;
  update?: PlotUpdateWithoutCultivationsDataInput | null;
  upsert?: PlotUpsertWithoutCultivationsInput | null;
}

export interface PlotUpdateOneWithoutNotesInput {
  create?: PlotCreateWithoutNotesInput | null;
  connect?: PlotWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: PlotUpdateWithoutNotesDataInput | null;
  upsert?: PlotUpsertWithoutNotesInput | null;
}

export interface PlotUpdateWithWhereUniqueWithoutRotationAreaInput {
  where: PlotWhereUniqueInput;
  data: PlotUpdateWithoutRotationAreaDataInput;
}

export interface PlotUpdateWithoutCultivationsDataInput {
  number?: number | null;
  active?: boolean | null;
  rotationArea?: RotationAreaUpdateOneRequiredWithoutPlotsInput | null;
  notes?: NoteUpdateManyWithoutPlotInput | null;
}

export interface PlotUpdateWithoutNotesDataInput {
  number?: number | null;
  active?: boolean | null;
  rotationArea?: RotationAreaUpdateOneRequiredWithoutPlotsInput | null;
  cultivations?: CultivationUpdateManyWithoutPlotInput | null;
}

export interface PlotUpdateWithoutRotationAreaDataInput {
  number?: number | null;
  active?: boolean | null;
  cultivations?: CultivationUpdateManyWithoutPlotInput | null;
  notes?: NoteUpdateManyWithoutPlotInput | null;
}

export interface PlotUpsertWithWhereUniqueWithoutRotationAreaInput {
  where: PlotWhereUniqueInput;
  update: PlotUpdateWithoutRotationAreaDataInput;
  create: PlotCreateWithoutRotationAreaInput;
}

export interface PlotUpsertWithoutCultivationsInput {
  update: PlotUpdateWithoutCultivationsDataInput;
  create: PlotCreateWithoutCultivationsInput;
}

export interface PlotUpsertWithoutNotesInput {
  update: PlotUpdateWithoutNotesDataInput;
  create: PlotCreateWithoutNotesInput;
}

export interface PlotWhereInput {
  AND?: PlotWhereInput[] | null;
  OR?: PlotWhereInput[] | null;
  NOT?: PlotWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  number?: number | null;
  number_not?: number | null;
  number_in?: number[] | null;
  number_not_in?: number[] | null;
  number_lt?: number | null;
  number_lte?: number | null;
  number_gt?: number | null;
  number_gte?: number | null;
  active?: boolean | null;
  active_not?: boolean | null;
  rotationArea?: RotationAreaWhereInput | null;
  cultivations_every?: CultivationWhereInput | null;
  cultivations_some?: CultivationWhereInput | null;
  cultivations_none?: CultivationWhereInput | null;
  notes_every?: NoteWhereInput | null;
  notes_some?: NoteWhereInput | null;
  notes_none?: NoteWhereInput | null;
}

export interface PlotWhereUniqueInput {
  id?: string | null;
}

export interface ProductAvailabilityCreateManyWithoutClientGroupInput {
  create?: ProductAvailabilityCreateWithoutClientGroupInput[] | null;
  connect?: ProductAvailabilityWhereUniqueInput[] | null;
}

export interface ProductAvailabilityCreateManyWithoutProductInput {
  create?: ProductAvailabilityCreateWithoutProductInput[] | null;
  connect?: ProductAvailabilityWhereUniqueInput[] | null;
}

export interface ProductAvailabilityCreateWithoutClientGroupInput {
  id?: string | null;
  startDate: any;
  endDate?: any | null;
  note?: string | null;
  product: ProductCreateOneWithoutProductAvailabilitiesInput;
}

export interface ProductAvailabilityCreateWithoutProductInput {
  id?: string | null;
  startDate: any;
  endDate?: any | null;
  note?: string | null;
  clientGroup: ClientGroupCreateOneWithoutProductAvailablitiesInput;
}

export interface ProductAvailabilityScalarWhereInput {
  AND?: ProductAvailabilityScalarWhereInput[] | null;
  OR?: ProductAvailabilityScalarWhereInput[] | null;
  NOT?: ProductAvailabilityScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  startDate?: any | null;
  startDate_not?: any | null;
  startDate_in?: any[] | null;
  startDate_not_in?: any[] | null;
  startDate_lt?: any | null;
  startDate_lte?: any | null;
  startDate_gt?: any | null;
  startDate_gte?: any | null;
  endDate?: any | null;
  endDate_not?: any | null;
  endDate_in?: any[] | null;
  endDate_not_in?: any[] | null;
  endDate_lt?: any | null;
  endDate_lte?: any | null;
  endDate_gt?: any | null;
  endDate_gte?: any | null;
  note?: string | null;
  note_not?: string | null;
  note_in?: string[] | null;
  note_not_in?: string[] | null;
  note_lt?: string | null;
  note_lte?: string | null;
  note_gt?: string | null;
  note_gte?: string | null;
  note_contains?: string | null;
  note_not_contains?: string | null;
  note_starts_with?: string | null;
  note_not_starts_with?: string | null;
  note_ends_with?: string | null;
  note_not_ends_with?: string | null;
}

export interface ProductAvailabilityUpdateManyDataInput {
  startDate?: any | null;
  endDate?: any | null;
  note?: string | null;
}

export interface ProductAvailabilityUpdateManyWithWhereNestedInput {
  where: ProductAvailabilityScalarWhereInput;
  data: ProductAvailabilityUpdateManyDataInput;
}

export interface ProductAvailabilityUpdateManyWithoutClientGroupInput {
  create?: ProductAvailabilityCreateWithoutClientGroupInput[] | null;
  connect?: ProductAvailabilityWhereUniqueInput[] | null;
  set?: ProductAvailabilityWhereUniqueInput[] | null;
  disconnect?: ProductAvailabilityWhereUniqueInput[] | null;
  delete?: ProductAvailabilityWhereUniqueInput[] | null;
  update?: ProductAvailabilityUpdateWithWhereUniqueWithoutClientGroupInput[] | null;
  updateMany?: ProductAvailabilityUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ProductAvailabilityScalarWhereInput[] | null;
  upsert?: ProductAvailabilityUpsertWithWhereUniqueWithoutClientGroupInput[] | null;
}

export interface ProductAvailabilityUpdateManyWithoutProductInput {
  create?: ProductAvailabilityCreateWithoutProductInput[] | null;
  connect?: ProductAvailabilityWhereUniqueInput[] | null;
  set?: ProductAvailabilityWhereUniqueInput[] | null;
  disconnect?: ProductAvailabilityWhereUniqueInput[] | null;
  delete?: ProductAvailabilityWhereUniqueInput[] | null;
  update?: ProductAvailabilityUpdateWithWhereUniqueWithoutProductInput[] | null;
  updateMany?: ProductAvailabilityUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ProductAvailabilityScalarWhereInput[] | null;
  upsert?: ProductAvailabilityUpsertWithWhereUniqueWithoutProductInput[] | null;
}

export interface ProductAvailabilityUpdateWithWhereUniqueWithoutClientGroupInput {
  where: ProductAvailabilityWhereUniqueInput;
  data: ProductAvailabilityUpdateWithoutClientGroupDataInput;
}

export interface ProductAvailabilityUpdateWithWhereUniqueWithoutProductInput {
  where: ProductAvailabilityWhereUniqueInput;
  data: ProductAvailabilityUpdateWithoutProductDataInput;
}

export interface ProductAvailabilityUpdateWithoutClientGroupDataInput {
  startDate?: any | null;
  endDate?: any | null;
  note?: string | null;
  product?: ProductUpdateOneRequiredWithoutProductAvailabilitiesInput | null;
}

export interface ProductAvailabilityUpdateWithoutProductDataInput {
  startDate?: any | null;
  endDate?: any | null;
  note?: string | null;
  clientGroup?: ClientGroupUpdateOneRequiredWithoutProductAvailablitiesInput | null;
}

export interface ProductAvailabilityUpsertWithWhereUniqueWithoutClientGroupInput {
  where: ProductAvailabilityWhereUniqueInput;
  update: ProductAvailabilityUpdateWithoutClientGroupDataInput;
  create: ProductAvailabilityCreateWithoutClientGroupInput;
}

export interface ProductAvailabilityUpsertWithWhereUniqueWithoutProductInput {
  where: ProductAvailabilityWhereUniqueInput;
  update: ProductAvailabilityUpdateWithoutProductDataInput;
  create: ProductAvailabilityCreateWithoutProductInput;
}

export interface ProductAvailabilityWhereInput {
  AND?: ProductAvailabilityWhereInput[] | null;
  OR?: ProductAvailabilityWhereInput[] | null;
  NOT?: ProductAvailabilityWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  startDate?: any | null;
  startDate_not?: any | null;
  startDate_in?: any[] | null;
  startDate_not_in?: any[] | null;
  startDate_lt?: any | null;
  startDate_lte?: any | null;
  startDate_gt?: any | null;
  startDate_gte?: any | null;
  endDate?: any | null;
  endDate_not?: any | null;
  endDate_in?: any[] | null;
  endDate_not_in?: any[] | null;
  endDate_lt?: any | null;
  endDate_lte?: any | null;
  endDate_gt?: any | null;
  endDate_gte?: any | null;
  note?: string | null;
  note_not?: string | null;
  note_in?: string[] | null;
  note_not_in?: string[] | null;
  note_lt?: string | null;
  note_lte?: string | null;
  note_gt?: string | null;
  note_gte?: string | null;
  note_contains?: string | null;
  note_not_contains?: string | null;
  note_starts_with?: string | null;
  note_not_starts_with?: string | null;
  note_ends_with?: string | null;
  note_not_ends_with?: string | null;
  product?: ProductWhereInput | null;
  clientGroup?: ClientGroupWhereInput | null;
}

export interface ProductAvailabilityWhereUniqueInput {
  id?: string | null;
}

export interface ProductCategoryCreateInput {
  id?: string | null;
  name: string;
  code?: string | null;
  image?: string | null;
  visibleToCustomer?: boolean | null;
  returnablePackaging?: boolean | null;
  order?: number | null;
  parentCategoryId?: string | null;
  category?: ProductCategoryCreateOneInput | null;
}

export interface ProductCategoryCreateOneInput {
  create?: ProductCategoryCreateInput | null;
  connect?: ProductCategoryWhereUniqueInput | null;
}

export interface ProductCategoryUpdateDataInput {
  name?: string | null;
  code?: string | null;
  image?: string | null;
  visibleToCustomer?: boolean | null;
  returnablePackaging?: boolean | null;
  order?: number | null;
  parentCategoryId?: string | null;
  category?: ProductCategoryUpdateOneInput | null;
}

export interface ProductCategoryUpdateInput {
  name?: string | null;
  code?: string | null;
  image?: string | null;
  visibleToCustomer?: boolean | null;
  returnablePackaging?: boolean | null;
  order?: number | null;
  parentCategoryId?: string | null;
  category?: ProductCategoryUpdateOneInput | null;
}

export interface ProductCategoryUpdateOneInput {
  create?: ProductCategoryCreateInput | null;
  connect?: ProductCategoryWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProductCategoryUpdateDataInput | null;
  upsert?: ProductCategoryUpsertNestedInput | null;
}

export interface ProductCategoryUpsertNestedInput {
  update: ProductCategoryUpdateDataInput;
  create: ProductCategoryCreateInput;
}

export interface ProductCategoryWhereInput {
  AND?: ProductCategoryWhereInput[] | null;
  OR?: ProductCategoryWhereInput[] | null;
  NOT?: ProductCategoryWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  code?: string | null;
  code_not?: string | null;
  code_in?: string[] | null;
  code_not_in?: string[] | null;
  code_lt?: string | null;
  code_lte?: string | null;
  code_gt?: string | null;
  code_gte?: string | null;
  code_contains?: string | null;
  code_not_contains?: string | null;
  code_starts_with?: string | null;
  code_not_starts_with?: string | null;
  code_ends_with?: string | null;
  code_not_ends_with?: string | null;
  image?: string | null;
  image_not?: string | null;
  image_in?: string[] | null;
  image_not_in?: string[] | null;
  image_lt?: string | null;
  image_lte?: string | null;
  image_gt?: string | null;
  image_gte?: string | null;
  image_contains?: string | null;
  image_not_contains?: string | null;
  image_starts_with?: string | null;
  image_not_starts_with?: string | null;
  image_ends_with?: string | null;
  image_not_ends_with?: string | null;
  visibleToCustomer?: boolean | null;
  visibleToCustomer_not?: boolean | null;
  returnablePackaging?: boolean | null;
  returnablePackaging_not?: boolean | null;
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  parentCategoryId?: string | null;
  parentCategoryId_not?: string | null;
  parentCategoryId_in?: string[] | null;
  parentCategoryId_not_in?: string[] | null;
  parentCategoryId_lt?: string | null;
  parentCategoryId_lte?: string | null;
  parentCategoryId_gt?: string | null;
  parentCategoryId_gte?: string | null;
  parentCategoryId_contains?: string | null;
  parentCategoryId_not_contains?: string | null;
  parentCategoryId_starts_with?: string | null;
  parentCategoryId_not_starts_with?: string | null;
  parentCategoryId_ends_with?: string | null;
  parentCategoryId_not_ends_with?: string | null;
  category?: ProductCategoryWhereInput | null;
}

export interface ProductCategoryWhereUniqueInput {
  id?: string | null;
  code?: string | null;
}

export interface ProductCreateInput {
  id?: string | null;
  status?: ProductStatus | null;
  copied?: boolean | null;
  name: string;
  code?: string | null;
  image?: string | null;
  extra?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  unit?: ProductUnit | null;
  alternativeUnit?: ProductUnit | null;
  amount?: number | null;
  avgWeight?: number | null;
  minOrderQuantity?: number | null;
  vat?: Vat | null;
  fixedPrices?: boolean | null;
  portionWillem?: number | null;
  portionDirk?: number | null;
  price?: number | null;
  groupName?: string | null;
  type?: ProductType | null;
  flex?: boolean | null;
  duration?: number | null;
  availableAsAlternativeUnitFor?: ProductCreateavailableAsAlternativeUnitForInput | null;
  category?: ProductCategoryCreateOneInput | null;
  discounts?: ProductDiscountCreateManyWithoutProductInput | null;
  productPromos?: ProductPromoCreateManyWithoutProductInput | null;
  productPrices?: ProductPriceCreateManyWithoutProductInput | null;
  productAvailabilities?: ProductAvailabilityCreateManyWithoutProductInput | null;
  frequencies?: ProductFrequencyCreateManyWithoutProductsInput | null;
  productOrders?: ProductOrderCreateManyWithoutProductInput | null;
  basketItems?: BasketItemCreateManyWithoutProductInput | null;
  stock?: ProductStockCreateManyWithoutProductInput | null;
  farm: FarmCreateOneWithoutProductsInput;
}

export interface ProductCreateManyWithoutFarmInput {
  create?: ProductCreateWithoutFarmInput[] | null;
  connect?: ProductWhereUniqueInput[] | null;
}

export interface ProductCreateOneInput {
  create?: ProductCreateInput | null;
  connect?: ProductWhereUniqueInput | null;
}

export interface ProductCreateOneWithoutBasketItemsInput {
  create?: ProductCreateWithoutBasketItemsInput | null;
  connect?: ProductWhereUniqueInput | null;
}

export interface ProductCreateOneWithoutDiscountsInput {
  create?: ProductCreateWithoutDiscountsInput | null;
  connect?: ProductWhereUniqueInput | null;
}

export interface ProductCreateOneWithoutProductAvailabilitiesInput {
  create?: ProductCreateWithoutProductAvailabilitiesInput | null;
  connect?: ProductWhereUniqueInput | null;
}

export interface ProductCreateOneWithoutProductOrdersInput {
  create?: ProductCreateWithoutProductOrdersInput | null;
  connect?: ProductWhereUniqueInput | null;
}

export interface ProductCreateOneWithoutProductPricesInput {
  create?: ProductCreateWithoutProductPricesInput | null;
  connect?: ProductWhereUniqueInput | null;
}

export interface ProductCreateWithoutBasketItemsInput {
  id?: string | null;
  status?: ProductStatus | null;
  copied?: boolean | null;
  name: string;
  code?: string | null;
  image?: string | null;
  extra?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  unit?: ProductUnit | null;
  alternativeUnit?: ProductUnit | null;
  amount?: number | null;
  avgWeight?: number | null;
  minOrderQuantity?: number | null;
  vat?: Vat | null;
  fixedPrices?: boolean | null;
  portionWillem?: number | null;
  portionDirk?: number | null;
  price?: number | null;
  groupName?: string | null;
  type?: ProductType | null;
  flex?: boolean | null;
  duration?: number | null;
  availableAsAlternativeUnitFor?: ProductCreateavailableAsAlternativeUnitForInput | null;
  category?: ProductCategoryCreateOneInput | null;
  discounts?: ProductDiscountCreateManyWithoutProductInput | null;
  productPromos?: ProductPromoCreateManyWithoutProductInput | null;
  productPrices?: ProductPriceCreateManyWithoutProductInput | null;
  productAvailabilities?: ProductAvailabilityCreateManyWithoutProductInput | null;
  frequencies?: ProductFrequencyCreateManyWithoutProductsInput | null;
  productOrders?: ProductOrderCreateManyWithoutProductInput | null;
  stock?: ProductStockCreateManyWithoutProductInput | null;
  farm: FarmCreateOneWithoutProductsInput;
}

export interface ProductCreateWithoutDiscountsInput {
  id?: string | null;
  status?: ProductStatus | null;
  copied?: boolean | null;
  name: string;
  code?: string | null;
  image?: string | null;
  extra?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  unit?: ProductUnit | null;
  alternativeUnit?: ProductUnit | null;
  amount?: number | null;
  avgWeight?: number | null;
  minOrderQuantity?: number | null;
  vat?: Vat | null;
  fixedPrices?: boolean | null;
  portionWillem?: number | null;
  portionDirk?: number | null;
  price?: number | null;
  groupName?: string | null;
  type?: ProductType | null;
  flex?: boolean | null;
  duration?: number | null;
  availableAsAlternativeUnitFor?: ProductCreateavailableAsAlternativeUnitForInput | null;
  category?: ProductCategoryCreateOneInput | null;
  productPromos?: ProductPromoCreateManyWithoutProductInput | null;
  productPrices?: ProductPriceCreateManyWithoutProductInput | null;
  productAvailabilities?: ProductAvailabilityCreateManyWithoutProductInput | null;
  frequencies?: ProductFrequencyCreateManyWithoutProductsInput | null;
  productOrders?: ProductOrderCreateManyWithoutProductInput | null;
  basketItems?: BasketItemCreateManyWithoutProductInput | null;
  stock?: ProductStockCreateManyWithoutProductInput | null;
  farm: FarmCreateOneWithoutProductsInput;
}

export interface ProductCreateWithoutFarmInput {
  id?: string | null;
  status?: ProductStatus | null;
  copied?: boolean | null;
  name: string;
  code?: string | null;
  image?: string | null;
  extra?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  unit?: ProductUnit | null;
  alternativeUnit?: ProductUnit | null;
  amount?: number | null;
  avgWeight?: number | null;
  minOrderQuantity?: number | null;
  vat?: Vat | null;
  fixedPrices?: boolean | null;
  portionWillem?: number | null;
  portionDirk?: number | null;
  price?: number | null;
  groupName?: string | null;
  type?: ProductType | null;
  flex?: boolean | null;
  duration?: number | null;
  availableAsAlternativeUnitFor?: ProductCreateavailableAsAlternativeUnitForInput | null;
  category?: ProductCategoryCreateOneInput | null;
  discounts?: ProductDiscountCreateManyWithoutProductInput | null;
  productPromos?: ProductPromoCreateManyWithoutProductInput | null;
  productPrices?: ProductPriceCreateManyWithoutProductInput | null;
  productAvailabilities?: ProductAvailabilityCreateManyWithoutProductInput | null;
  frequencies?: ProductFrequencyCreateManyWithoutProductsInput | null;
  productOrders?: ProductOrderCreateManyWithoutProductInput | null;
  basketItems?: BasketItemCreateManyWithoutProductInput | null;
  stock?: ProductStockCreateManyWithoutProductInput | null;
}

export interface ProductCreateWithoutProductAvailabilitiesInput {
  id?: string | null;
  status?: ProductStatus | null;
  copied?: boolean | null;
  name: string;
  code?: string | null;
  image?: string | null;
  extra?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  unit?: ProductUnit | null;
  alternativeUnit?: ProductUnit | null;
  amount?: number | null;
  avgWeight?: number | null;
  minOrderQuantity?: number | null;
  vat?: Vat | null;
  fixedPrices?: boolean | null;
  portionWillem?: number | null;
  portionDirk?: number | null;
  price?: number | null;
  groupName?: string | null;
  type?: ProductType | null;
  flex?: boolean | null;
  duration?: number | null;
  availableAsAlternativeUnitFor?: ProductCreateavailableAsAlternativeUnitForInput | null;
  category?: ProductCategoryCreateOneInput | null;
  discounts?: ProductDiscountCreateManyWithoutProductInput | null;
  productPromos?: ProductPromoCreateManyWithoutProductInput | null;
  productPrices?: ProductPriceCreateManyWithoutProductInput | null;
  frequencies?: ProductFrequencyCreateManyWithoutProductsInput | null;
  productOrders?: ProductOrderCreateManyWithoutProductInput | null;
  basketItems?: BasketItemCreateManyWithoutProductInput | null;
  stock?: ProductStockCreateManyWithoutProductInput | null;
  farm: FarmCreateOneWithoutProductsInput;
}

export interface ProductCreateWithoutProductOrdersInput {
  id?: string | null;
  status?: ProductStatus | null;
  copied?: boolean | null;
  name: string;
  code?: string | null;
  image?: string | null;
  extra?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  unit?: ProductUnit | null;
  alternativeUnit?: ProductUnit | null;
  amount?: number | null;
  avgWeight?: number | null;
  minOrderQuantity?: number | null;
  vat?: Vat | null;
  fixedPrices?: boolean | null;
  portionWillem?: number | null;
  portionDirk?: number | null;
  price?: number | null;
  groupName?: string | null;
  type?: ProductType | null;
  flex?: boolean | null;
  duration?: number | null;
  availableAsAlternativeUnitFor?: ProductCreateavailableAsAlternativeUnitForInput | null;
  category?: ProductCategoryCreateOneInput | null;
  discounts?: ProductDiscountCreateManyWithoutProductInput | null;
  productPromos?: ProductPromoCreateManyWithoutProductInput | null;
  productPrices?: ProductPriceCreateManyWithoutProductInput | null;
  productAvailabilities?: ProductAvailabilityCreateManyWithoutProductInput | null;
  frequencies?: ProductFrequencyCreateManyWithoutProductsInput | null;
  basketItems?: BasketItemCreateManyWithoutProductInput | null;
  stock?: ProductStockCreateManyWithoutProductInput | null;
  farm: FarmCreateOneWithoutProductsInput;
}

export interface ProductCreateWithoutProductPricesInput {
  id?: string | null;
  status?: ProductStatus | null;
  copied?: boolean | null;
  name: string;
  code?: string | null;
  image?: string | null;
  extra?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  unit?: ProductUnit | null;
  alternativeUnit?: ProductUnit | null;
  amount?: number | null;
  avgWeight?: number | null;
  minOrderQuantity?: number | null;
  vat?: Vat | null;
  fixedPrices?: boolean | null;
  portionWillem?: number | null;
  portionDirk?: number | null;
  price?: number | null;
  groupName?: string | null;
  type?: ProductType | null;
  flex?: boolean | null;
  duration?: number | null;
  availableAsAlternativeUnitFor?: ProductCreateavailableAsAlternativeUnitForInput | null;
  category?: ProductCategoryCreateOneInput | null;
  discounts?: ProductDiscountCreateManyWithoutProductInput | null;
  productPromos?: ProductPromoCreateManyWithoutProductInput | null;
  productAvailabilities?: ProductAvailabilityCreateManyWithoutProductInput | null;
  frequencies?: ProductFrequencyCreateManyWithoutProductsInput | null;
  productOrders?: ProductOrderCreateManyWithoutProductInput | null;
  basketItems?: BasketItemCreateManyWithoutProductInput | null;
  stock?: ProductStockCreateManyWithoutProductInput | null;
  farm: FarmCreateOneWithoutProductsInput;
}

export interface ProductCreateavailableAsAlternativeUnitForInput {
  set?: string[] | null;
}

export interface ProductDiscountCreateManyWithoutClientGroupInput {
  create?: ProductDiscountCreateWithoutClientGroupInput[] | null;
  connect?: ProductDiscountWhereUniqueInput[] | null;
}

export interface ProductDiscountCreateManyWithoutProductInput {
  create?: ProductDiscountCreateWithoutProductInput[] | null;
  connect?: ProductDiscountWhereUniqueInput[] | null;
}

export interface ProductDiscountCreateWithoutClientGroupInput {
  id?: string | null;
  discount: number;
  product: ProductCreateOneWithoutDiscountsInput;
}

export interface ProductDiscountCreateWithoutProductInput {
  id?: string | null;
  discount: number;
  clientGroup: ClientGroupCreateOneWithoutDiscountsInput;
}

export interface ProductDiscountScalarWhereInput {
  AND?: ProductDiscountScalarWhereInput[] | null;
  OR?: ProductDiscountScalarWhereInput[] | null;
  NOT?: ProductDiscountScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  discount?: number | null;
  discount_not?: number | null;
  discount_in?: number[] | null;
  discount_not_in?: number[] | null;
  discount_lt?: number | null;
  discount_lte?: number | null;
  discount_gt?: number | null;
  discount_gte?: number | null;
}

export interface ProductDiscountUpdateManyDataInput {
  discount?: number | null;
}

export interface ProductDiscountUpdateManyWithWhereNestedInput {
  where: ProductDiscountScalarWhereInput;
  data: ProductDiscountUpdateManyDataInput;
}

export interface ProductDiscountUpdateManyWithoutClientGroupInput {
  create?: ProductDiscountCreateWithoutClientGroupInput[] | null;
  connect?: ProductDiscountWhereUniqueInput[] | null;
  set?: ProductDiscountWhereUniqueInput[] | null;
  disconnect?: ProductDiscountWhereUniqueInput[] | null;
  delete?: ProductDiscountWhereUniqueInput[] | null;
  update?: ProductDiscountUpdateWithWhereUniqueWithoutClientGroupInput[] | null;
  updateMany?: ProductDiscountUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ProductDiscountScalarWhereInput[] | null;
  upsert?: ProductDiscountUpsertWithWhereUniqueWithoutClientGroupInput[] | null;
}

export interface ProductDiscountUpdateManyWithoutProductInput {
  create?: ProductDiscountCreateWithoutProductInput[] | null;
  connect?: ProductDiscountWhereUniqueInput[] | null;
  set?: ProductDiscountWhereUniqueInput[] | null;
  disconnect?: ProductDiscountWhereUniqueInput[] | null;
  delete?: ProductDiscountWhereUniqueInput[] | null;
  update?: ProductDiscountUpdateWithWhereUniqueWithoutProductInput[] | null;
  updateMany?: ProductDiscountUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ProductDiscountScalarWhereInput[] | null;
  upsert?: ProductDiscountUpsertWithWhereUniqueWithoutProductInput[] | null;
}

export interface ProductDiscountUpdateWithWhereUniqueWithoutClientGroupInput {
  where: ProductDiscountWhereUniqueInput;
  data: ProductDiscountUpdateWithoutClientGroupDataInput;
}

export interface ProductDiscountUpdateWithWhereUniqueWithoutProductInput {
  where: ProductDiscountWhereUniqueInput;
  data: ProductDiscountUpdateWithoutProductDataInput;
}

export interface ProductDiscountUpdateWithoutClientGroupDataInput {
  discount?: number | null;
  product?: ProductUpdateOneRequiredWithoutDiscountsInput | null;
}

export interface ProductDiscountUpdateWithoutProductDataInput {
  discount?: number | null;
  clientGroup?: ClientGroupUpdateOneRequiredWithoutDiscountsInput | null;
}

export interface ProductDiscountUpsertWithWhereUniqueWithoutClientGroupInput {
  where: ProductDiscountWhereUniqueInput;
  update: ProductDiscountUpdateWithoutClientGroupDataInput;
  create: ProductDiscountCreateWithoutClientGroupInput;
}

export interface ProductDiscountUpsertWithWhereUniqueWithoutProductInput {
  where: ProductDiscountWhereUniqueInput;
  update: ProductDiscountUpdateWithoutProductDataInput;
  create: ProductDiscountCreateWithoutProductInput;
}

export interface ProductDiscountWhereInput {
  AND?: ProductDiscountWhereInput[] | null;
  OR?: ProductDiscountWhereInput[] | null;
  NOT?: ProductDiscountWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  discount?: number | null;
  discount_not?: number | null;
  discount_in?: number[] | null;
  discount_not_in?: number[] | null;
  discount_lt?: number | null;
  discount_lte?: number | null;
  discount_gt?: number | null;
  discount_gte?: number | null;
  product?: ProductWhereInput | null;
  clientGroup?: ClientGroupWhereInput | null;
}

export interface ProductDiscountWhereUniqueInput {
  id?: string | null;
}

export interface ProductFrequencyCreateManyWithoutProductsInput {
  create?: ProductFrequencyCreateWithoutProductsInput[] | null;
  connect?: ProductFrequencyWhereUniqueInput[] | null;
}

export interface ProductFrequencyCreateWithoutProductsInput {
  id?: string | null;
  code: string;
  name: string;
}

export interface ProductFrequencyScalarWhereInput {
  AND?: ProductFrequencyScalarWhereInput[] | null;
  OR?: ProductFrequencyScalarWhereInput[] | null;
  NOT?: ProductFrequencyScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  code?: string | null;
  code_not?: string | null;
  code_in?: string[] | null;
  code_not_in?: string[] | null;
  code_lt?: string | null;
  code_lte?: string | null;
  code_gt?: string | null;
  code_gte?: string | null;
  code_contains?: string | null;
  code_not_contains?: string | null;
  code_starts_with?: string | null;
  code_not_starts_with?: string | null;
  code_ends_with?: string | null;
  code_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
}

export interface ProductFrequencyUpdateManyDataInput {
  code?: string | null;
  name?: string | null;
}

export interface ProductFrequencyUpdateManyWithWhereNestedInput {
  where: ProductFrequencyScalarWhereInput;
  data: ProductFrequencyUpdateManyDataInput;
}

export interface ProductFrequencyUpdateManyWithoutProductsInput {
  create?: ProductFrequencyCreateWithoutProductsInput[] | null;
  connect?: ProductFrequencyWhereUniqueInput[] | null;
  set?: ProductFrequencyWhereUniqueInput[] | null;
  disconnect?: ProductFrequencyWhereUniqueInput[] | null;
  delete?: ProductFrequencyWhereUniqueInput[] | null;
  update?: ProductFrequencyUpdateWithWhereUniqueWithoutProductsInput[] | null;
  updateMany?: ProductFrequencyUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ProductFrequencyScalarWhereInput[] | null;
  upsert?: ProductFrequencyUpsertWithWhereUniqueWithoutProductsInput[] | null;
}

export interface ProductFrequencyUpdateWithWhereUniqueWithoutProductsInput {
  where: ProductFrequencyWhereUniqueInput;
  data: ProductFrequencyUpdateWithoutProductsDataInput;
}

export interface ProductFrequencyUpdateWithoutProductsDataInput {
  code?: string | null;
  name?: string | null;
}

export interface ProductFrequencyUpsertWithWhereUniqueWithoutProductsInput {
  where: ProductFrequencyWhereUniqueInput;
  update: ProductFrequencyUpdateWithoutProductsDataInput;
  create: ProductFrequencyCreateWithoutProductsInput;
}

export interface ProductFrequencyWhereInput {
  AND?: ProductFrequencyWhereInput[] | null;
  OR?: ProductFrequencyWhereInput[] | null;
  NOT?: ProductFrequencyWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  code?: string | null;
  code_not?: string | null;
  code_in?: string[] | null;
  code_not_in?: string[] | null;
  code_lt?: string | null;
  code_lte?: string | null;
  code_gt?: string | null;
  code_gte?: string | null;
  code_contains?: string | null;
  code_not_contains?: string | null;
  code_starts_with?: string | null;
  code_not_starts_with?: string | null;
  code_ends_with?: string | null;
  code_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  products_every?: ProductWhereInput | null;
  products_some?: ProductWhereInput | null;
  products_none?: ProductWhereInput | null;
}

export interface ProductFrequencyWhereUniqueInput {
  id?: string | null;
  code?: string | null;
}

export interface ProductOrderCreateInput {
  id?: string | null;
  productDescription?: string | null;
  unit?: ProductUnit | null;
  vat?: Vat | null;
  priceExcl: number;
  quantity: number;
  remark?: string | null;
  corrected?: boolean | null;
  ambassadorDiscount?: boolean | null;
  deliveryDate?: any | null;
  portions?: number | null;
  usedPortions?: number | null;
  frequency?: string | null;
  clientOrder: ClientOrderCreateOneWithoutProductOrdersInput;
  product?: ProductCreateOneWithoutProductOrdersInput | null;
  deliveries?: ProductOrderDeliveryCreateManyWithoutProductOrderInput | null;
  discount?: ProductOrderDiscountCreateOneInput | null;
}

export interface ProductOrderCreateManyWithoutClientOrderInput {
  create?: ProductOrderCreateWithoutClientOrderInput[] | null;
  connect?: ProductOrderWhereUniqueInput[] | null;
}

export interface ProductOrderCreateManyWithoutProductInput {
  create?: ProductOrderCreateWithoutProductInput[] | null;
  connect?: ProductOrderWhereUniqueInput[] | null;
}

export interface ProductOrderCreateOneWithoutDeliveriesInput {
  create?: ProductOrderCreateWithoutDeliveriesInput | null;
  connect?: ProductOrderWhereUniqueInput | null;
}

export interface ProductOrderCreateWithoutClientOrderInput {
  id?: string | null;
  productDescription?: string | null;
  unit?: ProductUnit | null;
  vat?: Vat | null;
  priceExcl: number;
  quantity: number;
  remark?: string | null;
  corrected?: boolean | null;
  ambassadorDiscount?: boolean | null;
  deliveryDate?: any | null;
  portions?: number | null;
  usedPortions?: number | null;
  frequency?: string | null;
  product?: ProductCreateOneWithoutProductOrdersInput | null;
  deliveries?: ProductOrderDeliveryCreateManyWithoutProductOrderInput | null;
  discount?: ProductOrderDiscountCreateOneInput | null;
}

export interface ProductOrderCreateWithoutDeliveriesInput {
  id?: string | null;
  productDescription?: string | null;
  unit?: ProductUnit | null;
  vat?: Vat | null;
  priceExcl: number;
  quantity: number;
  remark?: string | null;
  corrected?: boolean | null;
  ambassadorDiscount?: boolean | null;
  deliveryDate?: any | null;
  portions?: number | null;
  usedPortions?: number | null;
  frequency?: string | null;
  clientOrder: ClientOrderCreateOneWithoutProductOrdersInput;
  product?: ProductCreateOneWithoutProductOrdersInput | null;
  discount?: ProductOrderDiscountCreateOneInput | null;
}

export interface ProductOrderCreateWithoutProductInput {
  id?: string | null;
  productDescription?: string | null;
  unit?: ProductUnit | null;
  vat?: Vat | null;
  priceExcl: number;
  quantity: number;
  remark?: string | null;
  corrected?: boolean | null;
  ambassadorDiscount?: boolean | null;
  deliveryDate?: any | null;
  portions?: number | null;
  usedPortions?: number | null;
  frequency?: string | null;
  clientOrder: ClientOrderCreateOneWithoutProductOrdersInput;
  deliveries?: ProductOrderDeliveryCreateManyWithoutProductOrderInput | null;
  discount?: ProductOrderDiscountCreateOneInput | null;
}

export interface ProductOrderDeliveryCreateManyWithoutBasketCompositionInput {
  create?: ProductOrderDeliveryCreateWithoutBasketCompositionInput[] | null;
  connect?: ProductOrderDeliveryWhereUniqueInput[] | null;
}

export interface ProductOrderDeliveryCreateManyWithoutProductOrderInput {
  create?: ProductOrderDeliveryCreateWithoutProductOrderInput[] | null;
  connect?: ProductOrderDeliveryWhereUniqueInput[] | null;
}

export interface ProductOrderDeliveryCreateWithoutBasketCompositionInput {
  id?: string | null;
  plannedDeliveryDate: any;
  deliveryDate?: any | null;
  deliveryLocation?: DeliveryLocation | null;
  portions?: number | null;
  cancelled?: boolean | null;
  deliveredProductIds?: ProductOrderDeliveryCreatedeliveredProductIdsInput | null;
  undeliverableProductIds?: ProductOrderDeliveryCreateundeliverableProductIdsInput | null;
  productOrder: ProductOrderCreateOneWithoutDeliveriesInput;
}

export interface ProductOrderDeliveryCreateWithoutProductOrderInput {
  id?: string | null;
  plannedDeliveryDate: any;
  deliveryDate?: any | null;
  deliveryLocation?: DeliveryLocation | null;
  portions?: number | null;
  cancelled?: boolean | null;
  deliveredProductIds?: ProductOrderDeliveryCreatedeliveredProductIdsInput | null;
  undeliverableProductIds?: ProductOrderDeliveryCreateundeliverableProductIdsInput | null;
  basketComposition?: BasketCompositionCreateOneWithoutDeliveriesInput | null;
}

export interface ProductOrderDeliveryCreatedeliveredProductIdsInput {
  set?: string[] | null;
}

export interface ProductOrderDeliveryCreateundeliverableProductIdsInput {
  set?: string[] | null;
}

export interface ProductOrderDeliveryScalarWhereInput {
  AND?: ProductOrderDeliveryScalarWhereInput[] | null;
  OR?: ProductOrderDeliveryScalarWhereInput[] | null;
  NOT?: ProductOrderDeliveryScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  plannedDeliveryDate?: any | null;
  plannedDeliveryDate_not?: any | null;
  plannedDeliveryDate_in?: any[] | null;
  plannedDeliveryDate_not_in?: any[] | null;
  plannedDeliveryDate_lt?: any | null;
  plannedDeliveryDate_lte?: any | null;
  plannedDeliveryDate_gt?: any | null;
  plannedDeliveryDate_gte?: any | null;
  deliveryDate?: any | null;
  deliveryDate_not?: any | null;
  deliveryDate_in?: any[] | null;
  deliveryDate_not_in?: any[] | null;
  deliveryDate_lt?: any | null;
  deliveryDate_lte?: any | null;
  deliveryDate_gt?: any | null;
  deliveryDate_gte?: any | null;
  deliveryLocation?: DeliveryLocation | null;
  deliveryLocation_not?: DeliveryLocation | null;
  deliveryLocation_in?: DeliveryLocation[] | null;
  deliveryLocation_not_in?: DeliveryLocation[] | null;
  portions?: number | null;
  portions_not?: number | null;
  portions_in?: number[] | null;
  portions_not_in?: number[] | null;
  portions_lt?: number | null;
  portions_lte?: number | null;
  portions_gt?: number | null;
  portions_gte?: number | null;
  cancelled?: boolean | null;
  cancelled_not?: boolean | null;
}

export interface ProductOrderDeliveryUpdateInput {
  plannedDeliveryDate?: any | null;
  deliveryDate?: any | null;
  deliveryLocation?: DeliveryLocation | null;
  portions?: number | null;
  cancelled?: boolean | null;
  deliveredProductIds?: ProductOrderDeliveryUpdatedeliveredProductIdsInput | null;
  undeliverableProductIds?: ProductOrderDeliveryUpdateundeliverableProductIdsInput | null;
  productOrder?: ProductOrderUpdateOneRequiredWithoutDeliveriesInput | null;
  basketComposition?: BasketCompositionUpdateOneWithoutDeliveriesInput | null;
}

export interface ProductOrderDeliveryUpdateManyDataInput {
  plannedDeliveryDate?: any | null;
  deliveryDate?: any | null;
  deliveryLocation?: DeliveryLocation | null;
  portions?: number | null;
  cancelled?: boolean | null;
  deliveredProductIds?: ProductOrderDeliveryUpdatedeliveredProductIdsInput | null;
  undeliverableProductIds?: ProductOrderDeliveryUpdateundeliverableProductIdsInput | null;
}

export interface ProductOrderDeliveryUpdateManyMutationInput {
  plannedDeliveryDate?: any | null;
  deliveryDate?: any | null;
  deliveryLocation?: DeliveryLocation | null;
  portions?: number | null;
  cancelled?: boolean | null;
  deliveredProductIds?: ProductOrderDeliveryUpdatedeliveredProductIdsInput | null;
  undeliverableProductIds?: ProductOrderDeliveryUpdateundeliverableProductIdsInput | null;
}

export interface ProductOrderDeliveryUpdateManyWithWhereNestedInput {
  where: ProductOrderDeliveryScalarWhereInput;
  data: ProductOrderDeliveryUpdateManyDataInput;
}

export interface ProductOrderDeliveryUpdateManyWithoutBasketCompositionInput {
  create?: ProductOrderDeliveryCreateWithoutBasketCompositionInput[] | null;
  connect?: ProductOrderDeliveryWhereUniqueInput[] | null;
  set?: ProductOrderDeliveryWhereUniqueInput[] | null;
  disconnect?: ProductOrderDeliveryWhereUniqueInput[] | null;
  delete?: ProductOrderDeliveryWhereUniqueInput[] | null;
  update?: ProductOrderDeliveryUpdateWithWhereUniqueWithoutBasketCompositionInput[] | null;
  updateMany?: ProductOrderDeliveryUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ProductOrderDeliveryScalarWhereInput[] | null;
  upsert?: ProductOrderDeliveryUpsertWithWhereUniqueWithoutBasketCompositionInput[] | null;
}

export interface ProductOrderDeliveryUpdateManyWithoutProductOrderInput {
  create?: ProductOrderDeliveryCreateWithoutProductOrderInput[] | null;
  connect?: ProductOrderDeliveryWhereUniqueInput[] | null;
  set?: ProductOrderDeliveryWhereUniqueInput[] | null;
  disconnect?: ProductOrderDeliveryWhereUniqueInput[] | null;
  delete?: ProductOrderDeliveryWhereUniqueInput[] | null;
  update?: ProductOrderDeliveryUpdateWithWhereUniqueWithoutProductOrderInput[] | null;
  updateMany?: ProductOrderDeliveryUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ProductOrderDeliveryScalarWhereInput[] | null;
  upsert?: ProductOrderDeliveryUpsertWithWhereUniqueWithoutProductOrderInput[] | null;
}

export interface ProductOrderDeliveryUpdateWithWhereUniqueWithoutBasketCompositionInput {
  where: ProductOrderDeliveryWhereUniqueInput;
  data: ProductOrderDeliveryUpdateWithoutBasketCompositionDataInput;
}

export interface ProductOrderDeliveryUpdateWithWhereUniqueWithoutProductOrderInput {
  where: ProductOrderDeliveryWhereUniqueInput;
  data: ProductOrderDeliveryUpdateWithoutProductOrderDataInput;
}

export interface ProductOrderDeliveryUpdateWithoutBasketCompositionDataInput {
  plannedDeliveryDate?: any | null;
  deliveryDate?: any | null;
  deliveryLocation?: DeliveryLocation | null;
  portions?: number | null;
  cancelled?: boolean | null;
  deliveredProductIds?: ProductOrderDeliveryUpdatedeliveredProductIdsInput | null;
  undeliverableProductIds?: ProductOrderDeliveryUpdateundeliverableProductIdsInput | null;
  productOrder?: ProductOrderUpdateOneRequiredWithoutDeliveriesInput | null;
}

export interface ProductOrderDeliveryUpdateWithoutProductOrderDataInput {
  plannedDeliveryDate?: any | null;
  deliveryDate?: any | null;
  deliveryLocation?: DeliveryLocation | null;
  portions?: number | null;
  cancelled?: boolean | null;
  deliveredProductIds?: ProductOrderDeliveryUpdatedeliveredProductIdsInput | null;
  undeliverableProductIds?: ProductOrderDeliveryUpdateundeliverableProductIdsInput | null;
  basketComposition?: BasketCompositionUpdateOneWithoutDeliveriesInput | null;
}

export interface ProductOrderDeliveryUpdatedeliveredProductIdsInput {
  set?: string[] | null;
}

export interface ProductOrderDeliveryUpdateundeliverableProductIdsInput {
  set?: string[] | null;
}

export interface ProductOrderDeliveryUpsertWithWhereUniqueWithoutBasketCompositionInput {
  where: ProductOrderDeliveryWhereUniqueInput;
  update: ProductOrderDeliveryUpdateWithoutBasketCompositionDataInput;
  create: ProductOrderDeliveryCreateWithoutBasketCompositionInput;
}

export interface ProductOrderDeliveryUpsertWithWhereUniqueWithoutProductOrderInput {
  where: ProductOrderDeliveryWhereUniqueInput;
  update: ProductOrderDeliveryUpdateWithoutProductOrderDataInput;
  create: ProductOrderDeliveryCreateWithoutProductOrderInput;
}

export interface ProductOrderDeliveryWhereInput {
  AND?: ProductOrderDeliveryWhereInput[] | null;
  OR?: ProductOrderDeliveryWhereInput[] | null;
  NOT?: ProductOrderDeliveryWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  plannedDeliveryDate?: any | null;
  plannedDeliveryDate_not?: any | null;
  plannedDeliveryDate_in?: any[] | null;
  plannedDeliveryDate_not_in?: any[] | null;
  plannedDeliveryDate_lt?: any | null;
  plannedDeliveryDate_lte?: any | null;
  plannedDeliveryDate_gt?: any | null;
  plannedDeliveryDate_gte?: any | null;
  deliveryDate?: any | null;
  deliveryDate_not?: any | null;
  deliveryDate_in?: any[] | null;
  deliveryDate_not_in?: any[] | null;
  deliveryDate_lt?: any | null;
  deliveryDate_lte?: any | null;
  deliveryDate_gt?: any | null;
  deliveryDate_gte?: any | null;
  deliveryLocation?: DeliveryLocation | null;
  deliveryLocation_not?: DeliveryLocation | null;
  deliveryLocation_in?: DeliveryLocation[] | null;
  deliveryLocation_not_in?: DeliveryLocation[] | null;
  portions?: number | null;
  portions_not?: number | null;
  portions_in?: number[] | null;
  portions_not_in?: number[] | null;
  portions_lt?: number | null;
  portions_lte?: number | null;
  portions_gt?: number | null;
  portions_gte?: number | null;
  cancelled?: boolean | null;
  cancelled_not?: boolean | null;
  productOrder?: ProductOrderWhereInput | null;
  basketComposition?: BasketCompositionWhereInput | null;
}

export interface ProductOrderDeliveryWhereUniqueInput {
  id?: string | null;
}

export interface ProductOrderDiscountCreateInput {
  id?: string | null;
  value: number;
  type: DiscountType;
}

export interface ProductOrderDiscountCreateOneInput {
  create?: ProductOrderDiscountCreateInput | null;
  connect?: ProductOrderDiscountWhereUniqueInput | null;
}

export interface ProductOrderDiscountUpdateDataInput {
  value?: number | null;
  type?: DiscountType | null;
}

export interface ProductOrderDiscountUpdateOneInput {
  create?: ProductOrderDiscountCreateInput | null;
  connect?: ProductOrderDiscountWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProductOrderDiscountUpdateDataInput | null;
  upsert?: ProductOrderDiscountUpsertNestedInput | null;
}

export interface ProductOrderDiscountUpsertNestedInput {
  update: ProductOrderDiscountUpdateDataInput;
  create: ProductOrderDiscountCreateInput;
}

export interface ProductOrderDiscountWhereInput {
  AND?: ProductOrderDiscountWhereInput[] | null;
  OR?: ProductOrderDiscountWhereInput[] | null;
  NOT?: ProductOrderDiscountWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  value?: number | null;
  value_not?: number | null;
  value_in?: number[] | null;
  value_not_in?: number[] | null;
  value_lt?: number | null;
  value_lte?: number | null;
  value_gt?: number | null;
  value_gte?: number | null;
  type?: DiscountType | null;
  type_not?: DiscountType | null;
  type_in?: DiscountType[] | null;
  type_not_in?: DiscountType[] | null;
}

export interface ProductOrderDiscountWhereUniqueInput {
  id?: string | null;
}

export interface ProductOrderScalarWhereInput {
  AND?: ProductOrderScalarWhereInput[] | null;
  OR?: ProductOrderScalarWhereInput[] | null;
  NOT?: ProductOrderScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  productDescription?: string | null;
  productDescription_not?: string | null;
  productDescription_in?: string[] | null;
  productDescription_not_in?: string[] | null;
  productDescription_lt?: string | null;
  productDescription_lte?: string | null;
  productDescription_gt?: string | null;
  productDescription_gte?: string | null;
  productDescription_contains?: string | null;
  productDescription_not_contains?: string | null;
  productDescription_starts_with?: string | null;
  productDescription_not_starts_with?: string | null;
  productDescription_ends_with?: string | null;
  productDescription_not_ends_with?: string | null;
  unit?: ProductUnit | null;
  unit_not?: ProductUnit | null;
  unit_in?: ProductUnit[] | null;
  unit_not_in?: ProductUnit[] | null;
  vat?: Vat | null;
  vat_not?: Vat | null;
  vat_in?: Vat[] | null;
  vat_not_in?: Vat[] | null;
  priceExcl?: number | null;
  priceExcl_not?: number | null;
  priceExcl_in?: number[] | null;
  priceExcl_not_in?: number[] | null;
  priceExcl_lt?: number | null;
  priceExcl_lte?: number | null;
  priceExcl_gt?: number | null;
  priceExcl_gte?: number | null;
  quantity?: number | null;
  quantity_not?: number | null;
  quantity_in?: number[] | null;
  quantity_not_in?: number[] | null;
  quantity_lt?: number | null;
  quantity_lte?: number | null;
  quantity_gt?: number | null;
  quantity_gte?: number | null;
  remark?: string | null;
  remark_not?: string | null;
  remark_in?: string[] | null;
  remark_not_in?: string[] | null;
  remark_lt?: string | null;
  remark_lte?: string | null;
  remark_gt?: string | null;
  remark_gte?: string | null;
  remark_contains?: string | null;
  remark_not_contains?: string | null;
  remark_starts_with?: string | null;
  remark_not_starts_with?: string | null;
  remark_ends_with?: string | null;
  remark_not_ends_with?: string | null;
  corrected?: boolean | null;
  corrected_not?: boolean | null;
  ambassadorDiscount?: boolean | null;
  ambassadorDiscount_not?: boolean | null;
  deliveryDate?: any | null;
  deliveryDate_not?: any | null;
  deliveryDate_in?: any[] | null;
  deliveryDate_not_in?: any[] | null;
  deliveryDate_lt?: any | null;
  deliveryDate_lte?: any | null;
  deliveryDate_gt?: any | null;
  deliveryDate_gte?: any | null;
  portions?: number | null;
  portions_not?: number | null;
  portions_in?: number[] | null;
  portions_not_in?: number[] | null;
  portions_lt?: number | null;
  portions_lte?: number | null;
  portions_gt?: number | null;
  portions_gte?: number | null;
  usedPortions?: number | null;
  usedPortions_not?: number | null;
  usedPortions_in?: number[] | null;
  usedPortions_not_in?: number[] | null;
  usedPortions_lt?: number | null;
  usedPortions_lte?: number | null;
  usedPortions_gt?: number | null;
  usedPortions_gte?: number | null;
  frequency?: string | null;
  frequency_not?: string | null;
  frequency_in?: string[] | null;
  frequency_not_in?: string[] | null;
  frequency_lt?: string | null;
  frequency_lte?: string | null;
  frequency_gt?: string | null;
  frequency_gte?: string | null;
  frequency_contains?: string | null;
  frequency_not_contains?: string | null;
  frequency_starts_with?: string | null;
  frequency_not_starts_with?: string | null;
  frequency_ends_with?: string | null;
  frequency_not_ends_with?: string | null;
}

export interface ProductOrderUpdateInput {
  productDescription?: string | null;
  unit?: ProductUnit | null;
  vat?: Vat | null;
  priceExcl?: number | null;
  quantity?: number | null;
  remark?: string | null;
  corrected?: boolean | null;
  ambassadorDiscount?: boolean | null;
  deliveryDate?: any | null;
  portions?: number | null;
  usedPortions?: number | null;
  frequency?: string | null;
  clientOrder?: ClientOrderUpdateOneRequiredWithoutProductOrdersInput | null;
  product?: ProductUpdateOneWithoutProductOrdersInput | null;
  deliveries?: ProductOrderDeliveryUpdateManyWithoutProductOrderInput | null;
  discount?: ProductOrderDiscountUpdateOneInput | null;
}

export interface ProductOrderUpdateManyDataInput {
  productDescription?: string | null;
  unit?: ProductUnit | null;
  vat?: Vat | null;
  priceExcl?: number | null;
  quantity?: number | null;
  remark?: string | null;
  corrected?: boolean | null;
  ambassadorDiscount?: boolean | null;
  deliveryDate?: any | null;
  portions?: number | null;
  usedPortions?: number | null;
  frequency?: string | null;
}

export interface ProductOrderUpdateManyWithWhereNestedInput {
  where: ProductOrderScalarWhereInput;
  data: ProductOrderUpdateManyDataInput;
}

export interface ProductOrderUpdateManyWithoutClientOrderInput {
  create?: ProductOrderCreateWithoutClientOrderInput[] | null;
  connect?: ProductOrderWhereUniqueInput[] | null;
  set?: ProductOrderWhereUniqueInput[] | null;
  disconnect?: ProductOrderWhereUniqueInput[] | null;
  delete?: ProductOrderWhereUniqueInput[] | null;
  update?: ProductOrderUpdateWithWhereUniqueWithoutClientOrderInput[] | null;
  updateMany?: ProductOrderUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ProductOrderScalarWhereInput[] | null;
  upsert?: ProductOrderUpsertWithWhereUniqueWithoutClientOrderInput[] | null;
}

export interface ProductOrderUpdateManyWithoutProductInput {
  create?: ProductOrderCreateWithoutProductInput[] | null;
  connect?: ProductOrderWhereUniqueInput[] | null;
  set?: ProductOrderWhereUniqueInput[] | null;
  disconnect?: ProductOrderWhereUniqueInput[] | null;
  delete?: ProductOrderWhereUniqueInput[] | null;
  update?: ProductOrderUpdateWithWhereUniqueWithoutProductInput[] | null;
  updateMany?: ProductOrderUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ProductOrderScalarWhereInput[] | null;
  upsert?: ProductOrderUpsertWithWhereUniqueWithoutProductInput[] | null;
}

export interface ProductOrderUpdateOneRequiredWithoutDeliveriesInput {
  create?: ProductOrderCreateWithoutDeliveriesInput | null;
  connect?: ProductOrderWhereUniqueInput | null;
  update?: ProductOrderUpdateWithoutDeliveriesDataInput | null;
  upsert?: ProductOrderUpsertWithoutDeliveriesInput | null;
}

export interface ProductOrderUpdateWithWhereUniqueWithoutClientOrderInput {
  where: ProductOrderWhereUniqueInput;
  data: ProductOrderUpdateWithoutClientOrderDataInput;
}

export interface ProductOrderUpdateWithWhereUniqueWithoutProductInput {
  where: ProductOrderWhereUniqueInput;
  data: ProductOrderUpdateWithoutProductDataInput;
}

export interface ProductOrderUpdateWithoutClientOrderDataInput {
  productDescription?: string | null;
  unit?: ProductUnit | null;
  vat?: Vat | null;
  priceExcl?: number | null;
  quantity?: number | null;
  remark?: string | null;
  corrected?: boolean | null;
  ambassadorDiscount?: boolean | null;
  deliveryDate?: any | null;
  portions?: number | null;
  usedPortions?: number | null;
  frequency?: string | null;
  product?: ProductUpdateOneWithoutProductOrdersInput | null;
  deliveries?: ProductOrderDeliveryUpdateManyWithoutProductOrderInput | null;
  discount?: ProductOrderDiscountUpdateOneInput | null;
}

export interface ProductOrderUpdateWithoutDeliveriesDataInput {
  productDescription?: string | null;
  unit?: ProductUnit | null;
  vat?: Vat | null;
  priceExcl?: number | null;
  quantity?: number | null;
  remark?: string | null;
  corrected?: boolean | null;
  ambassadorDiscount?: boolean | null;
  deliveryDate?: any | null;
  portions?: number | null;
  usedPortions?: number | null;
  frequency?: string | null;
  clientOrder?: ClientOrderUpdateOneRequiredWithoutProductOrdersInput | null;
  product?: ProductUpdateOneWithoutProductOrdersInput | null;
  discount?: ProductOrderDiscountUpdateOneInput | null;
}

export interface ProductOrderUpdateWithoutProductDataInput {
  productDescription?: string | null;
  unit?: ProductUnit | null;
  vat?: Vat | null;
  priceExcl?: number | null;
  quantity?: number | null;
  remark?: string | null;
  corrected?: boolean | null;
  ambassadorDiscount?: boolean | null;
  deliveryDate?: any | null;
  portions?: number | null;
  usedPortions?: number | null;
  frequency?: string | null;
  clientOrder?: ClientOrderUpdateOneRequiredWithoutProductOrdersInput | null;
  deliveries?: ProductOrderDeliveryUpdateManyWithoutProductOrderInput | null;
  discount?: ProductOrderDiscountUpdateOneInput | null;
}

export interface ProductOrderUpsertWithWhereUniqueWithoutClientOrderInput {
  where: ProductOrderWhereUniqueInput;
  update: ProductOrderUpdateWithoutClientOrderDataInput;
  create: ProductOrderCreateWithoutClientOrderInput;
}

export interface ProductOrderUpsertWithWhereUniqueWithoutProductInput {
  where: ProductOrderWhereUniqueInput;
  update: ProductOrderUpdateWithoutProductDataInput;
  create: ProductOrderCreateWithoutProductInput;
}

export interface ProductOrderUpsertWithoutDeliveriesInput {
  update: ProductOrderUpdateWithoutDeliveriesDataInput;
  create: ProductOrderCreateWithoutDeliveriesInput;
}

export interface ProductOrderWhereInput {
  AND?: ProductOrderWhereInput[] | null;
  OR?: ProductOrderWhereInput[] | null;
  NOT?: ProductOrderWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  productDescription?: string | null;
  productDescription_not?: string | null;
  productDescription_in?: string[] | null;
  productDescription_not_in?: string[] | null;
  productDescription_lt?: string | null;
  productDescription_lte?: string | null;
  productDescription_gt?: string | null;
  productDescription_gte?: string | null;
  productDescription_contains?: string | null;
  productDescription_not_contains?: string | null;
  productDescription_starts_with?: string | null;
  productDescription_not_starts_with?: string | null;
  productDescription_ends_with?: string | null;
  productDescription_not_ends_with?: string | null;
  unit?: ProductUnit | null;
  unit_not?: ProductUnit | null;
  unit_in?: ProductUnit[] | null;
  unit_not_in?: ProductUnit[] | null;
  vat?: Vat | null;
  vat_not?: Vat | null;
  vat_in?: Vat[] | null;
  vat_not_in?: Vat[] | null;
  priceExcl?: number | null;
  priceExcl_not?: number | null;
  priceExcl_in?: number[] | null;
  priceExcl_not_in?: number[] | null;
  priceExcl_lt?: number | null;
  priceExcl_lte?: number | null;
  priceExcl_gt?: number | null;
  priceExcl_gte?: number | null;
  quantity?: number | null;
  quantity_not?: number | null;
  quantity_in?: number[] | null;
  quantity_not_in?: number[] | null;
  quantity_lt?: number | null;
  quantity_lte?: number | null;
  quantity_gt?: number | null;
  quantity_gte?: number | null;
  remark?: string | null;
  remark_not?: string | null;
  remark_in?: string[] | null;
  remark_not_in?: string[] | null;
  remark_lt?: string | null;
  remark_lte?: string | null;
  remark_gt?: string | null;
  remark_gte?: string | null;
  remark_contains?: string | null;
  remark_not_contains?: string | null;
  remark_starts_with?: string | null;
  remark_not_starts_with?: string | null;
  remark_ends_with?: string | null;
  remark_not_ends_with?: string | null;
  corrected?: boolean | null;
  corrected_not?: boolean | null;
  ambassadorDiscount?: boolean | null;
  ambassadorDiscount_not?: boolean | null;
  deliveryDate?: any | null;
  deliveryDate_not?: any | null;
  deliveryDate_in?: any[] | null;
  deliveryDate_not_in?: any[] | null;
  deliveryDate_lt?: any | null;
  deliveryDate_lte?: any | null;
  deliveryDate_gt?: any | null;
  deliveryDate_gte?: any | null;
  portions?: number | null;
  portions_not?: number | null;
  portions_in?: number[] | null;
  portions_not_in?: number[] | null;
  portions_lt?: number | null;
  portions_lte?: number | null;
  portions_gt?: number | null;
  portions_gte?: number | null;
  usedPortions?: number | null;
  usedPortions_not?: number | null;
  usedPortions_in?: number[] | null;
  usedPortions_not_in?: number[] | null;
  usedPortions_lt?: number | null;
  usedPortions_lte?: number | null;
  usedPortions_gt?: number | null;
  usedPortions_gte?: number | null;
  frequency?: string | null;
  frequency_not?: string | null;
  frequency_in?: string[] | null;
  frequency_not_in?: string[] | null;
  frequency_lt?: string | null;
  frequency_lte?: string | null;
  frequency_gt?: string | null;
  frequency_gte?: string | null;
  frequency_contains?: string | null;
  frequency_not_contains?: string | null;
  frequency_starts_with?: string | null;
  frequency_not_starts_with?: string | null;
  frequency_ends_with?: string | null;
  frequency_not_ends_with?: string | null;
  clientOrder?: ClientOrderWhereInput | null;
  product?: ProductWhereInput | null;
  deliveries_every?: ProductOrderDeliveryWhereInput | null;
  deliveries_some?: ProductOrderDeliveryWhereInput | null;
  deliveries_none?: ProductOrderDeliveryWhereInput | null;
  discount?: ProductOrderDiscountWhereInput | null;
}

export interface ProductOrderWhereUniqueInput {
  id?: string | null;
}

export interface ProductPriceCreateInput {
  id?: string | null;
  startDate: any;
  endDate?: any | null;
  note?: string | null;
  value: number;
  unit?: PriceUnit | null;
  base?: boolean | null;
  product: ProductCreateOneWithoutProductPricesInput;
  clientGroup?: ClientGroupCreateOneWithoutProductPricesInput | null;
}

export interface ProductPriceCreateManyWithoutClientGroupInput {
  create?: ProductPriceCreateWithoutClientGroupInput[] | null;
  connect?: ProductPriceWhereUniqueInput[] | null;
}

export interface ProductPriceCreateManyWithoutProductInput {
  create?: ProductPriceCreateWithoutProductInput[] | null;
  connect?: ProductPriceWhereUniqueInput[] | null;
}

export interface ProductPriceCreateWithoutClientGroupInput {
  id?: string | null;
  startDate: any;
  endDate?: any | null;
  note?: string | null;
  value: number;
  unit?: PriceUnit | null;
  base?: boolean | null;
  product: ProductCreateOneWithoutProductPricesInput;
}

export interface ProductPriceCreateWithoutProductInput {
  id?: string | null;
  startDate: any;
  endDate?: any | null;
  note?: string | null;
  value: number;
  unit?: PriceUnit | null;
  base?: boolean | null;
  clientGroup?: ClientGroupCreateOneWithoutProductPricesInput | null;
}

export interface ProductPriceScalarWhereInput {
  AND?: ProductPriceScalarWhereInput[] | null;
  OR?: ProductPriceScalarWhereInput[] | null;
  NOT?: ProductPriceScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  startDate?: any | null;
  startDate_not?: any | null;
  startDate_in?: any[] | null;
  startDate_not_in?: any[] | null;
  startDate_lt?: any | null;
  startDate_lte?: any | null;
  startDate_gt?: any | null;
  startDate_gte?: any | null;
  endDate?: any | null;
  endDate_not?: any | null;
  endDate_in?: any[] | null;
  endDate_not_in?: any[] | null;
  endDate_lt?: any | null;
  endDate_lte?: any | null;
  endDate_gt?: any | null;
  endDate_gte?: any | null;
  note?: string | null;
  note_not?: string | null;
  note_in?: string[] | null;
  note_not_in?: string[] | null;
  note_lt?: string | null;
  note_lte?: string | null;
  note_gt?: string | null;
  note_gte?: string | null;
  note_contains?: string | null;
  note_not_contains?: string | null;
  note_starts_with?: string | null;
  note_not_starts_with?: string | null;
  note_ends_with?: string | null;
  note_not_ends_with?: string | null;
  value?: number | null;
  value_not?: number | null;
  value_in?: number[] | null;
  value_not_in?: number[] | null;
  value_lt?: number | null;
  value_lte?: number | null;
  value_gt?: number | null;
  value_gte?: number | null;
  unit?: PriceUnit | null;
  unit_not?: PriceUnit | null;
  unit_in?: PriceUnit[] | null;
  unit_not_in?: PriceUnit[] | null;
  base?: boolean | null;
  base_not?: boolean | null;
}

export interface ProductPriceUpdateInput {
  startDate?: any | null;
  endDate?: any | null;
  note?: string | null;
  value?: number | null;
  unit?: PriceUnit | null;
  base?: boolean | null;
  product?: ProductUpdateOneRequiredWithoutProductPricesInput | null;
  clientGroup?: ClientGroupUpdateOneWithoutProductPricesInput | null;
}

export interface ProductPriceUpdateManyDataInput {
  startDate?: any | null;
  endDate?: any | null;
  note?: string | null;
  value?: number | null;
  unit?: PriceUnit | null;
  base?: boolean | null;
}

export interface ProductPriceUpdateManyWithWhereNestedInput {
  where: ProductPriceScalarWhereInput;
  data: ProductPriceUpdateManyDataInput;
}

export interface ProductPriceUpdateManyWithoutClientGroupInput {
  create?: ProductPriceCreateWithoutClientGroupInput[] | null;
  connect?: ProductPriceWhereUniqueInput[] | null;
  set?: ProductPriceWhereUniqueInput[] | null;
  disconnect?: ProductPriceWhereUniqueInput[] | null;
  delete?: ProductPriceWhereUniqueInput[] | null;
  update?: ProductPriceUpdateWithWhereUniqueWithoutClientGroupInput[] | null;
  updateMany?: ProductPriceUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ProductPriceScalarWhereInput[] | null;
  upsert?: ProductPriceUpsertWithWhereUniqueWithoutClientGroupInput[] | null;
}

export interface ProductPriceUpdateManyWithoutProductInput {
  create?: ProductPriceCreateWithoutProductInput[] | null;
  connect?: ProductPriceWhereUniqueInput[] | null;
  set?: ProductPriceWhereUniqueInput[] | null;
  disconnect?: ProductPriceWhereUniqueInput[] | null;
  delete?: ProductPriceWhereUniqueInput[] | null;
  update?: ProductPriceUpdateWithWhereUniqueWithoutProductInput[] | null;
  updateMany?: ProductPriceUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ProductPriceScalarWhereInput[] | null;
  upsert?: ProductPriceUpsertWithWhereUniqueWithoutProductInput[] | null;
}

export interface ProductPriceUpdateWithWhereUniqueWithoutClientGroupInput {
  where: ProductPriceWhereUniqueInput;
  data: ProductPriceUpdateWithoutClientGroupDataInput;
}

export interface ProductPriceUpdateWithWhereUniqueWithoutProductInput {
  where: ProductPriceWhereUniqueInput;
  data: ProductPriceUpdateWithoutProductDataInput;
}

export interface ProductPriceUpdateWithoutClientGroupDataInput {
  startDate?: any | null;
  endDate?: any | null;
  note?: string | null;
  value?: number | null;
  unit?: PriceUnit | null;
  base?: boolean | null;
  product?: ProductUpdateOneRequiredWithoutProductPricesInput | null;
}

export interface ProductPriceUpdateWithoutProductDataInput {
  startDate?: any | null;
  endDate?: any | null;
  note?: string | null;
  value?: number | null;
  unit?: PriceUnit | null;
  base?: boolean | null;
  clientGroup?: ClientGroupUpdateOneWithoutProductPricesInput | null;
}

export interface ProductPriceUpsertWithWhereUniqueWithoutClientGroupInput {
  where: ProductPriceWhereUniqueInput;
  update: ProductPriceUpdateWithoutClientGroupDataInput;
  create: ProductPriceCreateWithoutClientGroupInput;
}

export interface ProductPriceUpsertWithWhereUniqueWithoutProductInput {
  where: ProductPriceWhereUniqueInput;
  update: ProductPriceUpdateWithoutProductDataInput;
  create: ProductPriceCreateWithoutProductInput;
}

export interface ProductPriceWhereInput {
  AND?: ProductPriceWhereInput[] | null;
  OR?: ProductPriceWhereInput[] | null;
  NOT?: ProductPriceWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  startDate?: any | null;
  startDate_not?: any | null;
  startDate_in?: any[] | null;
  startDate_not_in?: any[] | null;
  startDate_lt?: any | null;
  startDate_lte?: any | null;
  startDate_gt?: any | null;
  startDate_gte?: any | null;
  endDate?: any | null;
  endDate_not?: any | null;
  endDate_in?: any[] | null;
  endDate_not_in?: any[] | null;
  endDate_lt?: any | null;
  endDate_lte?: any | null;
  endDate_gt?: any | null;
  endDate_gte?: any | null;
  note?: string | null;
  note_not?: string | null;
  note_in?: string[] | null;
  note_not_in?: string[] | null;
  note_lt?: string | null;
  note_lte?: string | null;
  note_gt?: string | null;
  note_gte?: string | null;
  note_contains?: string | null;
  note_not_contains?: string | null;
  note_starts_with?: string | null;
  note_not_starts_with?: string | null;
  note_ends_with?: string | null;
  note_not_ends_with?: string | null;
  value?: number | null;
  value_not?: number | null;
  value_in?: number[] | null;
  value_not_in?: number[] | null;
  value_lt?: number | null;
  value_lte?: number | null;
  value_gt?: number | null;
  value_gte?: number | null;
  unit?: PriceUnit | null;
  unit_not?: PriceUnit | null;
  unit_in?: PriceUnit[] | null;
  unit_not_in?: PriceUnit[] | null;
  base?: boolean | null;
  base_not?: boolean | null;
  product?: ProductWhereInput | null;
  clientGroup?: ClientGroupWhereInput | null;
}

export interface ProductPriceWhereUniqueInput {
  id?: string | null;
}

export interface ProductPromoCreateManyWithoutProductInput {
  create?: ProductPromoCreateWithoutProductInput[] | null;
  connect?: ProductPromoWhereUniqueInput[] | null;
}

export interface ProductPromoCreateWithoutProductInput {
  id?: string | null;
  startDate: any;
  endDate?: any | null;
  type: ProductPromoType;
  value: number;
  secondValue?: number | null;
  shopOrderItems?: ShopOrderItemCreateManyWithoutCurrentPromoInput | null;
}

export interface ProductPromoScalarWhereInput {
  AND?: ProductPromoScalarWhereInput[] | null;
  OR?: ProductPromoScalarWhereInput[] | null;
  NOT?: ProductPromoScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  startDate?: any | null;
  startDate_not?: any | null;
  startDate_in?: any[] | null;
  startDate_not_in?: any[] | null;
  startDate_lt?: any | null;
  startDate_lte?: any | null;
  startDate_gt?: any | null;
  startDate_gte?: any | null;
  endDate?: any | null;
  endDate_not?: any | null;
  endDate_in?: any[] | null;
  endDate_not_in?: any[] | null;
  endDate_lt?: any | null;
  endDate_lte?: any | null;
  endDate_gt?: any | null;
  endDate_gte?: any | null;
  type?: ProductPromoType | null;
  type_not?: ProductPromoType | null;
  type_in?: ProductPromoType[] | null;
  type_not_in?: ProductPromoType[] | null;
  value?: number | null;
  value_not?: number | null;
  value_in?: number[] | null;
  value_not_in?: number[] | null;
  value_lt?: number | null;
  value_lte?: number | null;
  value_gt?: number | null;
  value_gte?: number | null;
  secondValue?: number | null;
  secondValue_not?: number | null;
  secondValue_in?: number[] | null;
  secondValue_not_in?: number[] | null;
  secondValue_lt?: number | null;
  secondValue_lte?: number | null;
  secondValue_gt?: number | null;
  secondValue_gte?: number | null;
}

export interface ProductPromoUpdateManyDataInput {
  startDate?: any | null;
  endDate?: any | null;
  type?: ProductPromoType | null;
  value?: number | null;
  secondValue?: number | null;
}

export interface ProductPromoUpdateManyWithWhereNestedInput {
  where: ProductPromoScalarWhereInput;
  data: ProductPromoUpdateManyDataInput;
}

export interface ProductPromoUpdateManyWithoutProductInput {
  create?: ProductPromoCreateWithoutProductInput[] | null;
  connect?: ProductPromoWhereUniqueInput[] | null;
  set?: ProductPromoWhereUniqueInput[] | null;
  disconnect?: ProductPromoWhereUniqueInput[] | null;
  delete?: ProductPromoWhereUniqueInput[] | null;
  update?: ProductPromoUpdateWithWhereUniqueWithoutProductInput[] | null;
  updateMany?: ProductPromoUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ProductPromoScalarWhereInput[] | null;
  upsert?: ProductPromoUpsertWithWhereUniqueWithoutProductInput[] | null;
}

export interface ProductPromoUpdateWithWhereUniqueWithoutProductInput {
  where: ProductPromoWhereUniqueInput;
  data: ProductPromoUpdateWithoutProductDataInput;
}

export interface ProductPromoUpdateWithoutProductDataInput {
  startDate?: any | null;
  endDate?: any | null;
  type?: ProductPromoType | null;
  value?: number | null;
  secondValue?: number | null;
  shopOrderItems?: ShopOrderItemUpdateManyWithoutCurrentPromoInput | null;
}

export interface ProductPromoUpsertWithWhereUniqueWithoutProductInput {
  where: ProductPromoWhereUniqueInput;
  update: ProductPromoUpdateWithoutProductDataInput;
  create: ProductPromoCreateWithoutProductInput;
}

export interface ProductPromoWhereInput {
  AND?: ProductPromoWhereInput[] | null;
  OR?: ProductPromoWhereInput[] | null;
  NOT?: ProductPromoWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  startDate?: any | null;
  startDate_not?: any | null;
  startDate_in?: any[] | null;
  startDate_not_in?: any[] | null;
  startDate_lt?: any | null;
  startDate_lte?: any | null;
  startDate_gt?: any | null;
  startDate_gte?: any | null;
  endDate?: any | null;
  endDate_not?: any | null;
  endDate_in?: any[] | null;
  endDate_not_in?: any[] | null;
  endDate_lt?: any | null;
  endDate_lte?: any | null;
  endDate_gt?: any | null;
  endDate_gte?: any | null;
  type?: ProductPromoType | null;
  type_not?: ProductPromoType | null;
  type_in?: ProductPromoType[] | null;
  type_not_in?: ProductPromoType[] | null;
  value?: number | null;
  value_not?: number | null;
  value_in?: number[] | null;
  value_not_in?: number[] | null;
  value_lt?: number | null;
  value_lte?: number | null;
  value_gt?: number | null;
  value_gte?: number | null;
  secondValue?: number | null;
  secondValue_not?: number | null;
  secondValue_in?: number[] | null;
  secondValue_not_in?: number[] | null;
  secondValue_lt?: number | null;
  secondValue_lte?: number | null;
  secondValue_gt?: number | null;
  secondValue_gte?: number | null;
  product?: ProductWhereInput | null;
  shopOrderItems_every?: ShopOrderItemWhereInput | null;
  shopOrderItems_some?: ShopOrderItemWhereInput | null;
  shopOrderItems_none?: ShopOrderItemWhereInput | null;
}

export interface ProductPromoWhereUniqueInput {
  id?: string | null;
}

export interface ProductScalarWhereInput {
  AND?: ProductScalarWhereInput[] | null;
  OR?: ProductScalarWhereInput[] | null;
  NOT?: ProductScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  status?: ProductStatus | null;
  status_not?: ProductStatus | null;
  status_in?: ProductStatus[] | null;
  status_not_in?: ProductStatus[] | null;
  copied?: boolean | null;
  copied_not?: boolean | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  code?: string | null;
  code_not?: string | null;
  code_in?: string[] | null;
  code_not_in?: string[] | null;
  code_lt?: string | null;
  code_lte?: string | null;
  code_gt?: string | null;
  code_gte?: string | null;
  code_contains?: string | null;
  code_not_contains?: string | null;
  code_starts_with?: string | null;
  code_not_starts_with?: string | null;
  code_ends_with?: string | null;
  code_not_ends_with?: string | null;
  image?: string | null;
  image_not?: string | null;
  image_in?: string[] | null;
  image_not_in?: string[] | null;
  image_lt?: string | null;
  image_lte?: string | null;
  image_gt?: string | null;
  image_gte?: string | null;
  image_contains?: string | null;
  image_not_contains?: string | null;
  image_starts_with?: string | null;
  image_not_starts_with?: string | null;
  image_ends_with?: string | null;
  image_not_ends_with?: string | null;
  extra?: string | null;
  extra_not?: string | null;
  extra_in?: string[] | null;
  extra_not_in?: string[] | null;
  extra_lt?: string | null;
  extra_lte?: string | null;
  extra_gt?: string | null;
  extra_gte?: string | null;
  extra_contains?: string | null;
  extra_not_contains?: string | null;
  extra_starts_with?: string | null;
  extra_not_starts_with?: string | null;
  extra_ends_with?: string | null;
  extra_not_ends_with?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  showMinOrderQuantityByDefault_not?: boolean | null;
  unit?: ProductUnit | null;
  unit_not?: ProductUnit | null;
  unit_in?: ProductUnit[] | null;
  unit_not_in?: ProductUnit[] | null;
  alternativeUnit?: ProductUnit | null;
  alternativeUnit_not?: ProductUnit | null;
  alternativeUnit_in?: ProductUnit[] | null;
  alternativeUnit_not_in?: ProductUnit[] | null;
  amount?: number | null;
  amount_not?: number | null;
  amount_in?: number[] | null;
  amount_not_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  avgWeight?: number | null;
  avgWeight_not?: number | null;
  avgWeight_in?: number[] | null;
  avgWeight_not_in?: number[] | null;
  avgWeight_lt?: number | null;
  avgWeight_lte?: number | null;
  avgWeight_gt?: number | null;
  avgWeight_gte?: number | null;
  minOrderQuantity?: number | null;
  minOrderQuantity_not?: number | null;
  minOrderQuantity_in?: number[] | null;
  minOrderQuantity_not_in?: number[] | null;
  minOrderQuantity_lt?: number | null;
  minOrderQuantity_lte?: number | null;
  minOrderQuantity_gt?: number | null;
  minOrderQuantity_gte?: number | null;
  vat?: Vat | null;
  vat_not?: Vat | null;
  vat_in?: Vat[] | null;
  vat_not_in?: Vat[] | null;
  fixedPrices?: boolean | null;
  fixedPrices_not?: boolean | null;
  portionWillem?: number | null;
  portionWillem_not?: number | null;
  portionWillem_in?: number[] | null;
  portionWillem_not_in?: number[] | null;
  portionWillem_lt?: number | null;
  portionWillem_lte?: number | null;
  portionWillem_gt?: number | null;
  portionWillem_gte?: number | null;
  portionDirk?: number | null;
  portionDirk_not?: number | null;
  portionDirk_in?: number[] | null;
  portionDirk_not_in?: number[] | null;
  portionDirk_lt?: number | null;
  portionDirk_lte?: number | null;
  portionDirk_gt?: number | null;
  portionDirk_gte?: number | null;
  price?: number | null;
  price_not?: number | null;
  price_in?: number[] | null;
  price_not_in?: number[] | null;
  price_lt?: number | null;
  price_lte?: number | null;
  price_gt?: number | null;
  price_gte?: number | null;
  groupName?: string | null;
  groupName_not?: string | null;
  groupName_in?: string[] | null;
  groupName_not_in?: string[] | null;
  groupName_lt?: string | null;
  groupName_lte?: string | null;
  groupName_gt?: string | null;
  groupName_gte?: string | null;
  groupName_contains?: string | null;
  groupName_not_contains?: string | null;
  groupName_starts_with?: string | null;
  groupName_not_starts_with?: string | null;
  groupName_ends_with?: string | null;
  groupName_not_ends_with?: string | null;
  type?: ProductType | null;
  type_not?: ProductType | null;
  type_in?: ProductType[] | null;
  type_not_in?: ProductType[] | null;
  flex?: boolean | null;
  flex_not?: boolean | null;
  duration?: number | null;
  duration_not?: number | null;
  duration_in?: number[] | null;
  duration_not_in?: number[] | null;
  duration_lt?: number | null;
  duration_lte?: number | null;
  duration_gt?: number | null;
  duration_gte?: number | null;
}

export interface ProductStockCreateManyWithoutProductInput {
  create?: ProductStockCreateWithoutProductInput[] | null;
  connect?: ProductStockWhereUniqueInput[] | null;
}

export interface ProductStockCreateWithoutProductInput {
  id?: string | null;
  value?: number | null;
  delta: number;
  note?: string | null;
}

export interface ProductStockScalarWhereInput {
  AND?: ProductStockScalarWhereInput[] | null;
  OR?: ProductStockScalarWhereInput[] | null;
  NOT?: ProductStockScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  value?: number | null;
  value_not?: number | null;
  value_in?: number[] | null;
  value_not_in?: number[] | null;
  value_lt?: number | null;
  value_lte?: number | null;
  value_gt?: number | null;
  value_gte?: number | null;
  delta?: number | null;
  delta_not?: number | null;
  delta_in?: number[] | null;
  delta_not_in?: number[] | null;
  delta_lt?: number | null;
  delta_lte?: number | null;
  delta_gt?: number | null;
  delta_gte?: number | null;
  note?: string | null;
  note_not?: string | null;
  note_in?: string[] | null;
  note_not_in?: string[] | null;
  note_lt?: string | null;
  note_lte?: string | null;
  note_gt?: string | null;
  note_gte?: string | null;
  note_contains?: string | null;
  note_not_contains?: string | null;
  note_starts_with?: string | null;
  note_not_starts_with?: string | null;
  note_ends_with?: string | null;
  note_not_ends_with?: string | null;
}

export interface ProductStockUpdateManyDataInput {
  value?: number | null;
  delta?: number | null;
  note?: string | null;
}

export interface ProductStockUpdateManyWithWhereNestedInput {
  where: ProductStockScalarWhereInput;
  data: ProductStockUpdateManyDataInput;
}

export interface ProductStockUpdateManyWithoutProductInput {
  create?: ProductStockCreateWithoutProductInput[] | null;
  connect?: ProductStockWhereUniqueInput[] | null;
  set?: ProductStockWhereUniqueInput[] | null;
  disconnect?: ProductStockWhereUniqueInput[] | null;
  delete?: ProductStockWhereUniqueInput[] | null;
  update?: ProductStockUpdateWithWhereUniqueWithoutProductInput[] | null;
  updateMany?: ProductStockUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ProductStockScalarWhereInput[] | null;
  upsert?: ProductStockUpsertWithWhereUniqueWithoutProductInput[] | null;
}

export interface ProductStockUpdateWithWhereUniqueWithoutProductInput {
  where: ProductStockWhereUniqueInput;
  data: ProductStockUpdateWithoutProductDataInput;
}

export interface ProductStockUpdateWithoutProductDataInput {
  value?: number | null;
  delta?: number | null;
  note?: string | null;
}

export interface ProductStockUpsertWithWhereUniqueWithoutProductInput {
  where: ProductStockWhereUniqueInput;
  update: ProductStockUpdateWithoutProductDataInput;
  create: ProductStockCreateWithoutProductInput;
}

export interface ProductStockWhereInput {
  AND?: ProductStockWhereInput[] | null;
  OR?: ProductStockWhereInput[] | null;
  NOT?: ProductStockWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  value?: number | null;
  value_not?: number | null;
  value_in?: number[] | null;
  value_not_in?: number[] | null;
  value_lt?: number | null;
  value_lte?: number | null;
  value_gt?: number | null;
  value_gte?: number | null;
  delta?: number | null;
  delta_not?: number | null;
  delta_in?: number[] | null;
  delta_not_in?: number[] | null;
  delta_lt?: number | null;
  delta_lte?: number | null;
  delta_gt?: number | null;
  delta_gte?: number | null;
  note?: string | null;
  note_not?: string | null;
  note_in?: string[] | null;
  note_not_in?: string[] | null;
  note_lt?: string | null;
  note_lte?: string | null;
  note_gt?: string | null;
  note_gte?: string | null;
  note_contains?: string | null;
  note_not_contains?: string | null;
  note_starts_with?: string | null;
  note_not_starts_with?: string | null;
  note_ends_with?: string | null;
  note_not_ends_with?: string | null;
  product?: ProductWhereInput | null;
}

export interface ProductStockWhereUniqueInput {
  id?: string | null;
}

export interface ProductUpdateDataInput {
  status?: ProductStatus | null;
  copied?: boolean | null;
  name?: string | null;
  code?: string | null;
  image?: string | null;
  extra?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  unit?: ProductUnit | null;
  alternativeUnit?: ProductUnit | null;
  amount?: number | null;
  avgWeight?: number | null;
  minOrderQuantity?: number | null;
  vat?: Vat | null;
  fixedPrices?: boolean | null;
  portionWillem?: number | null;
  portionDirk?: number | null;
  price?: number | null;
  groupName?: string | null;
  type?: ProductType | null;
  flex?: boolean | null;
  duration?: number | null;
  availableAsAlternativeUnitFor?: ProductUpdateavailableAsAlternativeUnitForInput | null;
  category?: ProductCategoryUpdateOneInput | null;
  discounts?: ProductDiscountUpdateManyWithoutProductInput | null;
  productPromos?: ProductPromoUpdateManyWithoutProductInput | null;
  productPrices?: ProductPriceUpdateManyWithoutProductInput | null;
  productAvailabilities?: ProductAvailabilityUpdateManyWithoutProductInput | null;
  frequencies?: ProductFrequencyUpdateManyWithoutProductsInput | null;
  productOrders?: ProductOrderUpdateManyWithoutProductInput | null;
  basketItems?: BasketItemUpdateManyWithoutProductInput | null;
  stock?: ProductStockUpdateManyWithoutProductInput | null;
  farm?: FarmUpdateOneRequiredWithoutProductsInput | null;
}

export interface ProductUpdateInput {
  status?: ProductStatus | null;
  copied?: boolean | null;
  name?: string | null;
  code?: string | null;
  image?: string | null;
  extra?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  unit?: ProductUnit | null;
  alternativeUnit?: ProductUnit | null;
  amount?: number | null;
  avgWeight?: number | null;
  minOrderQuantity?: number | null;
  vat?: Vat | null;
  fixedPrices?: boolean | null;
  portionWillem?: number | null;
  portionDirk?: number | null;
  price?: number | null;
  groupName?: string | null;
  type?: ProductType | null;
  flex?: boolean | null;
  duration?: number | null;
  availableAsAlternativeUnitFor?: ProductUpdateavailableAsAlternativeUnitForInput | null;
  category?: ProductCategoryUpdateOneInput | null;
  discounts?: ProductDiscountUpdateManyWithoutProductInput | null;
  productPromos?: ProductPromoUpdateManyWithoutProductInput | null;
  productPrices?: ProductPriceUpdateManyWithoutProductInput | null;
  productAvailabilities?: ProductAvailabilityUpdateManyWithoutProductInput | null;
  frequencies?: ProductFrequencyUpdateManyWithoutProductsInput | null;
  productOrders?: ProductOrderUpdateManyWithoutProductInput | null;
  basketItems?: BasketItemUpdateManyWithoutProductInput | null;
  stock?: ProductStockUpdateManyWithoutProductInput | null;
  farm?: FarmUpdateOneRequiredWithoutProductsInput | null;
}

export interface ProductUpdateManyDataInput {
  status?: ProductStatus | null;
  copied?: boolean | null;
  name?: string | null;
  code?: string | null;
  image?: string | null;
  extra?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  unit?: ProductUnit | null;
  alternativeUnit?: ProductUnit | null;
  amount?: number | null;
  avgWeight?: number | null;
  minOrderQuantity?: number | null;
  vat?: Vat | null;
  fixedPrices?: boolean | null;
  portionWillem?: number | null;
  portionDirk?: number | null;
  price?: number | null;
  groupName?: string | null;
  type?: ProductType | null;
  flex?: boolean | null;
  duration?: number | null;
  availableAsAlternativeUnitFor?: ProductUpdateavailableAsAlternativeUnitForInput | null;
}

export interface ProductUpdateManyWithWhereNestedInput {
  where: ProductScalarWhereInput;
  data: ProductUpdateManyDataInput;
}

export interface ProductUpdateManyWithoutFarmInput {
  create?: ProductCreateWithoutFarmInput[] | null;
  connect?: ProductWhereUniqueInput[] | null;
  set?: ProductWhereUniqueInput[] | null;
  disconnect?: ProductWhereUniqueInput[] | null;
  delete?: ProductWhereUniqueInput[] | null;
  update?: ProductUpdateWithWhereUniqueWithoutFarmInput[] | null;
  updateMany?: ProductUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ProductScalarWhereInput[] | null;
  upsert?: ProductUpsertWithWhereUniqueWithoutFarmInput[] | null;
}

export interface ProductUpdateOneRequiredInput {
  create?: ProductCreateInput | null;
  connect?: ProductWhereUniqueInput | null;
  update?: ProductUpdateDataInput | null;
  upsert?: ProductUpsertNestedInput | null;
}

export interface ProductUpdateOneRequiredWithoutBasketItemsInput {
  create?: ProductCreateWithoutBasketItemsInput | null;
  connect?: ProductWhereUniqueInput | null;
  update?: ProductUpdateWithoutBasketItemsDataInput | null;
  upsert?: ProductUpsertWithoutBasketItemsInput | null;
}

export interface ProductUpdateOneRequiredWithoutDiscountsInput {
  create?: ProductCreateWithoutDiscountsInput | null;
  connect?: ProductWhereUniqueInput | null;
  update?: ProductUpdateWithoutDiscountsDataInput | null;
  upsert?: ProductUpsertWithoutDiscountsInput | null;
}

export interface ProductUpdateOneRequiredWithoutProductAvailabilitiesInput {
  create?: ProductCreateWithoutProductAvailabilitiesInput | null;
  connect?: ProductWhereUniqueInput | null;
  update?: ProductUpdateWithoutProductAvailabilitiesDataInput | null;
  upsert?: ProductUpsertWithoutProductAvailabilitiesInput | null;
}

export interface ProductUpdateOneRequiredWithoutProductPricesInput {
  create?: ProductCreateWithoutProductPricesInput | null;
  connect?: ProductWhereUniqueInput | null;
  update?: ProductUpdateWithoutProductPricesDataInput | null;
  upsert?: ProductUpsertWithoutProductPricesInput | null;
}

export interface ProductUpdateOneWithoutProductOrdersInput {
  create?: ProductCreateWithoutProductOrdersInput | null;
  connect?: ProductWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ProductUpdateWithoutProductOrdersDataInput | null;
  upsert?: ProductUpsertWithoutProductOrdersInput | null;
}

export interface ProductUpdateWithWhereUniqueWithoutFarmInput {
  where: ProductWhereUniqueInput;
  data: ProductUpdateWithoutFarmDataInput;
}

export interface ProductUpdateWithoutBasketItemsDataInput {
  status?: ProductStatus | null;
  copied?: boolean | null;
  name?: string | null;
  code?: string | null;
  image?: string | null;
  extra?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  unit?: ProductUnit | null;
  alternativeUnit?: ProductUnit | null;
  amount?: number | null;
  avgWeight?: number | null;
  minOrderQuantity?: number | null;
  vat?: Vat | null;
  fixedPrices?: boolean | null;
  portionWillem?: number | null;
  portionDirk?: number | null;
  price?: number | null;
  groupName?: string | null;
  type?: ProductType | null;
  flex?: boolean | null;
  duration?: number | null;
  availableAsAlternativeUnitFor?: ProductUpdateavailableAsAlternativeUnitForInput | null;
  category?: ProductCategoryUpdateOneInput | null;
  discounts?: ProductDiscountUpdateManyWithoutProductInput | null;
  productPromos?: ProductPromoUpdateManyWithoutProductInput | null;
  productPrices?: ProductPriceUpdateManyWithoutProductInput | null;
  productAvailabilities?: ProductAvailabilityUpdateManyWithoutProductInput | null;
  frequencies?: ProductFrequencyUpdateManyWithoutProductsInput | null;
  productOrders?: ProductOrderUpdateManyWithoutProductInput | null;
  stock?: ProductStockUpdateManyWithoutProductInput | null;
  farm?: FarmUpdateOneRequiredWithoutProductsInput | null;
}

export interface ProductUpdateWithoutDiscountsDataInput {
  status?: ProductStatus | null;
  copied?: boolean | null;
  name?: string | null;
  code?: string | null;
  image?: string | null;
  extra?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  unit?: ProductUnit | null;
  alternativeUnit?: ProductUnit | null;
  amount?: number | null;
  avgWeight?: number | null;
  minOrderQuantity?: number | null;
  vat?: Vat | null;
  fixedPrices?: boolean | null;
  portionWillem?: number | null;
  portionDirk?: number | null;
  price?: number | null;
  groupName?: string | null;
  type?: ProductType | null;
  flex?: boolean | null;
  duration?: number | null;
  availableAsAlternativeUnitFor?: ProductUpdateavailableAsAlternativeUnitForInput | null;
  category?: ProductCategoryUpdateOneInput | null;
  productPromos?: ProductPromoUpdateManyWithoutProductInput | null;
  productPrices?: ProductPriceUpdateManyWithoutProductInput | null;
  productAvailabilities?: ProductAvailabilityUpdateManyWithoutProductInput | null;
  frequencies?: ProductFrequencyUpdateManyWithoutProductsInput | null;
  productOrders?: ProductOrderUpdateManyWithoutProductInput | null;
  basketItems?: BasketItemUpdateManyWithoutProductInput | null;
  stock?: ProductStockUpdateManyWithoutProductInput | null;
  farm?: FarmUpdateOneRequiredWithoutProductsInput | null;
}

export interface ProductUpdateWithoutFarmDataInput {
  status?: ProductStatus | null;
  copied?: boolean | null;
  name?: string | null;
  code?: string | null;
  image?: string | null;
  extra?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  unit?: ProductUnit | null;
  alternativeUnit?: ProductUnit | null;
  amount?: number | null;
  avgWeight?: number | null;
  minOrderQuantity?: number | null;
  vat?: Vat | null;
  fixedPrices?: boolean | null;
  portionWillem?: number | null;
  portionDirk?: number | null;
  price?: number | null;
  groupName?: string | null;
  type?: ProductType | null;
  flex?: boolean | null;
  duration?: number | null;
  availableAsAlternativeUnitFor?: ProductUpdateavailableAsAlternativeUnitForInput | null;
  category?: ProductCategoryUpdateOneInput | null;
  discounts?: ProductDiscountUpdateManyWithoutProductInput | null;
  productPromos?: ProductPromoUpdateManyWithoutProductInput | null;
  productPrices?: ProductPriceUpdateManyWithoutProductInput | null;
  productAvailabilities?: ProductAvailabilityUpdateManyWithoutProductInput | null;
  frequencies?: ProductFrequencyUpdateManyWithoutProductsInput | null;
  productOrders?: ProductOrderUpdateManyWithoutProductInput | null;
  basketItems?: BasketItemUpdateManyWithoutProductInput | null;
  stock?: ProductStockUpdateManyWithoutProductInput | null;
}

export interface ProductUpdateWithoutProductAvailabilitiesDataInput {
  status?: ProductStatus | null;
  copied?: boolean | null;
  name?: string | null;
  code?: string | null;
  image?: string | null;
  extra?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  unit?: ProductUnit | null;
  alternativeUnit?: ProductUnit | null;
  amount?: number | null;
  avgWeight?: number | null;
  minOrderQuantity?: number | null;
  vat?: Vat | null;
  fixedPrices?: boolean | null;
  portionWillem?: number | null;
  portionDirk?: number | null;
  price?: number | null;
  groupName?: string | null;
  type?: ProductType | null;
  flex?: boolean | null;
  duration?: number | null;
  availableAsAlternativeUnitFor?: ProductUpdateavailableAsAlternativeUnitForInput | null;
  category?: ProductCategoryUpdateOneInput | null;
  discounts?: ProductDiscountUpdateManyWithoutProductInput | null;
  productPromos?: ProductPromoUpdateManyWithoutProductInput | null;
  productPrices?: ProductPriceUpdateManyWithoutProductInput | null;
  frequencies?: ProductFrequencyUpdateManyWithoutProductsInput | null;
  productOrders?: ProductOrderUpdateManyWithoutProductInput | null;
  basketItems?: BasketItemUpdateManyWithoutProductInput | null;
  stock?: ProductStockUpdateManyWithoutProductInput | null;
  farm?: FarmUpdateOneRequiredWithoutProductsInput | null;
}

export interface ProductUpdateWithoutProductOrdersDataInput {
  status?: ProductStatus | null;
  copied?: boolean | null;
  name?: string | null;
  code?: string | null;
  image?: string | null;
  extra?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  unit?: ProductUnit | null;
  alternativeUnit?: ProductUnit | null;
  amount?: number | null;
  avgWeight?: number | null;
  minOrderQuantity?: number | null;
  vat?: Vat | null;
  fixedPrices?: boolean | null;
  portionWillem?: number | null;
  portionDirk?: number | null;
  price?: number | null;
  groupName?: string | null;
  type?: ProductType | null;
  flex?: boolean | null;
  duration?: number | null;
  availableAsAlternativeUnitFor?: ProductUpdateavailableAsAlternativeUnitForInput | null;
  category?: ProductCategoryUpdateOneInput | null;
  discounts?: ProductDiscountUpdateManyWithoutProductInput | null;
  productPromos?: ProductPromoUpdateManyWithoutProductInput | null;
  productPrices?: ProductPriceUpdateManyWithoutProductInput | null;
  productAvailabilities?: ProductAvailabilityUpdateManyWithoutProductInput | null;
  frequencies?: ProductFrequencyUpdateManyWithoutProductsInput | null;
  basketItems?: BasketItemUpdateManyWithoutProductInput | null;
  stock?: ProductStockUpdateManyWithoutProductInput | null;
  farm?: FarmUpdateOneRequiredWithoutProductsInput | null;
}

export interface ProductUpdateWithoutProductPricesDataInput {
  status?: ProductStatus | null;
  copied?: boolean | null;
  name?: string | null;
  code?: string | null;
  image?: string | null;
  extra?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  unit?: ProductUnit | null;
  alternativeUnit?: ProductUnit | null;
  amount?: number | null;
  avgWeight?: number | null;
  minOrderQuantity?: number | null;
  vat?: Vat | null;
  fixedPrices?: boolean | null;
  portionWillem?: number | null;
  portionDirk?: number | null;
  price?: number | null;
  groupName?: string | null;
  type?: ProductType | null;
  flex?: boolean | null;
  duration?: number | null;
  availableAsAlternativeUnitFor?: ProductUpdateavailableAsAlternativeUnitForInput | null;
  category?: ProductCategoryUpdateOneInput | null;
  discounts?: ProductDiscountUpdateManyWithoutProductInput | null;
  productPromos?: ProductPromoUpdateManyWithoutProductInput | null;
  productAvailabilities?: ProductAvailabilityUpdateManyWithoutProductInput | null;
  frequencies?: ProductFrequencyUpdateManyWithoutProductsInput | null;
  productOrders?: ProductOrderUpdateManyWithoutProductInput | null;
  basketItems?: BasketItemUpdateManyWithoutProductInput | null;
  stock?: ProductStockUpdateManyWithoutProductInput | null;
  farm?: FarmUpdateOneRequiredWithoutProductsInput | null;
}

export interface ProductUpdateavailableAsAlternativeUnitForInput {
  set?: string[] | null;
}

export interface ProductUpsertNestedInput {
  update: ProductUpdateDataInput;
  create: ProductCreateInput;
}

export interface ProductUpsertWithWhereUniqueWithoutFarmInput {
  where: ProductWhereUniqueInput;
  update: ProductUpdateWithoutFarmDataInput;
  create: ProductCreateWithoutFarmInput;
}

export interface ProductUpsertWithoutBasketItemsInput {
  update: ProductUpdateWithoutBasketItemsDataInput;
  create: ProductCreateWithoutBasketItemsInput;
}

export interface ProductUpsertWithoutDiscountsInput {
  update: ProductUpdateWithoutDiscountsDataInput;
  create: ProductCreateWithoutDiscountsInput;
}

export interface ProductUpsertWithoutProductAvailabilitiesInput {
  update: ProductUpdateWithoutProductAvailabilitiesDataInput;
  create: ProductCreateWithoutProductAvailabilitiesInput;
}

export interface ProductUpsertWithoutProductOrdersInput {
  update: ProductUpdateWithoutProductOrdersDataInput;
  create: ProductCreateWithoutProductOrdersInput;
}

export interface ProductUpsertWithoutProductPricesInput {
  update: ProductUpdateWithoutProductPricesDataInput;
  create: ProductCreateWithoutProductPricesInput;
}

export interface ProductWhereInput {
  AND?: ProductWhereInput[] | null;
  OR?: ProductWhereInput[] | null;
  NOT?: ProductWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  status?: ProductStatus | null;
  status_not?: ProductStatus | null;
  status_in?: ProductStatus[] | null;
  status_not_in?: ProductStatus[] | null;
  copied?: boolean | null;
  copied_not?: boolean | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  code?: string | null;
  code_not?: string | null;
  code_in?: string[] | null;
  code_not_in?: string[] | null;
  code_lt?: string | null;
  code_lte?: string | null;
  code_gt?: string | null;
  code_gte?: string | null;
  code_contains?: string | null;
  code_not_contains?: string | null;
  code_starts_with?: string | null;
  code_not_starts_with?: string | null;
  code_ends_with?: string | null;
  code_not_ends_with?: string | null;
  image?: string | null;
  image_not?: string | null;
  image_in?: string[] | null;
  image_not_in?: string[] | null;
  image_lt?: string | null;
  image_lte?: string | null;
  image_gt?: string | null;
  image_gte?: string | null;
  image_contains?: string | null;
  image_not_contains?: string | null;
  image_starts_with?: string | null;
  image_not_starts_with?: string | null;
  image_ends_with?: string | null;
  image_not_ends_with?: string | null;
  extra?: string | null;
  extra_not?: string | null;
  extra_in?: string[] | null;
  extra_not_in?: string[] | null;
  extra_lt?: string | null;
  extra_lte?: string | null;
  extra_gt?: string | null;
  extra_gte?: string | null;
  extra_contains?: string | null;
  extra_not_contains?: string | null;
  extra_starts_with?: string | null;
  extra_not_starts_with?: string | null;
  extra_ends_with?: string | null;
  extra_not_ends_with?: string | null;
  showMinOrderQuantityByDefault?: boolean | null;
  showMinOrderQuantityByDefault_not?: boolean | null;
  unit?: ProductUnit | null;
  unit_not?: ProductUnit | null;
  unit_in?: ProductUnit[] | null;
  unit_not_in?: ProductUnit[] | null;
  alternativeUnit?: ProductUnit | null;
  alternativeUnit_not?: ProductUnit | null;
  alternativeUnit_in?: ProductUnit[] | null;
  alternativeUnit_not_in?: ProductUnit[] | null;
  amount?: number | null;
  amount_not?: number | null;
  amount_in?: number[] | null;
  amount_not_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  avgWeight?: number | null;
  avgWeight_not?: number | null;
  avgWeight_in?: number[] | null;
  avgWeight_not_in?: number[] | null;
  avgWeight_lt?: number | null;
  avgWeight_lte?: number | null;
  avgWeight_gt?: number | null;
  avgWeight_gte?: number | null;
  minOrderQuantity?: number | null;
  minOrderQuantity_not?: number | null;
  minOrderQuantity_in?: number[] | null;
  minOrderQuantity_not_in?: number[] | null;
  minOrderQuantity_lt?: number | null;
  minOrderQuantity_lte?: number | null;
  minOrderQuantity_gt?: number | null;
  minOrderQuantity_gte?: number | null;
  vat?: Vat | null;
  vat_not?: Vat | null;
  vat_in?: Vat[] | null;
  vat_not_in?: Vat[] | null;
  fixedPrices?: boolean | null;
  fixedPrices_not?: boolean | null;
  portionWillem?: number | null;
  portionWillem_not?: number | null;
  portionWillem_in?: number[] | null;
  portionWillem_not_in?: number[] | null;
  portionWillem_lt?: number | null;
  portionWillem_lte?: number | null;
  portionWillem_gt?: number | null;
  portionWillem_gte?: number | null;
  portionDirk?: number | null;
  portionDirk_not?: number | null;
  portionDirk_in?: number[] | null;
  portionDirk_not_in?: number[] | null;
  portionDirk_lt?: number | null;
  portionDirk_lte?: number | null;
  portionDirk_gt?: number | null;
  portionDirk_gte?: number | null;
  price?: number | null;
  price_not?: number | null;
  price_in?: number[] | null;
  price_not_in?: number[] | null;
  price_lt?: number | null;
  price_lte?: number | null;
  price_gt?: number | null;
  price_gte?: number | null;
  groupName?: string | null;
  groupName_not?: string | null;
  groupName_in?: string[] | null;
  groupName_not_in?: string[] | null;
  groupName_lt?: string | null;
  groupName_lte?: string | null;
  groupName_gt?: string | null;
  groupName_gte?: string | null;
  groupName_contains?: string | null;
  groupName_not_contains?: string | null;
  groupName_starts_with?: string | null;
  groupName_not_starts_with?: string | null;
  groupName_ends_with?: string | null;
  groupName_not_ends_with?: string | null;
  type?: ProductType | null;
  type_not?: ProductType | null;
  type_in?: ProductType[] | null;
  type_not_in?: ProductType[] | null;
  flex?: boolean | null;
  flex_not?: boolean | null;
  duration?: number | null;
  duration_not?: number | null;
  duration_in?: number[] | null;
  duration_not_in?: number[] | null;
  duration_lt?: number | null;
  duration_lte?: number | null;
  duration_gt?: number | null;
  duration_gte?: number | null;
  category?: ProductCategoryWhereInput | null;
  discounts_every?: ProductDiscountWhereInput | null;
  discounts_some?: ProductDiscountWhereInput | null;
  discounts_none?: ProductDiscountWhereInput | null;
  productPromos_every?: ProductPromoWhereInput | null;
  productPromos_some?: ProductPromoWhereInput | null;
  productPromos_none?: ProductPromoWhereInput | null;
  productPrices_every?: ProductPriceWhereInput | null;
  productPrices_some?: ProductPriceWhereInput | null;
  productPrices_none?: ProductPriceWhereInput | null;
  productAvailabilities_every?: ProductAvailabilityWhereInput | null;
  productAvailabilities_some?: ProductAvailabilityWhereInput | null;
  productAvailabilities_none?: ProductAvailabilityWhereInput | null;
  frequencies_every?: ProductFrequencyWhereInput | null;
  frequencies_some?: ProductFrequencyWhereInput | null;
  frequencies_none?: ProductFrequencyWhereInput | null;
  productOrders_every?: ProductOrderWhereInput | null;
  productOrders_some?: ProductOrderWhereInput | null;
  productOrders_none?: ProductOrderWhereInput | null;
  basketItems_every?: BasketItemWhereInput | null;
  basketItems_some?: BasketItemWhereInput | null;
  basketItems_none?: BasketItemWhereInput | null;
  stock_every?: ProductStockWhereInput | null;
  stock_some?: ProductStockWhereInput | null;
  stock_none?: ProductStockWhereInput | null;
  farm?: FarmWhereInput | null;
}

export interface ProductWhereUniqueInput {
  id?: string | null;
  code?: string | null;
}

export interface RotationAreaCreateManyWithoutFieldInput {
  create?: RotationAreaCreateWithoutFieldInput[] | null;
  connect?: RotationAreaWhereUniqueInput[] | null;
}

export interface RotationAreaCreateManyWithoutRotationGroupInput {
  create?: RotationAreaCreateWithoutRotationGroupInput[] | null;
  connect?: RotationAreaWhereUniqueInput[] | null;
}

export interface RotationAreaCreateOneWithoutPlotsInput {
  create?: RotationAreaCreateWithoutPlotsInput | null;
  connect?: RotationAreaWhereUniqueInput | null;
}

export interface RotationAreaCreateOneWithoutRotationSchemaInput {
  create?: RotationAreaCreateWithoutRotationSchemaInput | null;
  connect?: RotationAreaWhereUniqueInput | null;
}

export interface RotationAreaCreateWithoutFieldInput {
  id?: string | null;
  number: number;
  active?: boolean | null;
  plots?: PlotCreateManyWithoutRotationAreaInput | null;
  rotationGroup?: RotationGroupCreateOneWithoutRotationAreasInput | null;
  rotationSchema?: RotationSchemaCreateManyWithoutRotationAreaInput | null;
}

export interface RotationAreaCreateWithoutPlotsInput {
  id?: string | null;
  number: number;
  active?: boolean | null;
  field: FieldCreateOneWithoutRotationAreasInput;
  rotationGroup?: RotationGroupCreateOneWithoutRotationAreasInput | null;
  rotationSchema?: RotationSchemaCreateManyWithoutRotationAreaInput | null;
}

export interface RotationAreaCreateWithoutRotationGroupInput {
  id?: string | null;
  number: number;
  active?: boolean | null;
  field: FieldCreateOneWithoutRotationAreasInput;
  plots?: PlotCreateManyWithoutRotationAreaInput | null;
  rotationSchema?: RotationSchemaCreateManyWithoutRotationAreaInput | null;
}

export interface RotationAreaCreateWithoutRotationSchemaInput {
  id?: string | null;
  number: number;
  active?: boolean | null;
  field: FieldCreateOneWithoutRotationAreasInput;
  plots?: PlotCreateManyWithoutRotationAreaInput | null;
  rotationGroup?: RotationGroupCreateOneWithoutRotationAreasInput | null;
}

export interface RotationAreaScalarWhereInput {
  AND?: RotationAreaScalarWhereInput[] | null;
  OR?: RotationAreaScalarWhereInput[] | null;
  NOT?: RotationAreaScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  number?: number | null;
  number_not?: number | null;
  number_in?: number[] | null;
  number_not_in?: number[] | null;
  number_lt?: number | null;
  number_lte?: number | null;
  number_gt?: number | null;
  number_gte?: number | null;
  active?: boolean | null;
  active_not?: boolean | null;
}

export interface RotationAreaUpdateManyDataInput {
  number?: number | null;
  active?: boolean | null;
}

export interface RotationAreaUpdateManyWithWhereNestedInput {
  where: RotationAreaScalarWhereInput;
  data: RotationAreaUpdateManyDataInput;
}

export interface RotationAreaUpdateManyWithoutFieldInput {
  create?: RotationAreaCreateWithoutFieldInput[] | null;
  connect?: RotationAreaWhereUniqueInput[] | null;
  set?: RotationAreaWhereUniqueInput[] | null;
  disconnect?: RotationAreaWhereUniqueInput[] | null;
  delete?: RotationAreaWhereUniqueInput[] | null;
  update?: RotationAreaUpdateWithWhereUniqueWithoutFieldInput[] | null;
  updateMany?: RotationAreaUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: RotationAreaScalarWhereInput[] | null;
  upsert?: RotationAreaUpsertWithWhereUniqueWithoutFieldInput[] | null;
}

export interface RotationAreaUpdateManyWithoutRotationGroupInput {
  create?: RotationAreaCreateWithoutRotationGroupInput[] | null;
  connect?: RotationAreaWhereUniqueInput[] | null;
  set?: RotationAreaWhereUniqueInput[] | null;
  disconnect?: RotationAreaWhereUniqueInput[] | null;
  delete?: RotationAreaWhereUniqueInput[] | null;
  update?: RotationAreaUpdateWithWhereUniqueWithoutRotationGroupInput[] | null;
  updateMany?: RotationAreaUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: RotationAreaScalarWhereInput[] | null;
  upsert?: RotationAreaUpsertWithWhereUniqueWithoutRotationGroupInput[] | null;
}

export interface RotationAreaUpdateOneRequiredWithoutPlotsInput {
  create?: RotationAreaCreateWithoutPlotsInput | null;
  connect?: RotationAreaWhereUniqueInput | null;
  update?: RotationAreaUpdateWithoutPlotsDataInput | null;
  upsert?: RotationAreaUpsertWithoutPlotsInput | null;
}

export interface RotationAreaUpdateOneRequiredWithoutRotationSchemaInput {
  create?: RotationAreaCreateWithoutRotationSchemaInput | null;
  connect?: RotationAreaWhereUniqueInput | null;
  update?: RotationAreaUpdateWithoutRotationSchemaDataInput | null;
  upsert?: RotationAreaUpsertWithoutRotationSchemaInput | null;
}

export interface RotationAreaUpdateWithWhereUniqueWithoutFieldInput {
  where: RotationAreaWhereUniqueInput;
  data: RotationAreaUpdateWithoutFieldDataInput;
}

export interface RotationAreaUpdateWithWhereUniqueWithoutRotationGroupInput {
  where: RotationAreaWhereUniqueInput;
  data: RotationAreaUpdateWithoutRotationGroupDataInput;
}

export interface RotationAreaUpdateWithoutFieldDataInput {
  number?: number | null;
  active?: boolean | null;
  plots?: PlotUpdateManyWithoutRotationAreaInput | null;
  rotationGroup?: RotationGroupUpdateOneWithoutRotationAreasInput | null;
  rotationSchema?: RotationSchemaUpdateManyWithoutRotationAreaInput | null;
}

export interface RotationAreaUpdateWithoutPlotsDataInput {
  number?: number | null;
  active?: boolean | null;
  field?: FieldUpdateOneRequiredWithoutRotationAreasInput | null;
  rotationGroup?: RotationGroupUpdateOneWithoutRotationAreasInput | null;
  rotationSchema?: RotationSchemaUpdateManyWithoutRotationAreaInput | null;
}

export interface RotationAreaUpdateWithoutRotationGroupDataInput {
  number?: number | null;
  active?: boolean | null;
  field?: FieldUpdateOneRequiredWithoutRotationAreasInput | null;
  plots?: PlotUpdateManyWithoutRotationAreaInput | null;
  rotationSchema?: RotationSchemaUpdateManyWithoutRotationAreaInput | null;
}

export interface RotationAreaUpdateWithoutRotationSchemaDataInput {
  number?: number | null;
  active?: boolean | null;
  field?: FieldUpdateOneRequiredWithoutRotationAreasInput | null;
  plots?: PlotUpdateManyWithoutRotationAreaInput | null;
  rotationGroup?: RotationGroupUpdateOneWithoutRotationAreasInput | null;
}

export interface RotationAreaUpsertWithWhereUniqueWithoutFieldInput {
  where: RotationAreaWhereUniqueInput;
  update: RotationAreaUpdateWithoutFieldDataInput;
  create: RotationAreaCreateWithoutFieldInput;
}

export interface RotationAreaUpsertWithWhereUniqueWithoutRotationGroupInput {
  where: RotationAreaWhereUniqueInput;
  update: RotationAreaUpdateWithoutRotationGroupDataInput;
  create: RotationAreaCreateWithoutRotationGroupInput;
}

export interface RotationAreaUpsertWithoutPlotsInput {
  update: RotationAreaUpdateWithoutPlotsDataInput;
  create: RotationAreaCreateWithoutPlotsInput;
}

export interface RotationAreaUpsertWithoutRotationSchemaInput {
  update: RotationAreaUpdateWithoutRotationSchemaDataInput;
  create: RotationAreaCreateWithoutRotationSchemaInput;
}

export interface RotationAreaWhereInput {
  AND?: RotationAreaWhereInput[] | null;
  OR?: RotationAreaWhereInput[] | null;
  NOT?: RotationAreaWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  number?: number | null;
  number_not?: number | null;
  number_in?: number[] | null;
  number_not_in?: number[] | null;
  number_lt?: number | null;
  number_lte?: number | null;
  number_gt?: number | null;
  number_gte?: number | null;
  active?: boolean | null;
  active_not?: boolean | null;
  field?: FieldWhereInput | null;
  plots_every?: PlotWhereInput | null;
  plots_some?: PlotWhereInput | null;
  plots_none?: PlotWhereInput | null;
  rotationGroup?: RotationGroupWhereInput | null;
  rotationSchema_every?: RotationSchemaWhereInput | null;
  rotationSchema_some?: RotationSchemaWhereInput | null;
  rotationSchema_none?: RotationSchemaWhereInput | null;
}

export interface RotationAreaWhereUniqueInput {
  id?: string | null;
}

export interface RotationGroupCreateManyWithoutCropGroupsInput {
  create?: RotationGroupCreateWithoutCropGroupsInput[] | null;
  connect?: RotationGroupWhereUniqueInput[] | null;
}

export interface RotationGroupCreateManyWithoutFarmInput {
  create?: RotationGroupCreateWithoutFarmInput[] | null;
  connect?: RotationGroupWhereUniqueInput[] | null;
}

export interface RotationGroupCreateOneWithoutRotationAreasInput {
  create?: RotationGroupCreateWithoutRotationAreasInput | null;
  connect?: RotationGroupWhereUniqueInput | null;
}

export interface RotationGroupCreateOneWithoutRotationSchemaInput {
  create?: RotationGroupCreateWithoutRotationSchemaInput | null;
  connect?: RotationGroupWhereUniqueInput | null;
}

export interface RotationGroupCreateWithoutCropGroupsInput {
  id?: string | null;
  name: string;
  rotationAreas?: RotationAreaCreateManyWithoutRotationGroupInput | null;
  rotationSchema?: RotationSchemaCreateManyWithoutRotationGroupInput | null;
  farm: FarmCreateOneWithoutRotationGroupsInput;
}

export interface RotationGroupCreateWithoutFarmInput {
  id?: string | null;
  name: string;
  cropGroups?: CropGroupCreateManyWithoutRotationGroupsInput | null;
  rotationAreas?: RotationAreaCreateManyWithoutRotationGroupInput | null;
  rotationSchema?: RotationSchemaCreateManyWithoutRotationGroupInput | null;
}

export interface RotationGroupCreateWithoutRotationAreasInput {
  id?: string | null;
  name: string;
  cropGroups?: CropGroupCreateManyWithoutRotationGroupsInput | null;
  rotationSchema?: RotationSchemaCreateManyWithoutRotationGroupInput | null;
  farm: FarmCreateOneWithoutRotationGroupsInput;
}

export interface RotationGroupCreateWithoutRotationSchemaInput {
  id?: string | null;
  name: string;
  cropGroups?: CropGroupCreateManyWithoutRotationGroupsInput | null;
  rotationAreas?: RotationAreaCreateManyWithoutRotationGroupInput | null;
  farm: FarmCreateOneWithoutRotationGroupsInput;
}

export interface RotationGroupScalarWhereInput {
  AND?: RotationGroupScalarWhereInput[] | null;
  OR?: RotationGroupScalarWhereInput[] | null;
  NOT?: RotationGroupScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
}

export interface RotationGroupUpdateManyDataInput {
  name?: string | null;
}

export interface RotationGroupUpdateManyWithWhereNestedInput {
  where: RotationGroupScalarWhereInput;
  data: RotationGroupUpdateManyDataInput;
}

export interface RotationGroupUpdateManyWithoutCropGroupsInput {
  create?: RotationGroupCreateWithoutCropGroupsInput[] | null;
  connect?: RotationGroupWhereUniqueInput[] | null;
  set?: RotationGroupWhereUniqueInput[] | null;
  disconnect?: RotationGroupWhereUniqueInput[] | null;
  delete?: RotationGroupWhereUniqueInput[] | null;
  update?: RotationGroupUpdateWithWhereUniqueWithoutCropGroupsInput[] | null;
  updateMany?: RotationGroupUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: RotationGroupScalarWhereInput[] | null;
  upsert?: RotationGroupUpsertWithWhereUniqueWithoutCropGroupsInput[] | null;
}

export interface RotationGroupUpdateManyWithoutFarmInput {
  create?: RotationGroupCreateWithoutFarmInput[] | null;
  connect?: RotationGroupWhereUniqueInput[] | null;
  set?: RotationGroupWhereUniqueInput[] | null;
  disconnect?: RotationGroupWhereUniqueInput[] | null;
  delete?: RotationGroupWhereUniqueInput[] | null;
  update?: RotationGroupUpdateWithWhereUniqueWithoutFarmInput[] | null;
  updateMany?: RotationGroupUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: RotationGroupScalarWhereInput[] | null;
  upsert?: RotationGroupUpsertWithWhereUniqueWithoutFarmInput[] | null;
}

export interface RotationGroupUpdateOneRequiredWithoutRotationSchemaInput {
  create?: RotationGroupCreateWithoutRotationSchemaInput | null;
  connect?: RotationGroupWhereUniqueInput | null;
  update?: RotationGroupUpdateWithoutRotationSchemaDataInput | null;
  upsert?: RotationGroupUpsertWithoutRotationSchemaInput | null;
}

export interface RotationGroupUpdateOneWithoutRotationAreasInput {
  create?: RotationGroupCreateWithoutRotationAreasInput | null;
  connect?: RotationGroupWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: RotationGroupUpdateWithoutRotationAreasDataInput | null;
  upsert?: RotationGroupUpsertWithoutRotationAreasInput | null;
}

export interface RotationGroupUpdateWithWhereUniqueWithoutCropGroupsInput {
  where: RotationGroupWhereUniqueInput;
  data: RotationGroupUpdateWithoutCropGroupsDataInput;
}

export interface RotationGroupUpdateWithWhereUniqueWithoutFarmInput {
  where: RotationGroupWhereUniqueInput;
  data: RotationGroupUpdateWithoutFarmDataInput;
}

export interface RotationGroupUpdateWithoutCropGroupsDataInput {
  name?: string | null;
  rotationAreas?: RotationAreaUpdateManyWithoutRotationGroupInput | null;
  rotationSchema?: RotationSchemaUpdateManyWithoutRotationGroupInput | null;
  farm?: FarmUpdateOneRequiredWithoutRotationGroupsInput | null;
}

export interface RotationGroupUpdateWithoutFarmDataInput {
  name?: string | null;
  cropGroups?: CropGroupUpdateManyWithoutRotationGroupsInput | null;
  rotationAreas?: RotationAreaUpdateManyWithoutRotationGroupInput | null;
  rotationSchema?: RotationSchemaUpdateManyWithoutRotationGroupInput | null;
}

export interface RotationGroupUpdateWithoutRotationAreasDataInput {
  name?: string | null;
  cropGroups?: CropGroupUpdateManyWithoutRotationGroupsInput | null;
  rotationSchema?: RotationSchemaUpdateManyWithoutRotationGroupInput | null;
  farm?: FarmUpdateOneRequiredWithoutRotationGroupsInput | null;
}

export interface RotationGroupUpdateWithoutRotationSchemaDataInput {
  name?: string | null;
  cropGroups?: CropGroupUpdateManyWithoutRotationGroupsInput | null;
  rotationAreas?: RotationAreaUpdateManyWithoutRotationGroupInput | null;
  farm?: FarmUpdateOneRequiredWithoutRotationGroupsInput | null;
}

export interface RotationGroupUpsertWithWhereUniqueWithoutCropGroupsInput {
  where: RotationGroupWhereUniqueInput;
  update: RotationGroupUpdateWithoutCropGroupsDataInput;
  create: RotationGroupCreateWithoutCropGroupsInput;
}

export interface RotationGroupUpsertWithWhereUniqueWithoutFarmInput {
  where: RotationGroupWhereUniqueInput;
  update: RotationGroupUpdateWithoutFarmDataInput;
  create: RotationGroupCreateWithoutFarmInput;
}

export interface RotationGroupUpsertWithoutRotationAreasInput {
  update: RotationGroupUpdateWithoutRotationAreasDataInput;
  create: RotationGroupCreateWithoutRotationAreasInput;
}

export interface RotationGroupUpsertWithoutRotationSchemaInput {
  update: RotationGroupUpdateWithoutRotationSchemaDataInput;
  create: RotationGroupCreateWithoutRotationSchemaInput;
}

export interface RotationGroupWhereInput {
  AND?: RotationGroupWhereInput[] | null;
  OR?: RotationGroupWhereInput[] | null;
  NOT?: RotationGroupWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  cropGroups_every?: CropGroupWhereInput | null;
  cropGroups_some?: CropGroupWhereInput | null;
  cropGroups_none?: CropGroupWhereInput | null;
  rotationAreas_every?: RotationAreaWhereInput | null;
  rotationAreas_some?: RotationAreaWhereInput | null;
  rotationAreas_none?: RotationAreaWhereInput | null;
  rotationSchema_every?: RotationSchemaWhereInput | null;
  rotationSchema_some?: RotationSchemaWhereInput | null;
  rotationSchema_none?: RotationSchemaWhereInput | null;
  farm?: FarmWhereInput | null;
}

export interface RotationGroupWhereUniqueInput {
  id?: string | null;
  name?: string | null;
}

export interface RotationSchemaCreateManyWithoutRotationAreaInput {
  create?: RotationSchemaCreateWithoutRotationAreaInput[] | null;
  connect?: RotationSchemaWhereUniqueInput[] | null;
}

export interface RotationSchemaCreateManyWithoutRotationGroupInput {
  create?: RotationSchemaCreateWithoutRotationGroupInput[] | null;
  connect?: RotationSchemaWhereUniqueInput[] | null;
}

export interface RotationSchemaCreateWithoutRotationAreaInput {
  id?: string | null;
  season: Season;
  rotationGroup: RotationGroupCreateOneWithoutRotationSchemaInput;
}

export interface RotationSchemaCreateWithoutRotationGroupInput {
  id?: string | null;
  season: Season;
  rotationArea: RotationAreaCreateOneWithoutRotationSchemaInput;
}

export interface RotationSchemaScalarWhereInput {
  AND?: RotationSchemaScalarWhereInput[] | null;
  OR?: RotationSchemaScalarWhereInput[] | null;
  NOT?: RotationSchemaScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  season?: Season | null;
  season_not?: Season | null;
  season_in?: Season[] | null;
  season_not_in?: Season[] | null;
}

export interface RotationSchemaUpdateManyDataInput {
  season?: Season | null;
}

export interface RotationSchemaUpdateManyWithWhereNestedInput {
  where: RotationSchemaScalarWhereInput;
  data: RotationSchemaUpdateManyDataInput;
}

export interface RotationSchemaUpdateManyWithoutRotationAreaInput {
  create?: RotationSchemaCreateWithoutRotationAreaInput[] | null;
  connect?: RotationSchemaWhereUniqueInput[] | null;
  set?: RotationSchemaWhereUniqueInput[] | null;
  disconnect?: RotationSchemaWhereUniqueInput[] | null;
  delete?: RotationSchemaWhereUniqueInput[] | null;
  update?: RotationSchemaUpdateWithWhereUniqueWithoutRotationAreaInput[] | null;
  updateMany?: RotationSchemaUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: RotationSchemaScalarWhereInput[] | null;
  upsert?: RotationSchemaUpsertWithWhereUniqueWithoutRotationAreaInput[] | null;
}

export interface RotationSchemaUpdateManyWithoutRotationGroupInput {
  create?: RotationSchemaCreateWithoutRotationGroupInput[] | null;
  connect?: RotationSchemaWhereUniqueInput[] | null;
  set?: RotationSchemaWhereUniqueInput[] | null;
  disconnect?: RotationSchemaWhereUniqueInput[] | null;
  delete?: RotationSchemaWhereUniqueInput[] | null;
  update?: RotationSchemaUpdateWithWhereUniqueWithoutRotationGroupInput[] | null;
  updateMany?: RotationSchemaUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: RotationSchemaScalarWhereInput[] | null;
  upsert?: RotationSchemaUpsertWithWhereUniqueWithoutRotationGroupInput[] | null;
}

export interface RotationSchemaUpdateWithWhereUniqueWithoutRotationAreaInput {
  where: RotationSchemaWhereUniqueInput;
  data: RotationSchemaUpdateWithoutRotationAreaDataInput;
}

export interface RotationSchemaUpdateWithWhereUniqueWithoutRotationGroupInput {
  where: RotationSchemaWhereUniqueInput;
  data: RotationSchemaUpdateWithoutRotationGroupDataInput;
}

export interface RotationSchemaUpdateWithoutRotationAreaDataInput {
  season?: Season | null;
  rotationGroup?: RotationGroupUpdateOneRequiredWithoutRotationSchemaInput | null;
}

export interface RotationSchemaUpdateWithoutRotationGroupDataInput {
  season?: Season | null;
  rotationArea?: RotationAreaUpdateOneRequiredWithoutRotationSchemaInput | null;
}

export interface RotationSchemaUpsertWithWhereUniqueWithoutRotationAreaInput {
  where: RotationSchemaWhereUniqueInput;
  update: RotationSchemaUpdateWithoutRotationAreaDataInput;
  create: RotationSchemaCreateWithoutRotationAreaInput;
}

export interface RotationSchemaUpsertWithWhereUniqueWithoutRotationGroupInput {
  where: RotationSchemaWhereUniqueInput;
  update: RotationSchemaUpdateWithoutRotationGroupDataInput;
  create: RotationSchemaCreateWithoutRotationGroupInput;
}

export interface RotationSchemaWhereInput {
  AND?: RotationSchemaWhereInput[] | null;
  OR?: RotationSchemaWhereInput[] | null;
  NOT?: RotationSchemaWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  season?: Season | null;
  season_not?: Season | null;
  season_in?: Season[] | null;
  season_not_in?: Season[] | null;
  rotationGroup?: RotationGroupWhereInput | null;
  rotationArea?: RotationAreaWhereInput | null;
}

export interface RotationSchemaWhereUniqueInput {
  id?: string | null;
}

export interface SeedSchemaCreateOneWithoutCropsInput {
  create?: SeedSchemaCreateWithoutCropsInput | null;
  connect?: SeedSchemaWhereUniqueInput | null;
}

export interface SeedSchemaCreateWithoutCropsInput {
  id?: string | null;
  frontGear: number;
  rearGear: number;
  inRowSpacing: number;
  rows: number;
}

export interface SeedSchemaUpdateOneWithoutCropsInput {
  create?: SeedSchemaCreateWithoutCropsInput | null;
  connect?: SeedSchemaWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SeedSchemaUpdateWithoutCropsDataInput | null;
  upsert?: SeedSchemaUpsertWithoutCropsInput | null;
}

export interface SeedSchemaUpdateWithoutCropsDataInput {
  frontGear?: number | null;
  rearGear?: number | null;
  inRowSpacing?: number | null;
  rows?: number | null;
}

export interface SeedSchemaUpsertWithoutCropsInput {
  update: SeedSchemaUpdateWithoutCropsDataInput;
  create: SeedSchemaCreateWithoutCropsInput;
}

export interface SeedSchemaWhereInput {
  AND?: SeedSchemaWhereInput[] | null;
  OR?: SeedSchemaWhereInput[] | null;
  NOT?: SeedSchemaWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  frontGear?: number | null;
  frontGear_not?: number | null;
  frontGear_in?: number[] | null;
  frontGear_not_in?: number[] | null;
  frontGear_lt?: number | null;
  frontGear_lte?: number | null;
  frontGear_gt?: number | null;
  frontGear_gte?: number | null;
  rearGear?: number | null;
  rearGear_not?: number | null;
  rearGear_in?: number[] | null;
  rearGear_not_in?: number[] | null;
  rearGear_lt?: number | null;
  rearGear_lte?: number | null;
  rearGear_gt?: number | null;
  rearGear_gte?: number | null;
  inRowSpacing?: number | null;
  inRowSpacing_not?: number | null;
  inRowSpacing_in?: number[] | null;
  inRowSpacing_not_in?: number[] | null;
  inRowSpacing_lt?: number | null;
  inRowSpacing_lte?: number | null;
  inRowSpacing_gt?: number | null;
  inRowSpacing_gte?: number | null;
  rows?: number | null;
  rows_not?: number | null;
  rows_in?: number[] | null;
  rows_not_in?: number[] | null;
  rows_lt?: number | null;
  rows_lte?: number | null;
  rows_gt?: number | null;
  rows_gte?: number | null;
  crops_every?: CropWhereInput | null;
  crops_some?: CropWhereInput | null;
  crops_none?: CropWhereInput | null;
}

export interface SeedSchemaWhereUniqueInput {
  id?: string | null;
}

export interface SequenceCreateManyWithoutFarmInput {
  create?: SequenceCreateWithoutFarmInput[] | null;
  connect?: SequenceWhereUniqueInput[] | null;
}

export interface SequenceCreateWithoutFarmInput {
  id?: string | null;
  sequenceType: SequenceType;
  prefix: string;
  nextValue: number;
  season?: string | null;
}

export interface SequenceScalarWhereInput {
  AND?: SequenceScalarWhereInput[] | null;
  OR?: SequenceScalarWhereInput[] | null;
  NOT?: SequenceScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  sequenceType?: SequenceType | null;
  sequenceType_not?: SequenceType | null;
  sequenceType_in?: SequenceType[] | null;
  sequenceType_not_in?: SequenceType[] | null;
  prefix?: string | null;
  prefix_not?: string | null;
  prefix_in?: string[] | null;
  prefix_not_in?: string[] | null;
  prefix_lt?: string | null;
  prefix_lte?: string | null;
  prefix_gt?: string | null;
  prefix_gte?: string | null;
  prefix_contains?: string | null;
  prefix_not_contains?: string | null;
  prefix_starts_with?: string | null;
  prefix_not_starts_with?: string | null;
  prefix_ends_with?: string | null;
  prefix_not_ends_with?: string | null;
  nextValue?: number | null;
  nextValue_not?: number | null;
  nextValue_in?: number[] | null;
  nextValue_not_in?: number[] | null;
  nextValue_lt?: number | null;
  nextValue_lte?: number | null;
  nextValue_gt?: number | null;
  nextValue_gte?: number | null;
  season?: string | null;
  season_not?: string | null;
  season_in?: string[] | null;
  season_not_in?: string[] | null;
  season_lt?: string | null;
  season_lte?: string | null;
  season_gt?: string | null;
  season_gte?: string | null;
  season_contains?: string | null;
  season_not_contains?: string | null;
  season_starts_with?: string | null;
  season_not_starts_with?: string | null;
  season_ends_with?: string | null;
  season_not_ends_with?: string | null;
}

export interface SequenceUpdateManyDataInput {
  sequenceType?: SequenceType | null;
  prefix?: string | null;
  nextValue?: number | null;
  season?: string | null;
}

export interface SequenceUpdateManyWithWhereNestedInput {
  where: SequenceScalarWhereInput;
  data: SequenceUpdateManyDataInput;
}

export interface SequenceUpdateManyWithoutFarmInput {
  create?: SequenceCreateWithoutFarmInput[] | null;
  connect?: SequenceWhereUniqueInput[] | null;
  set?: SequenceWhereUniqueInput[] | null;
  disconnect?: SequenceWhereUniqueInput[] | null;
  delete?: SequenceWhereUniqueInput[] | null;
  update?: SequenceUpdateWithWhereUniqueWithoutFarmInput[] | null;
  updateMany?: SequenceUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: SequenceScalarWhereInput[] | null;
  upsert?: SequenceUpsertWithWhereUniqueWithoutFarmInput[] | null;
}

export interface SequenceUpdateWithWhereUniqueWithoutFarmInput {
  where: SequenceWhereUniqueInput;
  data: SequenceUpdateWithoutFarmDataInput;
}

export interface SequenceUpdateWithoutFarmDataInput {
  sequenceType?: SequenceType | null;
  prefix?: string | null;
  nextValue?: number | null;
  season?: string | null;
}

export interface SequenceUpsertWithWhereUniqueWithoutFarmInput {
  where: SequenceWhereUniqueInput;
  update: SequenceUpdateWithoutFarmDataInput;
  create: SequenceCreateWithoutFarmInput;
}

export interface SequenceWhereInput {
  AND?: SequenceWhereInput[] | null;
  OR?: SequenceWhereInput[] | null;
  NOT?: SequenceWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  sequenceType?: SequenceType | null;
  sequenceType_not?: SequenceType | null;
  sequenceType_in?: SequenceType[] | null;
  sequenceType_not_in?: SequenceType[] | null;
  prefix?: string | null;
  prefix_not?: string | null;
  prefix_in?: string[] | null;
  prefix_not_in?: string[] | null;
  prefix_lt?: string | null;
  prefix_lte?: string | null;
  prefix_gt?: string | null;
  prefix_gte?: string | null;
  prefix_contains?: string | null;
  prefix_not_contains?: string | null;
  prefix_starts_with?: string | null;
  prefix_not_starts_with?: string | null;
  prefix_ends_with?: string | null;
  prefix_not_ends_with?: string | null;
  nextValue?: number | null;
  nextValue_not?: number | null;
  nextValue_in?: number[] | null;
  nextValue_not_in?: number[] | null;
  nextValue_lt?: number | null;
  nextValue_lte?: number | null;
  nextValue_gt?: number | null;
  nextValue_gte?: number | null;
  season?: string | null;
  season_not?: string | null;
  season_in?: string[] | null;
  season_not_in?: string[] | null;
  season_lt?: string | null;
  season_lte?: string | null;
  season_gt?: string | null;
  season_gte?: string | null;
  season_contains?: string | null;
  season_not_contains?: string | null;
  season_starts_with?: string | null;
  season_not_starts_with?: string | null;
  season_ends_with?: string | null;
  season_not_ends_with?: string | null;
  farm?: FarmWhereInput | null;
}

export interface SequenceWhereUniqueInput {
  id?: string | null;
}

export interface ShopClientCreateInput {
  id?: string | null;
  name: string;
  firstName: string;
  newsletter: boolean;
  email: string;
  phone: string;
}

export interface ShopClientCreateOneInput {
  create?: ShopClientCreateInput | null;
  connect?: ShopClientWhereUniqueInput | null;
}

export interface ShopClientUpdateDataInput {
  name?: string | null;
  firstName?: string | null;
  newsletter?: boolean | null;
  email?: string | null;
  phone?: string | null;
}

export interface ShopClientUpdateOneInput {
  create?: ShopClientCreateInput | null;
  connect?: ShopClientWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ShopClientUpdateDataInput | null;
  upsert?: ShopClientUpsertNestedInput | null;
}

export interface ShopClientUpdateOneRequiredInput {
  create?: ShopClientCreateInput | null;
  connect?: ShopClientWhereUniqueInput | null;
  update?: ShopClientUpdateDataInput | null;
  upsert?: ShopClientUpsertNestedInput | null;
}

export interface ShopClientUpsertNestedInput {
  update: ShopClientUpdateDataInput;
  create: ShopClientCreateInput;
}

export interface ShopClientWhereInput {
  AND?: ShopClientWhereInput[] | null;
  OR?: ShopClientWhereInput[] | null;
  NOT?: ShopClientWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  firstName?: string | null;
  firstName_not?: string | null;
  firstName_in?: string[] | null;
  firstName_not_in?: string[] | null;
  firstName_lt?: string | null;
  firstName_lte?: string | null;
  firstName_gt?: string | null;
  firstName_gte?: string | null;
  firstName_contains?: string | null;
  firstName_not_contains?: string | null;
  firstName_starts_with?: string | null;
  firstName_not_starts_with?: string | null;
  firstName_ends_with?: string | null;
  firstName_not_ends_with?: string | null;
  newsletter?: boolean | null;
  newsletter_not?: boolean | null;
  email?: string | null;
  email_not?: string | null;
  email_in?: string[] | null;
  email_not_in?: string[] | null;
  email_lt?: string | null;
  email_lte?: string | null;
  email_gt?: string | null;
  email_gte?: string | null;
  email_contains?: string | null;
  email_not_contains?: string | null;
  email_starts_with?: string | null;
  email_not_starts_with?: string | null;
  email_ends_with?: string | null;
  email_not_ends_with?: string | null;
  phone?: string | null;
  phone_not?: string | null;
  phone_in?: string[] | null;
  phone_not_in?: string[] | null;
  phone_lt?: string | null;
  phone_lte?: string | null;
  phone_gt?: string | null;
  phone_gte?: string | null;
  phone_contains?: string | null;
  phone_not_contains?: string | null;
  phone_starts_with?: string | null;
  phone_not_starts_with?: string | null;
  phone_ends_with?: string | null;
  phone_not_ends_with?: string | null;
}

export interface ShopClientWhereUniqueInput {
  id?: string | null;
}

export interface ShopOrderCreateOneWithoutShopOrderItemsInput {
  create?: ShopOrderCreateWithoutShopOrderItemsInput | null;
  connect?: ShopOrderWhereUniqueInput | null;
}

export interface ShopOrderCreateWithoutShopOrderItemsInput {
  id?: string | null;
  orderDate: any;
  deliveryDate: any;
  deliveryLocation: DeliveryLocation;
  paid?: boolean | null;
  status?: ShopOrderStatus | null;
  extraPackaging?: boolean | null;
  promocode?: string | null;
  shopClient: ShopClientCreateOneInput;
}

export interface ShopOrderItemCreateManyWithoutCurrentPromoInput {
  create?: ShopOrderItemCreateWithoutCurrentPromoInput[] | null;
  connect?: ShopOrderItemWhereUniqueInput[] | null;
}

export interface ShopOrderItemCreateWithoutCurrentPromoInput {
  id?: string | null;
  amount: number;
  priceIncl: number;
  delivered?: boolean | null;
  shopOrder?: ShopOrderCreateOneWithoutShopOrderItemsInput | null;
  product: ProductCreateOneInput;
}

export interface ShopOrderItemScalarWhereInput {
  AND?: ShopOrderItemScalarWhereInput[] | null;
  OR?: ShopOrderItemScalarWhereInput[] | null;
  NOT?: ShopOrderItemScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  amount?: number | null;
  amount_not?: number | null;
  amount_in?: number[] | null;
  amount_not_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  priceIncl?: number | null;
  priceIncl_not?: number | null;
  priceIncl_in?: number[] | null;
  priceIncl_not_in?: number[] | null;
  priceIncl_lt?: number | null;
  priceIncl_lte?: number | null;
  priceIncl_gt?: number | null;
  priceIncl_gte?: number | null;
  delivered?: boolean | null;
  delivered_not?: boolean | null;
}

export interface ShopOrderItemUpdateManyDataInput {
  amount?: number | null;
  priceIncl?: number | null;
  delivered?: boolean | null;
}

export interface ShopOrderItemUpdateManyWithWhereNestedInput {
  where: ShopOrderItemScalarWhereInput;
  data: ShopOrderItemUpdateManyDataInput;
}

export interface ShopOrderItemUpdateManyWithoutCurrentPromoInput {
  create?: ShopOrderItemCreateWithoutCurrentPromoInput[] | null;
  connect?: ShopOrderItemWhereUniqueInput[] | null;
  set?: ShopOrderItemWhereUniqueInput[] | null;
  disconnect?: ShopOrderItemWhereUniqueInput[] | null;
  delete?: ShopOrderItemWhereUniqueInput[] | null;
  update?: ShopOrderItemUpdateWithWhereUniqueWithoutCurrentPromoInput[] | null;
  updateMany?: ShopOrderItemUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: ShopOrderItemScalarWhereInput[] | null;
  upsert?: ShopOrderItemUpsertWithWhereUniqueWithoutCurrentPromoInput[] | null;
}

export interface ShopOrderItemUpdateWithWhereUniqueWithoutCurrentPromoInput {
  where: ShopOrderItemWhereUniqueInput;
  data: ShopOrderItemUpdateWithoutCurrentPromoDataInput;
}

export interface ShopOrderItemUpdateWithoutCurrentPromoDataInput {
  amount?: number | null;
  priceIncl?: number | null;
  delivered?: boolean | null;
  shopOrder?: ShopOrderUpdateOneWithoutShopOrderItemsInput | null;
  product?: ProductUpdateOneRequiredInput | null;
}

export interface ShopOrderItemUpsertWithWhereUniqueWithoutCurrentPromoInput {
  where: ShopOrderItemWhereUniqueInput;
  update: ShopOrderItemUpdateWithoutCurrentPromoDataInput;
  create: ShopOrderItemCreateWithoutCurrentPromoInput;
}

export interface ShopOrderItemWhereInput {
  AND?: ShopOrderItemWhereInput[] | null;
  OR?: ShopOrderItemWhereInput[] | null;
  NOT?: ShopOrderItemWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  amount?: number | null;
  amount_not?: number | null;
  amount_in?: number[] | null;
  amount_not_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  priceIncl?: number | null;
  priceIncl_not?: number | null;
  priceIncl_in?: number[] | null;
  priceIncl_not_in?: number[] | null;
  priceIncl_lt?: number | null;
  priceIncl_lte?: number | null;
  priceIncl_gt?: number | null;
  priceIncl_gte?: number | null;
  delivered?: boolean | null;
  delivered_not?: boolean | null;
  shopOrder?: ShopOrderWhereInput | null;
  product?: ProductWhereInput | null;
  currentPromo?: ProductPromoWhereInput | null;
}

export interface ShopOrderItemWhereUniqueInput {
  id?: string | null;
}

export interface ShopOrderUpdateOneWithoutShopOrderItemsInput {
  create?: ShopOrderCreateWithoutShopOrderItemsInput | null;
  connect?: ShopOrderWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: ShopOrderUpdateWithoutShopOrderItemsDataInput | null;
  upsert?: ShopOrderUpsertWithoutShopOrderItemsInput | null;
}

export interface ShopOrderUpdateWithoutShopOrderItemsDataInput {
  orderDate?: any | null;
  deliveryDate?: any | null;
  deliveryLocation?: DeliveryLocation | null;
  paid?: boolean | null;
  status?: ShopOrderStatus | null;
  extraPackaging?: boolean | null;
  promocode?: string | null;
  shopClient?: ShopClientUpdateOneRequiredInput | null;
}

export interface ShopOrderUpsertWithoutShopOrderItemsInput {
  update: ShopOrderUpdateWithoutShopOrderItemsDataInput;
  create: ShopOrderCreateWithoutShopOrderItemsInput;
}

export interface ShopOrderWhereInput {
  AND?: ShopOrderWhereInput[] | null;
  OR?: ShopOrderWhereInput[] | null;
  NOT?: ShopOrderWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  orderDate?: any | null;
  orderDate_not?: any | null;
  orderDate_in?: any[] | null;
  orderDate_not_in?: any[] | null;
  orderDate_lt?: any | null;
  orderDate_lte?: any | null;
  orderDate_gt?: any | null;
  orderDate_gte?: any | null;
  deliveryDate?: any | null;
  deliveryDate_not?: any | null;
  deliveryDate_in?: any[] | null;
  deliveryDate_not_in?: any[] | null;
  deliveryDate_lt?: any | null;
  deliveryDate_lte?: any | null;
  deliveryDate_gt?: any | null;
  deliveryDate_gte?: any | null;
  deliveryLocation?: DeliveryLocation | null;
  deliveryLocation_not?: DeliveryLocation | null;
  deliveryLocation_in?: DeliveryLocation[] | null;
  deliveryLocation_not_in?: DeliveryLocation[] | null;
  paid?: boolean | null;
  paid_not?: boolean | null;
  status?: ShopOrderStatus | null;
  status_not?: ShopOrderStatus | null;
  status_in?: ShopOrderStatus[] | null;
  status_not_in?: ShopOrderStatus[] | null;
  extraPackaging?: boolean | null;
  extraPackaging_not?: boolean | null;
  promocode?: string | null;
  promocode_not?: string | null;
  promocode_in?: string[] | null;
  promocode_not_in?: string[] | null;
  promocode_lt?: string | null;
  promocode_lte?: string | null;
  promocode_gt?: string | null;
  promocode_gte?: string | null;
  promocode_contains?: string | null;
  promocode_not_contains?: string | null;
  promocode_starts_with?: string | null;
  promocode_not_starts_with?: string | null;
  promocode_ends_with?: string | null;
  promocode_not_ends_with?: string | null;
  shopClient?: ShopClientWhereInput | null;
  shopOrderItems_every?: ShopOrderItemWhereInput | null;
  shopOrderItems_some?: ShopOrderItemWhereInput | null;
  shopOrderItems_none?: ShopOrderItemWhereInput | null;
}

export interface ShopOrderWhereUniqueInput {
  id?: string | null;
}

export interface SupplierCreateOneWithoutVarietiesInput {
  create?: SupplierCreateWithoutVarietiesInput | null;
  connect?: SupplierWhereUniqueInput | null;
}

export interface SupplierCreateWithoutVarietiesInput {
  id?: string | null;
  name: string;
}

export interface SupplierUpdateOneWithoutVarietiesInput {
  create?: SupplierCreateWithoutVarietiesInput | null;
  connect?: SupplierWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: SupplierUpdateWithoutVarietiesDataInput | null;
  upsert?: SupplierUpsertWithoutVarietiesInput | null;
}

export interface SupplierUpdateWithoutVarietiesDataInput {
  name?: string | null;
}

export interface SupplierUpsertWithoutVarietiesInput {
  update: SupplierUpdateWithoutVarietiesDataInput;
  create: SupplierCreateWithoutVarietiesInput;
}

export interface SupplierWhereInput {
  AND?: SupplierWhereInput[] | null;
  OR?: SupplierWhereInput[] | null;
  NOT?: SupplierWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  varieties_every?: VarietyWhereInput | null;
  varieties_some?: VarietyWhereInput | null;
  varieties_none?: VarietyWhereInput | null;
}

export interface SupplierWhereUniqueInput {
  id?: string | null;
  name?: string | null;
}

export interface TaskColumnCreateOneWithoutTasksInput {
  create?: TaskColumnCreateWithoutTasksInput | null;
  connect?: TaskColumnWhereUniqueInput | null;
}

export interface TaskColumnCreateWithoutTasksInput {
  id?: string | null;
  name: string;
  status: TaskStatus;
  order: number;
}

export interface TaskColumnUpdateOneRequiredWithoutTasksInput {
  create?: TaskColumnCreateWithoutTasksInput | null;
  connect?: TaskColumnWhereUniqueInput | null;
  update?: TaskColumnUpdateWithoutTasksDataInput | null;
  upsert?: TaskColumnUpsertWithoutTasksInput | null;
}

export interface TaskColumnUpdateWithoutTasksDataInput {
  name?: string | null;
  status?: TaskStatus | null;
  order?: number | null;
}

export interface TaskColumnUpsertWithoutTasksInput {
  update: TaskColumnUpdateWithoutTasksDataInput;
  create: TaskColumnCreateWithoutTasksInput;
}

export interface TaskColumnWhereInput {
  AND?: TaskColumnWhereInput[] | null;
  OR?: TaskColumnWhereInput[] | null;
  NOT?: TaskColumnWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  status?: TaskStatus | null;
  status_not?: TaskStatus | null;
  status_in?: TaskStatus[] | null;
  status_not_in?: TaskStatus[] | null;
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  tasks_every?: TaskWhereInput | null;
  tasks_some?: TaskWhereInput | null;
  tasks_none?: TaskWhereInput | null;
}

export interface TaskColumnWhereUniqueInput {
  id?: string | null;
  status?: TaskStatus | null;
}

export interface TaskCreateManyWithoutCultivationInput {
  create?: TaskCreateWithoutCultivationInput[] | null;
  connect?: TaskWhereUniqueInput[] | null;
}

export interface TaskCreateWithoutCultivationInput {
  id?: string | null;
  type: TaskType;
  dueDate: any;
  order?: number | null;
  column: TaskColumnCreateOneWithoutTasksInput;
}

export interface TaskScalarWhereInput {
  AND?: TaskScalarWhereInput[] | null;
  OR?: TaskScalarWhereInput[] | null;
  NOT?: TaskScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  type?: TaskType | null;
  type_not?: TaskType | null;
  type_in?: TaskType[] | null;
  type_not_in?: TaskType[] | null;
  dueDate?: any | null;
  dueDate_not?: any | null;
  dueDate_in?: any[] | null;
  dueDate_not_in?: any[] | null;
  dueDate_lt?: any | null;
  dueDate_lte?: any | null;
  dueDate_gt?: any | null;
  dueDate_gte?: any | null;
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
}

export interface TaskUpdateManyDataInput {
  type?: TaskType | null;
  dueDate?: any | null;
  order?: number | null;
}

export interface TaskUpdateManyWithWhereNestedInput {
  where: TaskScalarWhereInput;
  data: TaskUpdateManyDataInput;
}

export interface TaskUpdateManyWithoutCultivationInput {
  create?: TaskCreateWithoutCultivationInput[] | null;
  connect?: TaskWhereUniqueInput[] | null;
  set?: TaskWhereUniqueInput[] | null;
  disconnect?: TaskWhereUniqueInput[] | null;
  delete?: TaskWhereUniqueInput[] | null;
  update?: TaskUpdateWithWhereUniqueWithoutCultivationInput[] | null;
  updateMany?: TaskUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: TaskScalarWhereInput[] | null;
  upsert?: TaskUpsertWithWhereUniqueWithoutCultivationInput[] | null;
}

export interface TaskUpdateWithWhereUniqueWithoutCultivationInput {
  where: TaskWhereUniqueInput;
  data: TaskUpdateWithoutCultivationDataInput;
}

export interface TaskUpdateWithoutCultivationDataInput {
  type?: TaskType | null;
  dueDate?: any | null;
  order?: number | null;
  column?: TaskColumnUpdateOneRequiredWithoutTasksInput | null;
}

export interface TaskUpsertWithWhereUniqueWithoutCultivationInput {
  where: TaskWhereUniqueInput;
  update: TaskUpdateWithoutCultivationDataInput;
  create: TaskCreateWithoutCultivationInput;
}

export interface TaskWhereInput {
  AND?: TaskWhereInput[] | null;
  OR?: TaskWhereInput[] | null;
  NOT?: TaskWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  type?: TaskType | null;
  type_not?: TaskType | null;
  type_in?: TaskType[] | null;
  type_not_in?: TaskType[] | null;
  dueDate?: any | null;
  dueDate_not?: any | null;
  dueDate_in?: any[] | null;
  dueDate_not_in?: any[] | null;
  dueDate_lt?: any | null;
  dueDate_lte?: any | null;
  dueDate_gt?: any | null;
  dueDate_gte?: any | null;
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  cultivation?: CultivationWhereInput | null;
  column?: TaskColumnWhereInput | null;
}

export interface TaskWhereUniqueInput {
  id?: string | null;
}

export interface UserCreateInput {
  id?: string | null;
  auth0userId?: string | null;
  email: string;
  password?: string | null;
  passwordResetToken?: string | null;
  secondaryEmail?: string | null;
  name: string;
  firstName: string;
  dateOfBirth?: any | null;
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  permissions?: UserCreatepermissionsInput | null;
  farms?: FarmCreateManyWithoutUsersInput | null;
  farmPermissions?: FarmPermissionCreateManyWithoutUserInput | null;
}

export interface UserCreateManyWithoutFarmsInput {
  create?: UserCreateWithoutFarmsInput[] | null;
  connect?: UserWhereUniqueInput[] | null;
}

export interface UserCreateOneInput {
  create?: UserCreateInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateOneWithoutFarmPermissionsInput {
  create?: UserCreateWithoutFarmPermissionsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateWithoutFarmPermissionsInput {
  id?: string | null;
  auth0userId?: string | null;
  email: string;
  password?: string | null;
  passwordResetToken?: string | null;
  secondaryEmail?: string | null;
  name: string;
  firstName: string;
  dateOfBirth?: any | null;
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  permissions?: UserCreatepermissionsInput | null;
  farms?: FarmCreateManyWithoutUsersInput | null;
}

export interface UserCreateWithoutFarmsInput {
  id?: string | null;
  auth0userId?: string | null;
  email: string;
  password?: string | null;
  passwordResetToken?: string | null;
  secondaryEmail?: string | null;
  name: string;
  firstName: string;
  dateOfBirth?: any | null;
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  permissions?: UserCreatepermissionsInput | null;
  farmPermissions?: FarmPermissionCreateManyWithoutUserInput | null;
}

export interface UserCreatepermissionsInput {
  set?: Permission[] | null;
}

export interface UserScalarWhereInput {
  AND?: UserScalarWhereInput[] | null;
  OR?: UserScalarWhereInput[] | null;
  NOT?: UserScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  auth0userId?: string | null;
  auth0userId_not?: string | null;
  auth0userId_in?: string[] | null;
  auth0userId_not_in?: string[] | null;
  auth0userId_lt?: string | null;
  auth0userId_lte?: string | null;
  auth0userId_gt?: string | null;
  auth0userId_gte?: string | null;
  auth0userId_contains?: string | null;
  auth0userId_not_contains?: string | null;
  auth0userId_starts_with?: string | null;
  auth0userId_not_starts_with?: string | null;
  auth0userId_ends_with?: string | null;
  auth0userId_not_ends_with?: string | null;
  email?: string | null;
  email_not?: string | null;
  email_in?: string[] | null;
  email_not_in?: string[] | null;
  email_lt?: string | null;
  email_lte?: string | null;
  email_gt?: string | null;
  email_gte?: string | null;
  email_contains?: string | null;
  email_not_contains?: string | null;
  email_starts_with?: string | null;
  email_not_starts_with?: string | null;
  email_ends_with?: string | null;
  email_not_ends_with?: string | null;
  password?: string | null;
  password_not?: string | null;
  password_in?: string[] | null;
  password_not_in?: string[] | null;
  password_lt?: string | null;
  password_lte?: string | null;
  password_gt?: string | null;
  password_gte?: string | null;
  password_contains?: string | null;
  password_not_contains?: string | null;
  password_starts_with?: string | null;
  password_not_starts_with?: string | null;
  password_ends_with?: string | null;
  password_not_ends_with?: string | null;
  passwordResetToken?: string | null;
  passwordResetToken_not?: string | null;
  passwordResetToken_in?: string[] | null;
  passwordResetToken_not_in?: string[] | null;
  passwordResetToken_lt?: string | null;
  passwordResetToken_lte?: string | null;
  passwordResetToken_gt?: string | null;
  passwordResetToken_gte?: string | null;
  passwordResetToken_contains?: string | null;
  passwordResetToken_not_contains?: string | null;
  passwordResetToken_starts_with?: string | null;
  passwordResetToken_not_starts_with?: string | null;
  passwordResetToken_ends_with?: string | null;
  passwordResetToken_not_ends_with?: string | null;
  secondaryEmail?: string | null;
  secondaryEmail_not?: string | null;
  secondaryEmail_in?: string[] | null;
  secondaryEmail_not_in?: string[] | null;
  secondaryEmail_lt?: string | null;
  secondaryEmail_lte?: string | null;
  secondaryEmail_gt?: string | null;
  secondaryEmail_gte?: string | null;
  secondaryEmail_contains?: string | null;
  secondaryEmail_not_contains?: string | null;
  secondaryEmail_starts_with?: string | null;
  secondaryEmail_not_starts_with?: string | null;
  secondaryEmail_ends_with?: string | null;
  secondaryEmail_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  firstName?: string | null;
  firstName_not?: string | null;
  firstName_in?: string[] | null;
  firstName_not_in?: string[] | null;
  firstName_lt?: string | null;
  firstName_lte?: string | null;
  firstName_gt?: string | null;
  firstName_gte?: string | null;
  firstName_contains?: string | null;
  firstName_not_contains?: string | null;
  firstName_starts_with?: string | null;
  firstName_not_starts_with?: string | null;
  firstName_ends_with?: string | null;
  firstName_not_ends_with?: string | null;
  dateOfBirth?: any | null;
  dateOfBirth_not?: any | null;
  dateOfBirth_in?: any[] | null;
  dateOfBirth_not_in?: any[] | null;
  dateOfBirth_lt?: any | null;
  dateOfBirth_lte?: any | null;
  dateOfBirth_gt?: any | null;
  dateOfBirth_gte?: any | null;
  phone?: string | null;
  phone_not?: string | null;
  phone_in?: string[] | null;
  phone_not_in?: string[] | null;
  phone_lt?: string | null;
  phone_lte?: string | null;
  phone_gt?: string | null;
  phone_gte?: string | null;
  phone_contains?: string | null;
  phone_not_contains?: string | null;
  phone_starts_with?: string | null;
  phone_not_starts_with?: string | null;
  phone_ends_with?: string | null;
  phone_not_ends_with?: string | null;
  street?: string | null;
  street_not?: string | null;
  street_in?: string[] | null;
  street_not_in?: string[] | null;
  street_lt?: string | null;
  street_lte?: string | null;
  street_gt?: string | null;
  street_gte?: string | null;
  street_contains?: string | null;
  street_not_contains?: string | null;
  street_starts_with?: string | null;
  street_not_starts_with?: string | null;
  street_ends_with?: string | null;
  street_not_ends_with?: string | null;
  streetNumber?: string | null;
  streetNumber_not?: string | null;
  streetNumber_in?: string[] | null;
  streetNumber_not_in?: string[] | null;
  streetNumber_lt?: string | null;
  streetNumber_lte?: string | null;
  streetNumber_gt?: string | null;
  streetNumber_gte?: string | null;
  streetNumber_contains?: string | null;
  streetNumber_not_contains?: string | null;
  streetNumber_starts_with?: string | null;
  streetNumber_not_starts_with?: string | null;
  streetNumber_ends_with?: string | null;
  streetNumber_not_ends_with?: string | null;
  city?: string | null;
  city_not?: string | null;
  city_in?: string[] | null;
  city_not_in?: string[] | null;
  city_lt?: string | null;
  city_lte?: string | null;
  city_gt?: string | null;
  city_gte?: string | null;
  city_contains?: string | null;
  city_not_contains?: string | null;
  city_starts_with?: string | null;
  city_not_starts_with?: string | null;
  city_ends_with?: string | null;
  city_not_ends_with?: string | null;
  cityPostalCode?: string | null;
  cityPostalCode_not?: string | null;
  cityPostalCode_in?: string[] | null;
  cityPostalCode_not_in?: string[] | null;
  cityPostalCode_lt?: string | null;
  cityPostalCode_lte?: string | null;
  cityPostalCode_gt?: string | null;
  cityPostalCode_gte?: string | null;
  cityPostalCode_contains?: string | null;
  cityPostalCode_not_contains?: string | null;
  cityPostalCode_starts_with?: string | null;
  cityPostalCode_not_starts_with?: string | null;
  cityPostalCode_ends_with?: string | null;
  cityPostalCode_not_ends_with?: string | null;
}

export interface UserUpdateDataInput {
  auth0userId?: string | null;
  email?: string | null;
  password?: string | null;
  passwordResetToken?: string | null;
  secondaryEmail?: string | null;
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  permissions?: UserUpdatepermissionsInput | null;
  farms?: FarmUpdateManyWithoutUsersInput | null;
  farmPermissions?: FarmPermissionUpdateManyWithoutUserInput | null;
}

export interface UserUpdateManyDataInput {
  auth0userId?: string | null;
  email?: string | null;
  password?: string | null;
  passwordResetToken?: string | null;
  secondaryEmail?: string | null;
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  permissions?: UserUpdatepermissionsInput | null;
}

export interface UserUpdateManyWithWhereNestedInput {
  where: UserScalarWhereInput;
  data: UserUpdateManyDataInput;
}

export interface UserUpdateManyWithoutFarmsInput {
  create?: UserCreateWithoutFarmsInput[] | null;
  connect?: UserWhereUniqueInput[] | null;
  set?: UserWhereUniqueInput[] | null;
  disconnect?: UserWhereUniqueInput[] | null;
  delete?: UserWhereUniqueInput[] | null;
  update?: UserUpdateWithWhereUniqueWithoutFarmsInput[] | null;
  updateMany?: UserUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: UserScalarWhereInput[] | null;
  upsert?: UserUpsertWithWhereUniqueWithoutFarmsInput[] | null;
}

export interface UserUpdateOneInput {
  create?: UserCreateInput | null;
  connect?: UserWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: UserUpdateDataInput | null;
  upsert?: UserUpsertNestedInput | null;
}

export interface UserUpdateOneRequiredWithoutFarmPermissionsInput {
  create?: UserCreateWithoutFarmPermissionsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutFarmPermissionsDataInput | null;
  upsert?: UserUpsertWithoutFarmPermissionsInput | null;
}

export interface UserUpdateWithWhereUniqueWithoutFarmsInput {
  where: UserWhereUniqueInput;
  data: UserUpdateWithoutFarmsDataInput;
}

export interface UserUpdateWithoutFarmPermissionsDataInput {
  auth0userId?: string | null;
  email?: string | null;
  password?: string | null;
  passwordResetToken?: string | null;
  secondaryEmail?: string | null;
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  permissions?: UserUpdatepermissionsInput | null;
  farms?: FarmUpdateManyWithoutUsersInput | null;
}

export interface UserUpdateWithoutFarmsDataInput {
  auth0userId?: string | null;
  email?: string | null;
  password?: string | null;
  passwordResetToken?: string | null;
  secondaryEmail?: string | null;
  name?: string | null;
  firstName?: string | null;
  dateOfBirth?: any | null;
  phone?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  cityPostalCode?: string | null;
  permissions?: UserUpdatepermissionsInput | null;
  farmPermissions?: FarmPermissionUpdateManyWithoutUserInput | null;
}

export interface UserUpdatepermissionsInput {
  set?: Permission[] | null;
}

export interface UserUpsertNestedInput {
  update: UserUpdateDataInput;
  create: UserCreateInput;
}

export interface UserUpsertWithWhereUniqueWithoutFarmsInput {
  where: UserWhereUniqueInput;
  update: UserUpdateWithoutFarmsDataInput;
  create: UserCreateWithoutFarmsInput;
}

export interface UserUpsertWithoutFarmPermissionsInput {
  update: UserUpdateWithoutFarmPermissionsDataInput;
  create: UserCreateWithoutFarmPermissionsInput;
}

export interface UserWhereInput {
  AND?: UserWhereInput[] | null;
  OR?: UserWhereInput[] | null;
  NOT?: UserWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  auth0userId?: string | null;
  auth0userId_not?: string | null;
  auth0userId_in?: string[] | null;
  auth0userId_not_in?: string[] | null;
  auth0userId_lt?: string | null;
  auth0userId_lte?: string | null;
  auth0userId_gt?: string | null;
  auth0userId_gte?: string | null;
  auth0userId_contains?: string | null;
  auth0userId_not_contains?: string | null;
  auth0userId_starts_with?: string | null;
  auth0userId_not_starts_with?: string | null;
  auth0userId_ends_with?: string | null;
  auth0userId_not_ends_with?: string | null;
  email?: string | null;
  email_not?: string | null;
  email_in?: string[] | null;
  email_not_in?: string[] | null;
  email_lt?: string | null;
  email_lte?: string | null;
  email_gt?: string | null;
  email_gte?: string | null;
  email_contains?: string | null;
  email_not_contains?: string | null;
  email_starts_with?: string | null;
  email_not_starts_with?: string | null;
  email_ends_with?: string | null;
  email_not_ends_with?: string | null;
  password?: string | null;
  password_not?: string | null;
  password_in?: string[] | null;
  password_not_in?: string[] | null;
  password_lt?: string | null;
  password_lte?: string | null;
  password_gt?: string | null;
  password_gte?: string | null;
  password_contains?: string | null;
  password_not_contains?: string | null;
  password_starts_with?: string | null;
  password_not_starts_with?: string | null;
  password_ends_with?: string | null;
  password_not_ends_with?: string | null;
  passwordResetToken?: string | null;
  passwordResetToken_not?: string | null;
  passwordResetToken_in?: string[] | null;
  passwordResetToken_not_in?: string[] | null;
  passwordResetToken_lt?: string | null;
  passwordResetToken_lte?: string | null;
  passwordResetToken_gt?: string | null;
  passwordResetToken_gte?: string | null;
  passwordResetToken_contains?: string | null;
  passwordResetToken_not_contains?: string | null;
  passwordResetToken_starts_with?: string | null;
  passwordResetToken_not_starts_with?: string | null;
  passwordResetToken_ends_with?: string | null;
  passwordResetToken_not_ends_with?: string | null;
  secondaryEmail?: string | null;
  secondaryEmail_not?: string | null;
  secondaryEmail_in?: string[] | null;
  secondaryEmail_not_in?: string[] | null;
  secondaryEmail_lt?: string | null;
  secondaryEmail_lte?: string | null;
  secondaryEmail_gt?: string | null;
  secondaryEmail_gte?: string | null;
  secondaryEmail_contains?: string | null;
  secondaryEmail_not_contains?: string | null;
  secondaryEmail_starts_with?: string | null;
  secondaryEmail_not_starts_with?: string | null;
  secondaryEmail_ends_with?: string | null;
  secondaryEmail_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  firstName?: string | null;
  firstName_not?: string | null;
  firstName_in?: string[] | null;
  firstName_not_in?: string[] | null;
  firstName_lt?: string | null;
  firstName_lte?: string | null;
  firstName_gt?: string | null;
  firstName_gte?: string | null;
  firstName_contains?: string | null;
  firstName_not_contains?: string | null;
  firstName_starts_with?: string | null;
  firstName_not_starts_with?: string | null;
  firstName_ends_with?: string | null;
  firstName_not_ends_with?: string | null;
  dateOfBirth?: any | null;
  dateOfBirth_not?: any | null;
  dateOfBirth_in?: any[] | null;
  dateOfBirth_not_in?: any[] | null;
  dateOfBirth_lt?: any | null;
  dateOfBirth_lte?: any | null;
  dateOfBirth_gt?: any | null;
  dateOfBirth_gte?: any | null;
  phone?: string | null;
  phone_not?: string | null;
  phone_in?: string[] | null;
  phone_not_in?: string[] | null;
  phone_lt?: string | null;
  phone_lte?: string | null;
  phone_gt?: string | null;
  phone_gte?: string | null;
  phone_contains?: string | null;
  phone_not_contains?: string | null;
  phone_starts_with?: string | null;
  phone_not_starts_with?: string | null;
  phone_ends_with?: string | null;
  phone_not_ends_with?: string | null;
  street?: string | null;
  street_not?: string | null;
  street_in?: string[] | null;
  street_not_in?: string[] | null;
  street_lt?: string | null;
  street_lte?: string | null;
  street_gt?: string | null;
  street_gte?: string | null;
  street_contains?: string | null;
  street_not_contains?: string | null;
  street_starts_with?: string | null;
  street_not_starts_with?: string | null;
  street_ends_with?: string | null;
  street_not_ends_with?: string | null;
  streetNumber?: string | null;
  streetNumber_not?: string | null;
  streetNumber_in?: string[] | null;
  streetNumber_not_in?: string[] | null;
  streetNumber_lt?: string | null;
  streetNumber_lte?: string | null;
  streetNumber_gt?: string | null;
  streetNumber_gte?: string | null;
  streetNumber_contains?: string | null;
  streetNumber_not_contains?: string | null;
  streetNumber_starts_with?: string | null;
  streetNumber_not_starts_with?: string | null;
  streetNumber_ends_with?: string | null;
  streetNumber_not_ends_with?: string | null;
  city?: string | null;
  city_not?: string | null;
  city_in?: string[] | null;
  city_not_in?: string[] | null;
  city_lt?: string | null;
  city_lte?: string | null;
  city_gt?: string | null;
  city_gte?: string | null;
  city_contains?: string | null;
  city_not_contains?: string | null;
  city_starts_with?: string | null;
  city_not_starts_with?: string | null;
  city_ends_with?: string | null;
  city_not_ends_with?: string | null;
  cityPostalCode?: string | null;
  cityPostalCode_not?: string | null;
  cityPostalCode_in?: string[] | null;
  cityPostalCode_not_in?: string[] | null;
  cityPostalCode_lt?: string | null;
  cityPostalCode_lte?: string | null;
  cityPostalCode_gt?: string | null;
  cityPostalCode_gte?: string | null;
  cityPostalCode_contains?: string | null;
  cityPostalCode_not_contains?: string | null;
  cityPostalCode_starts_with?: string | null;
  cityPostalCode_not_starts_with?: string | null;
  cityPostalCode_ends_with?: string | null;
  cityPostalCode_not_ends_with?: string | null;
  farms_every?: FarmWhereInput | null;
  farms_some?: FarmWhereInput | null;
  farms_none?: FarmWhereInput | null;
  farmPermissions_every?: FarmPermissionWhereInput | null;
  farmPermissions_some?: FarmPermissionWhereInput | null;
  farmPermissions_none?: FarmPermissionWhereInput | null;
}

export interface UserWhereUniqueInput {
  id?: string | null;
  email?: string | null;
}

export interface VarietyCreateInput {
  id?: string | null;
  name: string;
  code?: string | null;
  alternativeCropName?: string | null;
  pricePerPlant?: number | null;
  type?: PlantOrSeed | null;
  orderWeekFrom?: number | null;
  orderWeekUntil?: number | null;
  soilBlockType?: string | null;
  gramsPerRowMeter?: number | null;
  supplier?: SupplierCreateOneWithoutVarietiesInput | null;
  crop: CropCreateOneWithoutVarietiesInput;
  cropTimings?: CropTimingCreateManyWithoutVarietiesInput | null;
  cultivations?: CultivationCreateManyWithoutVarietyInput | null;
}

export interface VarietyCreateManyWithoutCropInput {
  create?: VarietyCreateWithoutCropInput[] | null;
  connect?: VarietyWhereUniqueInput[] | null;
}

export interface VarietyCreateManyWithoutCropTimingsInput {
  create?: VarietyCreateWithoutCropTimingsInput[] | null;
  connect?: VarietyWhereUniqueInput[] | null;
}

export interface VarietyCreateOneInput {
  create?: VarietyCreateInput | null;
  connect?: VarietyWhereUniqueInput | null;
}

export interface VarietyCreateOneWithoutCultivationsInput {
  create?: VarietyCreateWithoutCultivationsInput | null;
  connect?: VarietyWhereUniqueInput | null;
}

export interface VarietyCreateWithoutCropInput {
  id?: string | null;
  name: string;
  code?: string | null;
  alternativeCropName?: string | null;
  pricePerPlant?: number | null;
  type?: PlantOrSeed | null;
  orderWeekFrom?: number | null;
  orderWeekUntil?: number | null;
  soilBlockType?: string | null;
  gramsPerRowMeter?: number | null;
  supplier?: SupplierCreateOneWithoutVarietiesInput | null;
  cropTimings?: CropTimingCreateManyWithoutVarietiesInput | null;
  cultivations?: CultivationCreateManyWithoutVarietyInput | null;
}

export interface VarietyCreateWithoutCropTimingsInput {
  id?: string | null;
  name: string;
  code?: string | null;
  alternativeCropName?: string | null;
  pricePerPlant?: number | null;
  type?: PlantOrSeed | null;
  orderWeekFrom?: number | null;
  orderWeekUntil?: number | null;
  soilBlockType?: string | null;
  gramsPerRowMeter?: number | null;
  supplier?: SupplierCreateOneWithoutVarietiesInput | null;
  crop: CropCreateOneWithoutVarietiesInput;
  cultivations?: CultivationCreateManyWithoutVarietyInput | null;
}

export interface VarietyCreateWithoutCultivationsInput {
  id?: string | null;
  name: string;
  code?: string | null;
  alternativeCropName?: string | null;
  pricePerPlant?: number | null;
  type?: PlantOrSeed | null;
  orderWeekFrom?: number | null;
  orderWeekUntil?: number | null;
  soilBlockType?: string | null;
  gramsPerRowMeter?: number | null;
  supplier?: SupplierCreateOneWithoutVarietiesInput | null;
  crop: CropCreateOneWithoutVarietiesInput;
  cropTimings?: CropTimingCreateManyWithoutVarietiesInput | null;
}

export interface VarietyScalarWhereInput {
  AND?: VarietyScalarWhereInput[] | null;
  OR?: VarietyScalarWhereInput[] | null;
  NOT?: VarietyScalarWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  code?: string | null;
  code_not?: string | null;
  code_in?: string[] | null;
  code_not_in?: string[] | null;
  code_lt?: string | null;
  code_lte?: string | null;
  code_gt?: string | null;
  code_gte?: string | null;
  code_contains?: string | null;
  code_not_contains?: string | null;
  code_starts_with?: string | null;
  code_not_starts_with?: string | null;
  code_ends_with?: string | null;
  code_not_ends_with?: string | null;
  alternativeCropName?: string | null;
  alternativeCropName_not?: string | null;
  alternativeCropName_in?: string[] | null;
  alternativeCropName_not_in?: string[] | null;
  alternativeCropName_lt?: string | null;
  alternativeCropName_lte?: string | null;
  alternativeCropName_gt?: string | null;
  alternativeCropName_gte?: string | null;
  alternativeCropName_contains?: string | null;
  alternativeCropName_not_contains?: string | null;
  alternativeCropName_starts_with?: string | null;
  alternativeCropName_not_starts_with?: string | null;
  alternativeCropName_ends_with?: string | null;
  alternativeCropName_not_ends_with?: string | null;
  pricePerPlant?: number | null;
  pricePerPlant_not?: number | null;
  pricePerPlant_in?: number[] | null;
  pricePerPlant_not_in?: number[] | null;
  pricePerPlant_lt?: number | null;
  pricePerPlant_lte?: number | null;
  pricePerPlant_gt?: number | null;
  pricePerPlant_gte?: number | null;
  type?: PlantOrSeed | null;
  type_not?: PlantOrSeed | null;
  type_in?: PlantOrSeed[] | null;
  type_not_in?: PlantOrSeed[] | null;
  orderWeekFrom?: number | null;
  orderWeekFrom_not?: number | null;
  orderWeekFrom_in?: number[] | null;
  orderWeekFrom_not_in?: number[] | null;
  orderWeekFrom_lt?: number | null;
  orderWeekFrom_lte?: number | null;
  orderWeekFrom_gt?: number | null;
  orderWeekFrom_gte?: number | null;
  orderWeekUntil?: number | null;
  orderWeekUntil_not?: number | null;
  orderWeekUntil_in?: number[] | null;
  orderWeekUntil_not_in?: number[] | null;
  orderWeekUntil_lt?: number | null;
  orderWeekUntil_lte?: number | null;
  orderWeekUntil_gt?: number | null;
  orderWeekUntil_gte?: number | null;
  soilBlockType?: string | null;
  soilBlockType_not?: string | null;
  soilBlockType_in?: string[] | null;
  soilBlockType_not_in?: string[] | null;
  soilBlockType_lt?: string | null;
  soilBlockType_lte?: string | null;
  soilBlockType_gt?: string | null;
  soilBlockType_gte?: string | null;
  soilBlockType_contains?: string | null;
  soilBlockType_not_contains?: string | null;
  soilBlockType_starts_with?: string | null;
  soilBlockType_not_starts_with?: string | null;
  soilBlockType_ends_with?: string | null;
  soilBlockType_not_ends_with?: string | null;
  gramsPerRowMeter?: number | null;
  gramsPerRowMeter_not?: number | null;
  gramsPerRowMeter_in?: number[] | null;
  gramsPerRowMeter_not_in?: number[] | null;
  gramsPerRowMeter_lt?: number | null;
  gramsPerRowMeter_lte?: number | null;
  gramsPerRowMeter_gt?: number | null;
  gramsPerRowMeter_gte?: number | null;
}

export interface VarietyUpdateDataInput {
  name?: string | null;
  code?: string | null;
  alternativeCropName?: string | null;
  pricePerPlant?: number | null;
  type?: PlantOrSeed | null;
  orderWeekFrom?: number | null;
  orderWeekUntil?: number | null;
  soilBlockType?: string | null;
  gramsPerRowMeter?: number | null;
  supplier?: SupplierUpdateOneWithoutVarietiesInput | null;
  crop?: CropUpdateOneRequiredWithoutVarietiesInput | null;
  cropTimings?: CropTimingUpdateManyWithoutVarietiesInput | null;
  cultivations?: CultivationUpdateManyWithoutVarietyInput | null;
}

export interface VarietyUpdateManyDataInput {
  name?: string | null;
  code?: string | null;
  alternativeCropName?: string | null;
  pricePerPlant?: number | null;
  type?: PlantOrSeed | null;
  orderWeekFrom?: number | null;
  orderWeekUntil?: number | null;
  soilBlockType?: string | null;
  gramsPerRowMeter?: number | null;
}

export interface VarietyUpdateManyWithWhereNestedInput {
  where: VarietyScalarWhereInput;
  data: VarietyUpdateManyDataInput;
}

export interface VarietyUpdateManyWithoutCropInput {
  create?: VarietyCreateWithoutCropInput[] | null;
  connect?: VarietyWhereUniqueInput[] | null;
  set?: VarietyWhereUniqueInput[] | null;
  disconnect?: VarietyWhereUniqueInput[] | null;
  delete?: VarietyWhereUniqueInput[] | null;
  update?: VarietyUpdateWithWhereUniqueWithoutCropInput[] | null;
  updateMany?: VarietyUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: VarietyScalarWhereInput[] | null;
  upsert?: VarietyUpsertWithWhereUniqueWithoutCropInput[] | null;
}

export interface VarietyUpdateManyWithoutCropTimingsInput {
  create?: VarietyCreateWithoutCropTimingsInput[] | null;
  connect?: VarietyWhereUniqueInput[] | null;
  set?: VarietyWhereUniqueInput[] | null;
  disconnect?: VarietyWhereUniqueInput[] | null;
  delete?: VarietyWhereUniqueInput[] | null;
  update?: VarietyUpdateWithWhereUniqueWithoutCropTimingsInput[] | null;
  updateMany?: VarietyUpdateManyWithWhereNestedInput[] | null;
  deleteMany?: VarietyScalarWhereInput[] | null;
  upsert?: VarietyUpsertWithWhereUniqueWithoutCropTimingsInput[] | null;
}

export interface VarietyUpdateOneRequiredInput {
  create?: VarietyCreateInput | null;
  connect?: VarietyWhereUniqueInput | null;
  update?: VarietyUpdateDataInput | null;
  upsert?: VarietyUpsertNestedInput | null;
}

export interface VarietyUpdateOneWithoutCultivationsInput {
  create?: VarietyCreateWithoutCultivationsInput | null;
  connect?: VarietyWhereUniqueInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  update?: VarietyUpdateWithoutCultivationsDataInput | null;
  upsert?: VarietyUpsertWithoutCultivationsInput | null;
}

export interface VarietyUpdateWithWhereUniqueWithoutCropInput {
  where: VarietyWhereUniqueInput;
  data: VarietyUpdateWithoutCropDataInput;
}

export interface VarietyUpdateWithWhereUniqueWithoutCropTimingsInput {
  where: VarietyWhereUniqueInput;
  data: VarietyUpdateWithoutCropTimingsDataInput;
}

export interface VarietyUpdateWithoutCropDataInput {
  name?: string | null;
  code?: string | null;
  alternativeCropName?: string | null;
  pricePerPlant?: number | null;
  type?: PlantOrSeed | null;
  orderWeekFrom?: number | null;
  orderWeekUntil?: number | null;
  soilBlockType?: string | null;
  gramsPerRowMeter?: number | null;
  supplier?: SupplierUpdateOneWithoutVarietiesInput | null;
  cropTimings?: CropTimingUpdateManyWithoutVarietiesInput | null;
  cultivations?: CultivationUpdateManyWithoutVarietyInput | null;
}

export interface VarietyUpdateWithoutCropTimingsDataInput {
  name?: string | null;
  code?: string | null;
  alternativeCropName?: string | null;
  pricePerPlant?: number | null;
  type?: PlantOrSeed | null;
  orderWeekFrom?: number | null;
  orderWeekUntil?: number | null;
  soilBlockType?: string | null;
  gramsPerRowMeter?: number | null;
  supplier?: SupplierUpdateOneWithoutVarietiesInput | null;
  crop?: CropUpdateOneRequiredWithoutVarietiesInput | null;
  cultivations?: CultivationUpdateManyWithoutVarietyInput | null;
}

export interface VarietyUpdateWithoutCultivationsDataInput {
  name?: string | null;
  code?: string | null;
  alternativeCropName?: string | null;
  pricePerPlant?: number | null;
  type?: PlantOrSeed | null;
  orderWeekFrom?: number | null;
  orderWeekUntil?: number | null;
  soilBlockType?: string | null;
  gramsPerRowMeter?: number | null;
  supplier?: SupplierUpdateOneWithoutVarietiesInput | null;
  crop?: CropUpdateOneRequiredWithoutVarietiesInput | null;
  cropTimings?: CropTimingUpdateManyWithoutVarietiesInput | null;
}

export interface VarietyUpsertNestedInput {
  update: VarietyUpdateDataInput;
  create: VarietyCreateInput;
}

export interface VarietyUpsertWithWhereUniqueWithoutCropInput {
  where: VarietyWhereUniqueInput;
  update: VarietyUpdateWithoutCropDataInput;
  create: VarietyCreateWithoutCropInput;
}

export interface VarietyUpsertWithWhereUniqueWithoutCropTimingsInput {
  where: VarietyWhereUniqueInput;
  update: VarietyUpdateWithoutCropTimingsDataInput;
  create: VarietyCreateWithoutCropTimingsInput;
}

export interface VarietyUpsertWithoutCultivationsInput {
  update: VarietyUpdateWithoutCultivationsDataInput;
  create: VarietyCreateWithoutCultivationsInput;
}

export interface VarietyWhereInput {
  AND?: VarietyWhereInput[] | null;
  OR?: VarietyWhereInput[] | null;
  NOT?: VarietyWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  code?: string | null;
  code_not?: string | null;
  code_in?: string[] | null;
  code_not_in?: string[] | null;
  code_lt?: string | null;
  code_lte?: string | null;
  code_gt?: string | null;
  code_gte?: string | null;
  code_contains?: string | null;
  code_not_contains?: string | null;
  code_starts_with?: string | null;
  code_not_starts_with?: string | null;
  code_ends_with?: string | null;
  code_not_ends_with?: string | null;
  alternativeCropName?: string | null;
  alternativeCropName_not?: string | null;
  alternativeCropName_in?: string[] | null;
  alternativeCropName_not_in?: string[] | null;
  alternativeCropName_lt?: string | null;
  alternativeCropName_lte?: string | null;
  alternativeCropName_gt?: string | null;
  alternativeCropName_gte?: string | null;
  alternativeCropName_contains?: string | null;
  alternativeCropName_not_contains?: string | null;
  alternativeCropName_starts_with?: string | null;
  alternativeCropName_not_starts_with?: string | null;
  alternativeCropName_ends_with?: string | null;
  alternativeCropName_not_ends_with?: string | null;
  pricePerPlant?: number | null;
  pricePerPlant_not?: number | null;
  pricePerPlant_in?: number[] | null;
  pricePerPlant_not_in?: number[] | null;
  pricePerPlant_lt?: number | null;
  pricePerPlant_lte?: number | null;
  pricePerPlant_gt?: number | null;
  pricePerPlant_gte?: number | null;
  type?: PlantOrSeed | null;
  type_not?: PlantOrSeed | null;
  type_in?: PlantOrSeed[] | null;
  type_not_in?: PlantOrSeed[] | null;
  orderWeekFrom?: number | null;
  orderWeekFrom_not?: number | null;
  orderWeekFrom_in?: number[] | null;
  orderWeekFrom_not_in?: number[] | null;
  orderWeekFrom_lt?: number | null;
  orderWeekFrom_lte?: number | null;
  orderWeekFrom_gt?: number | null;
  orderWeekFrom_gte?: number | null;
  orderWeekUntil?: number | null;
  orderWeekUntil_not?: number | null;
  orderWeekUntil_in?: number[] | null;
  orderWeekUntil_not_in?: number[] | null;
  orderWeekUntil_lt?: number | null;
  orderWeekUntil_lte?: number | null;
  orderWeekUntil_gt?: number | null;
  orderWeekUntil_gte?: number | null;
  soilBlockType?: string | null;
  soilBlockType_not?: string | null;
  soilBlockType_in?: string[] | null;
  soilBlockType_not_in?: string[] | null;
  soilBlockType_lt?: string | null;
  soilBlockType_lte?: string | null;
  soilBlockType_gt?: string | null;
  soilBlockType_gte?: string | null;
  soilBlockType_contains?: string | null;
  soilBlockType_not_contains?: string | null;
  soilBlockType_starts_with?: string | null;
  soilBlockType_not_starts_with?: string | null;
  soilBlockType_ends_with?: string | null;
  soilBlockType_not_ends_with?: string | null;
  gramsPerRowMeter?: number | null;
  gramsPerRowMeter_not?: number | null;
  gramsPerRowMeter_in?: number[] | null;
  gramsPerRowMeter_not_in?: number[] | null;
  gramsPerRowMeter_lt?: number | null;
  gramsPerRowMeter_lte?: number | null;
  gramsPerRowMeter_gt?: number | null;
  gramsPerRowMeter_gte?: number | null;
  supplier?: SupplierWhereInput | null;
  crop?: CropWhereInput | null;
  cropTimings_every?: CropTimingWhereInput | null;
  cropTimings_some?: CropTimingWhereInput | null;
  cropTimings_none?: CropTimingWhereInput | null;
  cultivations_every?: CultivationWhereInput | null;
  cultivations_some?: CultivationWhereInput | null;
  cultivations_none?: CultivationWhereInput | null;
}

export interface VarietyWhereUniqueInput {
  id?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
