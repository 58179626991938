import React, {useState} from 'react';
import {
  DeliveriesForDeliveryWeek_clientOrders,
  DeliveriesForDeliveryWeek_productOrderDeliveries,
  DeliveriesForDeliveryWeek_shopOrders,
  DeliveryLocation,
  ShopOrderStatus
} from "../../../../__generated__/types";
import {DeliveryLocationDetail} from "../../../../shared/utils/deliveryLocation.utils";
import {
  filterDeliveredDeliveries,
  filterDeliveriesByClient,
  filterDeliveriesByDeliveryLocation, isSelfHarvestProductOrder
} from "../../../../utils/productOrder.utils";
import classNames from "classnames";
import {chain, compact, divide, map, orderBy, uniqBy} from 'lodash';
import {
  deliveriesAreDelivered, findBasketProductOrder,
  productOrdersAreDelivered
} from "../../../../shared/utils/productOrder.utils";
import {clientName} from "../../../../shared/utils/client.utils";

interface DeliveryLocationButtonProps {
  expanded: boolean;
  deliveryLocation: DeliveryLocationDetail | null;
  deliveries: DeliveriesForDeliveryWeek_productOrderDeliveries[];
  otherClientOrders: DeliveriesForDeliveryWeek_clientOrders[];
  shopOrders: DeliveriesForDeliveryWeek_shopOrders[];
  onLocationChange: (deliveryLocation: DeliveryLocation | null | undefined) => void;
  onClientChange: (clientId: string) => void;
}

const nrOfFullyDeliveredClientOrders = (clientOrders: DeliveriesForDeliveryWeek_clientOrders[]) => clientOrders
  .filter(clientOrder => compact(clientOrder.productOrders).findIndex(productOrder => productOrder.deliveryDate === null) === -1)
  .length;

const nrOfFullyDeliveredClients = (clientOrders: DeliveriesForDeliveryWeek_clientOrders[]) => chain(clientOrders)
  .groupBy("client.id")
  .mapValues((clientOrders: DeliveriesForDeliveryWeek_clientOrders[]) =>
    clientOrders
      .filter(clientOrder => productOrdersAreDelivered(compact(clientOrder.productOrders))).length === clientOrders.length
  )
  .values()
  .filter(v => v)
  .value().length;

const nrOfFullyDeliveredShopOrders = (shopOrders: DeliveriesForDeliveryWeek_shopOrders[]) =>
  shopOrders.filter(clientOrder => clientOrder.status === ShopOrderStatus.DELIVERED).length;

const DeliveryLocationButton = (props: DeliveryLocationButtonProps) => {
  let deliveries = filterDeliveriesByDeliveryLocation(props.deliveries, props.deliveryLocation?.value || null);
  let shopOrders = props.shopOrders.filter(shopOrder => shopOrder.deliveryLocation === props.deliveryLocation?.value)
  deliveries = deliveries.filter(delivery => !isSelfHarvestProductOrder(delivery.productOrder));
  const deliveredDeliveries = filterDeliveredDeliveries(deliveries)

  const [clientId, setClientId] = useState<string>();

  let otherClientOrdersForDeliveryLocation = props.deliveryLocation === null ? props.otherClientOrders : [];

  let fullyDeliveredClients =
    chain(deliveries)
      .groupBy("productOrder.clientOrder.client.id")
      .mapValues((deliveries: DeliveriesForDeliveryWeek_productOrderDeliveries[]) => deliveriesAreDelivered(deliveries))
      .values()
      .filter(v => v)
      .value().length
    + nrOfFullyDeliveredClients(otherClientOrdersForDeliveryLocation)
    + nrOfFullyDeliveredShopOrders(shopOrders);

  let clientsFromDeliveries = map(deliveries, "productOrder.clientOrder.client");
  let clientsFromOtherOrders = map(otherClientOrdersForDeliveryLocation, "client");
  let clients = uniqBy([
    ...clientsFromDeliveries,
    ...clientsFromOtherOrders,
    ...shopOrders.map(shopOrder => shopOrder.shopClient),
  ], "id");

  return <div
    className={classNames(
      "p-1 mr-1 border-round-lg border-1 border-green cursor-pointer",
      {"bg-green-100 border-green-500 border-2 text-green-800 font-bold": fullyDeliveredClients === clients.length},
    )}
    onClick={() => {
      props.onLocationChange(props.expanded ? null : props.deliveryLocation?.value);
    }}>
    <div
      className="pt-1 pb-2 flex align-items-center justify-content-start font-medium text-xl"
    >
      <i className={classNames("pi pi-check-circle text-300 text-2xl",
        {"text-green-500": deliveredDeliveries.length === deliveries.length}
      )}/>
      <div className="px-1 flex-grow-1">{props.deliveryLocation ? props.deliveryLocation.city : 'Schuur'}</div>
      <div>{fullyDeliveredClients}/{clients.length}</div>
    </div>

    {props.expanded && <div>
      {orderBy(clients, (client) => clientName(client).toLowerCase(), "asc").map(client => {
        let clientDeliveries = filterDeliveriesByClient(deliveries, client.id);
        let clientOrdersForClient = props.otherClientOrders.filter(clientOrder => clientOrder.client.id === client.id);
        let shopOrdersForClient = shopOrders.filter(shopOrder => shopOrder.shopClient.id === client.id);

        let completedOrders = filterDeliveredDeliveries(clientDeliveries).length
          + nrOfFullyDeliveredClientOrders(clientOrdersForClient)
          + nrOfFullyDeliveredShopOrders(shopOrdersForClient);

        let allOrders = clientOrdersForClient.length
          + clientDeliveries.length
          + shopOrdersForClient.length;

        let productOrders = map(clientDeliveries, 'productOrder');
        let basketProductOrder = findBasketProductOrder(productOrders);

        return <div
          key={`delivery_${client.id}`}
          className={classNames(
            "text-lg p-1 cursor-pointer flex justify-content-between align-items-center my-1",
            {"font-bold border-800 border-2": clientId === client.id},
            {"font-bold text-green-800 border-green-800 border-2": clientId === client.id && completedOrders === allOrders},
            {"bg-green-100 border-1 border-green-300 border-round": completedOrders === allOrders}
          )}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setClientId(client.id);
            props.onClientChange(client.id);
          }}
        >
          <div><i className={classNames(
            "pi pi-check-circle text-500",
            {"text-green-500": completedOrders === allOrders},
            //{"text-green-300": completedOrders !== allOrders}
          )}></i></div>
          <div className="flex-grow-1 pl-1">{client.firstName} {client.name}</div>
          <div>
              {basketProductOrder && !client.communicationGoudenWortel && <span className="text-xs text-gray-400 pr-2">GW</span>}
              {basketProductOrder && 'P'}
          </div>
          <div>{!basketProductOrder && clientDeliveries.length > 0 && "Z"}</div>
          <div>{shopOrdersForClient.length > 0 && "V"}</div>
        </div>
      })}
    </div>}
  </div>;
};

export default DeliveryLocationButton;
